@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.button {
	display: inline-block;
	height: 48px;
	padding: 0 20px;
	background-color: @white3;
	border-radius: 8px;
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;

	@media (min-width: @large-tablet-min) {
		height: 40px;
	}

	&_common {
		background-color: @buttonLight;
		color: @black75;

		button& {
			background-color: @buttonLight;
		}

		.desktop &:hover {
			background-color: @buttonLightHover;
		}

		&:active {
			background-color: @buttonLightPressed !important;
		}
	}

	&_common-bordered {
		height: auto;
		padding: 7px 11px;
		background-color: @buttonLight;
		border: 1px solid @black10;
		color: @black60;


		button& {
			background-color: @button;
			border: 1px solid @black05;
		}

		.desktop &:hover {
			background-color: @buttonHover;
		}

		&:active {
			background-color: @buttonPressed !important;
		}
	}

	&_common-bordered &__label {
		text-transform: lowercase;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&_accent-blue {
		background-color: @buttonAccent;
		color: @white;

		button& {
			background-color: @buttonAccent;
		}

		.desktop &:hover {
			background-color: @buttonAccentHover;
		}

		&:active {
			background-color: @buttonAccentPressed !important;
		}
	}

	&_accent-red {
		background-color: @buttonRed;
		color: @white;

		button& {
			background-color: @buttonRed;
		}

		.desktop &:hover {
			background-color: @buttonRedHover;
		}

		&:active {
			background-color: @buttonRedPressed !important;
		}
	}

	&_cancel {
		background-color: transparent;
		color: @primaryRed;

		.desktop &:hover {
			background-color: @buttonHover;
		}

		&:active {
			background-color: @buttonPressed !important;
		}
	}

	&_disabled {
		background-color: @black05;
		color: @black20;

		button& {
			background-color: @black05;
		}
	}

	&_secondary {
		width: 100%;
		background-color: @blue10;
		color: @deepBlue;

		.button__label {
			.d-body-bold-14();
		}

		i {
			color: @buttonAccent;
		}

		&:hover {
			background-color: @blue20;
		}

		&:active {
			background-color: @blue30;
		}

		&-disabled {
			width: 100%;
			background-color: @grey10;
			color: @black35;

			i {
				color: @grey30;
			}
		}
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__icon {
		display: block;

		&_left {
			margin-right: 12px;
		}

		&_right {
			margin-left: 12px;
		}

		i {
			display: block;
		}
	}

	&__label {
		max-width: 100%;
		.d-semibold-14();
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
