@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	overflow: hidden;
}

.label {
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 20px;
	color: @black75;
	font-weight: 600;
}

.icon {
	color: @greyLight;
	transition: transform 0.2s ease;
}

.iconOpen {
	transform: rotate(180deg);
}