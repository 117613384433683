@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.adding-performer-dialog {
	@media (min-width: @large-tablet-min) {
		width: 704px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	@media (min-width: @large-tablet-min) and (min-height: 800px) {
		height: 564px;
	}

	&__title {
		display: flex;
		align-items: center;
	}


	&__body {
		display: flex;
		flex-direction: column;
	}


	&__performer-select {
		flex: 1 1 calc(100% - 65px);
		height: calc(100vh - 65px);
		margin-top: 16px;
		min-height: 100px;

		@media (min-width: @large-tablet-min) and (min-height: 800px) {
			max-height: 275px;
			min-height: 275px;
		}

		@media (max-height: 799px) {
			max-height: none;
		}
	}
}