@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.table-head-selection-row {
	display: flex;
	align-items: center;
	position: absolute;
	left: 58px;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	padding: 0 18px;
	background-color: @white;

	& > *:not(:last-child) {
		margin-right: 16px;
	}
}