@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.user-info {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 16px;
	align-items: center;

	&__avatar.user-avatar {}

	&__name-block {
		white-space: nowrap;
		overflow: hidden;
	}

	&__info-block {
		display: flex;
		column-gap: 12px;

		@media (max-width: @tablet-max) {
			flex-direction: column;
		}
	}

	&__name {
		color: @black100;
		overflow-x: hidden;
		text-overflow: ellipsis;

		@media (max-width: @tablet-max) {
			.d-semibold-14();
		}

		@media (min-width: @large-tablet-min) {
			.d-semibold-14();
			margin-bottom: -1px;
		}
	}

	&__position {
		color: @black75;
		overflow-x: hidden;
		text-overflow: ellipsis;

		@media (max-width: @tablet-max) {
			.d-regular-12();
		}

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}

	&__company {
		color: @black60;
		overflow-x: hidden;
		text-overflow: ellipsis;


		@media (max-width: @tablet-max) {
			.d-regular-12();
		}

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}
}