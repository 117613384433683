@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.notifications-button {
	.button& {
		padding: 4px;
		background-color: transparent;
		border: none;
	}

	.button__wrap&__wrap {
		justify-content: flex-start;
	}

	.button__icon&__icon {
		color: @black95;
	}

	.button__icon_left&__icon {
		margin-right: 0;
	}

	.button__label&__label {
		margin-left: 16px;
		color: @black85;
	}
}
