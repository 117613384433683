@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrapper {
	overflow-x: auto;
}

.table {
	width: 100%;
	border-collapse: collapse;
}

.headerRow {
	border-bottom: 1px solid @black10;
}

.headerCell {
	padding: 0 0 0 20px;
	height: 60px;
	font-size: 14px;
	font-weight: 400;
	color: @black60;
	text-align: start;
}

.row {
	border-collapse: collapse;
	border-spacing: 0;
	border-top: 1px solid @black10;
}


.cell {
	padding: 0 0 0 20px;
	height: 60px;
}