@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/variables";

.vertical-tab-content {
	background: @white;
	border-radius: 2.4em;
	padding: 3.2em;

	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&:not(:last-child) {
		margin-bottom: 2.4em;
	}
}