@import (reference) '../../../../../styles/variables';
@import (reference) '../../../../../styles/colors';
@import (reference) '../../../../../styles/typography';

.stages-select-dialog {
	@media (min-width: @tablet-min) {
		width: 704px;
	}

	&__items {
		@media (max-width: @tablet-max) {
			padding-bottom: 18px;
		}
		@media (min-width: @large-tablet-min) {
			padding-bottom: 16px;
		}
		&-stage-container {
			padding: 24px 0px;
			border-bottom: 1px solid @gray8;
		}
		&-stage-title {
			margin-bottom: 16px;
			color: @black60;
			.d-regular-12();
		}

		&-item {
			padding: 8px;
			margin: 4px 0px;
			border-radius: 8px;
			min-width: 408px;
			min-height: 40px;

			position: relative;
			display: grid;
			align-items: center;
			grid-template-columns: 28px auto 40px;
			overflow: hidden;

			cursor: pointer;
			user-select: none;

			@media (max-width: @tablet-max) {
				column-gap: 8px;
			}
			@media (min-width: @large-tablet-min) {
				column-gap: 16px;
			}
			@media (max-width: @large-mobile-min) {
				min-width: 304px;
			}


			&-name {
				.d-semibold-14();
				color: @black85;
			}

			&:not(&:last-of-type)::after {
				content: '';
				width: 100vw;
				height: 1px;
				background-color: @black10;
			}
		}

		&-selected-icon {
			color: @blue2;
			width: fit-content;
		}

		&-icon-container {
			border-radius: 50%;
			width: 32px;
			height: 32px;
			background-color: @white;
			overflow: hidden;
		}

		&-icon-background {
			width: 100%;
		    height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&__selected-process {
		background-color: @buttonFilterActive;
	}
}