@import (reference) "../../../../../../styles/colors";
@import (reference) "../../../../../../styles/variables";
@import (reference) "../../../../../../fonts/tz-icons/variables";

.radio-button-icon {
	position: relative;
	width: 20px;
	height: 20px;
	border: 1px solid @black35;
	border-radius: 50%;
	color: @white;
	overflow: hidden;
	transition: background-color 0.2s ease, border-color 0.2s ease;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 4px;
		left: 4px;
		width: 10px;
		height: 10px;
		background-color: transparent;
		border-radius: 50%;
		transition: background-color 0.08s ease;
	}

	.desktop .switch:not(.switch_disabled):hover & {
		border-color: @buttonAccentHover;
	}

	.switch:not(.switch_disabled):active & {
		border-color: @buttonAccentPressed !important;
	}

	.switch:focus-visible & {
		border-color: @black60;
	}

	&_checked {
		&::after {
			background-color: @buttonAccent;
			transition-duration: 0.2s;
		}
	}

	&_disabled:not(&_checked) {
		background-color: @black05;
		border-color: @black10;
	}

	.switch:focus-visible &_disabled {
		border-color: @black35;
	}

	&_disabled&_checked {
		border-color: @black20;

		&::after {
			background-color: @black20;
		}
	}

	.switch:focus-visible &_disabled&_checked {
		&::after {
			background-color: @black35;
		}
	}
}
