@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.editable-attachments {
	&__images {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
	}

	&__image {
		margin: 8px;
	}

	&__files {
		&:not(:first-child) {
			margin-top: 16px;
		}
	}

	&__file {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}