@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
}

.container {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
}
