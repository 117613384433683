@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";

.files-counter {
	display: flex;
	align-items: center;
	column-gap: 8px;
	margin-top: 12px;
	margin-bottom: 10px;

	&-icon {
		color: @grey;
	}

	&-title {
		color: @black100;
		.d-regular-12();
	}
}