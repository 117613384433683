@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.sidebar-menu-separator {
	margin: 8px 0;

	&_mobile {
		height: 8px;
	}

	&_tablet, &_desktop {
		height: 1px;
		margin: 16px 0;
		background-color: @black05;
	}
}