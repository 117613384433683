@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.tags-placeholder {
	&__wrap {
		display: flex;
		flex-wrap: wrap;
		margin: -4px;
	}

	&__tag {
		flex: none;
		margin: 4px;
	}
}