@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.offline-mode-dialog {
	@media (min-width: @tablet-min) and (max-width: @desktop-min - 1px) {
		width: 560px;
	}

	@media (min-width: @desktop-min) {
		width: 680px;
	}

	&__title-bar {
		display: flex;
		align-items: center;
	}

	&__title {
		margin-left: 12px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	&__description {
		margin: -16px 0 32px;
		padding: 12px 16px;
		background-color: @grey10;
		border-radius: 8px;
		.d-regular-12();
	}
	&__info {
		margin: -16px 0 32px;
		padding: 12px 16px;
		.d-regular-12();
	}
	&__objects-selection {
		.filter-button {
			background-color: @buttonAccent;
			color: white;
		}
		.filter-button_active {
			background-color: @buttonFilterActive;
			color: @black85;
		}
		padding: 12px 16px;
		&-popup {
			.select-popup__scrollable {
				max-height: 35vh;
			}
		}
	}
	&__section-header {
		display: flex;
		align-items: center;
		margin: 32px 0 24px;

		&-text-block {
			flex: 1 1;
		}

		&-title {
			.d-semibold-16();
			color: @black100;
		}

		&-error {
			margin-top: 2px;
			.d-regular-12();
			color: #eb0021;
		}

		&-btn {
			flex: none;
		}
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}
