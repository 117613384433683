@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.circle-loader {
	width: 24px;
	height: 24px;

	&_looped > &__progress {
		transform-origin: center;

		animation: circle-loader-dash-animation 4.17s linear infinite, circle-loader-rotate-animation 1.23s linear infinite;
	}

	&_definite > &__progress {
		transition: 0.2s ease stroke-dasharray;
	}
}

@keyframes circle-loader-dash-animation {
	0% {
		stroke-dasharray: 25 75;
		stroke-dashoffset: 0;
	}
	25% {
		stroke-dasharray: 75 25;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 25 75;
		stroke-dashoffset: -50;
	}
	75% {
		stroke-dasharray: 75 25;
		stroke-dashoffset: -50;
	}
	100% {
		stroke-dasharray: 25 75;
		stroke-dashoffset: -100;
	}
}

@keyframes circle-loader-rotate-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}