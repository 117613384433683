@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.photo-arrow-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 56px;
	height: 100%;
	padding: 0;
	color: @white;
	transition: color 0.2s ease;

	&_left {
		left: 0;
		background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);

		i {
			transform: rotate(90deg);
		}
	}

	&_right {
		right: 0;
		background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);

		i {
			transform: rotate(-90deg);
		}
	}

	i {
		display: block;
		font-size: 35px;

		&::before {
			display: block;
		}
	}
}