@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.wrapper {
	margin: 0;
}

.fieldLabel,
.selectedOption {
	.d-semibold-12();
}

.fieldLabel {
	color: @black85;
}

.selectedOption {
	color: @blue80;
}

.disabled span {
	color: @black35;
}
