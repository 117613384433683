@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.offline-journal-title {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-grow: 1;
	
	&__content {
		display: grid;
		justify-items: flex-start;
		align-items: center;
		grid-template-columns: minmax(200px, 448px) 1fr;
		grid-template-rows: 20px 34px;
		row-gap: 10px;
		margin-bottom: 0;
		.d-regular-14();

		&-title {
			.d-semibold-14();
		}

		&-date {
			display: flex;
		}
	}

	&__menu {
		padding: 0;
	}

	@media (max-width: @tablet-min) {
		&__content {
			row-gap: 4px;
			grid-template-columns: 1fr;
			grid-template-rows: 20px 20px 1fr;

			&-title {
				margin-bottom: 4px;
			}

			&-date {
				margin-bottom: 10px;
			}
		}
	}
}