@import (reference) '~@tehzor/ui-components/src/styles/variables';

.editing-space-type-decoration {
	border:1px solid blue;
	&__container {
		display: flex;
		@media (max-width: @tablet-min - 1px) {
			display: flex;
			flex-direction: column;
		}
	}

	&__items-grid {
		width:100%;
		@media (max-width: @tablet-min - 1px) {
			margin-left:0;
		}
	}
}