@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.btn-link(@color) {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	min-width: 32px;
	height: 32px;
	border-radius: 6px;
	color: @color;
	outline: none;
	user-select: none;
	transition: background-color 0.2s ease, color 0.2s ease;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	.desktop &:hover {
		color: @color;
	}

	&:active {
		color: @color !important;
	}
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;
	user-select: none;

	&__page {
		margin: 0 4px;

		&-link {
			.btn-link(@black85);
			padding: 0 11px;
		}

		&_selected &-link {
			background-color: @blue2;
			color: @white;
			cursor: default;

			.desktop &:hover {
				color: @white;
			}

			&:active {
				color: @white !important;
			}
		}

		&:not(&_selected) &-link {
			background-color: @buttonFilterActive;

			.desktop &:hover {
				background-color: @buttonFilterActiveHover;
			}

			&:active {
				background-color: @buttonFilterActivePressed !important;
			}
		}
	}

	&__arrow-btn {
		&:first-child {
			margin-right: 12px;
		}

		&:last-child {
			margin-left: 12px;
		}

		&-link {
			.btn-link(@buttonAccent);

			.desktop &:hover {
				background-color: @buttonLightHover;
			}

			&:active {
				background-color: @buttonLightPressed !important;
			}
		}

		&_prev &-icon {
			transform: rotate(90deg);
		}

		&_next &-icon {
			transform: rotate(270deg);
		}
	}

	&__disabled-btn {
		> a {
			color: @black20;
			cursor: default;
			pointer-events: none;
		}
	}

	&__break {
		margin: 0 4px;

		&-link {
			.btn-link(@black85);

			.desktop &:hover {
				background-color: @buttonLightHover;
			}

			&:active {
				background-color: @buttonLightPressed !important;
			}
		}
	}
}
