@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.entity-links {
	&__header {
		.d-semibold-16();
		color: @black100;
		padding: 22px 24px;
	}

	&__content {
		padding: 0 24px 22px;
		width: fit-content;

		&.isMobile {
			padding: 0px;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;

		& > a:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__link .link-button__label {
		.d-regular-14();
	}
}