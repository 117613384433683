@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.link-button {
	&.link-button_filled-disabled {
		color: rgba(@grayBlue4, 0.4);
		background-color: @grey10;
		padding: 6px 8px;
		border-radius: 6px;
	}
	&__filled-label {
		&.inline-button__label {
			.d-regular-12
		}
	}

	.desktop a&_disabled:hover {
		color: @black20;
	}

	a&_disabled:active {
		color: @black20 !important;
	}

	.desktop a&_filled-disabled:hover {
		color: rgba(@grayBlue4, 0.4);
	}

	a&_filled-disabled:active {
		color: rgba(@grayBlue4, 0.4) !important;
	}
}
