@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-presale-check-info-cell {
	padding-right: 8px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}