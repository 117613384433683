@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.button-base {
	cursor: pointer;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: 'Open Sans', sans-serif;

	&_button {
		background-color: transparent;
		border: none;
	}

	&_disabled {
		cursor: default;
	}
}
