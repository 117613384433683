@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.loading-panel {
	position: relative;
	min-height: 60px;

	&__loader {
		margin: 0 auto;

		&-wrap {
			position: sticky;
			top: 40vh;
			margin: auto;
			width: fit-content;
		}

		&-content {
			margin-top: 10px;
			text-align: center;
			color: @grayBlue1;
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1500;
		background-color: rgba(@white2, 0.5);
	}

	&__anim {
		&-enter {
			opacity: 0;
			animation-duration: 0.3s;
			animation-fill-mode: both;
			animation-timing-function: ease;
			animation-play-state: paused;
		}

		&-leave {
			animation-duration: 0.3s;
			animation-fill-mode: both;
			animation-timing-function: ease;
			animation-play-state: paused;
		}

		&-enter&-enter-active {
			animation-name: loading-panel-fade-in;
			animation-play-state: running;
		}

		&-leave&-leave-active {
			animation-name: loading-panel-fade-out;
			animation-play-state: running;
		}
	}
}

@keyframes loading-panel-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes loading-panel-fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
