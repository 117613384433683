@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.object-page {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	@media (max-width: @large-mobile-max) {
		padding: 0;
	}

	&__breadcrumbs {
		padding-bottom: 0px;
	}

	&__d-options {
		margin-bottom: 16px;
	}

	&__d-options,
	&__d-controls {
		gap: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__d-columns {
		display: grid;
		grid-column-gap: 24px;
		align-items: flex-start;

		@media (min-width: @desktop-min) {
			grid-column-gap: 32px;
		}

		.app-content_padding_72 & {
			grid-template-columns: 304px auto;

			@media (min-width: @large-tablet-min) and (max-width: @large-tablet-max) {
				grid-template-columns: 304px auto;
			}

			@media (min-width: @desktop-min) {
				grid-template-columns: 368px auto;
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: @large-tablet-max) {
				display: grid;
				grid-template-columns: 368px auto;
				grid-column-gap: 24px;
			}

			@media (min-width: @desktop-min) and (max-width: @desktop-max) {
				grid-template-columns: 304px auto;
			}

			@media (min-width: @large-desktop-min) {
				grid-template-columns: 368px auto;
			}
		}
	}

	&__d-column {
		@media (min-width: @large-desktop-min) {
			flex: 1 1 550px;
		}

		.app-content_padding_72 & {
			@media (min-width: @large-tablet-min) and (max-width: @tablet-max) {
				flex: 1 1 500px;
			}

			@media (min-width: @desktop-min) and (max-width: @large-tablet-max) {
				flex: 1 1 550px;
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: @tablet-max) {
				grid-area: info;
				position: relative;
				top: 0;
				margin: 0;
			}

			@media (min-width: @desktop-min) and (max-width: @large-tablet-max) {
				flex: 1 1 500px;
			}
		}
	}

	&__generate-spaces {
		&-dialog {
			min-width: 500px;

			&-select {
				margin-bottom: 24px;

				&-popup {
					max-height: 230px;

					@media (max-width: @large-mobile-min) {
						max-height: 190px;
					}
				}
			}

			&-toggle_container {
				display: flex;
				column-gap: 8px;

				@media (max-width: (@tablet-min - 1px)) {
					justify-content: space-between;
				}
			}

			&-toggle {
				order: 1;

				@media (max-width: (@tablet-min - 1px)) {
					order: 2;
				}
			}

			&-toggle-description {
				order: 2;
				margin: 0;
				color: @black4;

				@media (max-width: (@tablet-min - 1px)) {
					order: 1;
				}
			}

			&-button_container {
				display: flex;
				justify-content: flex-end;

				@media (max-width: (@tablet-min - 1px)) {
					justify-content: center;
					width: 100%;
				}
			}

			&-submit_button {
				@media (max-width: (@tablet-min - 1px)) {
					width: 100%;
				}
			}
		}
	}

	&__d-entities-grid {
		grid-template-columns: 100%;
		padding-bottom: 16px;

		&:not(:first-child) {
			margin-top: 56px;
		}

		.entity-grid-item__icon {
			font-size: 20px;
		}

		&>.entity-grid-item_inline {
			display: grid;
			grid-template-columns: 176px auto;
			grid-column-gap: 48px;
			justify-content: flex-start;
			border-color: @grey20;
			align-items: flex-start;
		}

		&-title {
			.d-semibold-14();
			padding-left: 0;
		}

		&-image {
			max-width: 124px;
		}

		&-custom-field {
			border-top: 1px solid @black10;
		}

		&-stages {
			margin-top: 56px;

			&__wrap {
				align-items: flex-start;

				&-stage {
					padding-bottom: 16px;

					&-name {
						.d-semibold-14();
						display: flex;
						gap: 12px;
						color: @black100 !important;
						padding-bottom: 2px;
					}
				}
			}
		}

		&-manager {
			padding-top: 6px !important;

			&__fullName,
			.entity-grid-item__header {
				padding-top: 14px;
			}
		}
	}

	&__image::before {
		padding-top: 100%;
	}
}