@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';

.tooltip {
	padding: 0.6em 1em;
	background-color: @white !important;
	border-radius: 0.4em;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
	color: @grayBlue2 !important;
	line-height: 1.5em;
	text-align: center;

	font-size: 12px;

	@media (min-width: @large-tablet-min) {
		font-size: 13px;
	}

	&.show {
		opacity: 1 !important;
	}
}
