@import (reference) '~@tehzor/ui-components/src/styles/variables';

.moving-warranty-claim-dialog {
	padding: 0 0 16px 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 16px 0;

	@media (min-width: @tablet-min) {
		min-width: 448px;
	}

	&__subtitle {
		margin: 0;
		font-size: 16px;
		font-weight: 600;
	}

	&__container {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		grid-gap: 16px 0;
	}


	&__item {

		&__title {
			padding: 10px 0;
			margin: 0;
			font-size: 14px;
		}
	}
}