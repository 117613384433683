@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

@class: ~'.sidebar-menu-item';

@{class} {
	display: flex;
	align-items: center;
	min-height: 56px;
	padding: 8px;
	transition: background-color 0.2s ease;

	.desktop &:hover {
		background-color: @buttonHover;
	}

	&:active {
		background-color: @buttonPressed !important;
	}

	&_tablet {
		margin: 8px 0;
	}

	&_desktop {
		margin: 8px 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	&_active {
		background-color: @black05;
	}

	&__wrap {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;
		width: 40px;
		height: 40px;
		border-radius: 8px;
		color: @black35;
		transition: background-color 0.2s ease, color 0.2s ease;

		@{class}_mobile & {
			color: @buttonAccent;
		}

		@{class}_desktop@{class}_active & {
			background-color: @buttonAccent;
			color: @white;
		}

		@{class}_tablet@{class}_active & {
			color: @buttonAccent;
		}
	}

	&__label {
		flex: auto;
		margin-left: 16px;
		.tm-semibold-15();
		color: @black85;
		text-overflow: ellipsis;
		overflow: hidden;
		transition: color 0.2s ease;

		@{class}_desktop & {
			.d-semibold-14();
		}

		@{class}_active & {
			color: @blue2;
		}
	}
}