@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.space-statuses-set {

	&__loading-panel {
		height: 100vh;
	}

	margin: 0px 32px;
	@media (max-width: @tablet-min) {
		margin: 0px;
	}

	&__breadcrumbs {
		margin: 10px 0px;
		padding-top: 16px;
		@media (max-width: @tablet-min) {
			padding: 10px 16px 0px 16px;
		}

		.breadcrumb {
			padding: 0px;
		}
	}

	&__wrap {
		width: 100%;
		padding: 16px 0px;

		display: grid;
		grid-template-columns: 368px auto;
		grid-column-gap: 32px;
		align-items: flex-start;

		@media (max-width: @large-tablet-min) {
			grid-template-columns: auto;
			grid-template-rows: auto auto;
			grid-row-gap: 32px;
		}

		&-info {
			position: sticky;
			width: 368px;
			margin-right: 20px;

			&__add-item > .entity-grid-item__content {
				padding-left: 0px;
			}
			@media (max-width: @large-tablet-min) {
				width: 100%;

				&__item {
					width: 100%;
				}
			}
		}

		&-statuses {
			flex-grow: 3;

			padding: 24px;

			@media (max-width: @large-tablet-min) {
				padding: 16px;
			}
		}
	}
}