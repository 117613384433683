.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container {
	display: grid;
	grid-auto-columns: min-content;
	grid-template-rows: 1fr;
	grid-auto-flow: column;
	grid-gap: 0 12px;
}