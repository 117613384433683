@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-task-info-cell {
	display: flex;

	&__select {

	}
	&__entity-info {
		padding: 4px 0;
		justify-content: flex-start;
		gap: 5px;
		.d-semibold-12();


		.entity-grid-item__content, .entity-grid-item__label {
			color: @black75;
		}

		&-value {
			color: @black75;
			max-width: 100%;
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}
	&__task {
		width: 100%;
		margin-left: 20px;

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&-desc {
			flex: 1 1;
			margin-bottom: 12px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}


		&-fix-date {
			font-weight: 600;
		}

		&-status-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 12px;
		}
	}

}