@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.problem-comments-v-filter {
	&__label {
		.tm-regular-13();

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}
}