@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../fonts/tz-icons/variables";

@class: ~'.menu-item';

@{class} {
	display: flex;
	position: relative;
	align-items: center;
	min-height: 48px;
	padding-left: 16px;
	padding-right: 28px;
	z-index: 2;
	background-color: @white;
	color: @black100;
	cursor: pointer;
	user-select: none;
	outline: none;
	transition: background-color 0.2s ease, color 0.2s ease;
	will-change: background-color;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@media (min-width: @large-tablet-min) {
		min-height: 44px;
	}

	&:first-of-type {
		margin-top: 4px;
	}

	&:last-of-type {
		margin-bottom: 4px;
	}

	.desktop &:not(&_disabled)&:hover {
		background-color: @buttonLightHover;

		@{class}__icon-wrap {
			color: @buttonAccentHover;
		}
	}

	&:not(&_disabled)&:active {
		background-color: @buttonLightPressed !important;

		@{class}__icon-wrap {
			color: @buttonAccentPressed;
		}
	}

	&_selectable {
		padding-right: 56px;
		color: @black60;
	}

	&_selected {
		background-color: @buttonLightSelected;
		padding-right: 16px;
		color: @black100;
	}

	&_with-icon {
		padding-left: 12px;
	}

	&_disabled {
		cursor: default;
	}

	&__wrap {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 18px;
	}

	&__icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;
		min-width: 40px;
		min-height: 40px;
		margin-right: 8px;
		color: @grey;
		transition: color 0.2s ease;

		i {
			display: block;
		}

		@{class}_disabled & {
			color: @black35
		}
	}

	&__title {
		flex: 1 1 100%;
		word-break: break-word;
		margin: 8px 0;
		font-weight: 600;
		transition: color 0.2s ease;

		@media (min-width: @large-tablet-min) {
			margin: 10px 0;
		}

		@{class}_selectable & {

		}

		@{class}_selected & {

		}

		@{class}_disabled & {
			color: @black35;
		}
	}

	&__mark {
		margin-left: 16px;
		color: @deepBlue;
	}
}
