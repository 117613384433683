@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

@class: ~'.menu-arrow-button';

@{class} {
	&__cont {
		position: relative;
		width: 24px;
		height: 24px;
	}

	&__line-1, &__line-2, &__line-3 {
		position: absolute;
		height: 2px;
		background-color: @grey;
		border-radius: 1px;
		transform-origin: 0 0;
	}

	@{class}__line-1 {
		top: 6px;
		left: 4px;
		width: 16px;
		transition: top 0.08s ease 0.1s, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1);
		will-change: top, left, width, transform;
	}

	@{class}__line-2 {
		top: 11px;
		left: 4px;
		width: 16px;
		transition: left 0.08s ease, width 0.08s ease;
		will-change: left, width;
	}

	@{class}__line-3 {
		top: 16px;
		left: 4px;
		width: 16px;
		transition: top 0.08s ease 0.1s, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1);
		will-change: top, left, width, transform;
	}

	&_active {
		@{class}__line-1 {
			top: 11px;
			left: 1px;
			width: 10px;
			transform: translate(-0.1px, 0.6px) rotate(-45deg);
			transition: top 0.08s ease, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		}

		@{class}__line-2 {
			left: 1px;
			width: 22px;
			transition: left 0.08s ease, width 0.08s ease;
		}

		@{class}__line-3 {
			top: 11px;
			left: 1px;
			width: 10px;
			transform: translate(1.2px, -0.2px) rotate(45deg);
			transition: top 0.08s ease, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		}
	}
}