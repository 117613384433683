@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrapper {
	display: flex;
	align-items: center;
	margin: 0;
	font-size: 16px;
	color: @black100;
}

.process {
	.d-regular-14();
}

.processName {
	padding: 5px 8px;
	border-radius: 8px;
	margin-left: 8px;
	.d-regular-12();
}
