@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

.photo-preview {
	display: block;
	position: relative;
	width: 100%;
	padding: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&__wrap {
		width: 100%;
		padding: calc(50% - 3px) 0;
		border: 3px solid transparent;
		border-radius: 8px;
		transition: opacity 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	}

	.desktop &:hover {
		.shadow4();
	}

	.desktop &:hover > &__wrap {
		background-color: rgba(16, 25, 35, 0.2);
	}

	&:active > &__wrap {
		background-color: rgba(16, 25, 35, 0.3) !important;
	}

	&_active > &__wrap {
		background-color: rgba(0, 0, 0, 0.2);
		border-color: @buttonAccent;
	}
}