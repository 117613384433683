@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.problem-tags-markers {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	&__tag{
		width:6px;
		height: 6px;
		border-radius: 50%;
		margin:0 4px;
		&:first-of-type{
			margin-left: 0;
		}
	}
	&__rest{
		.d-regular-12();
		color: @blue2;
		margin-left: 8px;
	}
}
