@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.ei-table {
	&__header {
		border-bottom: 1px solid @black10;
	}

	&__row {
		position: relative;
		border-top: none;

		&:not(:first-child) {
			padding-top: 1px;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 16px;
				right: 16px;
				height: 1px;
				background-color: @black10;
			}
		}

		@media (max-width: @desktop-min - 1px) {
			&:not(:first-child) {
				padding-top: 1px;

				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 16px;
					right: 16px;
					height: 1px;
					background-color: @black10;
				}
			}

			&_nested {
				&:not(:first-child) {
					&::before {
						left: 56px;
					}
				}
			}
		}

		@media (min-width: @desktop-min) {
			&:not(:first-child) {
				&::before {
					left: 0;
					right: 0;
					background-color: @black20;
				}
			}

			&_nested {
				&:not(:first-child) {
					&::before {
						left: 64px;
						background-color: @black10;
					}
				}
			}
		}

		&_selected {
			background-color: @buttonLightSelected;

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 2px;
				background-color: @blue2;
			}
		}
	}
}