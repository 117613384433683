@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-space-attachments {
	&__attach-btn{
		margin-top: 24px;
		padding: 14px;
		background-color: @buttonLight;
		border-radius: 8px;

		.button-base& {
			border: 1px solid @iconButtonHover;
		}
	}

	&__file {
		.button-base {
			color: @blue2;
		}
	}
}