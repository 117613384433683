@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.editable-space-statuses {
	display: flex;
	flex-direction: column;
	gap: 112px;

	&__stage {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-title {
			.d-semibold-16();
			padding-bottom: 12px;
		}

		&-add {
			padding-top: 16px;

			.inline-button__label {
				.d-body-bold-14();
			}
		}
	}

	&__status {
		&-dnd {
			align-items: flex-start;
			&-handle {
				margin-bottom: 12px;

				@media screen and (max-width: @tablet-max) {
					margin-top: 4px;
				}
			}
			&-without-handle {
				margin-left: 32px;
			}
		}

		&-icon {
			color: @grey;
		}

		&-desktop {
			display: flex;
			align-items: flex-start;
			gap: 16px;

			&.transfered {
				align-items: flex-start;
			}
			&__number {
				.d-semibold-14();
				color: @black60;
				margin-top: 45px;
			}
			&__container {
				display: flex;
				align-items: flex-end;
				gap: 16px;
				flex-wrap: wrap;
			}
			&__actions {
				margin-top: 36px;

				&-btns {
					justify-content: flex-start;

					.button-base {
						height: 40px;
						margin-bottom: 4px;
					}
				}
				&-menu {
					margin-bottom: 4px;
				}
			}

			&__options {
				display: flex;
				flex-direction: column;
				gap: 18px;
				margin: 18px 8px;

				&__block {
					display: flex;
					flex-direction: column;
					gap: 24px;
					&:not(:last-child) {
						padding-bottom: 18px;
						border-bottom: 1px solid @black10;	
					}
					
				}

				&__wrap {
					flex-direction: row;
					gap: 12px;
				}
			}
		}
		&-mobile {
			display: flex;
			gap: 16px;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;

			&__number {
				.d-semibold-14();
				color: @black60;
				margin-top: 5.5px;
			}
			&__container {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 8px;
				.d-regular-12();

				&-name {
					width: 183px;
					.d-semibold-14();
				}
				&-title {
					color: @black60;
				}
				&-auto {
					display: flex;
					gap: 4px;
				}
				&-menu {
					position: relative;
					top: -4px;
				}
			}
			&__dialog {
				display: flex;
				flex-direction: column;
				gap: 24px;

				&-toggles {
					display: flex;
					flex-direction: column;
					gap: 16px;
	
					&-block {
						display: flex;
						flex-direction: column;
						gap: 16px;

						&:not(:last-child) {
							padding-bottom: 16px;
							border-bottom: 1px solid @black10;	
						}
						
					}
	
					&-wrap {
						flex-direction: row;
						gap: 12px;
					}
				}
			}
		}

		&-number {
			.d-regular-14();
			color: @black85;
		}

		&-fields {
			&__options {
				display: flex;
				flex-direction: row;
				gap: 12px;

				&-title {
					margin: auto 0;
				}
			}
			&__name, &__trns, &__auto {
				@media (min-width: @large-tablet-min) {
					min-width: 240px;
					width: 240px;
				}

				&-title {
					@media (min-width: @large-tablet-min) {
						padding-bottom: 10px;
					}
					padding-bottom: 8px;

					.d-regular-14();
					color: @black85;
				}

			}
			&__trns, &__auto {
				&-footer {
					&-info {
						height: 40px;
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid @black10;
					}
					&-btns {

					}
				}
				&-dialog {
					.select-search {
						padding-bottom: 16px;
					}
				}
			}
			&__color {
				&-picker {
					width: 48px;
					height: 48px;
				}
			}

			&__notification-message {
				@media (min-width: @large-tablet-min) {
					min-width: 240px;
					width: 240px;
				}


				&__dialog {

					@media (min-width: @large-tablet-min) {
						width: 830px;
					}

					&-title {

						@media (max-width: @large-tablet-min) {
							white-space: normal;
						}
					}

					&-body {
						display: grid;
						grid-template-columns: 1fr;
						grid-auto-rows: min-content;
						grid-gap: 40px 0;
					}

					&-footer {
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}

				&__title {
					height: 69px;
				}

				&__body {
					height: 110px;
				}
			}
		}
	}

	&__preview {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
		align-items: center;

		&-icon {
			color: @black60;
			opacity: .5;
		}

		&-text {
			width: 100%;
			margin: 1px;
			color: @black100;
			min-width: 240px;
			width: 240px;
		}
	}
}