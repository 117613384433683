.problem-fix-cell-date {
	display: block;
	margin-bottom: 12px;
	font-weight: 600;
}

.problem-fix-cell-user {
	&:not(:last-child) {
		margin-bottom: 12px;
	}
}