@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.moving-check-dialog {
	@media (min-width: @tablet-min) {
		width: 448px;
	}
	&__content {
		display: flex;
		flex-direction: column;
		row-gap: 26px;
	}
	&__select-title {
		margin-bottom: 10px;
		.d-regular-14();
	}
}
