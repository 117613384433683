@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.addingPresaleCheckDialog {
	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}
}

.dialogBody {
	@media (max-width: @tablet-min) {
		padding-top: 0px;
	}
}

.dialogHeader {
	padding-bottom: 8px;
}

.dialogTitle {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}


