@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-meter-dialog {
	@media (min-width: @large-tablet-min) {
		width: 704px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__delete-button {
		margin: 0;
	}

	&__add-button {
		margin-bottom: 40px;
	}
}