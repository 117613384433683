@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.photo-navigation {
	display: flex;
	align-items: center;

	.icon-button&__prev, .icon-button&__next {
		flex: none;
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
		padding: 0;

		&:not(.icon-button_disabled) {
			color: @grey;
		}
	}

	&__prev {
		&-icon {
			margin: 0 1px 0 -1px;
			transform: rotate(90deg);
		}
	}

	&__next {
		&-icon {
			margin: 0 -1px 0 1px;
			transform: rotate(-90deg);
		}
	}

	&__items {
		flex: auto;
		margin: 0 14px;
	}

	&__item {
		&:not(:first-child) {
			margin-left: 6px;
		}

		&:not(:last-child) {
			margin-right: 6px;
		}

		.photo-preview&-photo {
			width: 52px;
		}
	}
}