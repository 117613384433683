@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.categories-sets {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 2px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
  &__header {
    display: flex;
    justify-content: space-between;
  }

	&__filters {
		@media (max-width: 1279px){
			margin-bottom: 12px;
		}
	}

	&__cell {
		&-relations {
			overflow: hidden;

			&__tag {
				margin: 5px 2px;
				color: @black4;
				max-width: 90%;

			}
		}
		&-stages {
			&__tag {
				margin: 5px 2px;
				color: @black4;
				max-width: 90%;
			}
		}

		&-name {
			padding: 0px;

			&__header {
				max-height: 42px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin: 0px;
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				color: @black100;
			}
			&__subtext {
				font-weight: normal;
				font-size: 12px;
				line-height: 18px;
			}
		}

	}
	&__loading-panel {
		@media (max-width: @tablet-min) {
			padding: 0 0 20px;
		}
	}
}