@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

@prefix: photo-carousel;

.@{prefix} {
	position: relative;

	&_clickable {
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			transition: background-color 0.2s ease;
		}

		.desktop &:hover::after {
			background-color: rgba(16, 25, 35, 0.2);
		}
	}

	&__touch-root {
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	&__wrapper {
		display: flex;
		height: 100%;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__hover-area {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
	}

	&__arrow-button {
		opacity: 0;
		pointer-events: all;
		transition: opacity 0.2s ease, color 0.2s ease;

		.desktop .@{prefix}:hover & {
			opacity: 1;
		}
	}
}