@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.space-indicator-tag {
	&__icon {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		margin-right: 2px;
	}
}