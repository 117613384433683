@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/shadows';

.filter-page {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: @white;
	padding-top: @mobile-header-height;

	.app-content_padding_56 & {
		padding-left: 56px;
	}

	.app-content_padding_72 & {
		padding-left: 72px;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 16px;
	}

	&__content {
		padding: 0px 16px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__buttons {
		flex: none;
		position: relative;
		margin-top: auto;
		padding: 16px;
		.shadow3();
	}
	&__no-option-select {
		border-bottom: 1px solid @gray8;
	}
}
