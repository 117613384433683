@import (reference) '../../../../../styles/colors';
@import (reference) '../../../../../styles/typography';
@import (reference) '../../../../../styles/variables';

.expandable-plate-expand-btn {
	display: block;
	width: 100%;
	height: 56px;
	padding: 0 16px;
	background-color: transparent;
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease,
		color 0.2s ease;
	@media (min-width: @large-tablet-min) {
		padding: 0 24px;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__icon {
		display: block;
		color: rgba(@black3, 0.6);
		transition: transform 0.2s ease;

		i {
			display: block;
		}
	}

	&_expanded &__icon {
		transform: rotate(180deg);
	}

	&__label {
		margin-right: 10px;
		.tm-bold-17();
		color: @black95;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@media (min-width: @large-tablet-min) {
			.d-bold-16();
		}
	}
}
