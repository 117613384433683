@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.popup-menu-with-action {
	max-width: 300px;

	&__body {
		max-height: 400px;
		padding-bottom: 12px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 56px;
		padding: 8px 28px;
		background-color: @buttonLight;
		border-top: 1px solid @black10;
	}
}
