@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.comments {

	display: flex;
	flex-direction: column;
	align-items: stretch;

	&__list,
	&__loading-panel {
		flex: 1 1;
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;
	}

	&__loader {
		padding: 12px;
		text-align: center;
	}

	&__footer {
		flex: none;
		max-height: 100%;
		border-top: 1px solid @black10;
		overflow-y: auto;
	}

	&__reply-btn {
		display: block;
	}

	&__reference {
		position: relative;
		margin-bottom: 16px;
	}

	&__empty-placeholder {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;

		&-icon {
			margin-bottom: 12px;
			color: @greyLight;
		}

		&-text {
			font-weight: 600;
			color: @grey;
		}
	}

	&__d-comments {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		height: 100%;

		&-header {
			display: flex;
			flex-direction: column;
			padding: 15px 24px 5px;
			align-items: start;
			.shadow2();

			@media (min-width: @large-desktop-min) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-title {
			align-self: flex-start;
			margin-bottom: 10px;
			.d-bold-18();
			color: @black100;

			@media (min-width: @large-desktop-min) {
				margin-right: 10px;
			}
		}

		&-v-filter {
			align-self: flex-start;
			margin-bottom: 10px;
		}

		&-body {
			flex: 1 1;
			overflow: hidden;
		}

		&-content {
			padding: 12px 0 28px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		&-reply-btn {
			width: unset !important;
			margin: 4px 24px 0;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-reference {
			padding-left: 24px;
			padding-right: 24px;

			@media (min-width: @large-desktop-min) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-input {
			margin-left: 24px;
			margin-right: 24px;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}
		}
	}

	&__d-comment {
		@media (max-width: @large-desktop-min - 1px) {
			padding-left: 24px;
			padding-right: 24px;
		}

		@media (min-width: @large-desktop-min) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.problem-comment_nested > & {
			@media (max-width: @large-desktop-min - 1px) {
				padding-left: 80px;
			}

			@media (min-width: @large-desktop-min) {
				padding-left: 112px;
			}
		}
	}
	&__d-comments {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
		height: 100%;

		&-header {
			flex: none;
			padding: 15px 24px 5px;
			.shadow2();

			@media (min-width: @large-desktop-min) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-title {
			margin-bottom: 10px;
			.d-bold-18();
			color: @black100;

			@media (min-width: @large-desktop-min) {
				margin-right: 10px;
			}
		}

		&-v-filter {
			margin-bottom: 10px;
		}

		&-body {
			flex: 1 1;
			overflow: hidden;
		}

		&-content {
			padding: 12px 0 28px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		&-reply-btn {
			width: unset !important;
			margin: 4px 24px 0;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-reference {
			padding-left: 24px;
			padding-right: 24px;

			@media (min-width: @large-desktop-min) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-input {
			margin-left: 24px;
			margin-right: 24px;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}
		}
	}
	&__d-comment {
		@media (max-width: @large-desktop-min - 1px) {
			padding-left: 24px;
			padding-right: 24px;
		}

		@media (min-width: @large-desktop-min) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.problem-comment_nested > & {
			@media (max-width: @large-desktop-min - 1px) {
				padding-left: 80px;
			}

			@media (min-width: @large-desktop-min) {
				padding-left: 112px;
			}
		}
	}
	&__m-plate {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__last-reply {
		margin: 0;

		&-plate {
			padding: 0;
		}
	}
}
