@import (reference) '../../../../../styles/colors';
@import (reference) '../../../../../styles/variables';
@import (reference) '../../../../../styles/typography';

@class: ~'.entity-grid-item';

@{class} {
	padding: 16px 0;

	@media (min-width: @tablet-min) {
		padding-left: 16px;
		padding-right: 16px;
	}

	&_full {
		@media (min-width: @tablet-min) {
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}

	&_inline {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@{class}__content {
			padding-left: 0;
		}
	}

	.entity-grid_with-borders & {
		padding: 16px 0;
		border-bottom: 1px solid @black10;

		@media (min-width: @tablet-min) {
			padding-left: 16px;
			padding-right: 16px;
		}

		@media (min-width: @desktop-min) {
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
	}

	&:not(&_inline) {
		@{class}__header {
			&:not(:last-child) {
				margin-bottom: 16px;

				@media (min-width: @desktop-min) {
					margin-bottom: 10px;
				}
			}
		}
	}

	&__icon {
		min-width: 20px;
		margin-right: 12px;
		color: @grey;

		@media (min-width: @desktop-min) {
			margin-right: 16px;
		}

		& > i {
			display: block;
		}
	}

	&__label {
		&:not(:last-child) {
			margin-right: 8px;
		}

		@media (max-width: @desktop-min - 1px) {
			color: @black100;
			font-weight: 600;
		}

		@media (min-width: @desktop-min) {
			color: @black75;
		}
	}
	&__title {
		color: @black100;
		font-weight: 600;
	}
	&__content {
		padding-left: 32px;

		@media (min-width: @desktop-min) {
			padding-left: 36px;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-left: auto;
	}
	&__d-flex {
		display: flex;
		justify-content: space-between;
		width: 60%;

	}
	&__fd-column {
		display: flex;
		flex-direction: column;
		opacity: 0.6;
	}

	&__fd-column-right {
		padding: 0 0 0 12px;
		border-left: 1px solid @grey20;
	}
}
