@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.et-select {

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__option {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5em 0.4em;
		border: 1px solid @gray2;
		border-radius: 2px;
		transition: background-color 0.2s ease;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&:not(:last-of-type) {
			margin-bottom: 1em;
		}

		.desktop &:hover {
			background-color: @gray6;
		}

		&:active {
			background-color: @gray2 !important;
		}

		&_selected {
			background-color: @gray1;
		}

		&-file-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: none;
			width: 6.5em;
			height: 2.8em;
			margin-right: 2.2em;
			padding: 0 1em;
			background-color: rgba(@gray3, 0.15);
			border-radius: 2px;

			&_docx {
				background-color: rgba(@blue, 0.13);
			}

			&_xlsx {
				background-color: rgba(@green, 0.13);
			}

			&_pdf {
				background-color: rgba(@red, 0.13);
			}
		}

		&-file-ext {
			font-size: 1.4em;
			line-height: 1;
			font-weight: 600;
			text-transform: uppercase;
			user-select: none;
		}

		&-file-icon_docx &-file-ext {
			color: @blue;
		}

		&-file-icon_xlsx &-file-ext {
			color: @green;
		}

		&-file-icon_pdf &-file-ext {
			color: @red;
		}

		&-name {
			flex: 1 1;
			font-size: 1.6em;
			line-height: 1.2em;
			color: @black2;
			user-select: none;
			word-break: break-word;
		}
	}
}
