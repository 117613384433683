@import (reference) '~@tehzor/ui-components/src/styles/colors';

.columns-customizer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content min-content 1fr;

	max-height: 70vh;
	padding: 16px;

	&__header {
		padding: 4px 0 20px;
		border-bottom: 1px solid @grey20;
	}

	&__title {
		margin: 0;
		font-size: 14px;
		font-weight: 600;
	}

	&__menu-layer {
		width: 365px;
		max-width: none;
	}

	&__bar {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;

		padding: 24px 0 16px;

		border-bottom: 1px solid @grey20;
	}

	&__list {
		overflow-y: auto;
		display: grid;
		grid-auto-rows: min-content;
		grid-gap: 16px 0;
		grid-template-columns: 1fr;

		padding: 16px 0 0;
	}
}
