@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.m-pagination {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px;

	&__btn {
		&.button {
			height: 40px;
			background-color: @black05;
		}

		&:not(.button_disabled) {
			color: @deepBlue;
		}

		&_prev.button {
			padding: 0 12px 0 8px;
		}

		&_prev &-icon {
			margin-right: 8px;
		}

		&_next.button {
			padding: 0 8px 0 12px;
		}

		&_next &-icon {
			margin-left: 8px;
		}
	}

	&__title {
		padding: 0 8px;
		color: @black85;
		text-align: center;
		overflow: hidden;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}