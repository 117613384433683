@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.entity-tasks {
  padding: 20px 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      .d-semibold-16();
      color: @black100;
    }

    &-add-button {
      display: flex;
      align-items: center;
    }

    &-entity-grid-item-header {
      margin-bottom: 0 !important;
    }
  }

  &__content {
    margin-top: 16px;

    &-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 12px;
      overflow: hidden;
    }
  }

  &__more-button {
    margin-top: 16px;
  }

  &__virtualize-list {
    overflow-y: auto;
    contain: strict;
    max-height: 80vh;
    min-height: 200px;

    &-wrapper {
      width: 100%;
      position: relative;
    }

    &-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &-item {
      margin-right: 32px;

      .inline-button__label {
        word-break: break-all;
      }
    }
  }
}