@import (reference) '../../../../../styles/colors';
@import (reference) '../../../../../styles/typography';
@import (reference) '../../../../../styles/variables';

.tab-link {
	padding: 12px 4px;
	cursor: pointer;
	user-select: none;

	@media (min-width: @desktop-min) {
		padding: 14px 4px 12px 0;
	}

	.tab-link__wrap {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.tab-link__icon {
		.d-semibold-14();
		color: @black60;
	}

	&__label {
		.tm-semibold-13();
		color: @black60;
		transition: color 0.2s ease;

		@media (min-width: @desktop-min) {
			.d-semibold-14();
		}
	}

	&_active {
		.tab-link__label {
			color: @black100;
		}
	}

	&_disabled {
		.tab-link__label {
			color: @black20;
		}

		.tab-link__icon {
			color: @black20;
		}
	}
}
