@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.object-name-info-cell {
	&__name {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__arrow {
		color: @blue2;
		&_expanded {
			border-radius: 6px;
			background-color: @buttonPressed;
			transform: rotate(180deg);
			display: block;
		}
	}

	&__info {
		display: flex;
		align-items: flex-start;
		flex: 1 1;
		margin-left: 26px;
		overflow: hidden;

		&-text {
			flex: 1 1;
			margin-right: 8px;
			overflow: hidden;
		}
	}
}
