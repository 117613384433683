@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.stats-tree-panel {
	&_not-level-0 {
		padding-top: 32px;
	}

	&__header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-height: 88px;
		padding-left: 192px;
	}

	&_without-children > &__header {
		margin-left: 64px;
	}

	&_level_0 > &__header {
		padding-left: 0;
	}

	&_level_1 > &__header {
		padding-left: 64px;
	}

	&_level_2 > &__header {
		padding-left: 128px;
	}

	&_not-level-0 &__header {
		border-top: 1px solid rgba(@black4, 0.05);
	}

	&__expand-btn {
		margin-right: 24px;
	}

	&__expand-btn-placeholder.button-placeholder {
		width: 40px;
		height: 56px;
		margin-right: 24px;
	}

	&__corner-arrow-icon {
		margin-right: 16px;
		color: @grey;
	}

	&__image, &__image-placeholder {
		width: 40px;
		height: 40px;
		margin-right: 16px;
	}

	&__image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
	}

	&_level_0 > &__header > &__image, &_level_0 > &__header > &__image-placeholder {
		width: 56px;
		height: 56px;
	}

	&__title-block {
		display: flex;
	}

	&__tag-block {
		margin-left: 33px;
	}

	&__title {
		font-size: 14px;
		line-height: 1.5em;
		font-weight: 700;
		color: rgba(@black4, 0.85);
	}

	&_level_0 > &__header > &__title-block > &__title {
		font-size: 18px;
		color: @black4;
	}

	&__title-placeholder {
		width: 200px;
		height: 14px;
	}

	&_level_0 > &__header > &__title-block > &__title-placeholder {
		height: 18px;
	}

	&__subtitle {
		.d-regular-12();
		color: rgba(@black4, 0.5);
	}

	&_level_0 > &__header > &__title-block > &__subtitle {
		.d-regular-14();
	}

	&__subtitle-placeholder {
		width: 300px;
		height: 12px;

		&:not(:first-child) {
			margin-top: 5px;
		}
	}

	&_level_0 > &__header > &__title-block > &__subtitle-placeholder {
		height: 14px;

		&:not(:first-child) {
			margin-top: 8px;
		}
	}

	&__permanent-content {
		padding-left: 36px;

		@media (min-width: @desktop-min) {
			padding-left: 64px;
		}
	}
}