@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.check-list-page {
	&__loading-panel {
		min-height: 100vh;
	}

	&__category-count {
		grid-area: toggle;
		.d-regular-12();
		color: @black60;
	}

	&__indicators {
		display: flex;
		max-height: 16px;

		& > *:not(:last-child) {
			margin-right: 13px;
		}

		&:not(:empty) + &:not(:empty)::before {
			width: 4px;
			height: 4px;
			display: flex;
			background: @grey30;
			border-radius: 50%;
			content: ' ';
			align-self: center;
			margin: 14px;
		}

		&:first-child::before {
			display: none;
		}
	}

	&__status {
		position: relative;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-areas: 'select percent';

		@media (max-width: @large-mobile-min) {
			grid-template-rows: 1fr;
			grid-template-areas:
				'select'
				'percent';
		}

		&-select {
			grid-area: select;
			color: @black95;

			&_hidden {
				visibility: hidden;
			}

			&_disabled {
				pointer-events: none;
			}

			&-menu {
				color: @black95;
			}
		}

		&-loader {
			position: absolute;
			margin: auto 0;
			width: 28px;
			height: 28px;
		}

		&-percent {
			display: flex;
			grid-area: percent;
			margin-left: 5px;
			width: 60px;

			& input {
				height: 32px;
			}

			& .text-field__div {
				min-height: auto;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 0;
				margin: 0;
			}

			& .text-field__wrap {
				@media (max-width: @large-mobile-min) {
					border-radius: 0;
					border: none;
					background-color: @grey10;
				}
			}

			& button {
				padding: 5px 10px;
				border: none;
				background-color: @grey10;
				color: @grey40;

				&:active {
					color: @blue2;
				}

				&:first-of-type {
					border-radius: 0 0 29px 29px;
					transform: rotate(90deg);
				}

				&:last-of-type {
					border-radius: 0 0 29px 29px;
					transform: rotate(-90deg);
				}
			}

			@media (max-width: @large-mobile-min) {
				margin-left: 0;
				margin-top: 5px;
			}
		}
	}

	&__m-list {
		.d-semibold-16();
	}

	&__m-container {
		padding: 0;
	}

	&__m-check-list {
		display: flex;
		flex-direction: column;
		row-gap: 12px;
	}

	&__m-lists {
		display: flex;
		flex-direction: column;

		&__info-block {
			margin-bottom: 16px;
		}

		&__breadcrumbs {
			margin: 0;
		}
	}

	&__m-list {
		&__container {
			display: flex;
			justify-content: space-between;
			column-gap: 8px;

			&__clickable {
				cursor: pointer;

				&:active {
					background-color: @buttonPressed;
				}
			}
		}

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-areas: 'title';

			&-title {
				display: flex;
				align-items: center;
				grid-area: title;
				.d-semibold-14();
				font-size: 14px;
				color: @black4;
			}
		}

		&__stage-group {
			&__header {
				padding: 8px 16px 12px 16px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin: 0;
				color: @black60;
				.d-regular-12();
			}

			&__check-list {
				padding: 16px 16px 44px;
			}
		}

		&__process-group {
			&__header {
				padding: 20px 18px;

				&_active i {
					transform: rotate(180deg);
					transition: transform 0.3s ease;
				}
			}
		}

		&-status {
			margin-top: 16px;
		}

		&-bullet {
			flex: 0 0 auto;
		}

		&-indicators {
			display: flex;
			flex-wrap: wrap;
			row-gap: 12px;
		}

		&-status {
			&-select {
				height: 32px;
			}
		}
	}

	&__d-plate {
		&-container {
			display: flex;
			padding: 20px 24px 20px 56px;
			cursor: pointer;
			column-gap: 12px;
			justify-content: space-between;

			&_active {
				background-color: @buttonFilterActive;
				cursor: auto;
			}

			&:not(&_active):hover {
				background-color: @white2;
			}

			&:not(&_active):active {
				background-color: @buttonPressed;
			}

			&_expanded &-header {
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
			}
		}

		&-header {
			display: grid;
			grid-template-columns: auto;

			grid-template-areas:
				'title'
				'indicators'
				'status'
				'toggle';

			&-title {
				display: grid;
				grid-area: title;
				grid-template-columns: 1fr;
				grid-template-areas: 'title';
				padding: 0;
				margin-bottom: 12px;

				.d-semibold-16();
				line-height: 21px;
				color: @black100;

				&-text {
					display: flex;
					align-items: center;
				}

				&__menu-btn {
					min-height: 40px;
					min-width: 40px;
				}
			}

			&-indicators {
				display: flex;
				flex-wrap: wrap;
				row-gap: 12px;
				grid-area: indicators;
				margin-bottom: 16px;
			}

			&-status {
				grid-area: status;
				margin-bottom: 12px;

				&-wrap {
					float: left;
				}
			}
		}
	}

	&__d-grid {
		position: relative;
		display: grid;
		grid-column-gap: 32px;
		grid-template-columns: max-content 1fr 0.65fr;
		grid-template-areas: 'info-col list-col detail-col';
	}

	&__d-info {
		grid-area: info-col;

		&__sticky {
			position: sticky;
			top: 25px;
		}
	}

	&__d-detail {
		grid-area: detail-col;

		&__sticky {
			position: sticky;
			top: 25px;
		}
	}

	&-list {
		grid-area: list-col;

		&__container {
			padding: 16px 0;
		}

		&__stage-group {
			&__header {
				padding: 8px 24px 24px;
				margin: 0;
				color: @black60;
				.d-regular-12();
			}
		}

		&__process-group {
			&__header {
				padding: 8px 26px;

				&_active i {
					transform: rotate(180deg);
					transition: transform 0.3s ease;
				}
			}
		}

		&__divider {
			border: none;
			margin: 16px 24px;
			border-bottom: 1px solid @grey20;

			@media (max-width: @desktop-min) {
				margin: 12px 0;
			}
		}
	}
}
