@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.filter-menu {
	&__button {
		.filter-button& {
			background-color: @white;
			height: 32px;
			padding: 0 8px 0 12px;
		}

		.filter-button__wrap&-wrap {
			justify-content: space-between;
		}

		.filter-button__label&-label {
			.d-regular-12();
		}

		.filter-button__icon&-icon {
			color: @buttonAccent;
			transition: transform 0.2s ease;

			&_open {
				transform: rotate(180deg);
			}
		}
	}
}
