@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.user-button {
	.button& {
		height: auto;
		padding: 4px 16px 4px 8px;
		background-color: transparent;
		border: none;
	}

	.button__wrap&__wrap {
		justify-content: space-between;
	}
}