@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.check-list-detail {
	height: 100%;

	&__container {
		display: flex;
		flex-direction: column;
		height: 790px;
	}

	&__scrollable {
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	&__tabs {
		&-links {
			border-bottom: solid 1px #e8eaec;
			padding: 0 24px;
		}

		&-link {
			.d-semibold-12();
		}
	}

	&__action {
		border-top: solid 1px #e8eaec;
		padding: 16px 24px;
		margin-top: auto;

		&-btn {
			width: 100%;
		}
	}

	&__item {
		padding: 16px 24px;
	}

	&__items {
		overflow-y: auto;
		position: relative;
		padding: 0 20px;
		height: 740px;

		@media (max-width: @desktop-min) {
			padding: 0;
		}
	}

	&__content {
		height: 100%;
	}

	&__list {
		height: 100%;
		&-filter {
			display: flex;
			justify-content: space-between;
		}

		&-body {
		}
	}
}
