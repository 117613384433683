@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-internal-acceptance-attachments {
	&__field {
		&:not(:last-child) {
			margin-bottom: 21px;
		}
	}

	&__attach-btn {
		margin-top: 24px;
		padding: 14px;
		background-color: @buttonLight;
		border-radius: 8px;

		.button-base& {
			border: 1px solid @iconButtonHover;
		}
	}

}