@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.history-marker-comment-change {
	max-width: 266px;
	color: @black100;
	.d-regular-14();

	&__text {
		.d-semibold-14()
	}
}