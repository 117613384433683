@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	color: @black100;
}