@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.check-list-items {
	&__accepted .tz-check-mark {
		font-size: 28px;
		color: @statusFixed;
	}

	&__accepted &__item-header-bullet-circle {
		background-color: @statusAccepted;
	}

	&__accepted &__item-header {
		grid-template-columns: 44px auto 1fr;
	}

	&__accepted &__item-header-actions {
		place-self: center auto;
	}

	&__item {
		padding: 20px 24px;
		display: grid;
		grid-column-gap: 12px;
		grid-template-columns: auto 1fr;
		grid-template-areas:
			'bullet header header'
			'bullet indicators indicators'
			'bullet footer footer'
			'bullet body body';
		cursor: pointer;

		@media (max-width: @desktop-min) {
			padding: 16px;
		}

		&:not(&_active):not(&_disabled):hover {
			background-color: @buttonLightSelected;
		}

		&_nested {
			padding-left: 48px;
		}

		&_disabled {
			opacity: 0.5;
			cursor: initial;
		}

		&_active {
			background-color: @buttonFilterActive;
			cursor: initial;
		}

		&-indicators {
			display: flex;
			flex-wrap: wrap;
			row-gap: 12px;
			margin-bottom: 16px;
			max-width: 80%;
			grid-area: indicators;

			@media (max-width: @desktop-min) {
				margin-bottom: 12px;
			}
		}

		&__divider {
			grid-area: divider;
			border: none;
			margin: 0;
			border-bottom: 1px solid @grey20;


			@media (max-width: @desktop-min) {
				margin: 0 24px;
			}
		}

		&-header {
			display: grid;
			grid-area: header;
			grid-template-columns: auto 1fr auto;
			grid-template-rows: minmax(22px, auto) auto;
			grid-template-areas:
				'bullet title actions'
				'subtitle subtitle actions';

			&-bullet {
				grid-area: bullet;
				display: flex;
				justify-content: center;
				align-items: start;
				.d-semibold-14();
				font-size: 14px;
				color: @black100;
				line-height: 21px;

				&-circle {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					height: 44px;
					width: 44px;
				}

				&-text-with-ellipsis {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 70px;
				}

				@media (max-width: @desktop-min) {
					.d-semibold-12();
				}
			}

			&-title {
				grid-area: title;
				margin-bottom: 4px;
				.d-semibold-14();

				&_clickable {
					.d-semibold-16();
					color: @black100;
					text-decoration: underline;
					text-decoration-color: @black100;
					text-underline-offset: 5px;
					text-decoration-thickness: 1px;
					width: fit-content;

					&:hover {
						text-decoration-color: @blue2;
					}
				}

				@media (max-width: @desktop-min) {
					.d-regular-12();

					.link-button__label {
						.d-regular-12()
					}
				}

			}

			&-subtitle {
				grid-area: subtitle;
				color: @black75;
				.d-regular-12();
				margin-bottom: 12px;

				&_accepted {
					display: flex;
					gap: 6px;

					> p {
						margin: 0;
					}
				}

				@media (max-width: @desktop-min) {
					margin-bottom: 8px;
				}
			}

			&-actions-container {
				background-color: @white2;
				margin-left: 16px;
				border-radius: 8px;
			}

			&-actions {
				grid-area: actions;

				&-menu-button {
					&:not(.check-list-items__item-header-actions-menu-button_disabled):hover {
						background-color: @buttonLightHover2 !important;
					}

					&_small {
						min-width: 32px;

						&:not(.check-list-items__item-header-actions-menu-button_disabled):hover {
							.check-list-items__item-header-actions-expand-menu-icon {
								margin-top: 8px;
								transition: margin-top 0.15s ease-out;
							}
						}
					}
				}

				&-expand-menu-icon {
					font-size: 8px !important;
					transition: margin-top 0.15s ease-out;
				}
			}
		}

		&-body {
			grid-area: body;
			margin: 0;
			.d-semibold-12();

			&-link .link-button__label {
				.d-semibold-12();
			}

			&-link_active i {
				transform: rotate(180deg);
			}
		}

		&-footer {
			grid-area: footer;
			display: flex;
			gap: 12px;
			align-items: center;
			min-height: 40px;
			margin-bottom: 16px;

			@media (max-width: @desktop-min) {
				margin-bottom: 8px;
			}

			&--no-children {
				margin-bottom: 0;

				@media (max-width: @desktop-min) {
					margin-bottom: 8px;
				}
			}
		}

		&-state {
			display: grid;
			grid-template-areas: 'status percents';

			&-tag {
				border: 1px solid @black10;
			}

			&-status {
				grid-area: status;
				color: @black95;
			}

			&-percents {
				grid-area: percents;

				&-input {
					& input {
						height: 32px;
					}

					width: 60px;
				}
			}
		}

		&-actions {
			display: flex;
			flex-wrap: nowrap;

			&-btn {
				height: 26px;
				width: 26px;
				min-height: 26px !important;
				min-width: 26px !important;
				border: 2px solid @grey !important;
				background-color: transparent !important;
				color: @grey;
				display: flex;
				align-items: center;

				@media (max-width: @tablet-min) {
					height: 36px;
					width: 36px;
				}

				&:active {
					background-color: transparent !important;
				}

				&-wrapper {
					padding: 6px;
					border-radius: 8px;

					&:hover {
						background-color: @buttonFilterActiveHover;
					}

					&:active {
						background-color: @buttonFilterActivePressed;
					}

					&_disabled {
						pointer-events: none;
					}
				}
			}
		}
	}

	&__popup {
		width: 488px;
		height: fit-content;

		@media (max-width: @tablet-min) {
			max-width: 488px;
			width: 90%;
		}

		&-close {
			position: absolute;
			top: 0;
			right: 3px;
			z-index: 100;
		}
	}

	&__description-dialog-wrapper {
		display: flex;
		align-items: center;
	}

	&__description {
		padding: 16px 40px 16px 20px;
		overflow-y: auto;

		@media (max-width: @desktop-min - 1) {
			padding: 0 30px 0 0;
		}
	}

	&__description-scrollbar {
		width: 100%;
		height: 264px;
	}
}
