@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.infinite-scroller {
	overflow-y: auto;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&_reversed &__wrapper {
		flex-direction: column-reverse;
	}

	&__content {

	}

	&__loader {
		position: relative;
	}

	&__track {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: -1;
		pointer-events: none;
	}

	&_reversed &__track {
		top: 0;
		bottom: unset;
	}
}