@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.desktop-photo-viewer {
	width: calc(100vw - 32px);
	height: calc(100vh - 32px);

	@media (min-width: @large-tablet-min) {
		width: 90vw;
		height: calc(100vh - 64px);
	}

	@media (min-width: @large-desktop-min) {
		width: 80vw;
	}

	&__layer {
		background-color: black;
		border-radius: 14px;
	}

	.dialog__body&__body {
		position: relative;
		padding: 0;
		user-select: none;
	}

	&__frame {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__image-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__image {
		display: block;
		max-width: 100%;
		max-height: 100%;
	}

	&__arrow-btn {
		width: 96px;
		z-index: 2;
	}
}

.overlay.desktop-photo-viewer-overlay {
	background-color: rgba(0, 0, 0, 0.65);
}