@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";


.meter-consumption {

		margin: 4px 0;
		display:flex;
  		flex-wrap: wrap;
  		justify-content: start;

		&-value {
			font-weight: 600;
			min-width: fit-content;
			max-width: 100%;
			margin-right: 4px;
		}
		&-date {
			color: @black45;
			font-weight: 400;
			font-size: 12px;
			min-width: fit-content;
 			max-width: 100%;
		}
	
}