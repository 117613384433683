@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";

@class: ~'.m-bottom-menu-item';

@{class} {
	height: 100%;
	border-radius: 8px;
	color: @black45;
	cursor: pointer;
	user-select: none;
	transition: background-color 0.2s ease, color 0.2s ease;

	.button-base& {
		padding: 2px 10px;
	}

	.desktop &:hover {
		background-color: @buttonHover;
	}

	&:active {
		background-color: @buttonPressed !important;
	}

	&_active {
		color: @buttonAccent;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
	}

	&__icon i {
		display: block;
	}

	&__label {
		max-width: 100%;
		.tm-bold-10();
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow-x: hidden;
	}
}