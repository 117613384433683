@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.check-item-mobile-detail {
	display: flex;
	row-gap: 16px;
	flex-direction: column;

	&__info {
		padding: 12px 16px;
	}
}
