@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';
@import (reference) '../../styles/common';

.loc-select {
	&__dialog {
		.dialog__layer&-layer {
			background-color: @gray6;
		}

		&-content {
			background-color: @white;
		}

		&-body {
			position: relative;
			margin-bottom: 0;
			padding: 0;
		}
	}

	&__wrap {
		width: 100%;
		height: 100%;

		@media (min-width: @large-tablet-min) {
			display: flex;
			align-items: stretch;
		}
	}

	&__tabs {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 100%;

		&-links {
			flex: none;
		}

		&-content {
			position: relative;
			display: flex;
			flex: 1 1 100%;
			flex-direction: column;
			align-items: stretch;
			padding-top: 1em;
			overflow: hidden;

			@media (min-width: @large-mobile-min) {
				padding-top: 15px;
			}

			@media (min-width: @tablet-min) {
				padding-top: 20px;
			}
		}
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 100%;
	}

	&__controls-wrap {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		@media (max-width: @tablet-max) {
			flex: 1 1;
			width: 100%;
			height: 100%;
			padding: 15px;
			background-color: @white;
		}

		@media (min-width: @large-tablet-min) {
			flex: none;
		}

		@media (min-width: @large-tablet-min) and (orientation: landscape) {
			width: 250px;
			padding: 28px 20px;
			background-color: @white;
			border-bottom: 1px solid @gray1;
			border-radius: 2px 0 0 2px;
		}

		@media (min-width: @large-tablet-min) and (orientation: portrait) {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
		}
	}

	&__controls {
		padding: 0 0 10px 0;
		overflow-y: auto;
		margin-top: 10px;
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		grid-gap: 30px 0;

		@media (min-width: @large-tablet-min) {
			padding: 0 20px 0 0;
			max-width: 350px;
		}

		&-col {
			width: 100%;
			@media (min-width: @large-tablet-min) and (orientation: landscape) {
				margin-bottom: 30px;
			}
		}

		&-wide-col {
			width: 100%;
		}

		&-title {
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 1;
			font-weight: 600;
			color: @black1;
		}

		&-select {
			display: block;
			width: 100%;
		}

		&-plan-select {
			white-space: pre-line !important;
		}

		&-layer-label {
			width: 100%;
			font-size: 14px;
			line-height: 1.5;
			letter-spacing: -0.01em;
			color: @black2;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&-points {
			border: 1px solid @gray1;
			border-radius: 5px;
			margin-bottom: 5px;
			cursor: pointer;

			&:hover {
				background-color: @buttonLightHover;
			}

			:active {
				background-color: @buttonLightPressed !important;
			}

			&-name {
				padding: 10px;
				margin: 0;
				font-weight: bold;
			}

			&-description {
				padding: 0 10px 10px 10px;
			}
		}

		&-result {
			display: block;
			width: 100%;

			&_margin {
				margin-bottom: 1em;

				@media (min-width: @large-mobile-min) {
					margin-bottom: 15px;
				}

				@media (min-width: @tablet-min) {
					margin-bottom: 20px;
				}
			}
		}
	}

	&__viewer-wrap {
		flex: 1 1 100%;
		height: 100%;
	}

	&__viewer {
		flex: 1 1;
		border-bottom: 1px solid @gray1;

		@media (max-width: @tablet-max) {
			flex: 1 1 100%;
			height: 100%;
		}

		@media (min-width: @large-tablet-min) {
		}

		@media (min-width: @large-tablet-min) and (orientation: landscape) {
		}

		@media (min-width: @large-tablet-min) and (orientation: portrait) {
		}
	}

	&__action-buttons {
		margin: 10px 0 0;

		@media (min-width: @large-mobile-min) {
			margin-top: 15px;
		}

		@media (min-width: @tablet-min) {
			margin-top: 20px;
		}
	}

	&__tabs-links {
		padding: 0 15px 15px;
	}

	&__plan-choose {
		&-body {
			padding: 0;
		}
	}
}
