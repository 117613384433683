@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';

.filter-button-root {
	position: relative;
	width: fit-content;
}

.filter-button {
	display: inline-block;
	height: 40px;
	padding: 0 16px;
	border-radius: 8px;
	.d-semibold-14();
	color: @black85;
	transition: background-color 0.2s ease, color 0.2s ease;

	button& {
		background-color: @white;

		.desktop &:not(&_disabled):hover {
			background-color: @buttonLightHover;
		}

		.desktop &:not(&_disabled):active, .mobile &:not(&_disabled):active {
			background-color: @buttonFilterActive;
		}
	}

	button&_active {
		background-color: @buttonFilterActive;

		.desktop &:not(&_disabled):hover {
			background-color: @buttonFilterActiveHover;
		}

		.desktop &:not(&_disabled):active, .mobile &:not(&_disabled):active {
			background-color: @buttonFilterActivePressed;
		}
	}

	&_active&_with-clearing {
		padding-right: 44px;
	}

	&_with-icon {
		padding-right: 12px;
	}

	&_disabled {
		background-color: @buttonLightHover;
		color: @black35;

		button& {
			background-color: @buttonLightHover;
		}
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__icon {
		display: block;

		&_left {
			margin-right: 12px;
		}

		&_right {
			margin-left: 12px;
		}

		i {
			display: block;
		}
	}

	&__label {
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		.d-semibold-14();
	}
}

.filter-button-clear {
	position: absolute;
	right: 8px;
	top: 0;
	bottom: 0;
	min-width: 24px;
	min-height: 24px;
	height: 24px;
	width: 24px;
	margin: auto;
	border-radius: 4px;

	button& {
		background-color: @buttonFilterClear;

		.desktop &:hover {
			background-color: @buttonFilterClearHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonFilterClearPressed;
		}
	}
}
