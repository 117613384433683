@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.legals {
  &__breadcrumbs-wrapper {
    display: flex;
    flex-direction: column;
  }


  &__breadcrumbs {
    margin: 10px 0px;
    padding: 16px 32px 0px 32px;
    @media (max-width: @tablet-min) {
      padding: 16px 16px 0px 16px;
    }

    .breadcrumb {
      padding: 0;
    }
  }

  &__page-header-wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: @tablet-min) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__page-header {
    margin: 16px 0px;
    padding: 0px 32px;
    @media (max-width: @tablet-min) {
      padding: 0px 16px;
    }

    &-amount {
      .d-regular-14();
      color: @black75;
    }
  }

  &__filters {
    display: flex;
    height: 40px;
    gap: 12px;

    padding: 0px 32px;
    @media (max-width: @tablet-min) {
      padding: 0px 16px;
    }
  }

  &__add-floating-button {
    z-index: 10;
  }

  &__cell {
    &-name {
      .d-semibold-14();
      color: @black100;
      padding-bottom: 6px;
    }
    &-company {
      .d-regular-14();
      color: @black60;

      @media (max-width: @tablet-min) {
        display: flex;
        flex-direction: column;
        .d-regular-12();
      }

      &-title {
        color: @black100;
      }
    }
    &-modified:first-child {
      padding-bottom: 4px;
    }
    &-created:first-child {
      padding-bottom: 4px;
    }
    &-delete {
      transition: opacity 0.2s ease;
      opacity: 0;
      visibility: hidden;
    }

    &-mobile {
      display: flex;

      &-select {
        padding: 10px;
        margin-right: 8px;
      }

      &-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &__date {
        .d-regular-12();

        &-name {
          color: @black60;
        }

        &-info {
          color: @black100;
          display: flex;
          gap: 8px;
        }
      }
    }
  }

  &__container {
    padding-bottom: 32px;

    &-table {
      margin: 0px 32px;
      @media (max-width: @tablet-min) {
        margin: 0px;
      }

      .table2__row_clickable {
        &:hover .legals__cell-delete {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-pagination, &-page-size {
      padding: 0px 32px;
      @media (max-width: @tablet-min) {
        padding: 0px 16px;
      }
    }
  }
}