@import (reference) "~@tehzor/ui-components/src/styles/colors";

.cell {
	padding-top: 4px;
}

.cellRest {
	padding-top: 4px;
	margin-left: 8px;
	font-weight: 600;
	color: @deepBlue;
}