@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.tasks-page {
	color: @black100;

	&__select-mode {
		min-width: 154px;

		&-btn {
			padding: 0 12px;
			cursor: pointer;
			user-select: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 154px;
			height: 32px;
			background-color: @white;
			border-radius: 6px;
			color: @black85;
			.d-semibold-12();
			border: none;
			outline: none;
			font-family: inherit;

			&-text {
				display: flex;
				column-gap: 4px;
			}

			&-title {
				color: @blue80;
			}

			&-icon {
				display: flex;
				align-items: center;
				color: @greyLight;
				transition: transform 0.1s ease-in;

				&_open {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&_add-button {
		display: flex;
		justify-content: right;
	}

	&_status {
		width: 100%;
		max-width: 130px;
	}

	&__mobile {
		@media (max-width: @tablet-min) {
			padding: 0;
		}
	}

	&__m-table {
		&-location {
			color: @black85;
			font-weight: 600;
			font-size: 12px;
		}
		&-cell {
			display: flex;
			align-content: space-between;
			margin-left: 4px;

			&-responsible {
				font-size: 12px;
				color: @black60;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				grid-gap: 8px;

				&--text {
					color: @black100;
				}
			}
			&-type {
				font-size: 12px;
				color: @black60;
			}

			&-status {
				font-size: 12px;
				font-weight: 600;
			}

			&-wrapper {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr;
				row-gap: 8px;

				&-grid {
					display: grid;
					grid-template-columns: 130px 1fr;
					column-gap: 16px;
				}
			}
		}
	}

	&__task-description {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 20px;
		line-height: 20px;

		&--attachment {
			grid-template-columns: 1fr 14px;
		}

		&-name {
			font-weight: 600;
			max-height: 40px;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&-long {
			max-height: 40px;
			text-overflow: ellipsis;
			margin-top: 4px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		&-type {
			margin-top: 4px;
			color: @black60;
		}

		&-preview {
			width: 14px;
			color: @grey;
		}
	}

	&__counter {
		font-size: 14px;
		line-height: 1.4;
		color: @grayBlue2;
		height: 50px;
		display: flex;
		align-items: center;
	}

	&__control-bar {
		display: flex;
		justify-content: space-between;
		padding: 14px 0 24px 0;
		align-items: center;

		&-switch-menu {
			margin-left: 15px;
		}
	}

	&__m {
		&-control-bar {
			display: grid;
			grid-template-columns: min-content 1fr min-content;
			grid-template-rows: auto auto;
			align-items: center;
			padding: 12px;

			@media (min-width: @tablet-min - 1px) {
				padding: 12px 0;
			}

			&-filter {
				width: 40px;
				height: 40px;
			}

			&-counter {
				justify-content: center;
			}

			&-selector {
				display: flex;
			}
		}

		&--task-description {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			grid-gap: 8px;

			&-preview {
				width: 14px;
				color: @grey;
			}
		}
		&-deadline {
			display: flex;
			flex-direction: column;
			font-size: 12px;

			&--text {
				color: @black60;
			}
		}
	}
	&__deadline {
		display: flex;
		flex-direction: column;

		&--text {
			font-size: 12px;
			color: @black60;
		}

		&-grid {
			display: grid;
			grid-template-columns: 38px 1fr;
			font-size: inherit;
			margin-top: 2px;

			&-preposition {
				color: @black60;
			}
		}

		&-tag {
			margin: 0;
			margin-top: 4px;
			max-width: 140px;
		}
	}

	&__d-table {
		&-menu {
			margin: 0;
			padding: 8px 28px 8px 8px;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: transparent;
			transition:
				background-color 0.2s,
				opacity 0.2s ease;
			visibility: hidden;
			opacity: 0;

			&-wrap {
				display: flex;
				justify-content: space-between;
			}
		}

		&-location {
			&-object {
				font-weight: 600;
			}

			&-title {
				color: @black60;
			}

			&-subtitle {
				margin-left: 4px;
			}
		}
	}
	& .table2__row_clickable {
		&:hover .tasks-page__d-table-menu {
			visibility: visible;
			opacity: 1;
			background-color: @buttonLightHover;
		}
		&:active .tasks-page__d-table-menu {
			background-color: @buttonLightPressed;
		}
	}
}
