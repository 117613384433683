@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.entity-docs-wrapper {
	margin-top: 24px;
}

.entity-docs {
	&__header {
		.d-semibold-16();
		color: @black100;
		padding: 22px 24px;
	}

	&__content {
		padding: 0 24px 22px;
		width: fit-content;
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 12px;
		margin-bottom: 16px;
	}

	&__virtualize-list {
		overflow-y: auto;
		contain: strict;
		max-height: 80vh;

		&-wrapper {
			width: 100%;
			position: relative;
		}

		&-items {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 12px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
}