@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.files-uploader-block {
	&__entity-grid-item {
		padding: 20px 24px;
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
	&__add-icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: auto;
		min-height: auto;
		padding: 0px;
	}
	&__entity-grid-title {
		.d-semibold-14();
		color: @black100;
	}
	&__entity-grid-content {
		padding-left: 32px;

		@media (min-width: @desktop-min) {
			padding-left: 0;
		}
	}
	&__entity-grid-icon {
		display: none;

		@media (max-width: @desktop-min) {
			display: block;
		}
	}
}
