@import (reference) "~@tehzor/ui-components/src/styles/colors";

.categories-cell{

	padding-top: 4px;

	&-rest {
		margin-left: 8px;
		font-weight: 600;
		color: @deepBlue;
	}
}