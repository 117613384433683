@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.entity-grid {
	overflow: hidden;

	&__wrap {
		display: grid;
		grid-template-columns: 100%;
		margin-top: -16px;
		margin-bottom: -16px;

		@media (max-width: @tablet-min - 1px) {
			grid-column-gap: 16px;
		}

		@media (min-width: @tablet-min) {
			grid-template-columns: repeat(2, 1fr);
			margin-left: -16px;
			margin-right: -16px;
		}

		.entity-grid_with-borders > & {
			margin-bottom: -17px;

			@media (min-width: @desktop-min) {
				margin-top: -24px;
				margin-bottom: -24px;
			}
		}
	}
}