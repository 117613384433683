@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-working-group-info-cell {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__wrapper {
		display: flex;
		flex-direction: row;
		padding: 12px 16px;
	}

	&__text {
		margin-left: 12px;
	}

	&__title {
		.d-semibold-16();
		margin-top: 3px;
	}

	&__subtitle {
		.d-regular-12();
		margin-top: 2px;
	}
}