@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.offline-journal-page {
	&__list {
		&-item {
			border-radius: 0;
			border-bottom: 1px solid @grey20;
	
			&:first-child {
				border-radius: 12px 12px 0 0;
			}
	
			&:last-child {
				border-radius: 0 0 12px 12px;
				border-bottom: none;
			}
		}

		&-content {
			padding: 0;
			padding-bottom: 18px;
		}

		&-expand-button {
			height: auto;
			padding: 18px 16px 16px;

			.expandable-plate-expand-btn__wrap {
				align-items: flex-start;
				justify-content: flex-end;
				flex-direction: row-reverse;
				gap: 16px;
			}

			.expandable-plate-expand-btn__label {
				display: flex;
				flex-grow: 1;
				margin-right: 0;
			}

			.expandable-plate-expand-btn__icon_right {
				color: @buttonAccent;
			}
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;

		&-breadcrumbs {
			margin-bottom: 34px;

			a:first-child {
				padding-left: 0;
			}
		}

		&-filters {
			margin-bottom: 24px;
		}

		&-display {
			display: flex;
			align-items: center;

			.page-header__sub-title {
				.d-regular-14();
			}

			&-filters {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}		
	}

	@media (max-width: @large-tablet-max) {
		&__header {
			&-breadcrumbs {
				margin-bottom: 24px;
			}

			&-display {
				justify-content: space-between;
			}
		}
	}

	@media (max-width: @tablet-min) {
		padding: 20px 0 20px;

		&__list {
			&-item {
				&:first-child {
					border-radius: 0;
				}
		
				&:last-child {
					border-radius: 0;
				}
			}

			&-expand-button {
				padding: 18px 12px 16px;
				
				.expandable-plate-expand-btn__wrap {
					gap: 12px;
				}
			}
		}

		&__header {
			padding: 0 11px;
		}
	}
}