@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';

.history-list {
	&__group {
		&:not(:first-child) {
			padding-top: 16px;
		}

		&:not(:last-child) {
			padding-bottom: 16px;
			border-bottom: 1px solid @grey10;
		}
	}

	&__date {
		margin-bottom: 25px;
		.d-semibold-14();
		color: @black45;
	}

	&__items {
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&__header {
		display: flex;
		margin-bottom: 4px;
	}

	&__user {
		flex-shrink: 0;
		margin-right: 15px;
	}

	&__info {
		.d-regular-14();
		color: @black4;
	}

	&__strong-text {
		.d-semibold-14();
	}
}
