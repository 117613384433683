@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.field-error {
	margin-top: 6px;
	color: @primaryRed;

	@media (max-width: @tablet-max) {
		.tm-regular-13();
	}

	@media (min-width: @large-tablet-min) {
		.d-regular-12();
	}
}
