@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.inline-button {
	display: inline-block;
	padding: 0;
	transition: color 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;

	&_common {
		color: rgba(@black3, 0.75);

		.desktop &:not(&_disabled)&:hover {
			color: rgba(@black3, 0.65);
		}

		&:not(&_disabled)&:active {
			color: rgba(@black3, 0.83) !important;
		}
	}

	&_accent {
		color: @deepBlue;

		.desktop &:not(&_disabled)&:hover {
			color: @buttonAccentHover;
		}

		&:not(&_disabled)&:active {
			color: @buttonAccentPressed !important;
		}
	}

	&_filled {
		background-color: @blue10;
		color: @black100;
		padding: 6px 8px;
		border-radius: 6px;
		width: fit-content;

		.inline-button__icon {
			color: @blue2;
		}

		.desktop &:not(&_disabled)&:hover {
			color: @black100;
			background-color: @blue20;
		}

		&:not(&_disabled)&:active {
			color: @black100 !important;
			background-color: @blue30 !important;
		}
	}

	&_cancel {
		color: @red2;

		.desktop &:not(&_disabled)&:hover {
			color: lighten(@red2, 10%);
		}

		&:not(&_disabled)&:active {
			color: darken(@red2, 8%) !important;
		}
	}

	&_disabled {
		color: rgba(@grayBlue4, 0.4);
		cursor: default;
	}

	&__wrap {
		display: flex;
		align-items: center;
	}

	&__icon {
		display: block;

		&_left {
			margin-right: 12px;

			&-disabled {
				i {
					color: rgba(@grayBlue4, 0.4);
				}
			}
		}

		&_right {
			margin-left: 12px;
		}

		i {
			display: block;
		}
	}

	&__label {
		.d-semibold-14();
	}
}
