@import (reference) '~@tehzor/ui-components/src/styles/colors';

.offline-journal-status {
	display: flex;
	align-items: center;
	gap: 10px;

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		width: max-content;
		padding: 4px 8px;
		border-radius: 32px;

		&_status_saved {
			background-color: @grey20;
			i {
				color: @grey70;
			}
		}
	
		&_status_progress {
			background-color: @blue10;
			i {
				color: @blue50;
			}
		}

		&_status_transferred {
			background-color: @emerald;
			i {
				color: @emerald2;
			}
		}
	
		&_status_error {
			background-color: @red10;
			i {
				color: @red50;
			}
		}
	
		i {
			font-size: 14px;
		}
	}

	&__circle-button {
		i {
			color: @blue50;
		}
	}
}