@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.message-reference {
	display: flex;
	align-items: center;
	padding: 12px 40px;
	background-color: @statusInactive25;

	&__wrapper {
		flex: 1 1;
		padding-left: 12px;
		border-left: 2px solid @deepBlue;
		overflow: hidden;
	}

	&__title {
		flex: none;
		font-weight: 600;
		color: @deepBlue;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: break-all;

		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}

	&__description {
		.tm-regular-13();
		color: @black60;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: break-all;
	}

	&__delete-btn {
		margin: 1px -8px 0 16px;
	}
}