@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-legal {
  &__wrap {
    display: flex;
    flex-direction: column;

    &-names {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
      margin-bottom: 64px;

      @media (max-width: @large-mobile-max) {
        margin-bottom: 20px;
      }
    }

    &-main-title {
      .d-semibold-16();
      margin-bottom: 14px;
    }

    &-main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      row-gap: 26px;
      margin-bottom: 64px;
      
      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: @large-mobile-max) {
        grid-template-columns: 1fr;
        margin-bottom: 26px;
      }
    }
  }
}