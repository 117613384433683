@import (reference) "~@tehzor/ui-components/src/styles/colors";


.check-list-cell {

	&__icon {
		color: @grey
	}

	&__label {
		color: @black4
	}
}

.cellIcon {
	color: @grey
}

.cellLabel {
	color: @black4
}