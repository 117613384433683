@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.manage-problem-tags-set-edit-page {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 10px;
		}
	}

	&_mobile {
		@media (max-width: @tablet-min - 1px) {
			padding: 0;
		}
	}

	&__d-table {
		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}
}
