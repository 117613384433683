@import (reference) "../../../styles/colors";

.circle-button {
	.button-base& {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4px;
		border: none;
		border-radius: 50%;
		transition: background-color 0.2s ease, color 0.2s ease;

		&_common {
			background-color: @white;
			color: @grey;

			.desktop &:hover {
				background-color: @buttonHover;
			}

			&:active {
				background-color: @buttonPressed !important;
			}
		}

		&_dark {
			background-color: rgba(48, 52, 56, 0.85);
			color: @white;

			.desktop &:hover {
				background-color: rgba(48, 52, 56, 0.7);
			}

			&:active {
				background-color: rgba(48, 52, 56, 0.95) !important;
			}
		}

	}

	&__icon {
		line-height: 1em;

		i {
			display: block;

			&::before {
				display: block;
			}
		}
	}
}