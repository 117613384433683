@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';

.history-item {
	margin-bottom: 28px;

	&__transition {
		position: relative;
		padding-left: 50px;
		color: @grey40;

		display: flex;

		@media (max-width: @tablet-min - 1) {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-left: 0px;
		}

		&-user {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		&-arrow {
			display: inline-block;
			@media (max-width: @tablet-min - 1px) {
				margin:16px;
				display: block;
				width: fit-content;
				transform: rotate(90deg);
			}

			@media (min-width: @tablet-min) {
				margin:auto 17px;
			}
		}
	}

	&__block {
		width: calc((100% - 50px)/2);
		padding: 10px 12px;
		background-color: @historyBackground;
		border-radius: 8px;

		@media (max-width: @tablet-min - 1px) {
			width: 100%;
		}
	}
}
