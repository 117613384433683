@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.dialog-menu {
	.dialog__layer&__layer {
		border-radius: 12px;
	}

	.dialog__body&__body {
		margin: 0;
		padding: 0;
	}
}