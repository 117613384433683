@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.inline-date-range-picker {
	&__week-row {
		position: relative;

		&-wrap {
			position: relative;
			z-index: 0;
		}

		&-selection {
			position: absolute;
			top: 0;
			height: 100%;
			z-index: -1;
			background-color: @buttonPressed;
			border-radius: 8px;
		}
	}
}