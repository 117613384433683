@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.indicators-dialog {
	@media (min-width: @tablet-min) {
		width: 528px;
	}

	&__content {
		height: 70vh;

		@media (max-width: @tablet-min) {
			padding: 8px 0;
		}
	}

	&__body {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding-top: 0;
		overflow-y: scroll;
		overflow-x: hidden;
		padding: 0 64px 0 64px;
		@media (max-width: @desktop-min) {
			padding: 0 16px;
		}
		@media (min-width: @tablet-max) {
			margin-bottom: 32px;
		}
		&--indicators {
			@media (max-width: @tablet-max) {
				padding-left: 0;
			}
		}
	}

	&__sets-info {
		display: flex;
		flex-direction: column;
	}

	&__set-wrapper {
		padding: 0 0 16px 0;
	}
	&__indicators-wrapper {
		@media (max-width: @tablet-max) {
			padding: 8px 0 0 0;
			width: 100%;
			gap: 6px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
	&__indicator-wrapper {
		padding: 6px 2px 6px 0px;
		@media (max-width: @tablet-max) {
			padding: 0;
			display: contents;
		}
	}
	&__select--indicator-set-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		white-space: nowrap;
		overflow: hidden;
		gap: 2px;

		&_name {
			font-size: 14px;
			line-height: 21px;
			font-weight: 600;
			margin-bottom: -1px;
			margin-top: 0;
		}

		&_stages {
			color: #747f8d;
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;
		}
	}
}
