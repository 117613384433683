@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: 1500;
	background-color: rgba(@black100, 0.2);
}