@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.mobileCell {
	display: flex;
}

.mobileSelect {
	margin-right: 8px;
	padding: 10px;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 10px;
}

.date {
	.d-regular-12();
}

.name {
	color: @black60;
}

.info {
	display: flex;
	gap: 8px;
	color: @black100;
}