@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.user-menu {
	&__button {
		.button& {
			padding-right: 0;
		}

		&-icon {
			align-self: stretch;
			margin-left: 9px;
			color: rgba(@black4, 0.45);
			transition: transform 0.2s ease, color 0.2s ease;

			&_open {
				transform: rotate(180deg);
			}
		}

		&-icon-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 48px;
			height: calc(100% + 8px);
			margin-top: -4px;
		}
	}
}
