@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.logo {
	&__icon {
		display: block;
		color: @blue2;

		@media (max-width: @large-mobile-min) {
			font-size: 33px;
		}

	}
	&_gray {
		path {
			fill: @grayBlue3;
		}
	}
}
