@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-object {
	@media (min-width: @large-tablet-min) {
		display: grid;
		grid-template-columns: calc(66% - 30px) calc(34% - 30px);
		grid-column-gap: 60px;
	}

	&__info-grid {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 25px;
		position: relative;
		grid-template-areas:
			'container'
			'city'
			'address'
			'contractor';


		@media (min-width: @large-tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
			grid-template-areas:
				'container container'
				'city contractor';
		}
	}

	&__container {
		grid-area: container;
		display: flex;
		gap: 40px;

		&>div {
			flex: 1 1 0;
		}
	}

	&__city {
		grid-area: city;
	}

	&__address {
		grid-area: address;
	}

	&__general-contractor {
		grid-area: contractor;
	}

	&__attachments {
		margin: 32px 0 0;

		&-scroll-area {
			margin-top: 0;
			padding: 0 16px 0 0;

			@media (min-width: @large-tablet-min) and (min-height: 800px) {
				max-height: 389px;
			}
		}
	}

	&__manager-field,
	&__stages,
	&__custom-fields {
		padding-top: 64px;

		&-title {
			color: @black100;
			padding-bottom: 4px;
			.d-semibold-16();
		}

		&-wrap {
			display: grid;
			grid-template-columns: 100%;
			grid-row-gap: 25px;
			position: relative;

			@media (min-width: @tablet-min) {
				grid-template-columns: calc(50% - 20px) calc(50% - 20px);
				grid-column-gap: 40px;
			}
		}
	}

	&__image-field {
		@media (max-width: @tablet-max) {
			display: flex;
			flex-direction: column-reverse;
			margin-top: 56px;
			gap: 18px
		}
	}
}