@import (reference) "../../../../../styles/variables";
@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";

.error-message {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 16px;
	width: 100%;
	height: 100%;	

	&.isColumns {
		flex-direction: column;
	}

	&__container {
		width: 100%;
		height: 100%;	
	}

	&__error-block {
		display: flex;
		gap: 8px;
		align-items: center;

		.tz-warning-20 {
			color: @red70;
		}
		.tz-info-24 {
			color: @blue2;
		}

		&-text {
			.d-semibold-14();

			@media (max-width: @desktop-min) {
				.d-semibold-12();
			}
		}

		&.isSlim {
			flex-direction: column;
		}
	}

	&__reload-block {
		&-button {
			color: @deepBlue;
			.d-semibold-14();

			@media (max-width: @desktop-min) {
				.d-semibold-12();
			}

			&.button-base {
				padding: 11px;
			}
		}
		.tz-reload-24 {
			color: @blue2;
		}
	}

	&__dialog-container {
		.d-regular-12();

		display: grid;
		grid-template-columns: 100px auto;
		gap: 12px;
		padding: 4px 0px;

		&-text {
			word-break: break-all;
		}
	}
}