@import (reference) "~@tehzor/ui-components/src/styles/colors";

.editable-relations {
	&__text-field.disabled * {
		color: @black20;
	}

	&__actions {
		display: grid;
		grid-template-columns: repeat(2, auto);
		gap: 16px;
	}

	&__checkbox {
		margin-top: 10px;
	}
}