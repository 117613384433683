@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-space-info-cell {
	display: flex;
	flex-direction: row;

	&__wrapper {
		display: flex;
		flex-direction: row;
		padding: 12px 16px;
	}

	&__view {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__indicators {
		margin-top: 8px;
		height: 4px;
		display: flex;
		justify-content: center;
	}

	&__indicator {
		height: 4px;
		width: 4px;
		border-radius: 50%;
		margin: 0 1.5px;
	}

	&__text {
		margin-left: 12px;
	}

	&__title {
		.d-semibold-14();
		margin-top: 3px;
	}

	&__status {
		.d-regular-12();
		margin-top: 2px;
	}
}
