@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.categories-set-migration-page {
	@media (max-width: @tablet-min - 1px) {
		padding: 0;
	}

	&__content{
		display: flex;

	};

	&__column{
		min-width: 400px;

	};

	&__select {
		margin: 8px;
	};

	&__button {
		margin: 8px;
	};

	&__checkbox {
		margin: 8px;
	};

	&__inline-button {
		margin: 0 5px;
	};

	&__counter {
		font-size: 14px;
		font-weight: 400;
		padding: 8px
	};

	&__header {
		font-size: 14px;
		padding: 8px
	};


	&__data{

		margin: 8px;	
		padding: 8px;
		border-radius: 4px;
		border: solid 1px;
		border-color: @gray3;
		
		&_matches{
			background-color: @green10;
		}
		
		&_equal{
			background-color: @blue10;
		}

		&_not-equal{
			background-color: @orange20;
		}
		
		&_undefined{
			background-color: @red20;
		}
	}
}