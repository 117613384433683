@import (reference) "../../styles/variables";
@import (reference) "../../styles/colors";


.wrapper {
	min-width: 200px;
	max-height: 70vh;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 24px 24px;

	@media (max-width: @large-tablet-max) {
		min-width: auto;
		max-height: none;
	}

}

.mobileIcon {
	background-color: unset !important;
	color: @greyLight !important;
	margin-right: 8px !important;
}