@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

.table-wrap {
	position: relative;
	overflow-y: auto;
}

.table2 {
	flex: auto 1;
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	overflow: hidden;

	&__head {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;
		position: relative;
		user-select: none;
		color: @black60;
	}

	&__head > &__row {
		border-top: none;
	}

	&__body {

	}


	&__row-wrap {
		position: relative;
		transition: background-color 0.2s ease;

		&_selected {
			background-color: @buttonFilterActive;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: -1px;
			bottom: 0;
			width: 4px;
			z-index: 2;
			background-color: @greyLight;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.1s ease, visibility 0s linear 0.1s;
		}


		&_expanded {
			&::before {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.1s ease;
			}
		}
	}

	&__row-wrap_with-hover {
		&:hover {
			background-color: @buttonHover;
			cursor: pointer;
		}
	}

	&__row {
		position: relative;
		z-index: 1;
		border-top: 1px solid @black10;
		transition: background-color 0.2s ease;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&_no-border {
			border-top: none;
		}

		&_clickable {
			cursor: pointer;

			.desktop &:hover {
				background-color: @buttonLightHover;
			}

			&:active {
				background-color: @buttonLightPressed !important;
			}
		}
	}

	&__row-sub-content {
		transition: background-color 0.2s ease;
		will-change: height, opacity;
	}

	&__row_expanded > &__cell {
		border-right-color: transparent;
	}
}