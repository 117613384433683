@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.pie-chart-label {
	line-height: 1;
	font-weight: 600;
	fill: rgba(@black4, 0.8);

	&_dark {
		fill: @white;
	}
}