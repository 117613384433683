@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.delegation-history-item {
	@media (min-width: @tablet-min) {
		padding-left: 8px;
	}

	&__info {
		display: flex;
		align-items: flex-start;
		.d-regular-14();

		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 16px;
		}

		&-avatar {
			flex: none;
			margin-right: 16px;
		}

		&-automatic-action-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: none;
			width: 34px;
			height: 34px;
			margin-right: 16px;
			background-color: @grey10;
			border-radius: 50%;
			color: @grey60;
		}

		&-text {
			@media (min-width: @tablet-min) {
				display: flex;
				flex-wrap: wrap;
			}
		}

		&-author {
			font-weight: 600;
			color: @black100;

			@media (min-width: @tablet-min) {
				margin-right: 8px;
			}
		}

		&-action {
			color: @black85;

			@media (max-width: @tablet-min - 1px) {
				.d-regular-12();
			}
		}
	}

	&__transition {
		position: relative;
		padding-left: 50px;
		color: @grey40;

		@media (min-width: @tablet-min) {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}

		&-user {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		&-arrow {
			display: inline-block;

			@media (max-width: @tablet-min - 1px) {
				margin: 10px;
				transform: rotate(90deg);
			}

			@media (min-width: @tablet-min) {
				margin: 10px 16px;
			}
		}
	}
}