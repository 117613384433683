@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.touchable-panel {
	position: relative;
	overflow: hidden;

	&__wrapper {
		display: inline-block;
		position: relative;
		will-change: transform;
	}

	&__content {

	}
}