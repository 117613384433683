.container {
	margin-left: -9px;
}

.cellIcon {
	margin-right: 12px;
}

.cellLabel {
	font-weight: 600;
}