@import (reference) '../../styles/colors.less';

.content-carousel {
	position: relative;

	&_clickable {
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			transition: background-color 0.2s ease;
		}

		.desktop &:hover::after {
			background-color: rgba(16, 25, 35, 0.2);
		}
	}

	&__touch-root {
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	&__wrapper {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: white;
	}
	&__img-title {
		color: @black75;
		text-align: center;
		margin: 0;
		width: 100%;
	}
	&__arrow-wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 16px;
	}
	&__arrow-button {
		opacity: 1;
		pointer-events: all;
		transition: opacity 0.2s ease, color 0.2s ease;
		color: @blue2;
		padding: 0;
		&--left {
			left: 0;
			transform: rotate(90deg);
		}

		&--right {
			right: 0;
			transform: rotate(-90deg);
		}
		&--disabled {
			color: @grey30;
		}
	}
}
