@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';

.entity-loading-status {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	border-radius: 32px;
	padding: 4px 16px 4px 10px;
	min-height: 28px;
	width: fit-content;
	.tz-offline-mode-20 {
		color: #718198;
	}
	.tz-data-transfer-20 {
		color: #57A5FF;
	}
	.tz-data-transfer-error-20 {
		color: #FF0F31;
	}
	.tz-mark-20 {
		color: #0ED78C;
	}
	&__text {
		font-size: 12px;
		color: #172A41;
		line-height: 18px;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		min-height: 18px;
	}
}