@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-problem-tag {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-top: 24px;
	padding-bottom: 24px;

	&:not(:last-child) {
		border-bottom: 1px solid #d9dde3;
	}

	&__grid {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}

	&__item {
		min-width: fit-content;
		max-width: 400px;
		padding: 10px;
		@media (max-width: @large-mobile-min - 1px) {
			max-width: 100%;
			min-width: none;
		}
	}

	&__buttons {
		margin-left: auto;
		min-width: fit-content;
		max-width: 400px;
		padding: 10px;
		@media (max-width: @large-mobile-min - 1px) {
			max-width: 100%;
			min-width: none;
		}
	}

	&_draft {
		background-color: @black05;
	}
}