@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px 0;
}

.title {
	.d-regular-14();
	margin: 0;
	color: @black85;
}