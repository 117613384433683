@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.presaleCheckPage {
	@media (max-width: (@tablet-min - 1px)) {
		padding: 16px 0 0;
	}
}

.breadcrumbs {
	@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
		margin-bottom: 16px;
	}
	@media (max-width: (@tablet-min - 1px)) {
		margin-bottom: 16px;
	}
}

.infoButtons {
	margin-top: 12px;
}

.documentsDescription {
	.d-regular-14();
	color: @black4;
}

.dPlate {
	margin-top: 24px;
}

.dColumns {
	display: flex;
	align-items: flex-start;

	.appContentPadding288 {
		@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
			display: grid;
			grid-template-columns: 368px 1fr;
			grid-template-areas:
        'attachments comments'
        'info comments';
			grid-column-gap: 24px;
		}
	}
}

.dColumn {
	position: sticky;
	top: 22px;

	&:not(:last-child) {
		margin-right: 24px;

		@media (min-width: @desktop-min) {
			margin-right: 32px;
		}
	}
}

.dColumnInfo {
	flex: none;
	@media (min-width: @large-desktop-min) {
		width: 368px;
	}
	@media (min-width: @large-tablet-min) and (max-width: (@large-desktop-min - 1px)) {
		width: 304px;
	}
}

.dColumnEntities {
	flex: 1 1;
	margin: -24px;
	padding: 24px;
	overflow-x: hidden;
}

.mVisibilityFilter {
	display: inline-block;
	width: auto;
	max-width: calc(100% - 32px);
	margin: 16px;

	& > * {
		max-width: 100%;
	}
}

.presaleCheckPage .mVisibilityFilter button {
	background-color: @buttonFilterActive;
}
