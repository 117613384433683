@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
	padding: 24px 32px;
	display: flex;
	flex-direction: column;
	gap: 24px 0;

	@media (max-width: @desktop-min) {
		padding: 8px 16px;
		gap: 16px 0;
	}

	@media (max-width: @large-mobile-max) {
		padding: 8px 0px;
		gap: 16px 0;
	}
}