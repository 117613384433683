@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-inspection-info-cell {
	padding-right: 8px;

	&_with-padding {
		padding-left: 56px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__desc-row {
		display: flex;
		align-items: flex-start;
		margin-bottom: 16px;
	}

	&__desc {
		flex: 1 1;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__image {
		width: 48px;
		margin-left: 16px;
	}
}