@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.owner-acceptance-page {
	@media (max-width: @tablet-min - 1px) {
		padding: 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 0;
		}
	}

	&__space-btn{
		padding-left: 0;
	}


	&__info-plate {
		padding: 27px 24px;
	}

	&__info-content {
		margin-top: 32px;
	}

	&__info-edit-icon {
		margin-right: 11px;
	}

	&__owner {
		border: 1px solid @black10;
		border-radius: 8px;
		padding: 12px 11px 12px 16px;
		box-sizing: border-box;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&-name {
			.d-semibold-14();
			color: @black100;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&-contact {
			.d-regular-12();
		}

		&-name,
		&-contact {
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-edit-icon {
			margin-left: 10px;
		}
	}

	&__documents {
		&-file:not(:last-child) {
			margin-bottom: 16px;
		}

		&-adding-btn {
			margin-top: 24px;
			padding: 10px;
			background-color: @buttonLight;
			border-radius: 8px;

			.button-base& {
				border: 1px solid @iconButtonHover;
			}
		}
	}

	&__d-columns {
		@media (min-width: @large-tablet-min) {
			display: flex;
			align-items: flex-start;
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
				display: grid;
				grid-template-columns: 368px 1fr;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-column-gap: 24px;
			}
		}
	}

	&__d-column {
		@media (min-width: @large-tablet-min) {
			position: sticky;
			top: 22px;
		}

		&:not(:last-child) {
			margin-right: 24px;

			@media (min-width: @desktop-min) {
				margin-right: 32px;
			}
		}

		&_info {
			flex: none;

			@media (min-width: @large-desktop-min) {
				width: 368px;
			}

			.app-content_padding_72 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					width: 304px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
					width: 368px;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					grid-area: attachments;
					position: relative;
					top: 0;
					width: 100%;
					margin: 0 0 24px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
					width: 304px;
				}
			}
		}

		&_entities {
			flex: 1 1;
			top: -2px;
			margin: -24px;
			padding: 24px;
			overflow-x: hidden;
		}
	}

	&__d-entities {
		&-info-cell {
			display: flex;
			align-items: flex-start;

			&_with-padding {
				padding-left: 58px;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: -1px;
					display: block;
					width: 68px;
					height: 1px;
					background-color: @white;
				}
			}
		}

		&-info {
			flex: 1 1;
			margin-left: 26px;
			overflow: hidden;

			&-title-1:not(:last-child) {
				margin-bottom: 16px;
			}

			&-title-2:not(:last-child) {
				margin-bottom: 12px;
			}

			&-field:not(:last-child) {
				margin-bottom: 4px;
			}

			&-title-1, &-title-2, &-field {
				max-width: 100%;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
		}

		&-problem {
			display: flex;

			&-info {
				flex: 1 1;
				overflow: hidden;
			}
		}

		&-reply {
			margin: 24px 0 0 44px;
		}

		&-fix-date {
			margin-bottom: 16px;
			font-weight: 600;
		}

		&-resp-user {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		&-link {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__d-entity-info {
		display: flex;
		align-items: flex-start;
		padding: 32px 32px 32px 80px;

		&-col {
			flex: 1 1 50%;

			&:not(:last-child) {
				padding-right: 32px;
			}

			&:first-child:last-child {
				flex: 1 1 100%;
			}
		}
	}

	&__d-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	&__m-entities {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&-item {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}

		&-info-cell {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-right: 8px;

			&_with-padding {
				padding-left: 56px;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: -1px;
					display: block;
					width: 56px;
					height: 1px;
					background-color: @white;
				}
			}
		}

		&-info {
			margin-right: 8px;
			overflow: hidden;

			&-title-1:not(:last-child) {
				margin-bottom: 16px;
			}

			&-title-2:not(:last-child) {
				margin-bottom: 12px;
			}

			&-field:not(:last-child) {
				margin-bottom: 4px;
			}
		}
	}

	&__tabs {
		@media (max-width: @desktop-min - 1px) {
			.shadow2();
		}

		@media (min-width: @desktop-min) {
			border-bottom: 1px solid @black10;
		}
	}

	&__tab-link {
		@media (max-width: @desktop-min - 1px) {
			&:first-child {
				margin-left: 18px;
			}

			&:last-child {
				margin-right: 18px;
			}
		}

		@media (min-width: @desktop-min) {
			&:first-child {
				margin-left: 24px;
			}

			&:last-child {
				margin-right: 24px;
			}
		}
	}

	&__resolution {
		.d-semibold-14();
		color: @black4;
	}
}
