@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

.photo-feed {
	position: relative;
	overflow: hidden;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&__wrapper {
		display: flex;
		margin: 0 -3px;
	}

	.photo-preview&__photo {
		display: block;
		width: 64px;
		margin: 0 3px;
		border-radius: 6px;
	}
}