@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

@prefix: downloadable-file;

.@{prefix} {
	display: inline-block;
	border-radius: 8px;

	.desktop &:hover {
		.@{prefix}__name {
			color: @black60;
			text-decoration: underline;
		}

		.@{prefix}__size {
			color: @black45;
		}
	}

	&:active {
		.@{prefix}__name {
			color: @black95 !important;
		}

		.@{prefix}__size {
			color: @black75 !important;
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__icon {
		flex: none;
		color: @black85;

		&_left {
			display: flex;
			min-width: 40px;
			gap: 8px;
		}

		i {
			display: block;
		}
	}

	&__label {
		flex: auto;
		overflow-x: hidden;
	}

	&__name {
		font-weight: 600;
		color: @black85;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: color 0.2s ease;
		word-break: break-word;
	}

	&__size {
		.tm-regular-13();
		color: @black60;
		transition: color 0.2s ease;

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}
}