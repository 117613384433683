@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.delegation-select-option {
	padding: 16px 0 16px 100px;
	transition: background-color 0.2s ease, color 0.2s ease;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&_level_0 {
		padding-left: 0;
	}

	&_level_1 {
		padding-left: 40px;
	}

	&_level_2 {
		padding-left: 80px;
	}

	&_selectable {
		cursor: pointer;

		.desktop &:hover {
			background-color: @buttonLightHover;
			color: @black75;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonLightPressed;
			color: @black75;
		}
	}

	&_selected {
		background-color: @buttonLightSelected;
		color: @black95;
	}

	&__header {
		display: flex;
		align-items: center;
		padding: 0 18px;

		&_extra-padding {

		}
	}

	&__select-wrap {
		display: flex;
		min-width: 20px;
		margin-right: 18px;
	}

	&__info {
		flex: 1 1;
	}

	&__rules {
		margin-top: 10px;
		padding-left: 56px;
	}
}