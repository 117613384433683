@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.companyCell {
	color: @black60;
	.d-regular-14();

	@media (max-width: @tablet-min) {
		display: flex;
		flex-direction: column;
		.d-regular-12();
	}
}

.title {
	color: @black100;
}