@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.adding-work-acceptance-dialog {
	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	@media (max-width: @tablet-min) {
		&__body {
			padding-top: 0px;
		}
	}

	&__header {
		padding-bottom: 8px;
	}

	&__title {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&__entity-type-select {
		.d-semibold-16();

		display: flex;
		align-items: center;
		gap: 12px;
		user-select: none;

		padding: 9px 0px;

		& > .tz-simple-arrow-20 {
			color: @blue2;
			transition: transform 0.2s ease;

			&.isOpen {
				transform: rotate(180deg);
			}
		}

		&:hover:not(.disabled) {
			cursor: pointer;
			color: @blue90;
		}
		&:active:not(.disabled) {
			color: @blue50;
		}
	}
}
