@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.tree-select-tag-option {
	padding-left: 138px;

	.switch&_level {
		&_0 {
			padding-left: 18px;
		}

		&_1 {
			padding-left: 58px;
		}

		&_2 {
			padding-left: 98px;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		margin-left: 15px;
		min-height: 48px;
	}
	&__label {
		font-weight: 600 !important;
	}
	&__expand-btn {
		.icon-button& {
			min-width: 32px;
			min-height: 32px;
			margin-right: 12px;
			border-radius: 6px;
			color: @buttonAccent;
		}

		&-icon {
			transform: rotate(0deg);
			transition: transform 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);

			&_expanded {
				transform: rotate(-180deg);
			}
		}
	}

	&__children {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}
}

.tree-select-tag-option-wrap {
	margin: 0;
	padding: 0;

	li& {
		list-style: none;
	}
}
