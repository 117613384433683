@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-result-work-acceptance-dialog {
	width: 60%;

	@media (max-width: @large-tablet-max) {
		width: 100%;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}
}