@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.problems-stats-cards {
	display: grid;
	grid-row-gap: 16px;
	align-content: start;

	@media (max-width: @tablet-min) {
		grid-template-columns: 100%;
	}

	&_col_3 {
		grid-column-gap: 16px;

		@media (max-width: @desktop-min + 99px) {
			grid-template-columns: minmax(auto, 344px);
			justify-content: center;
		}

		@media (min-width: @desktop-min + 100px) {
			grid-template-columns: repeat(2, minmax(auto, 344px));
		}

		@media (min-width: @large-desktop-min - 100px) {
			grid-template-columns: repeat(3, minmax(auto, 344px));
		}
	}

	&_col_4 {
		grid-column-gap: 32px;

		@media (max-width: @large-tablet-min + 99px) {
			grid-template-columns: minmax(auto, 344px);
		}

		@media (min-width: @large-tablet-min + 100px) {
			grid-template-columns: repeat(2, minmax(auto, 344px));
		}

		@media (min-width: @desktop-min + 150px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (min-width: @large-desktop-min) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&__card {}

	&__card-placeholder {
		height: 56px;
	}
}