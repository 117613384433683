@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.popup-plate {
	padding: 16px;
	border-radius: 14px;

	&__content {
		border-radius: unset;
	}
}
