@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-space-type-decoration {
	&__header {
		display: flex;
		align-items: center;

		&-actions {
			margin-left: auto;
		}
	}

	&__body {

	}

	&__item {
		display: grid;
		grid-gap: 0px 10px;
		grid-template-columns: 1fr 35px;
		grid-template-areas:
			"input actions"
			"space actions";
		margin-bottom: 15px;

		&-input-name {
			grid-area: input;
		}

		&-input-space {
			grid-area: space;
		}

		&-remove-button {
			grid-area: actions;
		}

	}

}