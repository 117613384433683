@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.work-acceptance-info-cell {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&__info {
		flex: 1 1;
		margin-left: 26px;
		overflow: hidden;
	}

	&__desc {
		overflow: hidden;
		display: -webkit-box;
		flex: 1 1;
		-webkit-box-orient: vertical;

		margin-bottom: 8px;

		color: @black4;

		-webkit-line-clamp: 2;
	}

	&__acceptors {
		margin-bottom: 8px;
	}

	&__indicators {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		gap: 16px;
	}

	&__d-table {
		&__cell {
			&-header {
				.d-semibold-14();
				color: @black4;
			}

			&-rest {
				color: @deepBlue;
				.d-semibold-14();
			}
		}
	}
}
