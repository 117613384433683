@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-meter {
	width: 100%;

	&__info-grid {
		display: grid;
		@media (min-width: @tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
		}
		grid-row-gap: 12px;
		margin-bottom: 12px;
	}

	&__consumptions-grid {
		display: grid;
		@media (min-width: @tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
		}
		padding: 32px 0;
		grid-row-gap: 12px;
		&:not(:last-child) {
			border-bottom: 1px solid @black10;
		}
	}

	&__field {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding-bottom: 21px;
	}

	&_label {
		font-size: 16px;
		font-weight: 600;
	}
}
