@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.offline-journal-item {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-left: 54px;
	.d-regular-14();

	&__info {
		display: flex;
		flex-direction: column;

		span {
			color: @black60;
		}
	}

	&__error {
		display: flex;
		flex-direction: column;
		align-self: start;

		padding: 8px;

		background-color: @red10;
		border-radius: 8px;

		span {
			color: @black60;
		}
	}

	@media (max-width: @tablet-min) {
		gap: 8px;
		padding-right: 52px;
		padding-left: 52px;
	}
}
