@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/common";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.objects-group {
	margin-bottom: 25px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		min-height: 51px;
		padding-bottom: 10px;
	}

	&__title {
		margin-right: 10px;
		.d-semibold-18();
		color: @black4;
	}

	&__buttons {
		display: flex;
		align-items: center;
	}

	&__arrow-btn {
		&-wrap {
			@media (max-width: @large-mobile-max) {
				&:not(:last-child) {
					margin-right: 10px;
				}
			}

			@media (min-width: @tablet-min) {
				min-width: 120px;
			}
		}

		&-label {
			font-size: 12px;
		}

		&-icon {
			transform: rotate(0deg);
			transition: transform 0.3s ease;
		}
	}

	&_visible &__arrow-btn-icon {
		transform: rotate(-180deg);
	}

	&__list {
		&:before, &:after {
			content: none;
			display: none;
		}

		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;

		font-size: 10px;

		@media (min-width: @large-tablet-min) {
			font-size: 11px;
		}

		@media (min-width: @large-mobile-min) and (max-width: @tablet-max) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: @large-tablet-min) and (max-width: @large-tablet-max) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (min-width: @desktop-min) and (max-width: @desktop-max){
			grid-template-columns: repeat(4, 1fr);
		}
		@media (min-width: @large-desktop-min) {
			grid-template-columns: repeat(5, 1fr);
		}

		.app-content_padding_288 & {
			@media (min-width: @desktop-min) and (max-width: @desktop-max) {
				grid-template-columns: repeat(3, 1fr);
			}
			@media (min-width: @large-desktop-min) and (max-width: (@large-desktop-min + @dockedSidebarMax)) {
				grid-template-columns: repeat(4, 1fr);
			}
			@media (min-width: (@large-desktop-min + @dockedSidebarMax + 1px)) {
				grid-template-columns: repeat(5, 1fr);
			}
		}
	}
}