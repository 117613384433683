@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.internal-acceptances-page {
	position: relative;

	@media (max-width: @large-mobile-max) {
		padding: 16px 0 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}

		@media (max-width: @large-mobile-max) {
			margin-bottom: 16px;
		}
	}

	&__filters-bar {
		@media (max-width: @large-tablet-max) {
			margin-bottom: 12px;
			display: flex;
			justify-content: flex-start;
		}

		@media (max-width: @large-mobile-max) {
			padding: 0 16px;
			display: flex;
			justify-content: flex-start;
		}
	}

	&__search {
		margin-right: 12px;
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__header {
		margin-bottom: 16px;

		@media (max-width: @large-mobile-max) {
			padding: 0 16px;
		}

		&-wrap {
			flex-wrap: wrap;
		}

		&-left {
			align-self: flex-start;
		}

		&-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			flex: 1 1;
			margin-bottom: 10px;
		}

		&-btn {
			margin: 14px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-export-icon {
			width: 12px;
			height: auto;
		}

		&-email-icon {
			width: 15px;
			height: auto;
		}

		&-add-report-btn {
			margin: 3px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-add-report-icon {
			width: 12px;
			height: auto;

			path {
				fill: @white;
				stroke: @white;
			}
		}
	}

	&__tabs-links {
		margin-bottom: 30px;
	}

	&__mobile-cell {
		color: @black4;

		&-info {
			display: flex;
			padding: 0 6px;
		}

		&-select {
			padding-right: 18px;
		}

		&-header {
		}

		&-number {
			.d-semibold-14();
		}

		&-description {
			.d-regular-14();
			margin-top: 4px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -moz-box;
			-moz-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;
		}

		&-created {
			margin-top: 8px;
			.d-regular-12();
			display: flex;
			flex-direction: column;

			&-label {
				color: @black60;
			}

			&-container {
				display: flex;
			}

			&-date {
				width: fit-content;
			}

			&-name {
				margin-left: 8px;
				width: fit-content;
			}
		}

		&-object {
			margin-top: 8px;
			.d-semibold-12();
		}
	}

	&__d-table {
		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}

	&__problems-stats-cell {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		&:not(:first-of-type) {
			margin-left: 8px;
		}
		&-icon {
		}

		&-label {
		}

		&-inspection-icon {
			color: @grey;
		}
	}
}
