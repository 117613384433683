@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.adding-check-dialog {
	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title-slash {
		color: @greyLight;
	}

	&__check-select {
		.d-semibold-16();

		&-btn-label {
			.d-semibold-16();
		}
	}
}