@import (reference) '~@tehzor/ui-components/src/styles/colors';

.objects {
	display: grid;
	grid-column-gap: 20px;
	grid-template-columns: 1fr;
	line-height: 20px;
}

.objectItem {
	font-size: 14px;
	line-height: 20px;
}

.moreObjectsText {
	font-size: 14px;
	line-height: 20px;
	color: @deepBlue;
}