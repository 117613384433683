@import (reference) "../../../../styles/colors";
@import (reference) "../../../../styles/variables";

.map-viewer {
	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&__map {
		display: block;
		width: 100%;
		height: 100%;
	}
}
