@import (reference) "~@tehzor/ui-components/src/styles/colors";

.spaces-sort-icon& {
    display: inline-block;
    margin: 0 16px;
    color: @blue2;
    background-color: @white;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover {
        background-color: @buttonFilterActiveHover
    }

    &:active {
        background-color: @buttonFilterActive
    }

    &_asc {
        transform: scale(1, -1);
    }
}