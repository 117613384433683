@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.progress {
	position: relative;
	height: 0.6em;
	border: 1px solid @gray1;
	border-radius: 1.5px;
	
	font-size: 10px;
	
	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}
	
	&__line {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		transition: width 0.2s ease, background-color 0.2s ease;
		
		&_started {
			background-color: @blue;
		}
		
		&_success {
			background-color: @green;
		}
		
		&_error {
			background-color: @red;
		}
	}
}
