@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.mobile-photo-viewer {
	&__layer {
		background-color: @greyPressed;
		box-shadow: unset;
	}

	.dialog__body&__body {
		position: relative;
		padding: 0;
	}

	&__frame {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	&__wrapper {
		display: flex;
		height: 100%;
	}

	&__image-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__image {
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}