@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-space-owner {
	&__phone-wrapper {
		display: flex;
		gap: 24px;
		margin-bottom: 21px;
		
		@media (max-width: calc(@tablet-min - 1px)) {
			flex-direction: column;
			gap: 0;
		}
	}

	&__field {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 21px;
		}
	}
}