@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-contract {
	@media (min-width: @large-tablet-min) {
		display: grid;
		grid-template-columns: calc(66% - 30px) calc(34% - 30px);
		grid-column-gap: 60px;
	}

	* > div {
		
	}

	&__fields {
		&-text {
			&__label {

			}
		}

		&-number {
			&__label {

			}
		}

		&-select, &-tree-select, &-single-select {
			&__label {

			}
			&__content {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&__text-field {
				text-overflow: ellipsis;
			}
			&__dialog {
				&-search {
					padding-bottom: 16px;
				}
				&-footer {
					&-btns {
						display: flex;
						gap: 16px;
					}
				}
			}
		}
	}

	&__blocks {
		display: flex;
		flex-direction: column;
		gap: 64px;

		&-name, &-price-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		&-company, &-contractor, &-main, &-dates {
			&-container {
				display: grid;
				grid-template-columns: calc(50% - 20px) calc(50% - 20px);
				grid-column-gap: 40px;
				grid-row-gap: 20px;

				@media (max-width: @tablet-min) {
					grid-template-columns: 100%;
				}
			}
		}

		&-company, &-contractor, &-main, &-dates, &-price {
			&-title {
				.d-semibold-16();
				color: @black100;
				padding-bottom: 4px;
			}
		}
		&-name {
			
		}

		&-company {
			&-title {

			}
			&-container {
				
			}
		}

		&-contractor {
			&-title {

			}
			&-container {
				
			}
		}

		&-main {
			&-title {

			}
			&-container {
				
			}
		}

		&-dates {
			&-title {

			}
			&-container {
				
			}
		}

		&-price {
			&-title {

			}
			&-container {
				
			}
		}
	}
}