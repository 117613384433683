@import (reference) "../../../../../../styles/colors";
@import (reference) "../../../../../../styles/variables";
@import (reference) "../../../../../../fonts/tz-icons/variables";

.checkbox-icon {
	position: relative;
	width: 20px;
	height: 20px;
	border: 1px solid @black35;
	border-radius: 4px;
	color: @white;
	overflow: hidden;
	transition: background-color 0.2s ease, border-color 0.2s ease;

	.desktop .switch:not(.switch_disabled):hover &:not(&_checked) {
		border-color: @buttonAccentHover;
	}

	.switch:not(.switch_disabled):active &:not(&_checked) {
		border-color: @buttonAccentPressed !important;
	}

	.switch:focus-visible & {
		border-color: @black60;
	}

	&_checked {
		border-color: @buttonAccent;
	}

	&_disabled:not(&_checked) {
		background-color: @black05;
		border-color: @black10;
	}

	.switch:focus-visible &_disabled {
		border-color: @black35;
	}

	&_disabled&_checked {
		border-color: @black20;

		.checkbox-icon__check-icon-bg {
			background-color: @black20;
		}
	}

	.switch:focus-visible &_disabled&_checked {
		.checkbox-icon__check-icon-bg {
			background-color: @black35;
		}
	}

	&_disabled&_semi-checked {
		background-color: transparent;
	}

	&_disabled&_semi-checked &__semi-check-icon {
		background-color: @black20;
	}

	.switch:focus-visible &_disabled&_semi-checked &__semi-check-icon {
		background-color: @black35;
	}

	&__icon-wrap {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__check-icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin-top: -1px;

		&-bg {
			width: 100%;
			height: 100%;
			background-color: @buttonAccent;
			transition: background-color 0.2s ease;
		}
	}

	&__semi-check-icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 10px;
		height: 10px;
		background-color: @buttonAccent;
		border-radius: 3px;
		transition: background-color 0.2s ease;
	}
}
