@import (reference) 'colors';

.d-title-bold-28 {
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
}

.d-bold-24 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
}

.d-semibold-24 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 600;
}

.d-bold-18 {
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
}

.d-semibold-18 {
	font-size: 18px;
	line-height: 27px;
	font-weight: 600;
}

.d-semibold-17 {
	font-size: 17px;
	line-height: 23px;
	font-weight: 600;
}

.d-bold-16 {
	font-size: 16px;
	line-height: 27px;
	font-weight: 700;
}

.d-semibold-16 {
	font-size: 16px;
	line-height: 27px;
	font-weight: 600;
}

.d-body-bold-14 {
	font-size: 14px;
	line-height: 21px;
	font-weight: 700;
}

.d-semibold-14 {
	font-size: 14px;
	line-height: 21px;
	font-weight: 600;
}

.d-regular-14 {
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
}

.d-semibold-12 {
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
}

.d-regular-12 {
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}

.tm-title-bold-24 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
}

.tm-bold-20 {
	font-size: 20px;
	line-height: 27px;
	font-weight: 700;
}

.tm-bold-17 {
	font-size: 17px;
	line-height: 23px;
	font-weight: 700;
}

.tm-semibold-17 {
	font-size: 17px;
	line-height: 23px;
	font-weight: 600;
}

.tm-body-bold-15 {
	font-size: 15px;
	line-height: 22px;
	font-weight: 700;
}

.tm-semibold-15 {
	font-size: 15px;
	line-height: 22px;
	font-weight: 600;
}

.tm-regular-15 {
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
}

.tm-body-bold-13 {
	font-size: 13px;
	line-height: 18px;
	font-weight: 700;
}

.tm-semibold-13 {
	font-size: 13px;
	line-height: 18px;
	font-weight: 600;
}

.tm-regular-13 {
	font-size: 13px;
	line-height: 18px;
	font-weight: 400;
}

.tm-semibold-11 {
	font-size: 11px;
	line-height: 16px;
	font-weight: 600;
}

.tm-regular-11 {
	font-size: 11px;
	line-height: 16px;
	font-weight: 400;
}

.tm-bold-10 {
	font-size: 10px;
	line-height: 16px;
	font-weight: 700;
}

.tm-semibold-10 {
	font-size: 10px;
	line-height: 16px;
	font-weight: 600;
}

.regular {
	font-weight: 400;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}
