@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.adding-meter-dialog {
	@media (min-width: @desktop-min) {
		width: 704px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__title {
		display: flex;
		align-items: center;
	}

	&__back-btn {
		margin-right: 8px;
		transform: rotate(180deg);
	}

	&__body {
		display: flex;
	}

	&__bind-btn-wrap {
		margin-top: 20px;
		padding-top: 16px;
		border-top: 1px solid @black10;
		text-align: center;
	}

	&__loader {
		padding: 12px;
		text-align: center;
	}
}
