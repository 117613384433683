@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-resp-user {

	&-row {
		display: flex;
		flex-direction: row;
		align-content: space-between;
		gap: 40px;

		&>div {
			width: 100%;
			overflow: hidden;
		}
	}

	&>div:not(:last-child) {
		margin-bottom: 25px;
	}

	&>div:last-child {
		margin-bottom: 34px;
	}


}