@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.color-select-button {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 12px;
	box-shadow: 0 10px 30px rgba(@black4, 0.1);
	opacity: 1;
	transition: background-color 0.2s ease, opacity 0.2s ease;

	@media (max-width: @large-mobile-min){
		width: 20px;
		height: 20px;
		border-radius: 10px;
	}
	.desktop &:not(&_disabled):hover {
		opacity: 0.7;
	}
	&_border{
    	border-radius: 15px;
    	padding: 2px;
		@media (max-width: @large-mobile-min){
			border-radius: 15px;
		}
	}
	&:not(&_disabled):active {
		opacity: 0.9 !important;
	}
}
