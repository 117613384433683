@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';

.entity-info {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 16px;
	align-items: flex-start;
	margin-bottom: 8px;

	&_no-title {
		display: flex;
		grid-column-gap: unset;
	}

	&__title-block {
		white-space: nowrap;
		overflow: hidden;
	}

	&__title {
		color: @black100;
		overflow-x: hidden;
		text-overflow: ellipsis;
		.d-semibold-14();
		transition: background-color 0.2s ease, color 0.2s ease;
	}

	&__sub-title {
		margin-top: 4px;
		color: @black75;
		overflow-x: hidden;
		text-overflow: ellipsis;
		.d-regular-12();
	}

	&__sub-sub-title {
		display: flex;
		flex-direction: row;
		color: @black45;
		overflow-x: hidden;
		text-overflow: ellipsis;
		.d-regular-12();
	}

	&__icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;

		&_size {
			&_32 {
				width: 32px;
				height: 32px;

				& > i {
					font-size: 20px;
				}
			}

			&_40 {
				width: 40px;
				height: 40px;

				& > i {
					font-size: 24px;
				}
			}

			&_44 {
				width: 44px;
				height: 44px;

				& > i {
					font-size: 28px;
				}
			}
		}
	}

	&__link-icon {
		color: @buttonAccent;
		.d-regular-12();
	}

	&__link {
		.d-regular-12();
		color: @black85;
	}
	&__spaces {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0 4px;
		align-items: center;
	}
	&__tag {
		padding: 0;
		color: @deepBlue;
		font-family: Open Sans;
		font-size: 12px;
		font-weight: 600;
		line-height: 15.84px;
		text-align: left;
	}
}
