@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.space-page {
	&__loading-panel {
		width: 100%;
		min-height: 100vh;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}

		@media (max-width: (@tablet-min - 1px)) {
			margin-bottom: 0;
		}
	}

	&__filters-bar {
		@media (max-width: @large-tablet-max) {
			margin-bottom: 12px;
		}
	}

	&__info-title {
		.d-semibold-14();
		color: @black4;
		margin-bottom: 19px;
	}

	&__info-plate {
		padding: 17px 24px 24px;
	}

	&__info-content {
		margin-top: 32px;
	}

	&__info-edit-icon {
		margin-right: 5px;
	}

	&__area {
		display: grid;
		grid-column-gap: 4px;
		grid-template-columns: auto auto;
		align-items: center;

		&-title {
			padding-top: 3px;
			color: @black85;

			@media (min-width: @desktop-min) {
				.d-regular-14();
			}
		}

		&-value {
			.d-semibold-14();

			color: @black100;
			text-align: right;
		}
	}
	&__entity-grid-content {
		padding-left: 0;
	}
	&__tasks {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: fit-content;

		&-link {
			width: fit-content;
			word-break: break-word;
		}
	}

	&__meter {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		box-sizing: border-box;
		padding-right: 7px;
		padding-bottom: 10px;

		&-serial-number {
			max-width: (100% - 26px);
			word-wrap: break-word;
		}

		&-block {
			&-content {
				padding: 0 9px 24px 24px;

				@media (max-width: @large-tablet-max) {
					padding: 0 16px 16px;
				}
			}

			&-btn {
				height: 68px;
				div {
					.d-semibold-14();
					white-space: break-spaces;
					text-align: left;
					color: @black4;
				}

				i {
					color: @buttonAccent;
				}

				@media (max-width: @large-tablet-max) {
					height: 56px;
					padding-right: 31px;
				}
			}

			@media (max-width: (@desktop-min - 1px)) {
				margin-top: 16px;
			}
		}

		&-type {
			&:not(:last-child) {
				margin-bottom: 12px;
				border-bottom: 1px solid @black10;
			}
		}

		&-name {
			.d-semibold-14();

			color: @black100;

			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-adding-btn {
			justify-content: center;

			width: 100%;
			max-width: 320px;
			margin-top: 24px;
			padding: 10px 10px 10px -36px;

			color: @deepBlue;

			background-color: @buttonLight;
			border-radius: 8px;

			.button-base& {
				border: 1px solid @iconButtonHover;
			}

			@media (max-width: (@desktop-max)) {
				max-width: 250px;
			}

			@media (max-width: @large-tablet-max) {
				max-width: none;
			}
		}

		&-edit-icon {
			margin-left: 10px;
		}
	}

	&__owners {
		@media (min-width: @desktop-min) {
			padding-left: 20px;
		}
	}

	&__owner {
		box-sizing: border-box;
		padding-right: 7px;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		&-name {
			.d-semibold-14();

			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			color: @black100;
		}

		&-contact {
			.d-regular-14();

			&-description {
				word-break: break-word;
			}
		}

		&-name,
		&-contact {
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-edit-icon {
			margin-left: 10px;
		}

		@media (min-width: @desktop-min) {
			padding: 12px 11px 12px 16px;
			border: 1px solid transparent;
			border-radius: 12px;

			&:hover {
				border: 1px solid @black10;
			}
		}
	}

	&__documents {
		&-file:not(:last-child) {
			margin-bottom: 16px;
		}

		&-adding-btn {
			margin-top: 24px;
			padding: 10px;
			background-color: @buttonLight;
			border-radius: 8px;

			.button-base& {
				border: 1px solid @iconButtonHover;
			}
		}
	}

	&__d-columns {
		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
				display: grid;
				grid-column-gap: 24px;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-template-columns: 368px 1fr;
			}
		}

		@media (min-width: @large-tablet-min) {
			display: flex;
			align-items: flex-start;
		}
	}

	&__d-column {
		&:not(:last-child) {
			margin-right: 24px;

			@media (min-width: @desktop-min) {
				margin-right: 32px;
			}
		}

		&_info {
			display: grid;
			grid-auto-rows: auto;
			grid-row-gap: 16px;
			flex: none;

			.app-content_padding_72 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					width: 304px;
				}

				@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
					width: 368px;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					position: relative;
					top: 0;

					grid-area: attachments;

					width: 100%;
					margin: 0 0 24px;
				}

				@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
					width: 304px;
				}
			}

			@media (min-width: @large-desktop-min) {
				width: 368px;
			}
		}

		&_entities {
			top: -2px;

			overflow-x: hidden;
			flex: 1 1;

			margin: -24px;
			padding: 24px;
		}

		@media (min-width: @large-tablet-min) {
			position: sticky;
			top: 22px;
		}
	}

	&__d-entity-info {
		display: flex;
		align-items: flex-start;
		padding: 32px 32px 32px 80px;

		&-col {
			flex: 1 1 50%;

			&:not(:last-child) {
				padding-right: 32px;
			}

			&:first-child:last-child {
				flex: 1 1 100%;
			}
		}
	}

	&__d-controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__tabs {
		@media (max-width: (@desktop-min - 1px)) {
			.shadow2();
		}

		@media (min-width: @desktop-min) {
			border-bottom: 1px solid @black10;
		}
	}

	&__tab-link {
		@media (max-width: (@desktop-min - 1px)) {
			&:first-child {
				margin-left: 18px;
			}

			&:last-child {
				margin-right: 18px;
			}
		}

		@media (min-width: @desktop-min) {
			&:first-child {
				margin-left: 24px;
			}

			&:last-child {
				margin-right: 24px;
			}
		}
	}

	&__m-visibility-filter {
		display: inline-block;
		width: auto;
		max-width: calc(100% - 32px);
		margin: 16px;

		& > * {
			max-width: 100%;
		}
	}

	@media (max-width: (@tablet-min - 1px)) {
		padding: 0 0 42px;
	}

	&__entity-grid-content-sets {
		display: flex;
		gap: 20px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
	&__sets-info-dialog {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__indicators-filter {
		&--sets-mobile-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
		&--set-mobile-wrapper {
			display: flex;
			flex-direction: column;
			gap: 2px;
		}
		&--set-mobile-header {
			display: flex;
			font-weight: 400;
			flex-direction: column;
			text-align: left;
			padding: 8px 16px 8px 16px;
			gap: 4px;
		}

		&--indicator-set-item_name-mobile {
			color: #172a41;
			font-size: 14px;
			line-height: 19.6px;
		}
		&--indicator-set-item_stages-mobile {
			font-size: 12px;
			color: #515f71;
		}
	}
}
