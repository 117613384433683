@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.problem-reply-cell {
	display: grid;
	grid-template-columns: auto min-content;
	grid-template-areas: 'title image'
		'desc image';
	padding: 16px;
	background-color: @replyBackground;
	border-radius: 8px;

	@media (max-width: 339px) {
		grid-template-columns: 1fr;
		grid-template-areas: 'title image'
			'desc desc';
	}

	.app-content_padding_72 & {
		@media (min-width: @desktop-min) and (max-width: 1399px) {
			grid-template-columns: 1fr;
			grid-template-areas: 'title'
			'image'
			'desc';
		}

		@media (min-width: 1400px) and (max-width: 1499px) {
			grid-template-columns: 1fr;
			grid-template-areas: 'title image'
			'desc desc';
		}
	}

	.app-content_padding_288 & {
		@media (min-width: @desktop-min) and (max-width: 1499px) {
			grid-template-columns: 1fr;
			grid-template-areas: 'title'
			'image'
			'desc';
		}

		@media (min-width: 1500px) and (max-width: (@large-desktop-min - 1px)) {
			grid-template-columns: 1fr;
			grid-template-areas: 'title image'
			'desc desc';
		}
	}

	&__info {
		flex: 1 1;
		overflow: hidden;
	}

	&__title-row {
		grid-area: title;
		align-self: start;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	&__title {
		margin-right: 6px;

		@media (max-width: (@desktop-min - 1px)) {
			.tm-semibold-15();
		}

		@media (min-width: @desktop-min) {
			.d-semibold-14();
		}
	}

	&__date {
		.d-regular-12();
		color: @black60;
	}

	&__desc {
		grid-area: desc;
		margin-top: 8px;

		@media (max-width: (@desktop-min - 1px)) {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			.d-regular-12();
			color: @black85;
		}

		@media (min-width: @desktop-min) {
			max-width: 100%;
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}

	&__image {
		grid-area: image;

		@media (max-width: (@desktop-min - 1px)) {
			width: 48px;
			height: 48px;
			margin-left: 16px;
		}

		@media (min-width: @desktop-min) {
			width: 52px;
			height: 52px;
			margin-left: 36px;
		}

		.app-content_padding_72 & {
			@media (min-width: @desktop-min) and (max-width: 1399px) {
				margin-top: 8px;
				margin-left: 0;
			}

			@media (min-width: 1400px) and (max-width: 1499px) {
				margin-left: 16px;
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @desktop-min) and (max-width: 1499px) {
				margin-top: 8px;
				margin-left: 0;
			}

			@media (min-width: 1500px) and (max-width: (@large-desktop-min - 1px)) {
				margin-left: 16px;
			}
		}
	}
}