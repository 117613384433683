@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

@class: ~'.menu-close-button';

@{class} {
	&__cont {
		position: relative;
		width: 24px;
		height: 24px;
	}

	&__line-1, &__line-2, &__line-3 {
		position: absolute;
		height: 2px;
		background-color: @grey;
		border-radius: 1px;
		transform-origin: 50% 50%;
	}

	@{class}__line-1 {
		top: 6px;
		left: 4px;
		width: 16px;
		transition: top 0.08s ease 0.1s, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	@{class}__line-2 {
		top: 11px;
		left: 4px;
		width: 16px;
		transition: opacity 0.08s ease 0.1s;
	}

	@{class}__line-3 {
		top: 16px;
		left: 4px;
		width: 16px;
		transition: top 0.08s ease 0.1s, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	&_active {
		@{class}__line-1 {
			top: 11px;
			left: 0;
			width: 24px;
			transform: rotate(45deg);
			transition: top 0.08s ease, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		}

		@{class}__line-2 {
			opacity: 0;
			transition: opacity 0.01s ease 0.1s;
		}

		@{class}__line-3 {
			top: 11px;
			left: 0;
			width: 24px;
			transform: rotate(-45deg);
			transition: top 0.08s ease, left 0.08s ease, width 0.08s ease, transform 0.08s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		}
	}
}