@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-critical-checkbox {
	margin-top: 16px;

	&__label {
		font-weight: 700;
	}

	&__explanation {
		margin-left: 8px;
		color: @black45;
	}
}