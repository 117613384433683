@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.structure-page {
	@media (max-width: @tablet-min - 1px) {
		padding: 0 0 42px;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 0;
		}
	}
	&__filters-bar {
		@media (max-width: @large-tablet-max) {
			margin-bottom: 12px;
		}
	}

	&__info-plate {
		padding: 17px 24px 24px 24px;
	}

	&__info-content {
		margin-bottom: 12px;

		&_check-list {
			padding: 16px;
		}
	}

	&__info-edit-icon {
		margin-right: 5px;
	}
	&__info-title {
		.d-semibold-14();
		color: @black4;
		margin-bottom: 19px;
	}

	&__area {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 4px;
		align-items: center;

		&-title {
			padding-top: 3px;
			color: @black85;

			@media (min-width: @desktop-min) {
				.d-regular-12();
			}
		}

		&-value {
			.d-semibold-14();
			text-align: right;
			color: @black100;
		}
	}

	&__owner {
		padding-right: 7px;
		box-sizing: border-box;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		@media (min-width: @desktop-min) {
			border: 1px solid transparent;
			border-radius: 12px;
			padding: 12px 11px 12px 16px;

			&:hover {
				border: 1px solid @black10;
			}
		}

		&-name {
			.d-semibold-14();
			color: @black100;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&-contact {
			.d-regular-14();
		}

		&-name,
		&-contact {
			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}

		&-edit-icon {
			margin-left: 10px;
		}
	}

	&__documents {
		&-file:not(:last-child) {
			margin-bottom: 16px;
		}

		&-adding-btn {
			margin-top: 24px;
			padding: 10px;
			background-color: @buttonLight;
			border-radius: 8px;

			.button-base& {
				border: 1px solid @iconButtonHover;
			}
		}
	}

	&__d-columns {
		@media (min-width: @large-tablet-min) {
			display: flex;
			align-items: flex-start;
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
				display: grid;
				grid-template-columns: 368px 1fr;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-column-gap: 24px;
			}
		}
	}

	&__d-column {
		@media (min-width: @large-tablet-min) {
			position: sticky;
			top: 22px;
		}

		&:not(:last-child) {
			margin-right: 24px;

			@media (min-width: @desktop-min) {
				margin-right: 32px;
			}
		}

		&_info {
			display: grid;
			grid-auto-rows: auto;
			grid-row-gap: 16px;
			flex: none;

			@media (min-width: @large-desktop-min) {
				width: 368px;
			}

			.app-content_padding_72 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					width: 304px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
					width: 368px;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					grid-area: attachments;
					position: relative;
					top: 0;
					width: 100%;
					margin: 0 0 24px;
				}

				@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
					width: 304px;
				}
			}
		}

		&_entities {
			flex: 1 1;
			top: -2px;
			margin: -24px;
			padding: 24px;
			overflow-x: hidden;
		}
	}

	&__d-entity-info {
		display: flex;
		align-items: flex-start;
		padding: 32px 32px 32px 80px;

		&-col {
			flex: 1 1 50%;

			&:not(:last-child) {
				padding-right: 32px;
			}

			&:first-child:last-child {
				flex: 1 1 100%;
			}
		}
	}

	&__d-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	&__tabs {
		@media (max-width: @desktop-min - 1px) {
			.shadow2();
		}

		@media (min-width: @desktop-min) {
			border-bottom: 1px solid @black10;
		}
	}

	&__tab-link {
		@media (max-width: @desktop-min - 1px) {
			&:first-child {
				margin-left: 18px;
			}

			&:last-child {
				margin-right: 18px;
			}
		}

		@media (min-width: @desktop-min) {
			&:first-child {
				margin-left: 24px;
			}

			&:last-child {
				margin-right: 24px;
			}
		}
	}

	&__m-visibility-filter {
		display: inline-block;
		width: auto;
		max-width: calc(100% - 32px);
		margin: 16px;

		& > * {
			max-width: 100%;
		}
	}

	&__check-lists {
		width: 100%;
		padding: 8px 16px 8px 16px;
	}

	&__work-acceptance-cell {
		padding: 4px;

		&_data {
			padding-top: 8px;
			color: @black85;
			font-size: 12px;
		}
	}

	&__check-list {
		&__loading-panel {
			min-height: 100vh;
		}

		&__links-dialog {
			&-title {
				.d-semibold-16();
				margin-bottom: 22px;
			}
		}

		&__divider {
			border: none;
			border-bottom: 1px solid @black10;
			margin: 0;

			&_hr {
				margin: '0 16px';
			}
		}

		&__body-toggle {
			grid-area: toggle;
			margin: 10px 0;
			.d-regular-12();

			&-link .link-button__label {
				.d-regular-12();
			}

			&-link_active i {
				transform: rotate(180deg);
			}
		}

		&__float-actions {
			float: right;
		}

		&__indicators {
			display: flex;

			& > *:not(:last-child) {
				margin-right: 13px;
			}

			&:not(:empty) + &:not(:empty)::before {
				width: 4px;
				height: 4px;
				display: flex;
				background: @grey30;
				border-radius: 50%;
				content: ' ';
				align-self: center;
				margin: 14px;
			}
			&:first-child::before {
				display: none;
			}
		}

		&__status {
			position: relative;
			display: grid;
			grid-column-gap: 5px;
			grid-template-columns: auto 1fr;
			grid-template-areas: 'select percent';

			@media (max-width: @large-mobile-min) {
				grid-template-rows: 1fr;
				grid-row-gap: 5px;
				grid-template-areas:
					'select'
					'percent';
			}

			&-select {
				grid-area: select;
				color: @black95;

				&_hidden {
					visibility: hidden;
				}

				&_disabled {
					pointer-events: none;
				}

				&-menu {
					color: @black95;
				}
			}

			&-loader {
				position: absolute;
				margin: auto 0;
				width: 28px;
				height: 28px;
			}

			&-percent {
				display: flex;
				grid-area: percent;
				width: 60px;

				& input {
					height: 32px;
				}

				& .text-field__div {
					min-height: auto;
					padding-top: 7px;
					padding-bottom: 7px;
					margin: 0;
				}

				& .text-field__wrap {
					@media (max-width: @large-mobile-min) {
						border-radius: 0;
						border: none;
						background-color: @grey10;
					}
				}

				& button {
					padding: 5px 10px;
					border: none;
					background-color: @grey10;
					color: @grey40;

					&:active {
						color: @blue2;
					}

					&:first-of-type {
						border-radius: 0 0 29px 29px;
						transform: rotate(90deg);
					}

					&:last-of-type {
						border-radius: 0 0 29px 29px;
						transform: rotate(-90deg);
					}
				}
			}
		}

		&__m-nav {
			grid-area: nav;
		}

		&__m-list-detail-info &__m-list {
			.d-semibold-16();
		}

		&__m-item-detail .check-item-detail {
			grid-area: category;
		}

		&__m-item-detail {
			display: grid;
			grid-row-gap: 16px;
			grid-template-rows: 1fr;
			grid-template-areas:
				'nav'
				'category';
		}

		&__m-list-detail {
			display: grid;
			grid-row-gap: 16px;
			grid-template-rows: 1fr;
			grid-template-areas:
				'nav'
				'info'
				'items';

			&-info {
				grid-area: info;
				padding: 0 16px 16px;

				&-tags {
					display: flex;
					flex-wrap: wrap;
					margin-top: 16px;

					& > * {
						flex: 0 0 auto;
						margin: 2px;
					}
				}
				&-header {
					padding: 0;
				}
				&-menu {
					margin-left: auto;
				}
			}

			&-items {
				grid-area: items;
			}
			&-tabs {
				margin-bottom: 16px;
			}
		}
		&__m-list-detail-space-info {
			padding: 16px;
			&-header {
				margin-bottom: 0 !important;
			}
			&-link {
				padding: 0;
				margin-left: 20px;
			}
		}

		&__m-container {
			padding: 0;
		}

		&__m-check-list {
			&__container {
				padding: 16px 16px 0 16px;
			}
		}

		&__m-lists {
			display: flex;
			flex-direction: column;
			padding: 0 16px;
		}

		&__m-list {
			display: flex;

			&-status {
				margin-top: 16px;
			}

			.d-semibold-14();

			&-bullet {
				flex: 0 0 auto;
			}

			&-text {
				margin-left: 10px;
				&-subtitle {
					margin-top: 4px;
					.d-regular-12();
				}
			}

			&-indicators {
				display: flex;
			}
			&-status {
				&-select {
					margin: 16px 0;
					height: 32px;
				}
			}
		}

		&__d-plate {
			margin-bottom: 16px;

			&-content {
				padding: 0 !important;
			}

			&_expanded &-header {
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
			}

			&-header {
				padding: 20px 24px 12px 24px;
				cursor: pointer;

				display: grid;
				grid-gap: 0 12px;
				grid-template-columns: 50px auto;

				grid-template-areas:
					'diagram title'
					'diagram indicators'
					'diagram status'
					'diagram toggle';

				&-title {
					display: inline-block;
					grid-area: title;

					.d-semibold-16();
					line-height: 21px;
					color: @black100;

					&-text {
						display: flex;
						align-items: center;
					}

					&-arrow {
						display: inline-block;
						vertical-align: middle;
						margin: 0 22px;
						color: @blue2;
						&_reversed {
							transform: rotate(180deg);
						}
					}
				}

				&-indicators {
					display: flex;
					grid-area: indicators;
					margin-bottom: 10px;
				}

				&-status {
					grid-area: status;

					&-wrap {
						float: left;
					}
				}

				&-diagram {
					grid-area: diagram;
				}
			}
		}

		&__d-grid {
			display: grid;
			grid-column-gap: 32px;
			grid-template-columns: 1fr 3.25fr;
			grid-template-areas:
				'info-col main-col'
				'none main-col';
		}

		&__d-info {
			grid-area: info-col;
		}

		&__d-actions {
			display: flex;
			justify-content: right;
			margin-bottom: 10px;

			& > button i {
				font-size: 20px !important;
			}
		}

		&__d-main {
			position: relative;
			display: grid;
			grid-area: main-col;
			grid-column-gap: 32px;
			grid-template-columns: 3fr 2fr;
			grid-template-areas:
				'list-col detail-col'
				'list-col none';

			&-list {
				grid-area: list-col;
			}

			&-detail {
				grid-area: detail-col;
				position: sticky;
			}
		}
	}
}
