@import (reference) '../../../styles/variables.less';

.search-select-popup {
  width: 100%;
	float: left;

	&__list {
		height: 300px;
		contain: strict;
		overflow: auto;

		&-inner {
			width: 100%;
			position: relative;

			&-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}
	}

	&__select {
		width: 100%;
		height: 400px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		@media (max-width: @desktop-min - 1px) {
			height: calc(100vh - 290px);
			max-height: none;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
		}

		&_popup {
			height: auto;
			max-height: 400px;

			@media (max-width: @desktop-min - 1px) {
				height: auto;
				max-height: calc(100vh - 290px);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}
		}

		label {
			align-items: flex-start;
		}

		&_no-checkbox {
			.switch__icon {
				display: none;
			}

			.switch__content {
				margin-left: 0;
			}
		}
	}
}