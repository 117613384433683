@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.tasks-schedule-popup {
	color: @black100;

	@media (min-width: @tablet-min) {
		width: 740px;
	}

	@media (min-width: @desktop-min) {
		width: 1100px;
	}

	&__content {
		@media (min-width: @tablet-min) {
			width: 740px;
		}

		@media (min-width: @desktop-min) {
			width: 100%;
		}

		@media (min-width: @tablet-min) and (min-height: 432px) {
			min-height: 400px
		}

		@media (min-width: @desktop-min) and (min-height: 482px) {
			min-height: 450px
		}
	}

	&__list-content {
		font-size: 14px;
		padding: 4px;
		line-height: 16px;

		.table2__row :nth-child(4) {
			display: flex;
			justify-content: center;
		}

		@media (min-width: @mobile-min) {
			font-size: 11px;
			padding: 2px;
			line-height: 14px;
		}

		@media (min-width: @tablet-min) {
			font-size: 12px;
			padding: 4px;
			line-height: 16px;
		}

		@media (min-width: @desktop-min) {
			font-size: 14px;
			padding: 8px;
			line-height: 18px;
		}
		&_button {
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&_time {
			display: flex;
			flex-direction: column;
			align-items: center;

			&-grid {
				display: grid;
				grid-template-columns: 20px 1fr;
				font-size: inherit;
				align-self: center;
				margin-top: 2px;
			}

			&-tag {
				margin: 0;
				margin-top: 4px;
			}
		}

		&_notime {
			font-size: inherit;
			line-height: 14px;
			text-align: center;
			background-color: #fff1f1;
			border-radius: 5px;
			padding: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: fit-content;
			padding: 4px;
		}

		&_task {
			display: table-cell;
			line-height: 1.125rem;

			&-name {
				font-weight: 400;
			}

			&-description {
				margin-top: 6px;
				color: @black60;
			}

			&-preview {
				margin-top: 8px;
				@img-width: 75px;
				width: @img-width;
				height: @img-width;
				min-width: @img-width;
				min-height: @img-width;

				@media (max-width: @large-desktop-min) {
					@img-width: 50px;
					width: @img-width;
					height: @img-width;
					min-width: @img-width;
					min-height: @img-width;
					margin-top: 12px;
				}
			}
		}

		&_status-wrapper {
			padding: 12px 0;
		}

		&_status {
			width: 100%;
		}

		&_priority-wrapper {
			padding: 12px 6px;
		}

		&_deadline-wrapper {
			padding: 12px 6px;
		}

		&_responsible-wrapper {
			padding: 12px 6px;
		}

		&_nouser {
			font-size: inherit;
			line-height: inherit;
			padding: 4px;
			text-align: center;
			background-color: #fff1f1;
			border-radius: 5px;
		}

		&_user {
			font-size: inherit;
			line-height: inherit;
			padding: inherit;
		}
	}

	&__button-add-task {
		position: absolute;
		font-size: 16px;
		vertical-align: middle;
		right: 70px;
		top: 10px;
		display: flex;
		align-items: center;
		z-index: 30;
		border: none;
		min-width: 40px;
		min-height: 40px;
		border-radius: 8px;
		color: #3391FF;
		background-color: white;
		transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;
		line-break: loose;

		span {
			margin-left: 8px;
		}

		&:hover {
			background-color:#F4F9FD;
		}

		@media (min-width: @tablet-min) {
			top: 8px;
		}

		@media (min-width: @desktop-min) {
			top: 16px;
		}
	}
}