@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.documents-count {
	display: flex;
	gap: 8px;
	width: fit-content;
	height: fit-content;

	&__icon {
		color: @grey;
	}

	&__number {
		.d-regular-12();
		color: @black4;
	}
}
