@import (reference) "../../../styles/colors";

.uploading-file {
	display: flex;
	align-items: center;

	&__info-wrapper {
		flex: auto;
		overflow-x: hidden;
	}

	&__info {
		max-width: 100%;

		&_waiting &-name, &_error &-name, &_local &-name {
			color: @black60;
		}

		&_waiting &-size, &_error &-size, &_local &-size {
			color: @black35;
		}
	}

	&__error-icon {
		color: @statusExpired;
	}

	&__warning-icon {
		color: @statusVerification;
	}

	&__reload-btn {
		flex: none;
		margin-right: 8px;
	}

	&__delete-btn {
		flex: none;
	}
}