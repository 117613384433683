@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.m-checklist-problem-info-cell {

	max-width: calc(100% - 36px);

	&-wrapper {
		display: flex;
		justify-content: space-between;
	}

	&-inner {
		display: flex;
		max-width: calc(100% - 64px);
		&__row-select {
			margin-right: 12px;
		}
	}

	&__problem {
		padding-right: 16px;

		&_with-padding {
			padding-left: 56px;
		}

		&-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 4px;

			&-title {
				display: flex;
				margin-bottom: 4px;
			}
		}

		&-desc {
			overflow: hidden;
			display: -webkit-box;
			flex: 1 1;
			-webkit-box-orient: vertical;

			margin-bottom: 8px;

			color: @black4;

			-webkit-line-clamp: 2;
		}

		&-image {
			width: 64px;

			@media (max-width: @tablet-min) {
				width: 48px;
			}

		}

		&-fix-date {
			font-weight: 400;
		}

		&-status-row {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			margin-top: 8px;
			padding-right: 8px;

			.problem-status-cell {
				flex-direction: row;
				gap: 16px;
			}
		}
	}

	&__markers {
		display: flex;
		margin-left: 8px;
	}

	&__extra-info {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-bottom: 8px;

		&-icon {
			margin-right: 8px;
			color: @greyLight;
		}

		&-fix-date {
			.d-regular-12();

			line-height: 16px;
		}

		&-value {
			.d-regular-12();

			overflow-x: hidden;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&--fix-date {
		margin-bottom: 4px;
	}

	&__reply {
		margin: 20px 0 0 72px;
	}
}