@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.popup {
	z-index: 1600;
	background-color: @white;
	border-radius: 8px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);

	&_as-plate {
		padding: 16px;
		border-radius: 14px;
	}

	&__content {
		border-radius: inherit;
		overflow: hidden;
	}

	&_as-plate &__content {
		border-radius: unset;
	}
}
