@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-reply {
	@media (min-width: @large-tablet-min) {
		width: 700px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__status-transition {
		margin-bottom: 36px;

		@media (min-width: @large-mobile-min) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-label {
			.d-semibold-14();
			color: @black95;
		}

		&-btn {
			@media (max-width: @large-mobile-min - 1px) {
				margin-top: 16px;
			}
		}
	}

	&__description {

	}

	&__attachments {
		margin: 32px 0 24px;

		&-scroll-area {
			margin-top: 32px;
			padding: 8px 16px 0 0;

			@media (min-width: @large-tablet-min) and (min-height: 800px) {
				max-height: 200px;
			}

			@media (min-width: @large-tablet-min) and (min-height: @large-desktop-min-height) {
				max-height: 300px;
			}
		}

		&-files {

		}

		&-file {

		}
	}
}