.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	background-color: #FFFFFF;
	color: #3391FF;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.2s ease, color 0.2s ease;

	&:hover {
		background-color: #F4F9FD;
	}
}

.revers {
	transform: scale(1, -1);
}