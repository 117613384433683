@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.editable-categories {
	&__with-offset {
		@media screen and (max-width: @large-mobile-max) {
			margin-bottom: 44px;
		}
	}

	&__text {
		margin-top: 24px;
	}

	&__item {
		&-dnd {
			&-handle {
				margin-top: 13px;

				@media screen and (max-width: @tablet-max) {
					margin-top: 10px;
				}
			}
			&-without-handle {
				margin-left: 32px;
			}
		}

		&.isDraggable {
			margin-left: 14px;
		}

		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;

		&-index {
			font-weight: 600;
			font-size: 16px;
			color: @black60;
			line-height: 27px;
			padding: 13px 0px;
			margin: 0px;

			@media screen and (max-width: @tablet-max) {
				font-size: 14px;
				padding-top: 8px;
				margin: 0px;
			}
		}

		&-name {
			width: 100%;
			position: relative;
			z-index: 1;
			margin-left: 12px;
			overflow: hidden;

			&__view-wrap {
				width: 100%;
				margin: 1px;
				background-color: transparent;
				border: none;
			}

			&__element {
				font-size: 14px;
				color: @black4;
				white-space: pre;
				text-wrap: balance;
			}
		}

		&-options {
			padding-left: 24px;

			@media screen and (max-width: @tablet-max) {
				min-width: auto;
			}

			&__view {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 8px;
				margin: 4px 0px;

				&-delete,
				&-add {
					z-index: 2;
				}

				@media (hover: hover) {
					opacity: 0;

					.editable-categories__item:hover & {
						opacity: 1;
					}
				}

				@media (max-width: @tablet-max) {
					opacity: 1;
					justify-content: center;
				}
			}

			&__edit {
				padding: 4px 0px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 8px;

				@media screen and (max-width: @large-mobile-max) {
					z-index: 2;
					position: fixed;
					width: 100%;
					bottom: 0px;
					right: 0px;
					padding: 8px 15px 16px 0px;
					background-color: white;
					justify-content: flex-end;
				}
			}
		}
	}

	&__preview {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
		align-items: center;

		&-icon {
			color: @black60;
			opacity: 0.5;
		}

		&-count {
			.d-semibold-18();
			color: @black60;
			background-color: @buttonFilterActive;
			border-radius: 6px;
			padding: 0px 8px;
		}

		&-text {
			width: 100%;
			margin: 1px;
			color: @black100;
			background-color: transparent;
			border: none;
		}
	}
}

.editable-categories__dialog {
	@media (min-width: @tablet-min) {
		width: 704px;
	}

	&-wrapper {
		display: flex;
		margin-bottom: 40px;
		flex-direction: column;
	}

	&-field {
		width: 100%;
		margin-bottom: 20px;
	}

	&-title {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 40px;
	}

	&-field-label {
		font-size: 14px;
		font-weight: 400;
		opacity: 0.6;
		margin-bottom: 5px;
	}
}
