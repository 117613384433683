@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.d-header-menu {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;

	&__items {
		display: flex;
		justify-content: flex-end;
		align-items: stretch;
		height: 100%;
		overflow: hidden;
	}

	&__item {
		&:not(:last-child) {
			margin-right: 48px;
		}
	}

	&__more-btn {
		flex: none;
		margin-left: 48px;
		color: @grey;
	}
}