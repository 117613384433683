@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.space-statuses-sets {
	&__loading-panel {

	}

	&__breadcrumbs {
		margin: 10px 0px;
		padding: 16px 32px 0px 32px;
		@media (max-width: @tablet-min) {
			padding: 16px 16px 0px 16px;
		}

		.breadcrumb {
			padding: 0;
		}
	}
	&__filters {
		display: flex;
		gap: 12px;

		padding: 0px 32px;
		@media (max-width: @tablet-min) {
			padding: 0px 16px;
		}

		@media (max-width: @large-mobile-max) {
			justify-content: space-between;
			&-search {
				width: 100%;
				.select-search_filter {
					max-width: none;
				}
			}
		}
	}
	&__page-header {
		margin: 16px 0px;
		padding: 0px 32px;
		@media (max-width: @tablet-min) {
			padding: 0px 16px;
		}

		&-amount {
			.d-regular-14();
			color: @black75;
		}
	}

	&__cell {
		&-name {
			.d-regular-14();
			@media (max-width: @tablet-min) {
				.d-regular-12();
			}

			&-text {
				.d-semibold-14();
				color: @black100;
				padding-bottom: 6px;
			}
			&-amount {
				color: @black75;
			}
		}
		&-company {
			display: flex;
			gap: 8px;
		
			&-title {
				color: @black100;
				.d-semibold-14();
				@media (max-width: @tablet-min) {
					.d-semibold-12();
				}
			}
			&-name {
				.d-regular-14();
				@media (max-width: @tablet-min) {
					.d-regular-12();
				}
			}
		}
		&-objects {
			.d-regular-14();
			@media (max-width: @tablet-min) {
				.d-regular-12();
			}
			
			&-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis; 
			}
			&-number {
				.d-semibold-14();
				color: @deepBlue;
			}
		}

		&-modified:first-child {
			padding-bottom: 4px;
		}

		&-delete {
			transition: opacity 0.2s ease;
			opacity: 0;
			visibility: hidden;
		}

		&-mobile {
			display: flex;

			&-select {
				padding: 10px;
				margin-right: 8px;
			}

			&-container {
				margin-top: 10px;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}

			&__date {
				.d-regular-12();

				&-name {
					color: @black75;
				}

				&-info {
					display: flex;
					gap: 8px;
				}
			}
		}
	}

	&__container {
		&-table {
			margin: 0px 32px;
			@media (max-width: @tablet-min) {
				margin: 0px;
			}

			.table2__row_clickable {
				&:hover .space-statuses-sets__cell-delete {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&-pagination, &-page-size {
			padding: 0px 32px;
			@media (max-width: @tablet-min) {
				padding: 0px 16px;
			}
		}
	}
}