@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.categories-set {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
	&__controls {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
		@media (max-width: @large-tablet-min) and (min-width: calc(@tablet-min - 1px)) {
			margin: 20px 0px;
		}
		@media (max-width: @tablet-min) {
			margin: 10px;
		}
	}

	&__wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 368px auto;
		grid-column-gap: 32px;
		align-items: flex-start;

		@media (max-width: @large-tablet-min) {
			grid-template-columns: auto;
			grid-template-rows: auto auto;
			grid-row-gap: 32px;
		}

		&-info {
			position: sticky;
			width: 368px;
			margin-right: 20px;

			&__name, &__relations, &__stages, &__save {
				width: 348px;
			}

			@media (max-width: @large-tablet-min) {
				padding-top: 20px;
				width: 100%;

				&__name, &__relations, &__stages, &__save {
					width: 100%;
				}
			}
		}

		&-table {
			flex-grow: 3;

			padding: 24px;

			@media (max-width: @large-tablet-min) {
				padding: 16px;
			}

			&__title {
				font-family: 'Open Sans';
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
				color: @black100;
				padding-bottom: 16px;
			}

			&__cell-color {
				min-width: fit-content;
				min-height: 30px;
				border-radius: 16px;
			}
		}
	}

	&__button {
		position: absolute;
		right: 32px;
		bottom: -32px;
	}

	&__loading-panel {
		@media (max-width: @tablet-min) {
			padding: 0 0 20px;
		}
	}
}