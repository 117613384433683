@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/shadows';

.entity-link-button {
	.button& {
		padding: 0 16px;
		border: none;
	}

	&_common:not(&_disabled) {
		background-color: @white;
		color: @deepBlue;
		.shadow4();

		.desktop &:hover {
			background-color: @buttonHover;
		}

		&:active {
			background-color: @buttonPressed !important;
		}
	}

	&_accent:not(&_disabled) {
		background-color: @buttonAccent;
		color: @white;
		.shadow4();

		.desktop &:hover {
			background-color: @buttonAccentHover;
			color: @white;
		}

		&:active {
			background-color: @buttonAccentPressed !important;
			color: @white !important;
		}
	}

	&_inline:not(&_disabled) {
		background-color: transparent;
		color: @deepBlue;

		.desktop &:hover {
			background-color: transparent;
			color: @buttonAccentHover;
		}

		&:active {
			background-color: transparent !important;
			color: @buttonAccentPressed !important;
		}
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	a&_disabled {
		color: @black20;

		.desktop &:hover {
			color: @black20;
		}

		&:active {
			color: @black20 !important;
		}
	}

	.button__icon&__icon_left {
		margin-right: 10px;
	}
}
