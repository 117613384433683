@keyframes placeholder-animation {
	0% {
		opacity: 1;
	}
	10% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}