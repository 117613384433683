@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/common';

.container {
	display: inline-flex;
	height: 40px;
	align-items: center;
	padding: 0 4px;
	background-color: white;
	border-radius: 8px;

	@media (max-width: @mobile-max) {
		width: 100%;
	}
}

.wrap {
	align-items: center;
	height: 40px;
	width: auto;
	border-radius: 8px;
	background-color: white;
	border: none;

	@media (max-width: @mobile-max) {
		width: 100%;
	}
}

.root {
	width: 250px;
	@media (max-width: @mobile-max) {
		width: 100%;
	}
}

.menu {
	max-width: 350px;

	@media (min-width: @tablet-min) {
		width: 328px;
	}
}

.menuItems {
	margin-bottom: 15px;
}

.input {
	.d-semibold-14();

	@media (max-width: @mobile-max) {
		width: 100%;
	}

	/* Убираем стрелочки type=number для Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Убираем стрелочки type=number для Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
}

.placeholder {
	position: absolute;
	top: 10px;
	right: 25px;
	color: @black60;
	.d-regular-14();
}

.clearbutton {
	position: relative;
	min-width: 24px;
	min-height: 24px;
}

.divider {
	border: none;
	margin: 8px 0;
	border-bottom: 1px solid @grey20;
}

.header {
	margin: 20px 16px;
	.d-semibold-14();
}

.headerMobile {
	.tm-bold-20();
	margin-bottom: 16px;
}

.menuItemTitle {
	.d-regular-14();
	color: @black100;
	@media (min-width: 1024px) {
		margin: 16px 0;
	}
}

.icon {
	min-width: 24px;
	min-height: 24px;
	margin-left: 10px;
}
