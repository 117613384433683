@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.color-picker-button {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 12px;
	box-shadow: 0 10px 30px rgba(@black4, 0.1);
	opacity: 1;
	transition: background-color 0.2s ease, opacity 0.2s ease;

	.desktop &:not(&_disabled):hover {
		opacity: 0.7;
	}

	&:not(&_disabled):active {
		opacity: 0.9 !important;
	}
}
