@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.eye-loader {
	@main-color: @blue2;
	@sub-color: white;

	&__lower-base,
	&__upper-base,
	&__apple,
	&__pupil {
		fill: @main-color;
	}
	&__back {
		fill: @sub-color;
	}

	&__apple, &__back {
		animation: apple-movement 3.2s ease-out infinite alternate;
	}

	&__pupil {
		animation: pupil-movement 3.2s ease-out infinite alternate;
	}
}

@keyframes apple-movement {
	0%, 20% {
		transform: translateX(0em);
	}
		
	35%, 65% {
		transform: translateX(.3em);
	}
	
	80%, 100% {
		transform: translateX(.6em);
	}
}

@keyframes pupil-movement {
	0%, 20% {
		transform: translate(0);
	}
	
	35%, 65% {
		transform: translate(.7em, -.05em);
	}
	
	80%, 100% {
		transform: translate(1.4em, 0em);
	}
}
