@import (reference) "~@tehzor/ui-components/src/styles/colors";

.responsibles-view-field {
	&:not(:last-child) {
	}

	&__body {
		display: flex;
		align-items: stretch;
	}

	&__progress {
		flex: none;
		position: relative;
		width: 16px;
		margin-right: 16px;

		&-start {
			position: absolute;
			top: 16px;
			left: 4px;
			width: 8px;
			height: 8px;
			background-color: @grey20;
			border-radius: 50%;
		}

		&-line {
			position: absolute;
			top: 23px;
			left: 7px;
			width: 2px;
			height: 41px;
			background-color: @grey20;
		}

		&-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 68px;
			left: 0;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			color: @white;

			&_green {
				background-color: @green70;
			}

			&_blue {
				background-color: @blue60;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 16px;
	}

	&__btn {
		margin-top: 6px;

		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	&__group {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__user {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}
}