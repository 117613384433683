@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.switch {
	display: inline-block;
	color: @black4;
	user-select: none;
	cursor: pointer;
	transition: color 0.2s ease;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&_disabled {
		color: @black60;
		cursor: default;
		pointer-events: none;
	}

	&:not(&_disabled) {
		.desktop &:hover {
			color: @buttonAccentHover;
		}

		&:active {
			color: @buttonAccentPressed !important;
		}
	}

	&__input {
		display: none;
	}

	&__icon {
		flex: none;
	}

	&__label-wrap {
		display: flex;
		align-items: center;
		cursor: inherit;
	}

	&__content {
		flex: 1 1;
		margin-left: 12px;
		margin-top: -1px;
		.d-regular-14();
	}
}
