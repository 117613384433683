// Mobile
@mobile-min: 320px;
@mobile-max: 479px;

// Large Mobile
@large-mobile-min: 480px;
@large-mobile-max: 767px;

// Tablet
@tablet-min: 768px;
@tablet-max: 1023px;

// Large Tablet
@large-tablet-min: 1024px;
@large-tablet-max: 1279px;

// Desktop
@desktop-min: 1280px;
@desktop-max: 1679px;

// Large Desktop
@large-desktop-min: 1680px;

// SideBar
@dockedSidebarMin: 80px;
@dockedSidebarMax: 300px;

@dockedSidebarMinTablet: 56px;
@dockedSidebarMaxTablet: 272px;

@dockedSidebarMobile: 304px;

// Header
@headerHeight: 5.5em;
@mobile-header-height: 56px;
@desktop-header-height: 72px;

// Heights
@large-tablet-min-height: 600px;
@desktop-min-height: 760px;
@large-desktop-min-height: 920px;