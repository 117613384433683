@import (reference) "../../../styles/colors";

.clickable-plate {
	user-select: none;
	
	&.hasHandleClick {
		cursor: pointer;

		.plate:hover {
			background-color: @buttonLightHover;
		}

		.plate:active {
			background-color: @buttonLightPressed;
		}
	}
}