@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-task {
	@media (min-width: @large-tablet-min) {
		display: grid;
		grid-template-columns: calc(66% - 30px) calc(34% - 30px);
		grid-column-gap: 60px;
	}

	& > div:not(:last-child) {
		margin-bottom: 25px;
	}

	& > div:last-child {
		margin-bottom: 34px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 25px;
		position: relative;

		@media (min-width: @tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
		}
	}

	&__description {
		@media (min-width: @tablet-min) {
			grid-column: span 2;
		}
	}
}