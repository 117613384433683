@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.fix-date-tag {
	color: @black75;

	&__icon {
		&_warning {
			color: @statusWaiting;
		}

		&_expired {
			color: @statusExpired;
		}
	}
}