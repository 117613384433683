@import (reference) '~@tehzor/ui-components/src/styles/variables';

.editing-export-template {

	&__container {
		display: flex;
		@media (max-width: @tablet-min - 1px) {
			display: flex;
			flex-direction: column;
		}
	}

	&__info-grid {

		width:368px;
		@media (max-width: @tablet-min - 1px) {
			width: auto;
		}
	}

	&__items-grid {
		margin-left: 32px;
		width:100%;
		@media (max-width: @tablet-min - 1px) {
			margin-left:0;
			margin-top:12px;
		}
	}
}