@import (reference) "~@tehzor/ui-components/src/styles/colors";

.empty-table-message {
	flex-grow: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&__icon {
		color: @greyLight;
	}

	&__text {
		margin: 36px 0 0 0;
		width: 208px;
		font-size: 14px;
		font-weight: 600;
		color: @black35;
		text-align: center;
	}
}