@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables.less";

.table-cell {
	padding: 24px 18px;
	word-break: break-word;
	overflow: hidden;

	@media (max-width: @large-desktop-min) {
		padding: 12px;
	}

	&_with-border {
		&:not(:last-child) {
			border-right: 1px solid @black05;
		}
	}
}