@import (reference) "../../../styles/colors";

.editable-field-label {
	margin-bottom: 11px;
	color: @black85;
	display: flex;

	&__addon {
		margin-left: auto;
	}
}