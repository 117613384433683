@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.presaleCheckProblemInfoCell {
	padding-right: 8px;
}

.problemWithPadding {
	padding-left: 56px;
}

.problemHeader {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.problemHeaderCheckBox {
	margin-left: 8px;
}

.problemDesc {
	flex: 1 1;
	margin-bottom: 10px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.problemImage {
	width: 48px;
	margin-left: 16px;
}

.problemFixDate {
	font-weight: 600;
}

.problemStatusRow {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 16px;
	padding-right: 8px;
}

.problemStatusCell {
	flex-direction: row;
	gap: 15px;
}

.extraInfo {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.extraInfoIcon {
	margin-right: 8px;
	color: @greyLight;
}

.extraInfoValue {
	max-width: 100%;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.reply {
	margin: 20px 0 0 72px;
}
