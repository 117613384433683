.space {
	&__indicators {
		display: flex;
		flex-wrap: wrap;
		flex-basis: 20%;
		margin-bottom: 5px;
		justify-content: center;
		height: 10px;
	}

	&__indicator {
		margin: 2px 2px;
		width: 5px;
		height: 5px;
	}

}