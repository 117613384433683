@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.stats-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 8px;
	background-color: rgba(@black4, 0.05);
	border-radius: 14px;

	&_clickable {
		cursor: pointer;
		transition: opacity 0.2s ease;

		.desktop &:hover {
			opacity: 0.5;
		}
	}

	&__icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;
		width: 56px;
		height: 56px;
		margin: -8px 0 -8px -8px;
		border-radius: 14px;
		color: @greyLight;
	}

	&__label {
		flex: 1 1 100%;
		.d-regular-14();
		color: rgba(@black3, 0.8);
		word-break: break-word;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&__icon-wrap + &__label {
		margin-left: 16px;
	}

	&__value-wrap {
		flex: auto;
		border-radius: 8px;
	}

	&__value-cont {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 96px;
		height: 40px;
		padding: 0 14px;
	}

	&__label + &__value-wrap {
		margin-left: 16px;
	}

	&__value {
		font-size: 24px;
		font-weight: 700;
		color: @black4;
	}

	&__difference {
		margin-left: 4px;
		margin-bottom: 12px;
		font-size: 11px;
		font-weight: 600;
		white-space: nowrap;

		&_positive {
			color: @primaryGreen;
		}

		&_negative {
			color: @primaryRed;
		}

		&-icon {
			display: inline-block;
			margin-right: 4px;

			&_up {
				transform: rotate(180deg);
			}
		}
	}
}