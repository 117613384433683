@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.expand-button {
	display: inline-block;
	width: 40px;
	height: auto;
	padding: 8px 8px 4px;
	background-color: @white3;
	border-radius: 12px;
	box-shadow: 0 10px 30px rgba(@black4, 0.1);
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;

	.desktop &:hover {
		background-color: @buttonHover;
	}

	&:active {
		background-color: @buttonPressed !important;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: auto;
		height: 100%;
		font-size: 1.4em;
	}

	&__icon {
		display: block;
		color: rgba(@black3, 0.6);
		transition: transform 0.2s ease;

		i {
			display: block;
		}
	}

	&_expanded &__icon {
		transform: rotate(-180deg);
	}

	&__label {
		max-width: 100%;
		margin-bottom: 2px;
		.tm-semibold-13();
		color: rgba(@black3, 0.35);
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
