@import (reference) "~@tehzor/ui-components/src/styles/colors";

.editable-stages {
	&__actions {
		display: grid;
		grid-template-columns: repeat(2, auto);
		gap: 16px;
	}

	&__text-field.disabled * {
		color: @black20;
	}
}