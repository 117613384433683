@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.editable-export-template {
	& > div:not(:last-child) {
		margin-bottom: 25px;
	}

	& > div:last-child {
		margin-bottom: 34px;
	}
	
	&__buttons {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom:16px;

		&-download{
			display: flex;
			justify-content: space-around;
		}
	}

	&__attachments {
		margin: 32px 0 0;

		&-scroll-area {
			margin-top: 0;
			padding: 0 16px 0 0;

			@media (min-width: @large-tablet-min) and (min-height: 800px) {
				max-height: 389px;
			}
		}

		&-files {
		}

		&-file {
		}
	}
}
