@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

@tag: tag;

.@{tag} {
	display: inline-flex;
	align-items: center;
	border-radius: 6px;
	color: @black85;
	overflow: hidden;
	max-width: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	&_small {
		padding: 4px 10px;
	}

	&_medium {
		padding: 3px 12px 4px;
	}

	&_large {
		padding: 5px 12px 6px;
	}

	&_dark {
		color: @white;
	}

	&_removable {
		padding-right: 3px;
	}

	&_with-icon {
		padding-left: 8px;
	}
	&_with-dot {
		padding: 4px 8px;
		.d-regular-12();
		border: 1px solid;
	}

	&_single-line {
		user-select: none;
	}

	&__label {
		flex: 1 1 auto;
		word-break: break-word;

		.@{tag}_single-line & {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.@{tag}_small & {
			.d-regular-12();
		}

		.@{tag}_medium & {
			.d-regular-14();
		}

		.@{tag}_large & {
			.d-regular-14();
		}
	}

	&__icon {
		height: auto;
		margin-right: 6px;

		i {
			display: block;
		}
	}
	&__dot {
		width: 6px;
		min-width: 6px;
		height: 6px;
		min-height: 6px;
		border-radius: 50%;
		margin-right: 8px;
	}

	&__close {
		margin-left: 6px;

		&.icon-button {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: none;
			position: relative;
			padding: 0;
			border-radius: 50%;
			cursor: pointer;
		}

		.desktop &.icon-button:hover {
			background-color: rgba(@black4, 0.1);
		}

		.desktop &.icon-button:active {
			background-color: rgba(@black4, 0.15) !important;
		}

		.@{tag}_small & {
			width: 20px;
			height: 20px;
			min-width: 20px;
			min-height: 20px;
			margin-top: -4px;
			margin-bottom: -4px;
		}

		.@{tag}_medium & {
			width: 22px;
			height: 22px;
			min-width: 22px;
			min-height: 22px;
			margin-bottom: -1px;
		}

		.@{tag}_large & {
			width: 26px;
			height: 26px;
			min-width: 26px;
			min-height: 26px;
			margin-top: -2px;
			margin-bottom: -4px;
		}

		&-icon {
			color: @grey;
			transition: color 0.2s ease;
		}

		&:active &-icon {
			color: @black85;
		}
	}
}
