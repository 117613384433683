@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.problem-page {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__mobile-right-buttons {
		display: flex;
		gap: 8px;
		align-items: center;
		overflow: hidden;
	}
	@media (max-width: (@tablet-min - 1px)) {
		padding: 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: (@tablet-min - 1px)) {
			margin-bottom: -16px;
		}
	}
	&__d-columns {
		display: grid;
		grid-column-gap: 24px;
		align-items: flex-start;

		@media (min-width: @desktop-min) {
			grid-column-gap: 32px;
		}

		.app-content_padding_72 & {
			grid-template-columns: 304px 1fr 1fr;

			@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
				grid-template-columns: 304px 1fr 1fr;
			}

			@media (min-width: @desktop-min) {
				grid-template-columns: 368px 0.55fr 0.45fr;
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
				display: grid;
				grid-template-columns: 368px 1fr;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-column-gap: 24px;
			}

			@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
				grid-template-columns: 304px 1fr 1fr;
			}

			@media (min-width: @large-desktop-min) {
				grid-template-columns: 368px auto 30%;
			}
		}
	}

	&__d-column {
		position: sticky;
		top: 22px;
		display: flex;
		flex-direction: column;
		row-gap: 24px;
		&_attachments {
			flex: none;

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					grid-area: attachments;
					position: relative;
					top: 0;
					margin-bottom: 0;
				}
			}
		}

		&_info {
			@media (min-width: @large-desktop-min) {
				flex: 1 1 550px;
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					grid-area: info;
					position: relative;
					top: 0;
				}
			}
		}

		&_comments {
			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					grid-area: comments;
				}
			}
		}
	}

	&__m-plate {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__m-info {
		padding-top: 0;
		padding-bottom: 0;
	}

	&__d-images {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&-carousel {
			width: 100%;
			border-radius: 14px;
			overflow: hidden;

			&-item {
				&:first-child {
					border-top-left-radius: 14px;
					border-bottom-left-radius: 14px;
				}

				&:last-child {
					border-top-right-radius: 14px;
					border-bottom-right-radius: 14px;
				}
			}
		}

		&-nav {
			width: 100%;
			margin-top: 20px;
		}
	}

	&__m-images {
		margin: 0 -16px;

		&-content {
			padding-left: 0 !important;
		}
	}

	&__m-image {
		&:first-child {
			margin-left: 48px;
		}

		&:last-child {
			margin-right: 16px;
		}
	}

	&__m-docs {
		&-content {
			padding-bottom: 16px;
		}
	}

	&__doc {
		&:not(:first-child) {
			padding-top: 8px;
		}

		&:not(:last-child) {
			padding-bottom: 8px;
		}

		&-file {
			max-width: 100%;
		}
	}

	&__empty-attachments {
		position: relative;
		width: 100%;
		background-color: @greyLight;
		border-radius: 14px;

		&::before {
			content: '';
			display: block;
			width: 100%;
			padding: 50% 0;
		}

		&-icon {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 120px;
			color: @white;

			.app-content_padding_288 & {
				@media (max-width: (@large-desktop-min - 1px)) {
					font-size: 100px;
				}
			}
		}
	}

	&__d-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	&__m-links {
		margin-bottom: 16px;
		white-space: nowrap;

		@media (max-width: (@tablet-min - 1px)) {
			margin-top: 16px;
		}

		@media (min-width: @tablet-min) {
			margin-left: -16px;
			margin-right: -16px;
		}
	}

	&__m-link {
		&:not(:last-child) {
			margin-right: 12px;
		}

		@media (max-width: @tablet-max) {
			&:first-child {
				margin-left: 16px;
			}

			&:last-child {
				margin-right: 16px;
			}
		}
	}

	&__d-entities-grid {
		@media (max-width: (@desktop-min - 1px)) {
			grid-template-columns: 100%;

			& > .entity-grid-item_full {
				grid-column-end: 2;
			}
		}

		&-display-place {
			display: flex;
			align-items: center;
			gap: 10px;

			> i {
				position: relative;
				color: @grey60;

				&::after {
					position: absolute;
					top: -4px;
					right: -4px;
					content: '';
					width: 12px;
					height: 12px;
					background-color: @red70;
					border-radius: 50%;
					border: 3px solid @white;
				}
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
				grid-template-columns: 100%;
			}

			@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
				grid-template-columns: 100%;

				& > .entity-grid-item_full {
					grid-column-end: 2;
				}
			}
		}
	}

	.entity-grid-item&__status {
		.entity-grid-item__content {
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: @mobile-max) {
				flex-direction: row-reverse;
			}
		}

		.base-status-select__btn {
			margin: 0 15px 0 0;

			@media (max-width: @mobile-max) {
				margin: 0 0 0 15px;
			}
		}

		.app-content_padding_72 & {
			@media (min-width: @large-tablet-min) and (max-width: 1099px) {
				display: block;

				& > .entity-grid-item__content {
					margin-top: 10px;
				}
			}
		}

		.app-content_padding_288 & {
			@media (min-width: @desktop-min) and (max-width: 1349px) {
				display: block;

				& > .entity-grid-item__content {
					margin-top: 10px;
				}
			}
		}

		@media (min-width: @large-desktop-min) {
			padding-right: calc(50% + 16px);

			& > .entity-grid-item__content {
				margin-left: 8px;
			}
		}
	}

	&__d-comments-plate {
		height: 100%;

		&-content {
			height: 100%;
			padding: 0;
		}
	}

	&__d-comments {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
		height: 100%;

		&-header {
			flex: none;
			padding: 15px 24px 5px;
			.shadow2();

			@media (min-width: @large-desktop-min) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-title {
			margin-bottom: 10px;
			.d-bold-18();
			color: @black100;

			@media (min-width: @large-desktop-min) {
				margin-right: 10px;
			}
		}

		&-v-filter {
			margin-bottom: 10px;
		}

		&-body {
			flex: 1 1;
			overflow: hidden;
		}

		&-content {
			padding: 12px 0 28px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		&-reply-btn {
			width: unset !important;
			margin: 4px 24px 0;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-reference {
			padding-left: 24px;
			padding-right: 24px;

			@media (min-width: @large-desktop-min) {
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&-input {
			margin-left: 24px;
			margin-right: 24px;

			@media (min-width: @large-desktop-min) {
				margin-left: 40px;
				margin-right: 40px;
			}
		}
	}

	&__d-comment {
		@media (max-width: (@large-desktop-min - 1px)) {
			padding-left: 24px;
			padding-right: 24px;
		}

		@media (min-width: @large-desktop-min) {
			padding-left: 40px;
			padding-right: 40px;
		}

		.problem-comment_nested > & {
			@media (max-width: (@large-desktop-min - 1px)) {
				padding-left: 80px;
			}

			@media (min-width: @large-desktop-min) {
				padding-left: 112px;
			}
		}
	}

	&__last-reply {
		margin: 0;

		&-plate {
			padding: 0;
		}
	}

	&__tags {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -12px;
	}

	&__tag {
		margin-right: 12px;
		margin-bottom: 12px;
	}

	&__views {
		justify-content: flex-start;

		&-dialog {
			@media (min-width: @tablet-min) {
				width: 704px;
			}
		}
		&-user {
			display: flex;
			gap: 16px;
			margin-bottom: 16px;

			&:last-child {
				margin: 0px;
			}

			&__container {
				&-title {
					display: flex;
					align-items: end;
					gap: 8px;

					&-name {
						.d-semibold-14();
						color: @black100;
					}

					&-date {
						.d-regular-12();
						color: @black75;
					}
				}

				&-position {
					.d-regular-12();
					color: @black75;
				}
			}
		}
	}
}
