.wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 16px 0;
}

.title {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
}

.list {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
}

.item {
	display: grid;
	grid-template-columns: min-content 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0 8px;
	align-items: center;
}

.itemIconContainer {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.itemText {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
}
