@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.plans-page {
	@media (max-width: @tablet-min) {
		padding: 0;
	}

	&__plate {
		overflow: visible;

		&-empty {
			padding: 20px;
		}
	}

	&__preview {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background-color: white;

		&-icon {
			color: @black60;
			opacity: .5;
			padding-left: 8px;
		}

		&-text {
			width: 100%;
			margin: 1px;
			color: @black100;
			min-width: 240px;
			width: 240px;
			background-color: transparent;
			border: none;
		}
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @large-mobile-max) {
			margin-bottom: 10px;
		}
	}

	&__menu-cell {
		width: 40px;
	}
}
