@import (reference) '~@tehzor/ui-components/src/styles/variables';

.adding-legal {
  &__container {
    width: 856px;

    @media (max-width: @desktop-min) {
      width: 100%;
    }
  }
}