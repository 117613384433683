@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.objects-page {
	&__breadcrumbs {
		&-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@media (max-width: @large-tablet-max) {
			.inline-button {
				padding-left: 0;
			}
		}

		@media (max-width: @large-mobile-max) {
			margin-bottom: 0 !important;
		}
	}

	&__mobile-cell {
		padding: 17px 16px;
	}

	&__filters {
		display: flex;
		gap: 12px;

		&-entities {
			margin-bottom: 0 !important;
		}

		@media (max-width: @large-mobile-max) {
			justify-content: space-between;

			&-search {
				width: 100%;

				.select-search_filter {
					max-width: none;
				}
			}
		}
	}

	&__table-menu {
		display: flex;
		justify-content: center;
	}

	&__controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 0;

		&-amount {
			display: flex;
			flex-direction: column;
			row-gap: 8px;
		}

		&-actions {
			display: flex;
			gap: 8px;
		}
	}

	&__legend {
		&-button {
			@media (max-width: @large-mobile-max) {
				color: @grey40 !important;
			}
		}

		&-content {
			display: grid;
			grid-gap: 14px 40px;
			grid-template-columns: 1fr;
		}

		&-dialog {
			@media (min-width: @large-mobile-min) {
				width: calc(100vw - 32px);
			}

			@media (min-width: @tablet-min) {
				width: 650px;
			}
		}

		&-group {
			&__title {
				.d-semibold-14();

				margin-bottom: 4px;
				padding: 4px 0;
				color: @black100;
			}

			&__item {
				display: flex;
				gap: 8px;
				align-items: center;

				&-icon {
					padding: 8px;
				}
			}
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__loading-panel2 {
		display: flex;
		align-items: center;
		min-height: 100vh;
	}

	&__companies-filter {
		margin-bottom: 40px;
	}
}
