@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.entity-links {
	&__content {
		width: fit-content;
	}

	&__container {
		display: flex;
		flex-direction: column;

		& > a:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__link .link-button__label {
		.d-regular-14();
	}
}
