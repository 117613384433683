@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.em-photo-viewer {
	&__previews {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.65);

		&-title {
			padding: 8px 16px 16px;
			font-weight: 600;
			color: @white;
			@media (min-width: @tablet-min) {
				padding: 8px 24px 20px;
			}
		}

		&-nav {
			padding: 12px 0 16px;

			&-item {
				pointer-events: none;

				&:not(:first-child) {
					margin-left: 6px;
				}

				&:not(:last-child) {
					margin-right: 6px;
				}

				&:first-child {
					margin-left: 16px;

					@media (min-width: @tablet-min) {
						margin-left: 24px;
					}
				}

				&:last-child {
					margin-right: 16px;

					@media (min-width: @tablet-min) {
						margin-right: 24px;
					}
				}
			}
		}

		&-photo {
			pointer-events: all;

			.photo-preview& {
				width: 52px;
			}

			&_last-of-group {
				position: relative;
				margin-right: 13px;

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: calc(100% + 12px);
					width: 1px;
					height: 100%;
					background-color: #666764;
					pointer-events: none;
				}
			}
		}
	}
}