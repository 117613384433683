@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.problems-page {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	&__mobile-right-buttons {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}

		@media (max-width: @large-mobile-max) {
			margin-bottom: 16px;
		}
	}

	&__mobile-cell {
		&-info {
			display: flex;
			padding: 0 6px;
		}

		&-select {
			padding-right: 18px;
		}
	}

	&__search {
		margin-right: 12px;

		@media (max-width: @mobile-max) {
			width: 100%;
		}
	}

	&__filters-bar {
		@media (max-width: @large-tablet-max) {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 12px;
		}

		@media (max-width: @large-mobile-max) {
			display: flex;
			justify-content: flex-start;
			padding: 0 16px;
		}
	}

	&__counter-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 24px;
	}

	&__statuses-filter-label {
		&_waiting {
			color: @statusWaiting;
		}

		&_handling {
			color: @statusHandling;
		}

		&_verification {
			color: @statusVerification;
		}

		&_fixed {
			color: @statusFixed;
		}
	}

	&__loading-panel {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-height: 100%;
	}

	&__header {
		&-wrap {
			justify-content: start;
		}

		&-btn {
			margin: 14px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-email-icon {
			width: 15px;
			height: auto;
		}

		&-add-report-icon {
			width: 12px;
			height: auto;

			path {
				fill: @white;
				stroke: @white;
			}
		}

		.page-header__left {
			margin-right: 0;
		}

		@media (max-width: @large-mobile-max) {
			padding: 0 16px;
		}
	}

	&__tabs-links {
		margin-bottom: 30px;
	}

	&__m-table {
		&-status-select {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;
			max-width: 100%;
			margin-top: 8px;
		}

		&-created {
			display: flex;
			flex-direction: column;
			color: @black4;

			&-date {
				overflow: hidden;
			}

			&-name {
				margin-top: 4px;
			}
		}

		&-inspectors,
		&-performers {
			margin-bottom: 4px;

			&-title {
				color: @black60;
			}

			&-text {
				display: flex;
				flex-direction: column;
			}

			&-user {
				&-name,
				&-company {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					.d-regular-12();

					&:not(:last-child) {
						margin-right: 8px;
					}
				}

				&-name {
					color: @black100;
				}

				&-company {
					color: @black60;
				}
			}

			&-rest,
			&-group {
				.d-semibold-14();
			}

			&-rest {
				color: @deepBlue;
			}

			&-group {
				color: @black4;
			}
		}

		&-description-cell {
			padding-left: 0;
		}

		&-object {
			.d-semibold-12();

			margin-top: 8px;
			color: @black4;
		}

		&-description {
			display: flex;
			justify-content: space-between;

			&-wrapper {
				display: flex;
				flex-direction: column;
			}

			&-header {
				display: flex;
				margin-bottom: 4px;

				.tz-comment-on-plan-16 {
					position: relative;

					display: flex;
					align-items: center;

					margin-left: 8px;

					color: @grey60;

					&::after {
						content: '';

						position: absolute;
						top: -4px;
						right: -4px;

						width: 12px;
						height: 12px;

						background-color: @red70;
						border: 3px solid @white;
						border-radius: 50%;
					}
				}
			}

			&-number {
				.d-semibold-14();

				color: @black4;
			}

			&-text {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				margin-bottom: 8px;
				padding-right: 16px;

				color: @black4;

				-webkit-line-clamp: 2;
				.d-regular-14();
			}

			&-category {
				margin-top: 4px;
				.d-regular-14();

				color: @black60;
			}

			&-image {
				width: 76px;
				min-width: 76px;
				height: 76px;
				min-height: 76px;
				margin-left: auto;
			}

			&-documents-count {
				margin-top: 8px;
			}

			@media (max-width: @large-desktop-min) {
				flex-direction: column;
			}
		}

		&-plannedFixDate {
			display: flex;
			flex-direction: column;
			gap: 4px;

			width: auto;
			margin-bottom: 4px;
			padding-right: 8px;

			color: @black85;
			.d-regular-12();

			&-display {
				width: max-content;
			}
		}

		&-local-status {
			width: auto;
			margin-top: 8px;
		}

		&-transfer-status {
			display: flex;
			gap: 18px;
			width: auto;
			margin-top: 8px;
		}

		&-location {
			&-object {
				.d-regular-14();

				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				color: @black4;

				-webkit-line-clamp: 3;
			}

			&-block {
				margin-top: 8px;
			}

			&-line {
				display: flex;
				font-size: 12px;
				line-height: 15.84px;
				color: @black4;
			}

			&-title {
				min-width: 40px;
				margin-right: 4px;
				color: @black60;
			}

			&-subtitle {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				color: @black100;

				-webkit-line-clamp: 2;
			}
		}

		&-number {
			position: absolute;
			top: 0;
			right: 50px;
			left: 40px;

			display: flex;
			align-items: center;

			width: initial !important;
			height: 28.6px;

			font-weight: 600;
		}

		&-menu {
			position: absolute;
			top: 0;
			right: 0;

			flex-basis: auto !important;

			width: auto !important;
			padding-top: 4px;
			padding-bottom: 4px;
		}

		&-tags {
			margin-left: 12px;
		}

		&-presentation-btn {
			margin-bottom: 8px;
		}
	}

	&__d-table {
		&-created {
			z-index: 20;
			display: flex;
			flex-direction: column;
			color: @black4;

			&-date {
				overflow: hidden;
			}

			&-name {
				margin-top: 4px;
			}
		}

		&-inspectors,
		&-performers {
			&-user {
				display: flex;
				flex-direction: column;

				&-name,
				&-company {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&-name {
					color: @black85;
				}

				&-company {
					color: @black75;
					.d-regular-12();
				}
			}

			&-rest,
			&-group {
				.d-semibold-14();
			}

			&-rest {
				color: @deepBlue;
			}

			&-group {
				color: @black4;
			}
		}

		&-description-cell {
			padding-left: 0;
		}

		&-description {
			display: flex;
			justify-content: space-between;

			&-header {
				display: flex;

				> .tz-comment-on-plan-16 {
					position: relative;

					display: flex;
					align-items: center;

					margin-left: 8px;

					color: @grey60;

					&::after {
						content: '';

						position: absolute;
						top: -4px;
						right: -4px;

						width: 12px;
						height: 12px;

						background-color: @red70;
						border: 3px solid @white;
						border-radius: 50%;
					}
				}
			}

			&-number {
				.d-semibold-14();

				color: @black4;
			}

			&-text {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				margin-top: 8px;
				padding-right: 16px;

				color: @black4;

				-webkit-line-clamp: 2;
				.d-regular-14();
			}

			&-category {
				margin-top: 4px;
				.d-regular-14();

				color: @black60;
			}

			&-image {
				width: 67px;
				min-width: 67px;
				height: 67px;
				min-height: 67px;

				@media (max-width: @large-desktop-min) {
					margin-top: 12px;
				}
			}

			@media (max-width: @large-desktop-min) {
				flex-direction: column;
			}
		}

		&-tags {
			margin-left: 12px;
		}

		&-location {
			&-object {
				.d-semibold-14();

				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				color: @black4;

				-webkit-line-clamp: 3;
				// color: @gray4;
			}

			&-block {
				margin-top: 8px;

				> .tz-comment-on-plan-16 {
					position: relative;

					display: flex;
					align-items: center;

					width: 16px;
					margin-top: 4px;

					color: @grey60;

					&::after {
						content: '';

						position: absolute;
						top: -4px;
						right: -4px;

						width: 12px;
						height: 12px;

						background-color: @red70;
						border: 3px solid @white;
						border-radius: 50%;
					}
				}
			}

			&-line {
				display: flex;
				color: @black4;
			}

			&-title {
				min-width: 50px;
				margin-right: 4px;
				color: @gray4;
			}

			&-subtitle {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;

				-webkit-line-clamp: 2;
			}
		}

		&-status {
			display: flex;
			align-items: center;

			&-icon {
				flex: none;

				width: 8px;
				height: 8px;
				margin-right: 4px;

				border-radius: 50%;

				&_waiting {
					background-color: @red2;
				}

				&_handling {
					background-color: @blue2;
				}

				&_verification {
					background-color: @orange2;
				}

				&_fixed {
					background-color: @green3;
				}
			}

			&-expiration-icon {
				flex: none;
				margin-right: 4px;
				font-size: 10px;
				color: @primaryRed;
			}
		}

		&-menu {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;

			margin: 0;
			padding: 8px 28px 8px 8px;

			visibility: hidden;
			opacity: 0;
			background-color: transparent;

			transition:
				background-color 0.2s,
				opacity 0.2s ease;

			&-wrap {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	& .table2__row_clickable {
		&:hover .problems-page__d-table-menu {
			visibility: visible;
			opacity: 1;
			background-color: @buttonLightHover;
		}

		&:active .problems-page__d-table-menu {
			background-color: @buttonLightPressed;
		}
	}

	&__offline {
		&_tag {
			margin-bottom: 10px;
			color: #172a41;
			background-color: @grey10;
		}

		&_icon {
			color: @grey;
		}
	}

	@media (max-width: @large-mobile-max) {
		padding: 16px 0 0;
	}
}
