@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-rule-dialog {

	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__content {
		overflow-y: auto;
		contain: strict;
		max-height: 77vh;

		&__rule{
			&:not(:last-child){
				border-bottom: 1px solid #E8EAEC;
			}
		}

	}

}