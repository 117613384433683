@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.categories-bar-chart {
	padding: 10px;
	background-color: @white2;
	border-radius: 14px;
	overflow-x: auto;

	&__grid {
		stroke: rgba(@black4, 0.05);
		stroke-width: 1px;
	}
}