@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.profile-page {
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__action {
		display: flex;
		justify-content: flex-end;

		&-button,
		&.error {
			margin-bottom: 12px;
		}
	}

	&__d-columns {
		display: grid;
		grid-column-gap: 24px;
		align-items: flex-start;

		.app-content_padding_72 & {
			grid-template-columns: 304px 1fr 1fr;

			@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
				grid-template-columns: 304px 1fr 1fr;
			}

			@media (min-width: @desktop-min) {
				grid-template-columns: 368px 0.55fr 0.45fr;
			}
		}

		.app-content_padding_288 & {
			@media (max-width: @desktop-min - 1px) {
				display: grid;
				grid-column-gap: 24px;
				grid-template-areas:
					'avatar settings'
					'data settings';
				grid-template-columns: 368px 1fr;
			}

			@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
				grid-template-columns: 304px 1fr 1fr;
			}

			@media (min-width: @large-desktop-min) {
				grid-template-columns: 368px auto 30%;
			}
		}

		@media (min-width: @desktop-min) {
			grid-column-gap: 32px;
		}
	}

	&__d-column {
		position: sticky;
		top: 22px;

		&_avatar {
			flex: none;

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					position: relative;
					top: 0;
					grid-area: avatar;
					margin-bottom: 0;
				}
			}
		}

		&_info {
			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					position: relative;
					top: 0;
					grid-area: data;
				}
			}

			@media (min-width: @large-desktop-min) {
				flex: 1 1 550px;
			}
		}

		&_settings {
			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
					grid-area: settings;
				}
			}
		}
	}

	&__m-column {
		margin-top: 16px;

		@media (min-width: @tablet-min) {
			&:first-child {
				margin: 0;
			}
		}
	}

	&__avatar {
		display: flex;
		justify-content: center;
		padding: 48px 0;

		& > .user-avatar {
			width: 128px;
			height: 128px;
		}
	}

	&__m-user-info {
		margin-bottom: 32px;
	}

	&__user-data-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;

		&-title {
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			color: @black100;
		}
	}

	&__entities-grid {
		grid-template-columns: 100%;

		& > .entity-grid-item_inline {
			display: grid;
			grid-column-gap: 48px;
			grid-template-columns: 176px auto;
			align-items: flex-start;
			justify-content: flex-start;
		}

		&-item__settings > .entity-grid-item__content {
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding: 0;
		}
	}

	@media (max-width: @tablet-min - 1px) {
		padding: 0;
	}
}
