@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 16px 0;

	@media (max-width: @tablet-min) {
		padding: 8px 15px 32px 15px;
		overflow-y: auto;
	}
}

.container {
	display: grid;
	grid-template-columns: repeat(2, 320px);
	grid-auto-rows: min-content;
	grid-gap: 16px 40px;

	@media (max-width: @tablet-min) {
		grid-template-columns: 1fr;
	}
}

.confirmDialogOverlay {
	z-index: 10000;
}