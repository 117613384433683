@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';

.external-link-button {


	&__wrap {
		align-items: flex-start;
	}

	&__left-icon {
		margin: 2px 8px 0 0;
	}

	&:not(.link-button_disabled) &__left-icon {
		color: @grey;
	}

	&__label {
		text-decoration: underline;
	}
}
