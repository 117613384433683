@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.page-header {
	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.5em;
	}

	&__left {
		&:not(:last-child) {
			margin-right: 1em;
		}
	}

	&__right {

	}

	&__title {
		font-size: 2.1em;
		line-height: 1.4;
		font-weight: 600;
		color: @grayBlue1;
	}

	&__sub-title {
		font-size: 1.4em;
		line-height: 1.4;
		color: @grayBlue2;
	}
}
