@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.files-drop-area {
	display: block;
	width: 100%;
	padding: 18px;
	border: 2px dashed @deepBlue;
	border-radius: 12px;
	color: @deepBlue;
	transition:  border-color 0.2s ease, color 0.2s ease;

	.button-base& {
		border: 2px dashed @deepBlue;
	}

	@media (min-width: @large-tablet-min) {
		padding: 20px;
	}

	&:not(&_disabled) {
		.desktop &:hover {
			border-color: @buttonAccentHover;
			color: @buttonAccentHover;
		}

		&:active {
			border-color: @buttonAccentPressed !important;
			color: @buttonAccentPressed !important;
		}
	}

	.button-base&_disabled {
		cursor: default;
		border-color: @black20;
		color: @black20;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	&__icon {
		&_left {
			margin-right: 12px;
		}

		i {
			display: block;
		}
	}

	&__label {
		.tm-semibold-15();
	}
}
