@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/shadows";

.problem-comments-page {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	height: calc(100vh - 56px);
	padding: 0;
	background-color: @white;

	&__comments {
		height: 100%;

		&-content {
			padding: 0 0 16px;
		}

		&-footer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		&-reply-btn {
			width: unset !important;
			margin: 0 16px;

			@media (min-width: @tablet-min) {
				margin-left: 32px;
				margin-right: 32px;
			}

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-reference {
			margin-top: -4px;
			padding-left: 16px;
			padding-right: 16px;

			@media (min-width: @tablet-min) {
				padding-left: 32px;
				padding-right: 32px;
			}
		}

		&-input {
			margin-left: 24px;
			margin-right: 16px;

			@media (min-width: @tablet-min) {
				margin-left: 32px;
				margin-right: 32px;
			}
		}
	}

	&__comment {
		@media (max-width: @tablet-min - 1px) {
			padding-left: 16px;
			padding-right: 16px;
		}

		@media (min-width: @tablet-min) {
			padding-left: 32px;
			padding-right: 32px;
		}

		.problem-comment_nested > & {
			padding-left: 64px;

			@media (min-width: @tablet-min) {
				padding-left: 80px;
			}
		}
	}
}