@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	padding: 16px;
	height: 100vh;
	background-color: @white;
	display: flex;
	flex-direction: column;
	gap: 20px 0;
}


