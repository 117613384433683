.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px 0;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 24px 0;
}

.bar {
	display: flex;
	justify-content: space-between;
}