@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.wrapper {
	border-right: 1px solid @gray8;

	@media (max-width: @large-mobile-max) {
		border-right: none;
	}
}

.header {
	@media (max-width: @large-mobile-max) {
		display: none;
	}
}

.headerRow {
	height: 60px;
}

.headerCell {
	height: 60px;
	text-align: left;
	font-size: 14px;
	font-weight: 400;
	color: @black60;
}

.body {
	@media (max-width: @large-mobile-max) {
		:first-child {
			border-top: none;
		}
	}
}

.bodyRow {
	height: 60px;
}


.bodyCell {
	height: 60px;
	@media (max-width: @large-mobile-max) {
		padding: 8px 0;
		width: 100%;
	}
}

.bodyDragCell {
	@media (max-width: @large-mobile-max) {
		padding: 8px 0;
		display: flex;
	}
}


.emptyRow {
	td {
		height: 60px;
		padding: 0 0 0 26px;
	}
}