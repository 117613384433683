@import (reference) "../../../../../styles/colors.less";

.time-picker {

	&__popup {
	width: 156px;
	height: 168px;
	overflow-y: scroll;
	}

	&__row {

		height: 40px;
		padding: 10px 16px;
		user-select: none;
		vertical-align: middle;

		&_selected {
			background-color: @buttonLightSelected;
		}

		&:hover{
			background-color: @buttonLightHover;
		}
	}
}