.unit-select {


	&__container {
		flex-grow: 1;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: min-content 1fr;
		grid-gap: 20px 0;
		overflow: hidden;
	}

	&__list {
		overflow: auto;
	}
}