@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px 0;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	margin: 0;
	.d-semibold-14();
	color: @black4;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 4px 0;
}

.infoItem {
	margin: 0;
	.d-regular-14();
	color: @black60;

	span {
		color: @black100;
	}
}

.dates {
	display: flex;
	flex-direction: column;
	gap: 8px 0;
}

.date {
	display: flex;
	flex-direction: column;
}

.dateName {
	margin: 0;
	.d-regular-14();
	color: @black60;
}

.dateValue {
	margin: 0;
	.d-regular-14();
	color: @black100;
}