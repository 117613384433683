@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: flex;
	align-items: flex-start;
	gap: 0 8px;
}

.order {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	color: @black60;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 4px 0
}

.title {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	color: @black85;
}

.subTitle {
	margin: 0;
	padding: 4px 0;
	font-size: 12px;
	font-weight: 400;
	color: @black75;
}

.infoItem {
	margin: 0;
	font-size: 12px;
	font-weight: 400;
	color: @black60;

	span {
		color: @black100;
	}
}

.icon {
	padding: 4px 0 0 0;
	color: @grey;
}

.menu {
	margin: 0 0 0 auto;
}