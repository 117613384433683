@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.select-dialog {
	@media (min-width: @tablet-min) {
		width: 700px;
	}

	&__content {
		height: 70vh;

		@media (max-width: @tablet-min) {
			height: 100vh;
			padding: 8px 0;
		}
	}

	&__body {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&--indicators {
			@media (max-width: @tablet-min) {
			}
		}
	}
}
