@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.select {
	display: inline-block;

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__element {
		display: block;
		width: 100%;
	}
}
