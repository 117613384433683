@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';

.notifications {
	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 1.5em 0 2.5em;
	}

	&__header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 2em;
	}

	&__settings-btn {
		width: 3.4em;
		height: 3.4em;
	}

	&__close-dialog-btn {
		width: 3.4em;
		height: 3.4em;
	}

	&__messages {
		flex: 1 1;
		margin-top: 1.5em;
		padding: 0 2em 2em;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		&-loader {
			padding: 1em 0;
			font-size: 1.4em;
			line-height: 1.4em;
			text-align: center;
			color: @gray4;
		}
	}

	&__delete-all {
		&-btn {
			align-self: center;
			max-width: 100%;
			margin: 2.5em 0 0;
		}

		&-icon {
			color: #46a3cb;
			font-size: 20px;
		}
	}

	&__enable-web-push-btn {
		align-self: center;
		max-width: 100%;
		margin: 2.5em 0 0;
	}

	&__web-push-unavailable {
		max-width: 100%;
		padding: 1.78em 1.43em 0;
		font-size: 1.4em;
		line-height: 1.3;
		text-align: center;
		color: @grayBlue3;
	}
}

.notification {
	position: relative;
	z-index: 1;
	padding: 1.5em;
	background-color: @white;
	border-radius: 2px;
	box-shadow: 0 10px 20px rgba(210, 224, 233, 0.2);
	display: grid;
	grid-template-columns: 45px auto 30px;
	grid-gap: 10px;

	&:not(:first-child) {
		margin-top: 2em;
	}

	&__icons {
		padding: 5px;
		position: relative;
		height: 45px;

		&-entity {
			color: #8695a6;
			font-size: 28px;
		}
		&-type > * {
			font-size: 22px;
		}

		&-type {
			position: absolute;
			height: 28px;
			width: 28px;

			bottom: 0px;
			right: 0px;

			display: flex;
			justify-content: center;
			align-items: center;

			background-color: white;
			border-radius: 100%;

			&-default {
				color: #60b2ff;
				background-color: #e5f2ff;
				border-radius: 100%;
			}

			&-add {
				color: #76cf30;
				background-color: #e9f7de;
				border-radius: 100%;
			}

			&-edit {
				color: #a63bf7;
				background-color: #f4e7fe;
				border-radius: 100%;
			}

			&-delete {
				color: #ff0f31;
				background-color: #ffe3e7;
				border-radius: 100%;
			}

			&-fix-date-expired {
				color: #ff0f31;
				background-color: #ffe3e7;
				border-radius: 100%;
			}

			&-fix-date-coming {
				color: #ff8b05;
				background-color: #ffeedb;
				border-radius: 100%;
			}

			&-status-waiting {
				color: #ff8b05;
				background-color: #ffeedb;
				border-radius: 100%;
			}

			&-status-handling {
				color: #3392ff;
				background-color: #e5f1ff;
				border-radius: 100%;
			}

			&-status-fixed {
				color: #76cf30;
				background-color: #e9f7de;
				border-radius: 100%;
			}

			&-status-verification {
				color: #ffe50a;
				background-color: #fffce0;
				border-radius: 100%;
			}
		}
	}

	&__status {
		&_waiting {
			color: #ff8b05;
		}

		&_handling {
			color: #0f7fff;
		}

		&_fixed {
			color: #76cf30;
		}

		&_verification {
			color: #e5cd00;
		}

		&_acceptance-waiting {
			color: #ff8b05;
		}

		&_acceptance-in-progress {
			color: #0f7fff;
		}

		&_acceptance-done {
			color: #76cf30;
		}

		&_claim-waiting {
			color: #de7cce;
		}

		&_claim-in-progress {
			color: #57a5ff;
		}

		&_claim-done {
			color: #89d64d;
		}

		&_work-acceptance-waiting {
			color: #ff8b05;
		}
		&_work-acceptance-in-progress {
			color: #0f7fff;
		}
		&_work-acceptance-problems-identified {
			color: #e5cd00;
		}
		&_work-acceptance-accepted {
			color: #76cf30;
		}
		&_work-acceptance-rejected {
			color: #ff0f31;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 5px;

		&-message {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #172a41;
			flex: none;
			order: 0;
			flex-grow: 0;

			&::first-letter {
				text-transform: capitalize;
			}
		}

		&-date {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			color: #747f8d;

			flex: none;
			order: 1;
			flex-grow: 0;
		}
	}

	&__delete {
		&-btn-icon {
			color: @primaryRed;
		}
	}
	a {
		border-bottom: 1px solid #0c86ba;
	}
}
