@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.entityTypeSelect {
	.d-semibold-16();

	display: flex;
	align-items: center;
	gap: 12px;
	user-select: none;
	padding: 9px 0px;
}

.tzSimpleArrow20 {
	color: @blue2;
	transition: transform 0.2s ease;
}

.isOpen {
	transform: rotate(180deg);
}

.entityTypeSelect:hover {
	cursor: pointer;
	color: @blue90;
}

.entityTypeSelect:active {
	color: @blue50;
}

.disabled:hover {
	cursor: not-allowed;
	color: black;
}
