@import (reference) '~@tehzor/ui-components/src/styles/variables';

.template-select-dialog {
	min-width: 600px;

	@media (max-width: @tablet-max) {
		min-width: auto;
	}

	&__body {
		@media (max-width: ( @large-mobile-min - 1px)) {
			overflow: auto;
		}
	}
}
