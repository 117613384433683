@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.problem-statuses-tooltip {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 10px;
	align-items: center;
	padding: 16px 25px 16px 20px;
	background-color: @white;
	border-radius: 14px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);
	color: rgba(@black4, 0.85);
	user-select: none;

	&__value {
		.d-bold-18();
	}

	&__name {
		.d-regular-14();
		text-transform: lowercase;
	}
}