@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.ed-photo-viewer {
	&__body {
		display: flex;
	}

	&__frame {
		width: calc(100% - 136px);
	}

	&__close-btn {
		top: 8px;
		right: 8px;
	}

	&__previews {
		width: 136px;
		height: 100%;

		&-content {
			padding: 32px 48px;
		}
	}

	&__entity-separator {
		width: 64px;
		height: 1px;
		background: white;
		opacity: 0.27;
		margin-left: -12px;
		margin-bottom: 32px;
		&:last-child{
			display: none;
		}
	}

	&__entity {
		&:not(:last-child) {
			margin-bottom: 32px;
		}

		&-title {
			margin-bottom: 18px;
			.d-bold-18();
			color: @grey;
			transition: color 0.2s ease;

			&_active {
				color: @deepBlue;
			}
		}

		&-date {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			&::before, &::after {
				content: '';
				flex: 1 1;
				display: block;
				height: 1px;
				background-color: @black10;
			}

			&-value {
				margin: 0 16px;
				font-weight: 600;
				color: @black45;
			}
		}

		&-nav {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: -6px;

			.photo-preview&-item {
				width: 72px;
				margin: 10px;
			}
		}
	}
}