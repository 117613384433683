@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.object-mobile-cell {
	padding:0;

	&__info {
		display: flex;
		flex-direction: column;
		.d-regular-12();

		&-header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding-right: 50px;
		}

		&-name {
			color: @black4;
			.d-semibold-16();
			line-height: 21px;
		}
		&-menu {
			position: absolute;
			top: 12px;
			right: 10px;
		}

		&-city, &-company, &-count {
			color: @black60;
		}
		&-stages {
			color: @black4;
		}
		&-city {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 8px;
			padding-right: 16px;
		}

		&-separator{
			width: px;
			height: 4px;
			border-radius: 50%;
			background-color: @separator;
		}
		&-problems{
			margin-top: 10px;
		}
	}
}
