@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

.app-info {
	&__about {
		margin-bottom: 4px;
		color: @black75;

		@media (max-width: @tablet-max) {
			.tm-regular-13();
		}

		@media (min-width: @large-tablet-min) {
			.d-regular-14();
		}
	}

	&__copyright {
		color: @black60;

		@media (max-width: @tablet-max) {
			.tm-regular-11();
		}

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}
}