@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/variables";

.breadcrumb {
	margin-top: 2px;
	margin-bottom: 2px;
	color: @black60;

	&_inactive {
		cursor: default;
		pointer-events: none;

		a&:hover, a&:active {
			cursor: default;
			pointer-events: none;
		}
	}

	&:not(:last-child) {
		margin-right: 10px;
	}

	&__label {
		font-weight: 400;
	}
}