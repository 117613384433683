@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.file-icon {
	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__ext {
		white-space: nowrap;
		font-size: 9.5px;
		text-transform: uppercase;
	}
}
