@import (reference) "../../../styles/variables.less";
@import (reference) "../../../styles/colors.less";


.wrapper {
	padding: 0 0 24px 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
	grid-template-rows: 1fr;
	grid-gap: 120px 0;

	:nth-child(3) {
		border-right: none;
	}

	:last-child {
		border-right: none;
	}

}