@import (reference) "~@tehzor/ui-components/src/styles/colors";

.popup {
	min-width: auto;
	width: fit-content;
}

.iconsContainer {
	padding: 4px;
	display: grid;
	grid-template-columns: repeat(4, min-content);
	grid-auto-rows: 1fr;
}

.iconItem {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-radius: 6px;
}

.iconItemActive {
	border: 1px solid @iconButtonPressed;
	background: @white2;
}

.icon {
	color: @grey
}

.trigger {
	width: 104px;
}

.triggerContainer {
	display: flex;
}