@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.legal-page {
  @media (max-width: (@tablet-min - 1px)) {
    padding: 0;
  }

  &__breadcrumbs {
    @media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
      margin-bottom: 16px;
    }
    @media (max-width: @tablet-min - 1px) {
      margin-left: 6px;
      margin-bottom: 0;

      .breadcrumb__label {
        color: @black85;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 368px 1fr 544px;
    column-gap: 32px;

    @media (max-width: @desktop-max) {
      grid-template-columns: 300px 1fr 300px;
    }

    &-info {
      box-sizing: border-box;
      margin-bottom: 16px;

      &__full-name-title {
        .d-regular-14();
        color: @black85;

        @media (max-width: @large-tablet-max) {
          color: @black100;
        }
      }

      &__short-name-title {
        .d-regular-14();
        color: @black100;
        word-break: break-word;
      }

      &__createdAt {
        .d-semibold-14();
        color: @black85;
      }

      &__modifiedAt {
        .d-semibold-14();
        color: @black85;
      }

      &__mobile-open-editor-header {
        margin-bottom: 0 !important;
      }

      &__mobile-open-editor-item {
      }

      &__mobile-open-editor {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;

        &-btn {
          background: inherit;
          outline: none;
          border: none;
          padding: 0;

          > i {
            color: @buttonAccent;
          }
        }

        &-btn-active {
          transform: rotate(180deg);
        }
      }
    }

    &-content-wrapper {
      > .plate {
        width: 100%;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      width: 100%;

      &-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;

        @media(max-width: @large-tablet-max) {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          .d-semibold-16();
          color: @black100;
          margin-bottom: 14px;

          @media(max-width: @large-tablet-max) {
            margin-bottom: 28px;
          }
        }

        &-item {
          display: grid;
          grid-template-areas: "icon iconName name";
          grid-template-columns: 20px 150px 1fr;
          grid-template-rows: 80px;
          column-gap: 12px;
          align-items: center;
          align-content: center;
          .d-regular-14();
          border-bottom: 1px solid @grey20;
          height: 80px;
          padding: 16px 0;

          @media(max-width: @tablet-min) {
            grid-template-columns: 20px 1fr;
            grid-template-areas:
                "icon iconName"
                "icon name";
            grid-template-rows: 1fr 1fr;
            align-items: flex-start;
            align-content: flex-start;
          }

          &-icon {
            display: flex;
            align-items: center;
            grid-area: icon;
            > i {
              color: @grey;
            }
          }

          &-icon-name {
            grid-area: iconName;
            color: @black85;

            @media(max-width: @large-tablet-max) {
              .d-semibold-14();
            }
          }

          &-name {
            grid-area: name;
            color: @black100;

            @media(max-width: @large-tablet-max) {
              color: @black85;
            }
          }
        }
      }
    }

    &-sidebar-menu-wrapper {
      display: flex;
      margin-left: auto;
      height: calc(100vh - 180px);
      width: 100%;

      > .plate {
        width: 100%;
      }

      .plate__content {
        width: 100%;
        padding-top: 8px;
      }

      @media (max-width: @desktop-max) {
        width: 300px;
      }
    }

    &-sidebar-menu {
      display: flex;
      height: calc(100vh - 180px);
      flex-direction: column;
      width: 100%;
      position: relative;
      padding-bottom: 86px;

      &-items {
        overflow-y: auto;
      }

      &-add-button {
        width: 100%;
        position: absolute;
        bottom: 40px;
        z-index: 9;
      }
    }
  }

  &__bank-account {
    &-item {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;
      column-gap: 16px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        border-bottom: 1px solid @gray8;
        padding-bottom: 16px;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: @buttonFilterActive;
        width: 44px;
        height: 44px;

        > i {
          color: @grey;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;

        &-number {
          color: @black100;
          .d-semibold-14();
        }

        &-bank-name {
          color: @black75;
          .d-regular-12();
        }

        &-button {
          display: flex;
          justify-content: space-between;
          user-select: none;
          cursor: pointer;
          align-items: center;
          margin-top: 14px;
          column-gap: 4px;
          color: @deepBlue;
          .d-regular-12();

          &-icon {
            display: flex;
            align-items: center;
            transition: 0.1s;
          }

          &-icon_expanded {
            transform: rotate(180deg);
          }
        }
      }

      &-icon-menu {
        margin-left: auto;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      margin-top: 10px;

      &-title {
        color: @black60;
      }

      &-content {
        color: @black100;
        margin-bottom: 12px;
      }
    }
  }

  &__authorized-person {
    &-item {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;
      column-gap: 16px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        border-bottom: 1px solid @gray8;
        padding-bottom: 16px;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: @buttonFilterActive;
        width: 44px;
        height: 44px;

        > i {
          color: @grey;
        }
      }

      &-body {
        display: flex;
        flex-direction: column;

        &-number {
          color: @black100;
          .d-semibold-14();
        }

        &-name {
          color: @black75;
          .d-regular-12();
        }

        &-button {
          display: flex;
          justify-content: space-between;
          user-select: none;
          cursor: pointer;
          align-items: center;
          margin-top: 14px;
          column-gap: 4px;
          color: @deepBlue;
          .d-regular-12();

          &-icon {
            display: flex;
            align-items: center;
            transition: 0.1s;
          }

          &-icon_expanded {
            transform: rotate(180deg);
          }
        }
      }

      &-icon-menu {
        margin-left: auto;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      margin-top: 10px;

      &-title {
        color: @black60;
      }

      &-content {
        color: @black100;
        margin-bottom: 12px;
      }
    }
  }

  &__content-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-bottom: 0;
  }

  &__view-tab {
    display: flex;
    column-gap: 8px;
    &-title {
      color: @black60;
    }
  }

  &__view-tabs-mobile {
    padding: 0 14px;
  }
}
