@import (reference) "../../../../../styles/variables";
.mobile-photo-viewer__footer {
	display: flex;
	flex-direction: row;
	z-index: 2;
	position: absolute;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	min-height: 72px;
	width: 100%;
	align-items: center;
    padding: 0 32px;
	justify-content: space-between;
	color: white;
	overflow: hidden;
	@media (max-width: @large-mobile-min){
		justify-content: center;
		flex-flow: wrap;
		flex-direction: column;
		padding: 16px;
		gap: 16px;
	}
	.em-photo-viewer__previews-nav {
		padding: 12px 0 16px;
		width: 100%;
	}
	i{
		color: white;
	}
	&_edit{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		gap: 40px;
	}
	&_edit-color{
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
	}
	&_edit-actions{
		display: flex;
		flex-direction: row;
		gap: 10px;
		@media (max-width: @large-mobile-min){
			width: 100%;
			.button-base:first-child{
				height: 48px;
				width: 100%;
				color: #0E72E3;
				background-color: white;

			}
			.button-base:last-child{
				display: none;
			}			
		}
	}
	&-info{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		width: 100%;
		&-title{
			//padding: 8px 16px 16px;
			font-weight: 600;
			color: #FFFFFF;
		}
	}
	
	&_edit-label{
		color: white;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
	}
}

