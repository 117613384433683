@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) '~@tehzor/ui-components/src/styles/colors';


.editable-meter-consumption {

	width: 100%;
	display: grid;
	align-content: start;
	grid-row-gap: 12px;
	margin-bottom: 21px;
	
	
	&_label {
		border-top: 1px solid @black10;
		padding-top: 21px;
		font-weight: 600;
	}
	
}