@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../fonts/tz-icons/variables";

.me-table {
	&__row-select-cell {
		position: absolute;
		top: 3px;
		left: 0;
	}

	&__row {
		flex-wrap: wrap;
		position: relative;
		padding: 40px 0 10px 0;
	}

	&__cell {
		padding: 5px 10px;
		word-break: break-word;
		overflow: hidden;
		font-size: 14px;
		line-height: 14px;

		&_non-clickable {
			cursor: default;
		}

		&-header {
			margin-bottom: 5px;
			font-size: 9px;
			line-height: 14px;
			color: @gray4;
		}
	}
	&__hidden-cell {
		display: none;
	}
}