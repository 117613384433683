@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.contract {
	margin: 0 32px 32px;
	@media (max-width: @tablet-min) {
		margin: 0px;
	}

	&__loading-panel {
		@media (max-width: @tablet-min) {
			padding: 0 0 20px;
		}
	}

	&__breadcrumbs {
		padding: 16px 0px;

		@media (max-width: @large-tablet-min) {
			padding: 12px 0px 0px 8px;
		}
	}

	&__actions {
		display: flex;
		justify-content: center;
		gap: 16px;
	}

	&__wrap {
		width: 100%;
		display: grid;
		grid-template-columns: 368px auto;
		grid-column-gap: 32px;
		align-items: flex-start;

		@media (max-width: @large-tablet-min) {
			grid-template-columns: auto;
			grid-template-rows: auto auto;
			grid-row-gap: 32px;
		}
	}

	&__info {
		.entity-grid-item__content {
			.d-semibold-14();
			color: @black85;
		}
	}

	&__blocks {
		display: grid;
		flex-direction: column;
		gap: 80px;
		margin-bottom: -24px;

		@media (max-width: @tablet-min) {
			gap: 32px;
		}

		&-company,
		&-contractor,
		&-main,
		&-dates,
		&-price {
			display: flex;
			flex-direction: column;
			gap: 32px;

			@media (max-width: @tablet-min) {
				gap: 8px;
			}

			&__header {
				.d-semibold-16();
				color: @black100;
			}

			&__container {
				grid-template-columns: 100%;

				@media (min-width: @large-desktop-min) {
					grid-template-columns: calc(50% - 28px) calc(50% - 28px);
					grid-column-gap: 56px;
				}
			}
		}
	}

	&__cells {
		margin-bottom: 0px !important;

		&-item {
			justify-content: flex-start;
			align-items: flex-start;
			gap: 32px;
			border-bottom: 1px solid @black10;
			overflow: hidden;

			@media (max-width: @tablet-min) {
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
			}

			&-fix-date-title {
				.d-regular-14();
				margin-bottom: 8px;
			}

			&-header {
				min-width: 160px;
				width: 160px;
			}
			&-content {
				@media (max-width: @tablet-min) {
					padding-left: 32px !important;
				}
				text-overflow: ellipsis;
				display: inline-block;
			}
		}

		&-objects,
		&-plans,
		&-categories {
			display: flex;
			flex-direction: column;
		}

		&-price {
			display: flex;
			flex-direction: column;
		}
		&-plans {
			&-item {
				display: inline-block;
				padding-right: 8px;
			}
			&-btn {
				display: block;
			}
		}
	}
	&__downloadable-content {
		display: flex;
		flex-direction: column;
	}
}
