@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";
@import (reference) "../../styles/typography";

.marker-comment-dialog {
	margin: 0 8px;
	top: calc(100% - 160px);
	min-width: 60%;

	&__body {
		padding: 16px;
	}

	&__footer {
		padding: 16px;
	}

	&__header {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
	}

	&__layer {
		border-radius: 8px;
	}

	&__title {
		font-size: 14px;
		font-weight: 400;
	}

	&__text {
		max-height: 120px;
		overflow-y:auto;
	}

	&__close {
		position: absolute;
		cursor: pointer;

		right: 16px;

		padding: 16px;

		background: #F2F2F2;
		border-radius: 6px;
		width: 32px;
		height: 32px;
	}

	&__delete {
		position: absolute;
		cursor: pointer;

		right: 64px;

		padding: 16px;

		background: #F2F2F2;
		border-radius: 6px;
		width: 32px;
		height: 32px;
	}

	&__text-input {
		margin-top: 20px;
	}

	&__apply-button {
		margin-top: 20px;
	}

	.tz-delete {
		cursor: pointer;
		font-size: 16.5px;
		color: @red50;
	}
}
