@shadow1: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 8px 12px rgba(31, 52, 79, 0.1);
@shadow2: 0 4px 4px rgba(0, 0, 0, 0.05);

.shadow1 {
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);
}

.shadow2 {
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.shadow3 {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 8px 12px rgba(31, 52, 79, 0.2);
}

.shadow4 {
	box-shadow: 0 10px 14px rgba(31, 52, 79, 0.05);
}