@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.contracts {
	margin: 0 32px 32px;
	@media (max-width: @tablet-min) {
		margin: 0px;
	}

	&__add-floating-button {
		z-index: 10;
	}

	&__breadcrumbs {
		padding: 16px 0px;
		@media (max-width: @large-tablet-min) {
			padding: 12px 0px 0px 8px;
			margin-bottom: 8px;
		}
	}

	&__actions {
		@media (max-width: @large-tablet-max) {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 16px;
		}
	}

	&__page-header {
		padding: 0px 0px 26px 0px;

		@media (max-width: @large-tablet-min) {
			padding: 0px 16px;
		}

		&-amount {
			.d-regular-14();
			color: @black60;
			margin: 0px;
		}
	}

	&__filters {
		@media (max-width: @large-tablet-min) {
			padding: 0px 16px;
		}
	}

	&__container {
		&-table {
			.table2__row_clickable {
				&:hover .contracts__cell-delete {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&-pagination {

		}
		&-page-size {
			
		}
	}

	&__cell {
		&-name {
			width: 84vw;

			&-text {
				white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
				margin: 0;
				padding-right: 20px;
				width: 100%;
				.d-semibold-14();
				color: @black100;
			}
			&-number {
				white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
				margin: 0;
				padding-right: 20px;
				width: 100%;

				.d-regular-14();
				color: @black75;

				@media (max-width: @large-tablet-min) {
					.d-regular-12();
				}
			}
		}

		&-signing-date {
			@media (max-width: @large-tablet-min) {
				display: flex;
				gap: 8px;
			}

			&-header {
				.d-regular-12();
				color: @black60;
			}
			&-date {
				.d-regular-14();
				color: @black100;

				@media (max-width: @large-tablet-min) {
					.d-regular-12();
				}
			}
		}

		&-contractor {
			&-name {
				display: flex;
				flex-direction: column;
				width: 84vw;

				&-text {
					white-space: nowrap;
    			overflow: hidden;
    			text-overflow: ellipsis;
					margin: 0;
					padding-right: 20px;
					width: 100%;
				}

				&-add-text {
					color: @black75;
				}
			}
		}

		&-company {
			&-name {
				display: flex;
				flex-direction: column;
				width: 84vw;

				&-text {
					white-space: nowrap;
    			overflow: hidden;
    			text-overflow: ellipsis;
					margin: 0;
					padding-right: 20px;
					width: 100%;
				}

				&-add-text {
					color: @black75;
				}
			}
		}

		&-company, &-contractor {
			&-header {
				.d-regular-12();
				color: @black60;
			}
			&-name {
				.d-regular-14();
				color: @black100;

				@media (max-width: @large-tablet-min) {
					.d-regular-12();
				}
			}
		}

		&-objects {
			&-text {
				.d-regular-14();
				color: @black100;
			}
			&-number {
				.d-semibold-14();
				color: @deepBlue;
			}
		}

		&-created, &-modified {
			@media (max-width: @large-tablet-min) {
				display: flex;
				gap: 8px;
			}
			&-header {
				.d-regular-12();
				color: @black60;
			}
			&-date, &-name {
				.d-regular-14();
				color: @black100;

				@media (max-width: @large-tablet-min) {
					.d-regular-12();
				}
			}
		}

		&-delete {
			transition: opacity 0.2s ease;
			opacity: 0;
			visibility: hidden;
		}

		&-mobile {
			display: flex;
			gap: 8px;

			&-select {
				padding: 10px;
			}
			&-container {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}
	}
}