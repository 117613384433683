@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.documents-page {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 16px;
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__cell_icon {
		margin-right: 16px;
		margin-bottom: -6px;
		display: inline;
	}

	&__cell_name {
		vertical-align: top;
		display: inline;
	}

	&__mobile_cell {
		&_name {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 600;
		}

		&_company {
			font-size: 12px;
			color: @black60
		}

		&_created {
			font-size: 12px;
		}
	}
}

.tz-file-type-pdf {
	color: @red50
}

.tz-file-type-xls {
	color: @red50
}

.tz-file-type-doc {
	color: #0A9460
}