@import (reference) "../../../styles/colors";

.dnd-table {
	user-select: none;
	box-sizing: border-box;
	border-collapse: collapse;
	width: 100%;

	&__header {
		padding: 0;
		margin: 0;

		&-row {
			border-bottom: 1px solid @black10;

			&__cell {
				padding: 12px;

				&-drag {
					width: 26px;
				}
			}
		}
	}

	&__body {
		padding: 0;
		margin: 0;

		&-row {
			border-collapse: collapse;
			border-spacing: 0;
			border-top: 1px solid @black10;
			cursor: pointer;

			&__cell {
				padding: 8px;

				&-drag {
					&-handle {
						color: @black60;
					}
				}
			}

			&-empty {
				empty-cells: hide;

				&__cell {
					width: 100%;
				}

				&.isOver {
					border: 2px solid @buttonAccentHover;
				}
			}

			&.isDragging {
				opacity: .5;
			}

			&-top, &-bottom {
				position: relative;

				&::after {
					content: '';

					opacity: 1;
					width: 9px;
					height: 9px;
					border-radius: 100%;
					position: absolute;
					left: -3px;
					background-color: @buttonAccentHover;
				}

				&::before {
					content: '';

					opacity: 1;
					width: 100%;
					height: 2px;
					position: absolute;
					background-color: @buttonAccentHover;
				}
			}

			&-top {
				&::after {
					top: -6px;
				}

				&::before {
					top: -2px;
				}
			}

			&-bottom {
				&::after {
					bottom: -3px;
				}

				&::before {
					bottom: 0px;
				}
			}
		}
	}
}