@import (reference) '~@tehzor/ui-components/src/styles/variables';

.adding-resp-user {
	&__container {
		@media (min-width: @tablet-min) {
			width: 608px;
		}

		@media (min-width: @desktop-min) {
			width: 808px;
		}
	}
}