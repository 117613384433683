@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";


.history-resp-users-change {
	color: @black100;
	.d-regular-14();

	&__header {
		display: flex;
		align-items: center;
	}

	&__group {
		.d-semibold-14();
		margin-bottom: 2px;
	}


	&__users {
		padding-left: 28px;
	}

	&__user {
		width: 100%;
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		.d-regular-12();
		color: @black60;
	}

	&__name {
		.d-regular-14();
		color: @black100;
	}

	&__icon {
		color: @grey40;
		margin-right: 13px;
	}
}

