@import (reference) '~@tehzor/ui-components/src/styles/colors';

.offline-journal-filter-page {
	.filter-page__content {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__date-button {
		.filter-button {
			background-color: @buttonAccent;
			color: @white;
		}

		.filter-button-clear {
			background-color: @deepBlue;
			color: @white;
		}
	}
}