@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: calc(100dvh - 32px);
	transform: translate(-50%, -50%);
	outline: none;

	@media (max-width: (@tablet-min - 1px)) {
		width: calc(100vw - 32px);
	}

	@media (min-width: @tablet-min) {
		left: 50%;
		max-width: calc(100vw - 32px);
	}

	&_fs {
		width: 100vw;
		height: 100dvh;
		max-width: 100vw;
		max-height: 100dvh;
		top: 0;
		left: 0;
		transform: translate(0, 0);
	}

	&_fs_mobile {
		@media (max-width: (@tablet-min - 1px)) {
			width: 100vw;
			height: 100dvh;
			max-width: 100vw;
			max-height: 100dvh;
			top: 0;
			left: 0;
			transform: translate(0, 0);
		}
	}

	&_fs_tablet {
		@media (max-width: (@desktop-min - 1px)) {
			width: 100vw;
			height: 100dvh;
			max-width: 100vw;
			max-height: 100dvh;
			top: 0;
			left: 0;
			transform: translate(0, 0);
		}
	}

	&__layer {
		height: 100%;
		background-color: @white;
		border-radius: 14px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);
		overflow: hidden;

		@media (min-width: @desktop-min) {
			border-radius: 24px;
		}
	}

	&_fs &__layer {
		border-radius: 0;
	}

	&_fs_mobile &__layer {
		@media (max-width: (@tablet-min - 1px)) {
			border-radius: 0;
		}
	}

	&_fs_tablet &__layer {
		@media (max-width: (@desktop-min - 1px)) {
			border-radius: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		max-height: calc(100dvh - 32px);
	}

	&_fs &__content {
		max-height: 100%;
	}

	&_fs_mobile &__content {
		@media (max-width: (@tablet-min - 1px)) {
			max-height: 100%;
		}
	}

	&_fs_tablet &__content {
		@media (max-width: (@desktop-min - 1px)) {
			max-height: 100%;
		}
	}

	&__header {
		@media (max-width: (@desktop-min - 1px)) {
			padding: 14.5px 16px;
		}

		@media (min-width: @desktop-min) {
			padding: 44px 64px 20px;
		}

		&_margin-right {
			@media (max-width: (@desktop-min - 1px)) {
				padding-right: 56px;
			}

			@media (min-width: @desktop-min) {
				padding-right: 72px;
			}
		}

		&-title {
			width: 100%;
			color: @black100;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			user-select: none;

			@media (max-width: (@desktop-min - 1px)) {
				.tm-bold-20();
			}

			@media (min-width: @desktop-min) {
				.d-bold-24();
			}
		}
	}

	&__body {
		flex: 1 1 auto;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		@media (max-width: (@desktop-min - 1px)) {
			padding: 16px;
		}

		@media (min-width: @desktop-min) {
			padding: 16px 64px;
		}

		&:first-of-type {
			padding-top: 56px;
		}

		&:first-child {
			@media (max-width: (@desktop-min - 1px)) {
				padding-top: 16px;
			}

			@media (min-width: @desktop-min) {
				padding-top: 32px;
			}
		}

		&:last-of-type {
			@media (max-width: (@desktop-min - 1px)) {
				padding-bottom: 16px;
			}

			@media (min-width: @desktop-min) {
				padding-bottom: 32px;
			}
		}

		&_scroll-blocked {
			overflow-y: hidden;
		}
	}

	&__footer {
		flex: none;

		@media (max-width: (@desktop-min - 1px)) {
			padding: 16px;
		}

		@media (min-width: @desktop-min) {
			padding: 16px 64px 32px;
		}
	}

	&__close-btn {
		position: absolute;
		z-index: 1;

		@media (max-width: (@desktop-min - 1px)) {
			top: 8px;
			right: 8px;
		}

		@media (min-width: @desktop-min) {
			top: 16px;
			right: 16px;
		}
	}
}

.overlay.dialog-overlay {
	&_transparent {
		background-color: transparent;
	}
}
