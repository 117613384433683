@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.layout {
	padding: 20px !important;
	border-radius: 12px !important;

	@media (max-width: @large-mobile-min) {
		padding: 12px !important;
	}
}

.layout_error {
	background-color: @red10 !important;
}

.wrapper {
	padding: 0 !important;
	gap: 0 16px;
	opacity: 1 !important;
	z-index: 2;
}

.buttonClose {
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 8px;
	color: @blue2;
	z-index: 2;
}

.container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0 16px;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.iconWrapper {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	padding: 0 20px 0 0;
	margin: 0;
	font-size: 16px;
	font-weight: 700;
	color: @black4;

	@media (max-width: @large-mobile-min) {
		font-size: 12px;
	}
}

.description {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	color: @black85;

	@media (max-width: @large-mobile-min) {
		font-size: 12px;
	}
}

.links {
	padding: 8px 0 0 0;
	display: flex;
	flex-wrap: wrap;
	gap: 0 16px;

	@media (max-width: @large-mobile-min) {
		padding: 0 0 0 0;
	}
}

.actions {
	margin: 16px 0 0 0;
	display: flex;
	justify-content: flex-end;
	gap: 0 20px;
}