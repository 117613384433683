@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

body {
	overflow-y: hidden;
}

.page-cont {
	width: 100%;
	padding: 10px 16px;

	@media (min-width: @tablet-min) {
		padding: 16px;
	}

	@media (min-width: @desktop-min) {
		padding: 20px 32px 32px;
	}

	&_extra-bottom-margin {
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 56px;
		}
	}
}

.page-back-btn {
	&__icon {
		display: block;
		transform: rotate(180deg);
		line-height: 1;
	}
}

.panel {
	background-color: @white;
	border: 1px solid @gray1;
	border-radius: 2px;
	box-shadow: 0 1.5em 3em rgba(210, 224, 233, 0.2);
}

.fields-grid {
	display: grid;
	grid-row-gap: 3em;
	margin-bottom: 3em;
	padding: 0 2em;

	@media (max-width: @tablet-min) {
		grid-template-columns: 100%;
	}

	@media (min-width: @tablet-min) and (max-width: @tablet-max) {
		grid-column-gap: 4%;
		grid-template-columns: 48% 48%;
	}

	@media (min-width: @large-tablet-min) and (max-width: @desktop-min - 1px) {
		grid-column-gap: 2.6%;
		grid-template-columns: 31.6% 31.6% 31.6%;
	}

	@media (min-width: @desktop-min) and (max-width: @large-desktop-min - 1px) {
		grid-column-gap: 2%;
		grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
	}

	@media (min-width: @large-desktop-min) {
		grid-column-gap: 1.6%;
		grid-template-columns: 18.72% 18.72% 18.72% 18.72% 18.72%;
	}

	.app-content_padding_288 & {
		@media (min-width: @tablet-min + @dockedSidebarMax) and (max-width: @large-tablet-min + @dockedSidebarMax - 1px) {
			grid-column-gap: 4%;
			grid-template-columns: 48% 48%;
		}

		@media (min-width: @large-tablet-min + @dockedSidebarMax) and (max-width: @desktop-min + @dockedSidebarMax - 1px) {
			grid-column-gap: 2.6%;
			grid-template-columns: 31.6% 31.6% 31.6%;
		}

		@media (min-width: @desktop-min + @dockedSidebarMax) and (max-width: @large-desktop-min + @dockedSidebarMax - 1px) {
			grid-column-gap: 2%;
			grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
		}

		@media (min-width: @large-desktop-min + @dockedSidebarMax) {
			grid-column-gap: 1.6%;
			grid-template-columns: 18.72% 18.72% 18.72% 18.72% 18.72%;
		}
	}
}

.field {
	&__label {
		margin-bottom: 0.625em;
		font-size: 1.5em;
		line-height: 1;
		font-weight: 600;
		color: @black1;
	}

	&__value {
		font-size: 1.4em;
		line-height: 1.3em;
		letter-spacing: -0.01em;
		color: @black2;
		white-space: pre-line;
	}
}
