@import (reference) "../../../styles/colors";

.uploading-image {
	position: relative;
	width: 70px;

	&__photo {
		&-wrap {
			.photo-preview_active > .photo-preview__wrap&_error {
				border-color: @statusExpired;
			}
		}
	}

	&__delete-btn {
		position: absolute;
		top: -8px;
		right: -8px;
	}

	&__center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__loader {
		pointer-events: none;
	}

	.circle-button&__reload-btn {
		color: @buttonAccent;
	}

	&__warning-icon {
		width: 28px;
		height: 28px;
		background-color: @white;
		border-radius: 50%;
		color: @statusVerification;
		pointer-events: none;
	}
}