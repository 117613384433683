@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.wrapper {
	padding: 0 24px;
	border-right: 1px solid @gray8;

	@media (max-width: @desktop-min) {
		padding: 0;
	}
}

.header {
	padding: 0 0 16px 0;
	border-bottom: 1px solid @gray8;
}

.title {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 600;
	color: @black85;
}

.titleClickable {
	cursor: pointer;
}

.counter {
	margin-left: 5px;
	padding: 0;
	font-size: 16px;
	font-weight: 600;
	color: @black35;
}