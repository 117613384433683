@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.indicators-select {
	&__expandable-plate {
		padding: 0 0 16px 0;
		box-shadow: none;
		border-radius: 0;
		@media (max-width: @large-tablet-max) {
			padding: 0 0 16px 0;
		}
	}
	&__expandable-plate-content {
		padding: 0;
		@media (max-width: @large-tablet-max) {
			padding: 0 16px 0 0 !important;
		}
	}
	&__expandable-plate-expand-btn {
		padding: 0;
		@media (max-width: @large-tablet-max) {
			padding-left: 0px;
		}
		&--indicator-set-item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			white-space: nowrap;
			overflow: hidden;
			gap: 2px;

			&_name {
				font-size: 14px;
				line-height: 21px;
				font-weight: 600;
				margin-bottom: -1px;
				margin-top: 0;
			}

			&_stages {
				color: #747f8d;
				font-size: 12px;
				line-height: 18px;
				font-weight: 400;
			}
		}
	}
}
