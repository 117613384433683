@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) "../../../styles/typography";

.work-acceptances-space {
	position: relative;

	&_rest > &__status,
	&_systems > &__status,
	&_technical-underground > &__status,
	&_technical-spaces > &__status,
	&_common-areas > &__status {
		.desktop &:hover {
			transform: scale3d(1.05, 1.05, 1);
		}
	}

	&_disabled {
		background: @white;
		opacity: 0.2;
	}

	&_disabled &__status {
		cursor: none;
		user-select: none;
		pointer-events: none;

		.desktop &:hover {
			transform: none;
			box-shadow: none;
		}
	}

	@media (max-width: @tablet-min) {
		&_rest > &__status,
		&_systems > &__status,
		&_technical-underground > &__status,
		&_technical-spaces > &__status,
		&_common-areas > &__status {
			max-width: 100%;
		}
	}

	&__status {
		position: relative;
		border-radius: 9px;
		cursor: pointer;
		user-select: none;
		transition: transform 0.2s ease;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		overflow: visible;

		&-shadow {
			transition: filter 0.2s ease;
			fill: @white;
		}

		.desktop &:hover {
			transform: scale3d(1.2, 1.2, 1);
		}

		.desktop &:hover > &-shadow {
			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
		}

		&:active > &-shadow {
			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
		}
	}

	&__number {
		.semi-bold();
		font-size: 12px;
		line-height: 21px;
		fill: @black4;
	}

	&__counter-icon {
		fill: @repeatCounter;
	}

	&__counter-text {
		.semi-bold();
		font-size: 10px;
		fill: @repeatCounter;
	}

}