@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
	padding: 24px;
	border-bottom: 1px solid @gray8;

	@media (max-width: @large-mobile-max) {
		padding: 16px 16px 0 16px;
	}
}