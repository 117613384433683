@import "../../styles/shared";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

@padding: 6%;

.plan-page {
	&__dialog {
		@media (min-width: @large-mobile-min) and (max-width: @tablet-min - 1px) {
			width: 400px;
		}

		@media (min-width: @tablet-min) {
			width: 550px;
		}

		&-content {
			& > div:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
}

.app-tmp {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 72px);
	overflow: hidden;
}

.app-bar1 {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100px;
	padding: 0 @padding;
	background-color: #919191;
	box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
	z-index: 1;
	overflow: auto;

	&__left {
		display: flex;
		align-items: center;
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__label {
		color: @white;
	}

	&__separator {
		width: 1px;
		height: 52px;
		margin: 0 25px 0 40px;
		background-color: @gray2;
	}

	&__btn {
		margin-left: 10px;
	}
}

.app-content1 {
	display: flex;
	align-items: flex-start;
	height: 100%;
	margin-top: -100px;
	padding-top: 100px;
}

.app-sidebar1 {
	flex: 1 1 350px;
	height: 100%;
	padding: 0 @padding;
	background-color: @gray3;
	overflow-y: auto;
}

.app-main {
	display: flex;
	flex-direction: column;
	flex: 2 2 650px;
	height: 100%;
	background-color: @white;
}

.app-main-area {
	position: relative;
	width: 100%;
	height: 100%;
	margin-bottom: -80px;
	padding-bottom: 80px;
	overflow: auto;
}

.app-main-actions {
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 80px;
	padding: 0 @padding / 2;
	z-index: 1;
	overflow: auto;

	> .btn {
		margin-left: 5px;
	}
}

.edit-block {
	&__title {
		margin: 40px 0 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid @black;
		font-size: 1.3em;
		line-height: 1.28em;
		color: @black;

		&-name {
			margin-top: 4px;
			margin-bottom: 12px;
		}
		&-floor {
			margin-top: 4px;
		}
	}

	&__field-title {
		color: @gray4;
	}

	&__list {
		margin: 30px 0 40px;
		user-select: none;

		&-item {
			position: relative;
			min-height: 30px;
			padding: 6px 0;

			&-title {
				color: @black;
				opacity: 0.6;
				cursor: pointer;
				.transition(opacity);
			}

			&_selected &-title {
				opacity: 1;
			}

			&:hover &-title {
				opacity: 0.8;
			}

			&-actions {
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
				.transition(opacity);
			}

			&_selected &-actions {
				opacity: 1;
			}

			&:hover &-actions {
				opacity: 1;
			}

			&-btn {
				margin: 0 4px;
				opacity: 0.5;
			}
		}
	}
}

.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	padding: 0 15px;
	background-color: transparent;
	border: 1px solid #727272;
	border-radius: 18px;
	font: 1em/1em 'Roboto', sans-serif;
	color: @black;
	cursor: pointer;
	box-sizing: border-box;
	user-select: none;
	.transition();

	&__label {
		padding: 0 15px;
	}

	> svg {
		display: block;
		margin-left: 10px;
	}

	&_blue {
		padding: 0 30px;
		background-color: #00baff;
		color: @white;
	}

	&_red {
		padding: 0 30px;
		background-color: #ff0042;
		color: @white;
	}

	&_white {
		border-color: @white;
		color: @white;
	}

	&_circle {
		width: 52px;
		height: 52px;
		padding: 0;
		border-radius: 50%;

		> svg {
			margin-left: 0;
		}
	}

	&_active {
		background-color: rgb(0, 186, 255);
		box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.11);
	}

	&_disabled, &_disabled:hover {
		cursor: initial;
		pointer-events: none;
		background-color: @black05;
		color: @black20;

		button& {
			background-color: @black05;
		}
	}

	&_no-border {
		border: none;
	}

	&_small-circle {
		width: 26px;
		height: 26px;
		padding: 0;
		border-radius: 50%;

		> svg {
			margin-left: 0;
		}
	}
}

.plan-editor {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #CFCFCF;
	overflow: scroll;

	&__wrapper {
		position: absolute;
		//transform-origin: left top;
		overflow: hidden;

		> svg {
			display: block;
			position: absolute;
			//top: 50%;
			//left: 50%;
			border: 1px solid #BCBCBC;
			box-sizing: content-box;
		}
	}
}

.svg_select_boundingRect {
	fill: rgba(0, 186, 255, 0.2);
	stroke: @gray2;
	stroke-width: 2px;
}

.svg_select_points_point {
	fill: rgb(0, 186, 255);
	cursor: pointer;
}
