@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/typography';

.select-search {
	&__wrap {
		align-items: center;
	}

	.select-search_common > & {
		&__wrap {
			background-color: @buttonLightSelected;

			&:hover {
				background-color: @buttonLightHover;
			}
			&.disabled {
				background-color: @grey10;
			}
		}

		&__icon.disabled {
			color: @black35;
		}


		&__input, &__placeholder {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}

		&__placeholder {
			position: absolute;
			color: @black60;
			top: 14px;
		}
	}

	.select-search_filter > & {
		&__wrap {
			border-radius: 8px;
			background-color: white;
			height: 40px;
			width: auto;
			max-width: 296px;
		}

		&__placeholder {
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
			height: fit-content;
			.tm-regular-15();
		}

		&__clearbutton {
			position: relative;
		}

		&__icon {
			color: @blue2;
			font-size: 16px;
			margin: 0;
			padding: 0;
			min-height: 40px;

			&:active {
				background-color: @buttonPressed;
			}
			&.disabled {
				color: @grey40;
				pointer-events: none;
			}
		}
	}

	.select-search_popup > & {
		&__wrap {
			border-radius: 8px;
			background-color: @buttonLightSelected;
			height: 40px;
			width: auto;
		}

		&__input {
			margin-right: 40px;
		}

		&__placeholder {
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
			height: fit-content;
			.tm-regular-15();
		}

		&__clearbutton {
			position: relative;
			left: 5px;
		}

		&__icon {
			color: @blue2;
			font-size: 16px;
			margin: 0;
			padding: 0;
			min-height: 40px;

			&:active {
				background-color: @buttonPressed;
			}
			&.disabled {
				color: @grey40;
				pointer-events: none;
			}
		}
	}
}