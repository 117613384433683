@import (reference) '~@tehzor/ui-components/src/styles/colors';

.mobile-filters-link-btn {
	position: relative;

	&__mark {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 12px;
		height: 12px;
		background-color: @statusExpired;
		border: 3px solid @button;
		border-radius: 50%;

		.icon-button:active & {
			border-color: @buttonPressed;
		}
	}
}