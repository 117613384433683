@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.comment-input {
	position: relative;

	@media (min-width: @large-tablet-min) {
		border: 1px solid @black10;
		border-radius: 8px;
		transition: 0.2s ease border-color;

		&_focused {
			border-color: @iconButtonHover;
		}
	}

	&__scrollbar {
		max-height: 59px;
		overflow-x: hidden;

		@media (min-height: @large-tablet-min-height) {
			max-height: 101px;
		}

		@media (min-height: @desktop-min-height) {
			max-height: 143px;
		}

		@media (min-height: @large-desktop-min-height) {
			max-height: 185px;
		}
	}

	&__textarea {
		display: block;
		width: 100%;
		background-color: transparent;
		border: none;
		font-family: 'Open Sans', sans-serif;
		color: @black95;
		overflow: hidden;
		outline: none;
		resize: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		@media (max-width: @tablet-max) {
			padding: 8px;
			.tm-regular-15
		}

		@media (min-width: @large-tablet-min) {
			padding: 8px 16px 9px;
			.d-regular-14();
		}
	}

	&_disabled &__textarea {
		color: @black45;
	}

	&__placeholder {
		position: absolute;
		top: 8px;
		color: @black60;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;

		@media (max-width: @tablet-max) {
			left: 12px;
			right: 12px;
		}

		@media (min-width: @large-tablet-min) {
			left: 20px;
			right: 20px;
		}
	}
}