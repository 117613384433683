@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.categories-tags {
	&__wrap {
		margin: -4px;
	}

	&__tag {
		max-width: 250px;
		margin: 4px;
		transition: background-color 0.2s ease, color 0.2s ease;

		&:not(&_active) {
			color: @black60;
		}

		&_active {
			color: @black4;
		}

		.tag_dark&_active {
			color: @white;
		}
	}
}