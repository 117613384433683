@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.action-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: @tablet-min - 1px) {
		& > * {
			width: 50%;
		}
	}

	@media (min-width: @large-tablet-min) {
		& > *:not(:first-child) {
			margin-left: 16px;
		}
	}
}
