.toggle {
	&__wrap {
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	&__content {
		margin-left: 0;
		margin-right: 12px;
	}
}