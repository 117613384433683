@appBackground: #EEF5FB;

@white: #FFFFFF;
@white2: #F4F9FD;
@white3: #FBFDFF;
@blue: #0C86BA;
@red: #FF6661;
@orange: #FDB44D;
@green: #24B4B3;

@gray1: #E8E8E8;
@gray2: #E0E0E0;
@gray3: #C4C4C4;
@gray4: #989898;
@gray5: #DADDE8;
@gray6: #EEEEEE;
@gray7: #9AABC0;
@gray8: #D9DDE3;

@black1: #1E3A4A;
@black2: #3F4359;
@black3: #233539;
@black4: #172A41;

@grayBlue1: #445D6C;
@grayBlue2: #5D7481;
@grayBlue3: #3A5260;
@grayBlue4: #A8BDDC;

@blue2: #3391FF;
@orange2: #FFC52F;
@red2: #FF6565;
@green2: #2DCB57;
@green3: #13D7CB;
@emerald: #E2FDF3;
@emerald2: #0CB676;
@grayBlue4: #567396;

@primaryGreen: #11C7BC;
@primaryRed: #FD5151;
@primaryOrange: #FFC52F;
@deepBlue: #0E72E3;

@grey: #8695A6;
@greyHover: #66707B;
@greyPressed: #303438;
@greyLight: #C4D1E3;

@button: #FFFFFF;
@buttonHover: #F4F9FD;
@buttonPressed: #E3ECF5;
@buttonInactive: #F3F4F5;

@buttonLight: #FBFDFF;
@buttonLightHover: #F4F9FD;
@buttonLightHover2: #E9F1F8;
@buttonLightPressed: #E3ECF5;
@buttonLightSelected: #EEF5FB;

@iconButtonHover: #A7D0FF;
@iconButtonPressed: #7CB8FF;

@buttonAccent: #3391FF;
@buttonAccentHover: #57A4FF;
@buttonAccentPressed: #2589FF;

@buttonRed: #FD5151;
@buttonRedHover: #FD6666;
@buttonRedPressed: #F73F3F;

@buttonFilterActive: #DFE9F8;
@buttonFilterActiveHover: #C3D4ED;
@buttonFilterActivePressed: #D4E1F2;

@buttonFilterClear: #0b40901F;
@buttonFilterClearHover: #0b409033;
@buttonFilterClearPressed: #0B409040;

@statusExpired: #FD2942;
@statusExpired10: #FFE3E7;
@statusWaiting: #FF9D28;
@statusWaiting10: #FFEDD8;
@statusHandling: #60B2FF;
@statusHandling10: #E5F2FF;
@statusVerification: #FFD800;
@statusVerification10: #FFFADE;
@statusFixed: #90D857;
@statusInactive: #C4D1E3;
@statusInactive25: #F0F3F8;
@statusInactive10: #F8FAFC;
@statusAccepted: #EFF9E6;

@replyBackground: #C3D4ED;

@repeatCounter: #B162EF;

@blue10: #E5F1FF;
@blue20: #C2DEFF;
@blue30: #9ECBFF;
@blue40: #7AB8FF;
@blue50: #57A5FF;
@blue60: #3392FF;
@blue70: #0F7FFF;
@blue80: #006DEB;
@blue90: #005DC7;
@blue100: #004CA3;

@green10: #E9F7DE;
@green20: #D6F1C1;
@green30: #C3EAA4;
@green40: #B0E387;
@green50: #9DDC6A;
@green60: #89D64D;
@green70: #76CF30;
@green80: #66B229;
@green90: #559523;
@green100: #45781C;

@greenSuccess: #0CB676;

@orange10: #FFEEDB;
@orange20: #FFDEB8;
@orange30: #FFCD94;
@orange40: #FFBD70;
@orange50: #FFAC4D;
@orange60: #FF9C29;
@orange70: #FF8B05;
@orange80: #E07800;
@orange90: #BD6500;
@orange100: #995200;

@red10: #FFE3E7;
@red20: #FFC2CB;
@red30: #FF9EAC;
@red40: #FF7A8D;
@red50: #FF576F;
@red60: #FF3350;
@red70: #FF0F31;
@red80: #EB0021;
@red90: #C7001C;
@red100: #A30017;

@yellow10: #FFFCE0;
@yellow20: #FFF8BE;
@yellow30: #FFF499;
@yellow40: #FFF075;
@yellow50: #FFEC52;
@yellow60: #FFE82E;
@yellow70: #FFE50A;
@yellow80: #E5CD00;
@yellow90: #C2AD00;
@yellow100: #9E8D00;

@black05: #F3F4F5;
@black10: #E8EAEC;
@black20: #D1D4D9;
@black35: #AEB5BD;
@black45: #969FA9;
@black60: #747F8D;
@black75: #515F71;
@black85: #3A4A5D;
@black95: #23354B;
@black100: #172A41;

@grey10: #edeff2;
@grey20: #d9dde3;
@grey30: #c4cad4;
@grey40: #afb8c5;
@grey50: #9aa6b6;
@grey60: #8693a7;
@grey70: #718198;
@grey80: #606f85;
@grey90: #515e71;
@grey100: #424d5c;

@historyBackground: #F5F6F8;
@separator: #C4CAD4;