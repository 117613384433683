@import (reference) "~@tehzor/ui-components/src/styles/variables";

.main {
	display: flex;
	flex-direction: column;
	gap: 24px 0;
	
	@media (max-width: @desktop-min) {
		padding: 0 16px;
	}

	@media (max-width: @large-mobile-max) {
		padding: 0;
	}
}