@import (reference) '~@tehzor/ui-components/src/styles/colors';

.expandable-mobile-info {
	margin-top: 16px;
	padding-top: 16px;
	border-top: 1px solid @black10;

	&_no-border {
		border-top: none;
	}
	&_expanded {
		//margin-bottom: -8px; из-за отступа появляется подергивание
		padding-top: 12px;
		text-align: center;
	}

	&__hide-btn {
		&-icon {
			transform: rotate(180deg);
		}
	}

	&__expand-btn {
		&-icon {
			margin-left: 8px;
		}
	}
}

.expandable-mobile-info-panel {
	&__content {
		margin-top: 16px;
		padding-top: 16px;
		border-top: 1px solid @black10;
		// background-color: burlywood;
	}
}
