@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.custom-action-buttons {
	&__wrapper {
		display: flex;
		justify-content: flex-end;
	}

	&__checkbox {
		margin-right: auto;
		padding-right: 16px;
	}

	&__button {
		@media (max-width: (@desktop-min - 1px)) {
			min-width: 164px;
		}
	}

	&__submit-button-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__submit-button-label {
		@media (max-width: (@desktop-min - 1px)) {
			.tm-semibold-10();
			opacity: 0.7;
		}
	}
}

.mobile-notification {
	display: none;

	&__visible {
		.d-semibold-12();
		display: block;

		height: 24px;
		line-height: 15.84px;
		text-align: center;
		color: @greenSuccess;
	}
}

.fast-adding-toast-layout {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 258px;

	.Toastify {
		position: relative;
	}

	.Toastify__toast-container {
		position: static;
		width: 100%;
	}
}

.fast-adding-toast {
	position: static;

	&__icon {
		color: @greenSuccess;
	}
}