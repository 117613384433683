@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.tasks-shedule-table-wrap {
	display: flex;
	margin: 0 auto;
	width: 100%;

	@media (max-width: @desktop-min) {
		overflow-y: auto;
	}
}

.tasks-shedule-table-info {
	border-spacing: 0;
	padding: 0;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 8px 12px rgba(31, 52, 79, 0.2);

	&_head {
		&-row{
			display: flex;
			justify-content: space-between;
			width: 350px;
			&_cell {
				display: flex;
				align-items: center;
				height: 80px;
				color: @black60;
				.d-regular-14();

				&:nth-child(1) {
					margin-left: 30px;
					width: 276px;
				}

				&:nth-child(2) {
					text-align: start;
				}
			}
		}
	}

	&_body {
		height: 100%;
		&-row {
			display: flex;
			justify-content: space-between;
			width: 350px;
			border-top: 1px solid @gray8;
			&_cell {
				display: flex;
				overflow: hidden;
			}
		}
	}
}

.tasks-shedule-table-wrapper-month {
	display: flex;
	flex-direction: column;
	width: calc(100% - 360px);
}

.tasks-shedule-table-wrap-month {
	display: flex;
	overflow-y: auto;

	@media (max-width: @desktop-min) {
		overflow-y: inherit;
	}
}

.tasks-shedule-table-month {
	border-spacing: 0;
	padding: 0;

	&_head {
		border-bottom: 1px solid @gray8;
		border-bottom: 1px solid @gray8;
		&-row {
			&_cell {
				padding: 0;
				padding: 0;
			}
		}
	}

	&_body {
		height: 100%;

		&-row {
			&_cell {
				border-top: 1px solid @gray8;
				border-right: 1px solid @gray8;
				min-width: 120px;
			}
		}
	}
}
