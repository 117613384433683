@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.internal-acceptance-page {
	@media (max-width: (@tablet-min - 1px)) {
		padding: 0;
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @large-mobile-max) {
			margin-bottom: 0;
		}
	}
	&__info-buttons {
		margin-top: 12px;
	}
	
	&__documents {
		&-description {
			.d-regular-14();
			color: @black4;
			&-button .inline-button__label {
				.d-regular-14();
			}
		}
	}

	&__d-columns {
		display: flex;
		align-items: flex-start;

		.app-content_padding_288 & {
			@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
				display: grid;
				grid-template-columns: 368px 1fr;
				grid-template-areas:
					'attachments comments'
					'info comments';
				grid-column-gap: 24px;
			}
		}
	}

	&__d-column {
		position: sticky;
		top: 22px;

		&:not(:last-child) {
			margin-right: 24px;

			@media (min-width: @desktop-min) {
				margin-right: 32px;
			}
		}

		&_info {
			flex: none;

			@media (min-width: @large-desktop-min) {
				width: 368px;
			}

			.app-content_padding_72 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					width: 304px;
				}

				@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
					width: 368px;
				}
			}

			.app-content_padding_288 & {
				@media (min-width: @large-tablet-min) and (max-width: (@desktop-min - 1px)) {
					grid-area: attachments;
					position: relative;
					top: 0;
					width: 100%;
					margin: 0 0 24px;
				}

				@media (min-width: @desktop-min) and (max-width: (@large-desktop-min - 1px)) {
					width: 304px;
				}
			}
		}

		&_entities {
			flex: 1 1;
			margin: -24px;
			padding: 24px;
			overflow-x: hidden;
		}
	}

	&__d-entities {
		.table2__cell_selection {
			padding-top: 36px;
		}

		&-number {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		&-expand-btn {
			margin-right: 12px;

			&-icon {
				transition: transform 0.2s ease;

				&_expanded {
					transform: rotate(-180deg);
				}
			}
		}

		&-type-icon {
			margin-right: 14px;
			color: @greyLight;
		}

		&-number-value {
			.d-semibold-16();
			color: @black95;
		}

		&-status {
			max-width: 100%;
			margin-top: 4px;
		}

		&-fix-date {
			margin-top: 10px;
			font-weight: 600;
		}

		&-category {
			margin-top: 6px;
		}

		&-resp-user {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		&-link {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__d-entity-info {
		display: flex;
		align-items: flex-start;
		padding: 32px 32px 32px 80px;

		&-col {
			flex: 1 1 50%;

			&:not(:last-child) {
				padding-right: 32px;
			}

			&:first-child:last-child {
				flex: 1 1 100%;
			}
		}
	}

	&__d-last-reply {
		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
		}

		&-title {
			.d-bold-18();
		}
	}

	&__d-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
	}

	&__m-visibility-filter {
		display: inline-block;
		width: auto;
		max-width: calc(100% - 32px);
		margin: 16px;

		& > * {
			max-width: 100%;
		}
	}

	&__m-entities {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&-item {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
}
