@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.wrapper {
	padding: 0 0 0 40px;
	display: flex;
	flex-wrap: wrap;

	&:not(:last-child) {
		border-bottom: 1px solid @grey10;
	}

	@media (max-width: @desktop-min) {
		padding: 0 24px 0 64px;
		justify-content: center;
	}
}

.title {
	margin: 0 0 0 -40px;
	min-width: 40px;
	font-size: 24px;
	font-weight: 700;
	color: @greyLight;
	line-height: 80px;
}

.list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.listItem {
	width: 288px;
	height: 80px;
	display: grid;
	grid-template-columns: min-content 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0 16px;
	align-items: center;

	@media (max-width: @mobile-max) {
		width: 200px;
	}

}

.listItemDisabled {
	opacity: 0.23;
}

.name {
	margin: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.84px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 4;
	-webkit-line-clamp: 4;
	overflow: hidden;
	text-overflow: ellipsis;
}

.innerCell {
	opacity: 0.26;
}