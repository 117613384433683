@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/variables";
@import (reference) "../../../../../styles/typography";

.mobile-entity-content-row {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:not(:last-child) {
		margin-bottom: 6px;
	}

	@media (max-width: @tablet-min - 1px) {
		.tm-regular-13();
	}

	@media (min-width: @tablet-min) {
		.tm-regular-15();
	}

	&__label {
		color: @black75;
	}

	&__content {
		font-weight: 600;
		color: @black95;
	}
}