@import (reference) "../../styles/variables";

.wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 40px;


	@media (max-width: @desktop-min) {
		grid-gap: 0;
		padding: 0;
	}
}