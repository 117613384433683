@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

@class: ~'.m-sidebar';

@{class} {
	display: inline-flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: 304px;
	height: 100%;
	z-index: 5300;
	background-color: @white;
	box-shadow: @shadow1;

	&_hidden {
		box-shadow: none;
	}

	&__menu-btn-wrap {
		flex: none;
		margin: 8px;
	}

	&__anim-area {
		flex: auto;
		height: 100%;
		overflow: hidden;
	}

	&__cont {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		flex: auto;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.simplebar-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: stretch;
			height: 100%;
		}
	}

	&__menu-wrap {
		flex: auto;
		padding: 16px 0;
	}

	&__app-info-wrap {
		flex: none;
		padding: 16px;
	}

	&__user-info-wrap {
		flex: none;
		width: 100%;
		padding: 20px 8px;
		border-bottom: 1px solid @black05;
	}

	.user-button {
		display: block;
		padding-left: 4px;
		padding-right: 4px;
	}

	.user-info {
		grid-column-gap: 20px;
	}

	.user-info__name {
		.tm-semibold-17();
	}

	.user-info__position {
		.tm-regular-15();
	}
}

.m-sidebar-toggle-area {
	position: fixed;
	top: 0;
	left: 0;
	width: 24px;
	height: 100%;
	z-index: 1190;
}

.overlay.m-sidebar-overlay {
	z-index: 1280;
}