@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.app-header {
	flex: none;
	position: relative;
	width: 100%;
	z-index: 1200;

	//@media (max-width: @tablet-min - 1px) {
	//	position: fixed;
	//	top: 0;
	//	left: 0;
	//	right: 0;
	//}

	@media (min-width: @tablet-min) and (max-width: @desktop-min - 1px) {
		width: calc(100% - 56px);
		margin-left: 56px;
	}

	&.error {
		align-items: center;
		padding: 0 16px;
		background-color: @white;
		box-shadow: 0 2px 15px rgba(112, 126, 153, 0.12);
		width: 100%;
		height: @desktop-header-height;

		@media (max-width: @desktop-min) {
			height: @mobile-header-height;
		}
	}

	&__mobile-right-menu {
		display: flex;
		align-items: center;
		gap: 8px 0;
	}
}
