@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.confirm-dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: calc(100vh - 32px);
	transform: translate(-50%, -50%);
	outline: none;

	@media (max-width: @large-mobile-min - 1px) {
		width: calc(100vw - 32px);
	}

	@media (min-width: @large-mobile-min) {
		left: 50%;
		width: auto;
		max-width: calc(100vw - 32px);
	}

	&__layer {
		height: 100%;
		background-color: @white;
		border-radius: 14px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);
		overflow: hidden;
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		max-height: calc(100vh - 32px);
	}

	&__title {
		width: 100%;
		color: @black100;
		user-select: none;

		@media (max-width: @tablet-max) {
			padding: 20px 16px 12px;
			.tm-bold-20();
		}

		@media (min-width: @large-tablet-min) {
			padding: 26px 32px 10px;
			.d-bold-18();
		}
	}

	&__message {
		color: @black75;

		@media (max-width: @tablet-max) {
			padding: 8px 16px;
		}

		@media (min-width: @large-tablet-min) {
			padding: 8px 32px;
		}
	}

	&__actions {
		display: flex;
		justify-content: space-between;

		@media (max-width: @tablet-max) {
			padding: 16px;
		}

		@media (min-width: @large-mobile-min) {
			justify-content: flex-end;
		}

		@media (min-width: @large-tablet-min) {
			padding: 16px 32px;
		}
	}

	&__button {
		&:not(:last-child) {
			margin-right: 8px;
		}

		@media (max-width: @large-mobile-min - 1px) {
			width: calc(50% - 4px);
		}

		@media (min-width: @large-mobile-min) {
			min-width: 120px;
		}
	}
}
