@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
	display: grid;
	grid-template-columns: 368px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0 32px;
	align-items: start;

	@media ( max-width: @desktop-min) {
		padding: 16px;
		grid-gap: 0 16px;
	}

	@media (max-width: @large-tablet-min) {
		grid-template-columns: 1fr;
		grid-template-rows: min-content min-content;
		grid-gap: 16px 0;
	}

	@media (max-width: @large-mobile-max) {
		padding: 0;
	}
}