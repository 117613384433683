@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
.template-item {

	&__description {
		.d-semibold-14();
		color: @black4;
	}

	&__wrapper{
		display: flex;
		flex-direction: column;
		@media (max-width: @tablet-max) {
			flex-direction: column-reverse;
		}
	}
	&__category {
		background-color:#FFF1CE;
		width:fit-content;
		padding:4px 8px;
		border-radius: 6px;
		margin-top:10px;

		@media (max-width: @tablet-max) {
			margin-top:8px;
		}
	}

	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top:4px;
	}

	&__date {
		.d-semibold-12();
	}

	&__separator {
		width: 4px;
		height: 4px;
		background-color: @greyLight;
		border-radius: 50%;
		margin:0 8px;
	}

	&__reason {
		.d-semibold-12();
	}
}
