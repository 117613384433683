@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.new-attachment {
	display: flex;
	align-items: center;
	
	font-size: 10px;
	
	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}
	
	&__preview {
		flex: none;
		width: 6em;
	}
	
	&__wrap {
		display: flex;
		flex: 1 1 1%;
		width: calc(100% - 6em);
		padding-left: 1.8em;
		
		@media (max-width: @tablet-min - 1px) {
			flex-direction: column;
			align-items: stretch;
		}
		
		@media (min-width: @tablet-min) {
			justify-content: space-between;
			align-items: center;
		}
	}
	
	&-title-block {
		@media (max-width: @tablet-min - 1px) {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
		}
		
		@media (min-width: @tablet-min) {
			flex: 1 1 30%;
			width: 30%;
		}
	}
	
	&__progress-block {
		@media (max-width: @tablet-min - 1px) {
			margin: 0.6em 0;
		}
		
		@media (min-width: @tablet-min) {
			flex: 1 1 40%;
			width: 40%;
			padding: 0 8% 0 5%;
		}
	}
	
	&__controls-block {
		display: flex;
		align-items: center;
		
		@media (max-width: @tablet-min - 1px) {
			display: flex;
			justify-content: space-between;
		}
		
		@media (min-width: @tablet-min) {
			justify-content: flex-end;
			flex: 1 1 30%;
			width: 30%;
			max-width: 200px;
		}
	}
	
	&__title {
		font-size: 1.4em;
		font-weight: 600;
		line-height: 1.5em;
		color: @black2;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		
		@media (max-width: @tablet-min - 1px) {
			padding-right: 0.5em;
		}
	}
	
	&__file-size {
		font-size: 1.2em;
		line-height: 1.5em;
		letter-spacing: -0.01em;
		color: @gray4;
		white-space: nowrap;
	}
	
	&__progress-title {
		margin-bottom: 0.4em;
		font-size: 1.2em;
		line-height: 1.2em;
		letter-spacing: -0.01em;
		color: @gray4;
		
		&-started {
			color: @blue;
		}
		
		&-success {
			color: @green;
		}
		
		&-error {
			color: @red;
		}
	}
	
	&__progress {
	
	}
	
	&__btn {
		margin: 0.5em 0.2em 0.5em 0;
		
		&-icon {
			font-size: 16px;
		}
		
		&-label {
			margin-left: -0.2em;
			font-size: 1.2em;
		}
		
		&:not(:first-child) {
			margin-left: 1em;
		}
	}
}
