@import (reference) '~@tehzor/ui-components/src/styles/colors';

.wrapper {
	display: flex;
	align-items: center;
	padding: 8px 0;
	gap: 8px;
}

.icon {
	color: @grey30;
}

.count {
	margin: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: @black4;
}
