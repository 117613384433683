@import (reference) "~@tehzor/ui-components/src/styles/colors";

.fix-date-display-value {
	white-space: pre-wrap;

	&__icon {
		vertical-align: text-bottom;

		&_warning {
			color: @statusWaiting;
		}

		&_expired {
			color: @statusExpired;
		}
	}
}