@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.select-option {
	&.switch {
		display: block;
		padding: 0 16px 0 18px;
		color: @black75;
		transition: background-color 0.2s ease, color 0.2s ease;
	}

	.desktop &:not(.switch_disabled):hover {
		background-color: @buttonLightHover;
		color: @black75;
	}

	&:not(.switch_disabled):active {
		background-color: @buttonLightPressed !important;
		color: @black75 !important;
	}

	.switch_checked& {
		background-color: @buttonLightSelected;
		color: @black95;
	}

	.switch_disabled& {
		background-color: @black05;
		color: @black35;
		cursor: default;
	}

	&__wrap {
		min-height: 48px;
		padding: 8px 0;
	}

	&__content {
		margin-left: 18px;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
