@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.checks-page {
	position: relative;
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 10px;
		}
	}
	&__filters-bar{
		@media (max-width: @desktop-min - 1px) {
			margin-bottom: 12px;
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__header {
		margin-bottom: 16px;


		&-wrap {
			flex-wrap: wrap;
			margin-top: 0;
		}

		&-left {
			align-self: flex-start;
		}

		&-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			flex: 1 1;
			margin-bottom: 10px;
		}

		&-btn {
			margin: 14px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-export-icon {
			width: 12px;
			height: auto;
		}

		&-email-icon {
			width: 15px;
			height: auto;
		}

		&-add-report-btn {
			margin: 3px 0;

			&:not(:first-child) {
				margin-left: 28px;
			}
		}

		&-add-report-icon {
			width: 12px;
			height: auto;

			path {
				fill: @white;
				stroke: @white;
			}
		}
	}

	&__tabs-links {
		margin-bottom: 30px;
	}

	&__m-table {
		&-number {
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			left: 40px;
			right: 50px;
			width: initial !important;
			font-weight: 600;
		}

		&-menu {
			position: absolute;
			top: 0;
			right: 0;
			flex-basis: auto !important;
			width: auto !important;
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}

	&__d-table {
		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
		&-description-documents-count {
			margin-top: 4px;
		}
	}
}
