@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

@class: ~'.problem-comment';

@{class} {
	&_root {
		margin-top: 12px;
	}

	&__plate {
		padding-top: 16px;
		padding-bottom: 12px;
		transition: background-color 0.15s ease;

		&_selected {
			background-color: @buttonLight
		}

		@{class}_nested > & {
			@media (max-width: @tablet-max) {
				padding-left: 48px;
			}

			@media (min-width: @large-tablet-min) and (max-width: @large-desktop-min - 1px) {
				padding-left: 56px;
			}

			@media (min-width: @large-desktop-min) {
				padding-left: 72px;
			}
		}
	}

	&__title {
		margin-bottom: 16px;
		.tm-bold-17();
		color: @black100;

		@media (min-width: @large-tablet-min) {
			.d-bold-18();
		}
	}

	&__content {
		&_official {
			background-color: rgba(#A7CFFF, 0.3);
			border-radius: 12px;

			@media (max-width: @tablet-max) {
				padding: 16px;
			}

			@media (min-width: @large-tablet-min) {
				padding: 24px;
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&-left {
			display: flex;
			flex-wrap: wrap;
			flex: 1 1;
			padding-right: 6px;
		}

		&-right {
			flex: none;
		}
	}

	&__author {
		&:not(:last-child) {
			margin-right: 16px;
		}

		@{class}__children & {
			grid-column-gap: 12px;

			&-name {
				.d-semibold-12();
			}

			&-position {
				.d-regular-12();
			}
		}
	}

	&__desc {
		margin-top: 12px;
		color: @black95;
		white-space: pre-wrap;
		word-break: break-word;

		@{class}__children @{class}__content_not-official > & {
			margin-left: 46px;
		}

		&-appeal {
			font-weight: 600;
			color: @deepBlue;
		}
	}

	&__attachments {
		margin: 16px 0;

		@{class}__content_official > & {
			padding: 16px;
			background-color: @white2;
			border-radius: 10px;
		}

		@{class}__children @{class}__content_not-official > & {
			margin-left: 46px;
		}
	}

	&__images {
		display: flex;
		flex-wrap: wrap;
		margin: -8px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__image {
		width: 70px;
		margin: 8px;
	}

	&__files {

	}

	&__file-wrap {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__file {
		max-width: 100%;
	}

	&__footer {
		margin-top: 8px;

		@{class}__children @{class}__content_not-official > & {
			margin-left: 46px;
		}

		&-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 14px;
			padding-top: 14px;
			border-top: 1px solid @greyLight;
		}
	}

	&__date {
		display: inline-block;
		.tm-regular-13();
		color: @black75;
		word-break: break-word;

		@media (min-width: @large-tablet-min) {
			.d-regular-14();
		}

		&:not(:last-of-type) {
			margin-right: 12px;
		}

		&:last-of-type {
			margin-right: 16px;
		}
	}

	&__comment-btn {
		&-label {
			.tm-regular-13();

			@media (min-width: @large-tablet-min) {
				.d-regular-14();
			}
		}
	}

	&__children {

	}
}