@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.indicators-set {
	&__container {
		display: flex;
		flex-direction: column;
		font-weight: 400;
		line-height: 19.6px;
		text-align: left;
		width: 100%;
		gap: 8px;

		&.indicators-dialog {
			width: 100%;
			padding: 16px 0 16px 0;
		}

		&__header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			padding: 4px 0 4px 0;
			gap: 2px;

			&.indicators-dialog {
				padding: 4px 0 4px 0;
			}
		}

		&__name {
			height: 20px;
			color: #172a41;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100%;
		}

		.indicators-set {
			&__stages {
				height: 20px;
				color: #747f8d;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
				font-size: 12px;
				&.indicators-dialog {
					height: unset;
				}
				&.indicators-dialog-mobile {
					height: unset;
				}
			}
			&__indicators {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				gap: 8px;
				width: 100%;

				&.indicators-grid-item {
					flex-direction: row;
				}
				&.indicators-dialog {
					flex-direction: column;
					flex-wrap: unset;
				}
				&.indicators-legend {
					flex-direction: column;
					flex-wrap: unset;
					gap: 2px;
					margin-bottom: 8px;
					.spaces-page {
						&__legend-item {
							&:first-child {
								margin-bottom: 10px !important;
							}
						}
					}
				}
				&.indicators-dialog-mobile {
					flex-direction: row;
					flex-wrap: wrap;
				}
				.indicators__count {
					font-weight: 600;
					text-align: center;
					color: #0e72e3;
				}
			}
		}
	}
}
