@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.instruction {
	margin-bottom: 40px;

	&__element {
		border: 1px solid @black35;
		border-radius: 4px;
		padding: 4px;
		margin: 4px;
		display: flex;
		width: auto;
		justify-content: flex-start;
		align-items: flex-start;

		&-header {
			display: flex;
		}
		&-button-container {
			width: 24px;
			height: 24px;
			display: flex;
			flex-shrink: 0;
			margin-right: 4px;
		}

		&-button {
			align-self: center;
			.icon-button& {
				padding: 2px;
				color: @blue2;
				min-width: 8px;
				min-height: 8px;
			}
		}
		&-icon {
			&_reverse {
				transform: rotate(180deg);
			}
		}
	}
	&__container {
		display: flex;
		flex-direction: column;
	}
	&__title {
		.d-semibold-16();
		color: @black4;
		margin-bottom: 20px;
	}
	&__subtitle {
		.d-semibold-12;
		color: @black4;
		margin-left: 5px;
	}

	&__field {
		color: @black4;
		.d-semibold-14();
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
	}

	&__description {
		.d-regular-14();
		margin-left: 10px;
		color: @black4;
	}

	&__element-icon {
		padding: 0;
		margin: 0;

		&_reverse {
			transform: rotate(180deg);
		}
	}
	&__expandable {
		&_hidden {
			display: none;
		}
	}
}
