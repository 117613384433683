@import (reference) '~@tehzor/ui-components/src/styles/variables';

.adding-space-type-decoration {
	&__container {
		width: 70vw;

		@media (max-width: @desktop-min - 1px) {
			width: 100%;
		}
	}
}