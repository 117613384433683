@import (reference) "~@tehzor/ui-components/src/styles/variables";

.app {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	overflow-y: hidden;

	&__wrap {
		position: relative;
		flex: auto;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	&__footer {
		flex: 0 0 auto;
	}
}
