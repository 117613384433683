@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

@selectPrefixCls: rc-select;
@arrowColor: @blue2;
@clearColor: @gray4;

.@{selectPrefixCls} {
	display: block;
	position: relative;

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	ul, li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	> ul > li > a {
		padding: 0;
		background-color: #fff;
	}

	&-arrow {
		position: absolute;
		top: 35%;
		right: 1.5em;
		outline: none;
		transition: transform 0.3s ease;
		transform: translateY(0em);
		color: @arrowColor;
	}

	&-arrow &-arrow-loading {
	}

	&-open &-arrow {
		transform: rotate(180deg) translateY(.5em);
	}

	&-selection {
		display: block;
		position: relative;
		background-color: transparent;
		border: 1px solid @gray2;
		border-radius: 2px;
		outline: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		transition: background-color 0.2s ease, border-color 0.2s ease;

		.select__element_error & {
			border-color: @red;
		}

		&__rendered {
			min-height: 4.06em;
			padding-left: 0.98em;
			padding-right: 0.98em;
		}

		&__placeholder {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			padding: 0 3em 0 0.7em;
			transform: translateY(-50%);
			font-size: 1.4em;
			letter-spacing: -0.01em;
			color: @gray4;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&__clear {
			position: absolute;
			top: 45%;
			right: 3.7em;
			margin-top: -1em;
			padding: 0.6em;
			outline: none;
			transition: opacity 0.2s ease;
			
			color: @clearColor;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

			.icon {
				width: 1em;
				height: 1em;
			}

			.desktop &:hover {
				opacity: 0.7;
			}
		}
	}

	&-focused &-selection {
		border-color: @blue;
	}

	&-enabled &-selection {
		.desktop &:hover {
			background-color: @gray6;
		}

		&:active {
			background-color: @gray2 !important;
		}
	}

	&-selection--single {
		position: relative;
		cursor: pointer;

		.@{selectPrefixCls}-selection__rendered {
			display: flex;
			position: relative;
			align-items: center;
		}

		.@{selectPrefixCls}-selection-selected-value {
			width: 100%;
			padding: 0 3em 0 0;
			background-color: transparent;
			font-size: 1.4em;
			line-height: 1.5em;
			letter-spacing: -0.01em;
			color: @black2;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			pointer-events: none;

			.@{selectPrefixCls}-allow-clear & {
				padding-right: 4.7em;
			}
		}
	}

	&-selection--multiple {
		height: 100%;
		cursor: pointer;

		.@{selectPrefixCls}-search--inline {
			width: auto;
			margin: 0.57em 0;

			.@{selectPrefixCls}-search__field {
				width: 0.75em;

				&__wrap {
					width: auto;
				}
			}
		}

		.@{selectPrefixCls}-selection__rendered {
			display: flex;
			position: relative;
			align-items: center;
			width: 100%;
			overflow: hidden;

			.@{selectPrefixCls}-allow-clear & {
				padding-right: 3.4em;
			}

			& > ul {
				display: flex;
				width: 100%;
			}

			.@{selectPrefixCls}-selection__choice {
				margin-top: 0.4em;
				margin-bottom: 0.4em;
			}
		}

		.@{selectPrefixCls}-selection__clear {
			top: 1.9em;
			right: 1em;
		}

		.@{selectPrefixCls}-selection__placeholder {
			padding-right: 0.7em;
		}
	}

	&_wrap-tags &-selection--multiple {
		height: 100%;
		cursor: pointer;

		.@{selectPrefixCls}-search--inline {
			float: left;
		}

		.@{selectPrefixCls}-selection__rendered {
			display: flex;
			position: relative;
			align-items: center;
			width: 100%;

			.@{selectPrefixCls}-allow-clear & {
				padding-right: 3.4em;
			}

			& > ul {
				display: block;
			}

			.@{selectPrefixCls}-selection__choice {
				float: left;
				margin-top: 0.4em;
				margin-bottom: 0.4em;
			}
		}

		.@{selectPrefixCls}-selection__clear {
			top: 1.9em;
			right: 1em;
		}

		.@{selectPrefixCls}-selection__placeholder {
			padding-right: 0.7em;
		}
	}

	& &-selection__choice {
		position: relative;
		max-width: calc(100% - 1.85em);
		margin-right: 0.8em;
		padding: 0.4em 2.6em 0.4em 0.8em;
		background-color: @gray1;
		border-radius: 2px;
		overflow: hidden;

		&__disabled {
			flex: none;
			padding-right: 0.8em;
		}

		&__content {
			max-width: 100%;
			font-size: 1.4em;
			line-height: 1.5em;
			letter-spacing: -0.01em;
			color: @black2;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&__remove {
			position: absolute;
			bottom: -.5em;
			right: 0;
			margin-top: -1.2em;
			padding: 0.8em;
			outline: none;
			cursor: pointer;
			transition: opacity 0.2s ease;
			color: @clearColor;

			.icon {
				width: 1em;
				height: 1em;
			}

			.desktop &:hover {
				opacity: 0.7;
			}
		}
	}

	&-search__field__wrap {
		display: inline-block;
	}

	//&-search__field__placeholder {
	//	position: absolute;
	//	top: 0;
	//	left: 3px;
	//	color: #aaa;
	//}

	&-search {
		font-size: 1.4em;
		line-height: 1.5em;
		letter-spacing: -0.01em;
		color: @black2;
	}

	&-search--inline {
		//display: none;
		width: 100%;

		.@{selectPrefixCls}-search__field__wrap {
			width: 100%;
		}

		.@{selectPrefixCls}-search__field {
			width: 100%;
			background: transparent;
			border: none;
			font-size: 1em;
			outline: 0;

			&::-ms-clear {
				display: none;
			}
		}

		.@{selectPrefixCls}-search__field__mirror {
			position: absolute;
			top: -999px;
			left: 0;
			white-space: pre;
		}
	}

	&-enabled&-selection--multiple {
		cursor: pointer;
	}

	&-enabled {

	}

	&-disabled {
		cursor: default;

		.@{selectPrefixCls}-selection {
			background-color: @gray2;
			border-color: @white;
		}

		.@{selectPrefixCls}-selection--single,
		.@{selectPrefixCls}-selection--multiple,
		.@{selectPrefixCls}-selection__choice__remove {
			cursor: default !important;
		}

		.@{selectPrefixCls}-selection__choice {
			background-color: @gray6 !important;
		}
	}

	&-dropdown {
		position: absolute;
		left: -9999px;
		top: -9999px;
		z-index: 1500;
		background-color: @white;
		border: 1px solid @gray2;
		outline: none;

		font-size: 10px;

		@media (min-width: @large-tablet-min) {
			font-size: 11px;
		}

		&:empty,
		&-hidden {
			display: none;
		}

		&-placement-bottomLeft {
			border-top: none;
			border-bottom-right-radius: 2px;
			border-bottom-left-radius: 2px;
		}

		&-placement-topLeft {
			border-bottom: none;
			border-top-right-radius: 2px;
			border-top-left-radius: 2px;
		}

		&-menu {
			outline: none;
			margin: 0;
			padding: 0;
			list-style: none;
			z-index: 9999;

			> li {
				margin: 0;
				padding: 0;
			}

			//&-item-group-list {
			//	margin: 0;
			//	padding: 0;
			//
			//	> li.@{selectPrefixCls}-menu-item {
			//		padding-left: 20px;
			//	}
			//}
			//
			//&-item-group-title {
			//	color: #999;
			//	line-height: 1.5;
			//	padding: 8px 10px;
			//	border-bottom: 1px solid #dedede;
			//}

			li&-item {
				display: block;
				position: relative;
				width: 100%;
				margin: 0;
				padding: 0.7em;
				font-size: 1.4em;
				line-height: 1.5em;
				letter-spacing: -0.01em;
				color: @black2;
				cursor: pointer;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				transition: background-color 0.2s ease;

				.@{selectPrefixCls}-dropdown--multiple & {
					padding-left: 2.3em;
				}

				&-disabled {
					color: @gray3;
					cursor: default;
				}

				&-selected {
					background-color: @gray1;
				}

				.desktop &:hover {
					background-color: @gray6;
				}

				&:active {
					background-color: @gray2 !important;
				}

				//&-divider {
				//	height: 1px;
				//	margin: 1px 0;
				//	overflow: hidden;
				//	background-color: #e5e5e5;
				//	line-height: 0;
				//}
			}

			&-item-checkbox {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				left: 0.7em;
				top: 50%;
				width: 14px;
				height: 14px;
				margin-top: -7px;
				border: 1px solid @gray3;

				&::after {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					background-color: @blue;
					opacity: 0;
					transition: opacity 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);
				}

				&__selected::after {
					opacity: 1;
				}
			}
		}

		&-slide-up-enter,
		&-slide-up-appear {
			opacity: 0;
			transform-origin: 0 0;
			animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
			animation-play-state: paused;
			animation-duration: 0.3s;
			animation-fill-mode: both;
		}

		&-slide-up-leave {
			opacity: 1;
			transform-origin: 0 0;
			animation-timing-function: cubic-bezier(0.69, 0.17, 0.56, 0.77);
			animation-play-state: paused;
			animation-duration: 0.3s;
			animation-fill-mode: both;
		}

		&-slide-up-enter&-slide-up-enter-active&-placement-bottomLeft,
		&-slide-up-appear&-slide-up-appear-active&-placement-bottomLeft {
			animation-name: rcSelectDropdownSlideUpIn;
			animation-play-state: running;
		}

		&-slide-up-leave&-slide-up-leave-active&-placement-bottomLeft {
			animation-name: rcSelectDropdownSlideUpOut;
			animation-play-state: running;
		}

		&-slide-up-enter&-slide-up-enter-active&-placement-topLeft,
		&-slide-up-appear&-slide-up-appear-active&-placement-topLeft {
			animation-name: rcSelectDropdownSlideDownIn;
			animation-play-state: running;
		}

		&-slide-up-leave&-slide-up-leave-active&-placement-topLeft {
			animation-name: rcSelectDropdownSlideDownOut;
			animation-play-state: running;
		}
	}
}

@keyframes rcSelectDropdownSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0 0;
		transform: scaleY(1);
	}
}

@keyframes rcSelectDropdownSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0 0;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0 0;
		transform: scaleY(0);
	}
}

@keyframes rcSelectDropdownSlideDownIn {
	0% {
		opacity: 0;
		transform-origin: 0 100%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0 100%;
		transform: scaleY(1);
	}
}

@keyframes rcSelectDropdownSlideDownOut {
	0% {
		opacity: 1;
		transform-origin: 0 100%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0 100%;
		transform: scaleY(0);
	}
}
