@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.select-popup {
	min-width: 280px;
	width: fit-content;
	max-width: 300px;
	overflow: hidden;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 8px;
		padding: 8px 16px;

		&-actions {
			padding: 7px 0px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		&-tabs {
			padding-bottom: 8px;
			.tabs__fake-item::after {
				height: 3px;
			}
		}
	}

	&__scrollable {
		max-height: 400px;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	&__footer {
		padding: 0 16px;
	}

	&__info {
		height: 40px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid @black10;
	}

	&__count {
		font-size: 12px;
		line-height: 18px;
	}

	&__actions {
		justify-content: flex-end;
		height: 72px;
		width: 100%;
	}

	&__clear div {
		font-size: 12px;
		line-height: 18px;
		color: @deepBlue;
	}
	&__no-option-select {
		border-bottom: 1px solid @gray8;
	}
}
