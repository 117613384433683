@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.work-acceptance-page-check-list-items {
	&__accepted .tz-check-mark {
		font-size: 28px;
		color: @statusFixed;
	}

	&__accepted &__item-header-bullet-circle {
		background-color: @statusAccepted;
	}

	&__accepted &__item-header {
		grid-template-columns: 44px auto 1fr;
	}

	&__accepted &__item-header-actions {
		place-self: center auto;
	}

	&__next {
		position: relative;

		&_disabled {
			position: absolute;
			background-color: rgba(255, 255, 255, 0.5);
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
		}
	}

	&__item {
		display: grid;
		grid-row-gap: 10px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'header'
			'indicators'
			'footer'
			'body';

		&-indicators {
			grid-area: indicators;
			padding-left: 60px;
		}

		@media (min-width: @desktop-min) {
			&:hover &-header-actions > div {
				visibility: visible;
			}

			&_active &-header-actions > div {
				visibility: visible;
			}
		}

		&_disabled &-actions {
			visibility: hidden;
		}

		&_clickable {
			padding: 24px;
			cursor: pointer;
		}

		&_active {
			background-color: @buttonFilterActive;
			cursor: auto;
		}

		&_clickable:not(&_active):hover {
			background-color: @buttonLightSelected;
		}

		&_clickable:not(&_active):active {
			background-color: @buttonPressed;
		}

		&-header {
			display: grid;
			grid-area: header;
			grid-column-gap: 16px;
			grid-template-columns: 44px 1fr auto;
			grid-template-rows: minmax(22px, auto) 22px;
			grid-template-areas:
				'bullet title actions'
				'bullet subtitle actions';

			&-bullet {
				grid-area: bullet;

				&-circle {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					height: 44px;
					width: 44px;

					&-content {
						.d-semibold-16();
						color: @black100;
						line-height: 21px;
					}
				}
			}

			&-title {
				grid-area: title;
				.d-semibold-14();

				&_clickable {
					.d-semibold-16();
					color: @black100;
					text-decoration: underline;
					text-decoration-color: @black100;
					text-underline-offset: 5px;
					text-decoration-thickness: 1px;
					width: fit-content;

					&:hover {
						text-decoration-color: @blue2;
					}
				}
			}

			&-subtitle {
				grid-area: subtitle;
				.d-regular-12();

				&_accepted {
					display: flex;
					width: fit-content;
				}

				&-name {
					color: @black60;
				}
			}
			&-actions-container {
				background-color: @white2;
				border-radius: 8px;
				margin-left: 8px;
			}

			&-actions {
				display: flex;
				grid-area: actions;
				justify-content: end;

				@media (min-width: @desktop-min) {
					width: 80px;
					height: 44px;
				}
				&-menu-button {
					&:hover {
						background-color: @buttonLightHover2 !important;
					}
					&_small {
						min-width: 32px;
						&:hover {
							.work-acceptance-page-check-list-items__item-header-actions-expand-menu-icon {
								margin-top: 8px;
								transition: margin-top 0.15s ease-out;
							}
						}
					}
				}
				&-expand-menu-icon {
					font-size: 8px !important;
					transition: margin-top 0.15s ease-out;
				}
			}
		}

		&-body {
			grid-area: body;
			margin: 10px 0;
			.d-regular-12();

			&-link .link-button__label {
				.d-regular-12();
			}

			&-link_active i {
				transform: rotate(180deg);
			}
		}

		&-footer {
			grid-area: footer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			padding-left: 60px;
		}

		&-state {
			display: grid;
			grid-column-gap: 8px;
			grid-template-areas: 'status percents';
			margin-right: 8px;

			&-tag {
				border: 1px solid @black10;
			}

			&-status {
				grid-area: status;
				color: @black95;
			}

			&-percents {
				grid-area: percents;

				&-input {
					& input {
						height: 32px;
					}
					width: 60px;
				}
			}
		}

		&-actions {
			padding: 0 6px;
			display: flex;
			flex-wrap: nowrap;

			&-btn {
				height: 26px;
				width: 26px;
				min-height: 26px !important;
				min-width: 26px !important;
				border: 2px solid @grey!important;
				background-color: transparent !important;
				color: @grey;
				display: flex;
				align-items: center;

				@media (max-width: @tablet-min) {
					height: 40px;
					width: 40px;
				}

				&:active {
					background-color: transparent !important;
				}

				&-wrapper {
					padding: 6px;
					border-radius: 8px;

					&:hover {
						background-color: @buttonFilterActiveHover;
					}

					&:active {
						background-color: @buttonFilterActivePressed;
					}

					&_disabled {
						pointer-events: none;
					}
				}
			}
		}
	}

	&__popup {
		width: 488px;
		height: fit-content;

		@media (max-width: @tablet-min) {
			max-width: 488px;
			width: 90%;
		}

		&-close {
			position: absolute;
			top: 0;
			right: 3px;
			z-index: 100;
		}
	}

	&__description {
		padding: 16px 40px 16px 20px;
		overflow-y: auto;

		@media (max-width: @desktop-min - 1) {
			padding: 0 30px 0 0;
		}
	}
	&__description-scrollbar {
		width: 100%;
		height: 264px;
	}
}
