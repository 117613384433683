// @import (reference) '../../../styles/colors';
// @import (reference) '../../../styles/variables';
// @import (reference) '../../../styles/typography';

// .problems-space {
// 	position: relative;

// 	&_rest > &__status,
// 	&_systems > &__status,
// 	&_technical-underground > &__status,
// 	&_technical-spaces > &__status,
// 	&_common-areas > &__status {
// 		.desktop &:hover {
// 			transform: scale3d(1.05, 1.05, 1);
// 		}
// 	}

// 	&_disabled {
// 		background: @white;
// 		opacity: 0.2;
// 	}

// 	&_disabled &__status {
// 		cursor: none;
// 		user-select: none;
// 		pointer-events: none;

// 		.desktop &:hover {
// 			transform: none;
// 			box-shadow: none;
// 		}
// 	}

// 	@media (max-width: @tablet-min) {
// 		&_rest > &__status,
// 		&_systems > &__status,
// 		&_technical-underground > &__status,
// 		&_technical-spaces > &__status,
// 		&_common-areas > &__status {
// 			max-width: 100%;
// 		}
// 	}

// 	&__status {
// 		position: relative;
// 		border-radius: 9px;
// 		cursor: pointer;
// 		user-select: none;
// 		transition: transform 0.2s ease;
// 		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// 		overflow: visible;

// 		&-shadow {
// 			transition: filter 0.2s ease;
// 			fill: @white;
// 		}

// 		.desktop &:hover {
// 			transform: scale3d(1.2, 1.2, 1);
// 		}

// 		.desktop &:hover > &-shadow {
// 			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
// 		}

// 		&:active > &-shadow {
// 			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
// 		}
// 	}

// 	&__number {
// 		.semi-bold();
// 		font-size: 12px;
// 		line-height: 21px;
// 		fill: @black4;
// 	}

// 	&__counter-icon {
// 		fill: @repeatCounter;
// 	}

// 	&__counter-text {
// 		.semi-bold();
// 		font-size: 10px;
// 		fill: @repeatCounter;
// 	}
// }

// .space_indicators {
// 	&__popup {
// 		&--root-class {
// 			background-color: #fff;
// 			padding: 20px;
// 			box-shadow: 0px 8px 12px 0px #1f344f1a, 0px 0px 2px 0px #0000001a;
// 		}
// 	}
// }

// .space-indicator-tag {
// 	&__icon {
// 		width: 6px;
// 		height: 6px;
// 		border-radius: 50%;
// 		margin-right: 2px;
// 	}
// }
// &__choose-checkbox {
// 	width: 26px;
// 	height: 26px;
// 	position: absolute;
// 	z-index: 10;
// 	background: @white;
// 	border-radius: 6px;
// 	top: 0px;
// 	left: 0px;
// }

// &__choose-checkbox-input {
// 	width: 22px;
// 	height: 22px;
// 	padding: 0;
// 	margin: 0;
// 	border-radius: 6px;
// 	cursor: pointer;
// }

// &__space-checkbox {
// 	position: absolute;
// 	display: flex;
// 	z-index: 10;
// 	left: 0px;
// 	background: @white;
// 	width: 26px;
// 	height: 26px;
// 	border-radius: 6px;
// 	flex-direction: row;
// 	align-content: center;
// 	justify-content: center;
// 	align-items: center;
// }
// &_apartments--checked {
// 	background-color: @buttonFilterActive;
// }

// &_commercial-spaces--checked {
// 	background-color: @buttonFilterActive;
// }

// &_common-areas--checked {
// 	background-color: @buttonFilterActive;
// }

// &_underground-parking--checked {
// 	background-color: @buttonFilterActive;
// }

// &_ground-parking--checked {
// 	background-color: @buttonFilterActive;
// }

// &_storerooms--checked {
// 	background-color: @buttonFilterActive;
// }

// &_technical-spaces--checked {
// 	background-color: @buttonFilterActive;
// }

// &_technical-underground--checked {
// 	background-color: @buttonFilterActive;
// }

// &_systems--checked {
// 	background-color: @buttonFilterActive;
// }

// &_rest--checked {
// 	background-color: @buttonFilterActive;
// }

// &_flats--checked {
// 	background-color: @buttonFilterActive;
// }

// &_bkt--checked {
// 	background-color: @buttonFilterActive;
// }

// &_pon--checked {
// 	background-color: @buttonFilterActive;
// }

// &_improvement--checked {
// 	background-color: @buttonFilterActive;
// }

// &_linear-objects--checked {
// 	background-color: @buttonFilterActive;
// }

// &_cabinets--checked {
// 	background-color: @buttonFilterActive;
// }

// &_auxiliary-spaces--checked {
// 	background-color: @buttonFilterActive;
// }

// &_offices--checked {
// 	background-color: @buttonFilterActive;
// }

// &_land-lots--checked {
// 	background-color: @buttonFilterActive;
// }

// &_houses--checked {
// 	background-color: @buttonFilterActive;
// }

@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.problems-space {
	position: relative;

	&_rest > &__status,
	&_systems > &__status,
	&_technical-underground > &__status,
	&_technical-spaces > &__status,
	&_common-areas > &__status {
		.desktop &:hover {
			transform: scale3d(1.05, 1.05, 1);
		}
	}

	&_disabled {
		background: @white;
		opacity: 0.2;
	}

	&_disabled &__status {
		cursor: none;
		user-select: none;
		pointer-events: none;

		.desktop &:hover {
			transform: none;
			box-shadow: none;
		}
	}
	&__popup {
		&--root-class {
			background-color: #fff;
			padding: 20px;
			box-shadow: 0px 8px 12px 0px #1f344f1a, 0px 0px 2px 0px #0000001a;
		}
	}

	@media (max-width: @tablet-min) {
		&_rest > &__status,
		&_systems > &__status,
		&_technical-underground > &__status,
		&_technical-spaces > &__status,
		&_common-areas > &__status {
			max-width: 100%;
		}
	}

	&__status {
		position: relative;
		border-radius: 9px;
		cursor: pointer;
		user-select: none;
		transition: transform 0.2s ease;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		overflow: visible;

		&-shadow {
			transition: filter 0.2s ease;
			fill: @white;
		}

		.desktop &:hover {
			transform: scale3d(1.2, 1.2, 1);
		}

		.desktop &:hover > &-shadow {
			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
		}

		&:active > &-shadow {
			filter: drop-shadow(0px 5px 10px rgba(93, 116, 129, 0.3));
		}
	}

	&__number {
		.semi-bold();
		font-size: 12px;
		line-height: 21px;
		fill: @black4;
	}

	&__counter-icon {
		fill: @repeatCounter;
	}

	&__counter-text {
		.semi-bold();
		font-size: 10px;
		fill: @repeatCounter;
	}

	&__choose-checkbox {
		width: 26px;
		height: 26px;
		position: absolute;
		z-index: 10;
		background: @white;
		border-radius: 6px;
		top: 0px;
		left: 0px;
	}

	&__choose-checkbox-input {
		width: 22px;
		height: 22px;
		padding: 0;
		margin: 0;
		border-radius: 6px;
		cursor: pointer;
	}

	&__space-checkbox {
		position: absolute;
		display: flex;
		z-index: 10;
		left: 0px;
		background: @white;
		width: 26px;
		height: 26px;
		border-radius: 6px;
		flex-direction: row;
		align-content: center;
		justify-content: center;
		align-items: center;
	}
	&_apartments--checked {
		background-color: @buttonFilterActive;
	}

	&_commercial-spaces--checked {
		background-color: @buttonFilterActive;
	}

	&_common-areas--checked {
		background-color: @buttonFilterActive;
	}

	&_underground-parking--checked {
		background-color: @buttonFilterActive;
	}

	&_ground-parking--checked {
		background-color: @buttonFilterActive;
	}

	&_storerooms--checked {
		background-color: @buttonFilterActive;
	}

	&_technical-spaces--checked {
		background-color: @buttonFilterActive;
	}

	&_technical-underground--checked {
		background-color: @buttonFilterActive;
	}

	&_systems--checked {
		background-color: @buttonFilterActive;
	}

	&_rest--checked {
		background-color: @buttonFilterActive;
	}

	&_flats--checked {
		background-color: @buttonFilterActive;
	}

	&_bkt--checked {
		background-color: @buttonFilterActive;
	}

	&_pon--checked {
		background-color: @buttonFilterActive;
	}

	&_improvement--checked {
		background-color: @buttonFilterActive;
	}

	&_linear-objects--checked {
		background-color: @buttonFilterActive;
	}

	&_cabinets--checked {
		background-color: @buttonFilterActive;
	}

	&_auxiliary-spaces--checked {
		background-color: @buttonFilterActive;
	}

	&_offices--checked {
		background-color: @buttonFilterActive;
	}

	&_land-lots--checked {
		background-color: @buttonFilterActive;
	}

	&_houses--checked {
		background-color: @buttonFilterActive;
	}
}
