@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';
@import (reference) '../../styles/typography';

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: calc(100dvh - 32px);
	outline: none;

	@media (max-width: (@tablet-min - 1px)) {
		width: calc(100vw- 32px);
	}

	@media (min-width: @tablet-min) {
		left: 50%;
		max-width: calc(100vw - 32px);
	}
}

.modalFs,
.modalFsMobile,
.modalFsTablet {
	width: 100vw;
	height: 100vh;
	max-width: 100vw;
	max-height: 100vh;
	top: 0;
	left: 0;
	transform: translate(0, 0);
}

.modalLayer {
	height: 100%;
	background-color: @white;
	border-radius: 14px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(31, 52, 79, 0.1);
	overflow: hidden;

	@media (min-width: @desktop-min) {
		border-radius: 24px;
	}

	&.modalFs,
	&.modalFsMobile,
	&.modalFsTablet {
		border-radius: 0;
	}
}

.modalContent {
	display: flex;
	flex-direction: column;
	max-height: calc(100dvh - 32px);
	max-width: 100%;

	&.modalFs,
	&.modalFsMobile,
	&.modalFsTablet {
		max-height: 100%;
	}
}

.modalOverlayTransparent {
	background-color: transparent;
}

.closeButton {
	position: absolute;
	z-index: 1;

	@media (max-width: (@desktop-min - 1px)) {
		top: 8px;
		right: 8px;
	}

	@media (min-width: @desktop-min) {
		top: 16px;
		right: 16px;
	}
}
