@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: flex;
	gap: 0 12px;
}

.number {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	color: @black60;
}

.name {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	color: @black100;
}