@import (reference) "../../../styles/variables";

.pagination-and-size {
	margin-top: 32px;

	@media (max-width: @tablet-min - 1px) {
		display: flex;
		flex-direction: column;
	}

	@media (min-width: @tablet-min) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 16px;
		align-items: center;
	}


	&__pagination {
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 16px;
		}
	}

	&__page-size {

	}
}