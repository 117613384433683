@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-owner-acceptance {
	@media (min-width: @large-tablet-min) {
		display: grid;
		grid-template-columns: calc(66% - 30px) calc(34% - 30px);
		grid-column-gap: 60px;
	}

	&__info-grid {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 25px;
		position: relative;

		@media (min-width: @tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
		}
	}

	&__attachments {
		margin: 32px 0 0;

		&-scroll-area {
			margin-top: 0;
			padding: 0 16px 0 0;

			@media (min-width: @large-tablet-min) and (min-height: 800px) {
				max-height: 389px;
			}
		}

		&-files {

		}

		&-file {

		}
	}
}