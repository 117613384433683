@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.adding-entity-dialog {
	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	@media (max-width: @tablet-min) {
		&__body {
			padding-top: 0px;
		}
	}

	&__header {
		padding-bottom: 8px;
	}
}