@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.responsibles-filter-page {
	&__item {
		&-content {
			.d-semibold-14();
			color: @black75;
		}
		&-additional-content, &-leader {
			.d-regular-12();
		}
		&-additional-content {
			color: @black60;
		}
		&-leader {
			color: @deepBlue;
		}
	}
}