@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.w-claims-page {
	&__head-wrap {
		@media (max-width: @large-mobile-max) {
			margin-bottom: 10px;
			padding: 0 16px;
		}

		@media (max-width: @large-tablet-max) {
			display: flex;
			flex-direction: column;
		}
	}

	&__filters-bar {
		display: flex;
		flex-direction: column;

		@media (max-width: @desktop-min - 1px) {
			margin-bottom: 12px;
		}

		@media (max-width: @large-tablet-max) {
			flex-direction: row;
			justify-content: space-between;
			order: 2;

			height: 40px;
			margin-left: auto;
		}
	}

	&__counter-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (min-width: @desktop-min) {
			padding-bottom: 24px;
		}
	}

	&__entities-filters {
		margin-bottom: 0;
	}

	&__breadcrumbs {
		padding-bottom: 0;

		.breadcrumb {
			padding: 0;
		}

		@media (max-width: @large-tablet-max) {
			width: 100%;
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__header {
		margin-bottom: 18px;

		.page-header__sub-title {
			.d-regular-14();
		}

		&-wrap {
			flex-wrap: wrap;
		}

		&-left {
			align-self: flex-start;
			margin-bottom: 10px;
		}

		&-right {
			display: flex;
			flex: 1 1;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;

			margin-bottom: 10px;
		}

		@media (max-width: @large-tablet-max) {
			margin: 0;
		}

		@media (min-width: @desktop-min) {
			margin-bottom: 0;
		}
	}

	&__m-table {
		&-number {
			position: absolute;
			top: 0;
			right: 50px;
			left: 40px;

			display: flex;
			align-items: center;

			width: initial !important;
			height: 28.6px;

			font-weight: 600;
		}

		&-menu {
			position: absolute;
			top: 0;
			right: 0;

			flex-basis: auto !important;

			width: auto !important;
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}

	&__d-table {
		& .table2 {
			min-width: 1160px !important;
		}

		&-menu {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
		}
	}

	& .plate {
		overflow: initial;
	}

	&__search {
		margin-right: 12px;
	}

	@media (max-width: @large-mobile-max) {
		padding: 0;
	}

	@media (min-width: @desktop-min) {
		&__scrollbar-container {
			position: relative;
		}

		&__scrollbar-horizontal {
			position: sticky;
			z-index: 10;
			bottom: 0;
			overflow-x: auto;

			&--hidden {
				display: none;
			}
		}

		&__dummy-scrollbar {
			height: 1px;
		}
	}
}
