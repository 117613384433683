@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";


.history-status-change {
	&__status-container {
		color: @white;
		.d-semibold-14();
		padding: 5px 12px;
		border-radius: 6px;
		width: fit-content;
	}
}

