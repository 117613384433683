@import (reference) "~@tehzor/ui-components/src/styles/colors";

.highest {
	color: @red80;
}

.high {
	color: @red60;
}

.medium {
	color: @orange70;
}

.low {
	color: @blue60;

	&::before {
		display: inline-block;
		transform: rotate(180deg);
		width: 16px;
	}
}

.lowest {
	color: @blue80;

	&::before {
		display: inline-block;
		transform: rotate(180deg);
	}
}

.priority {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	width: fit-content;
	padding: 6px;
	border-radius: 6px;

	&-text {
		margin-left: 5px;
		color: @black100;
		font-weight: 400;
	}
}