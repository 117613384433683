@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.m-header-menu {
	&__item {
		margin: 0 8px;

		&:first-child {
			margin-left: 12px;
		}

		&:last-child {
			margin-right: 12px;
		}
	}
}
