@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.dEntities {
	color: @black4;

	.number {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.expandBtn {
		margin-right: 12px;

		.icon {
			transition: transform 0.2s ease;

			&_expanded {
				transform: rotate(-180deg);
			}
		}
	}

	.typeIcon {
		margin-right: 14px;
		color: @greyLight;
	}

	.numberValue {
		.d-semibold-16();
		color: @black95;
	}

	.status {
		max-width: 100%;
		margin-top: 4px;
	}

	.fixDate {
		margin-top: 10px;
		font-weight: 600;
	}

	.category {
		margin-top: 6px;
	}

	.respUser {
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}

	.link {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.dTablePerformers {
	.user {
		display: flex;
		flex-direction: column;

		.name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: @black85;
		}

		.company {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: @black75;
			.d-regular-12();
		}
	}

	.rest {
		.d-semibold-14();
		color: @deepBlue;
	}

	.group {
		.d-semibold-14();
		color: @black4;
	}
}

.dateTag {
	margin-top: 8px;
}

.plannedFixDate {
	width: auto;
	margin-bottom: 4px;
	padding-right: 8px;

	.d-regular-14();

	&-display {
		width: max-content;
	}
}