@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";
@import url('react-toastify/dist/ReactToastify.css');

:root {
	--toastify-color-light: @buttonFilterActive;
	--toastify-color-info: @buttonFilterActive;
	--toastify-color-success: @buttonFilterActive;
	--toastify-color-error: @red10;
	--toastify-color-warning: @orange10;
}

.Toastify__toast--info {
	background-color: @buttonFilterActive;

	.Toastify__toast-icon {
		color: @grayBlue4;
	}
}

.Toastify__toast--success {
	background-color: @buttonFilterActive;

	.Toastify__toast-icon {
		color: @grayBlue4;
	}
}

.Toastify__toast--error {
	background-color: @red10;

	.Toastify__toast-icon {
		color: @red50;
	}
}

.Toastify__toast--warning {
	background-color: @orange10;

	.Toastify__toast-icon {
		color: @orange60;
	}
}

.Toastify__progress-bar--wrp {
	height: 100%;
	border-radius: 12px;
	overflow: hidden;
	z-index: 1;
}

.Toastify__progress-bar--bg {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 5px;
}

.Toastify__progress-bar {
	height: 5px;
}

.Toastify__progress-bar--default {
	background: @blue2;
}

.Toastify__progress-bar--info {
	background: @blue2;
}

.Toastify__progress-bar--success {
	background: @blue2;
}

.Toastify__progress-bar--error {
	background: @red40;
}

.Toastify__progress-bar--warning {
	background: @orange50;
}

.Toastify__toast-icon {
	margin: 0;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.toasts-main-container {
	width: 448px;

	@media (max-width: @large-mobile-min) {
		padding: 16px 0 0 0;
		top: 72px;
		width: 90%;
		left: 50%;
		transform: translateX(-50%);
	}
}

.toastify-base-toast {
	padding: 20px;
	position: relative;

	&_wrapper {
		gap: 0 16px;
		display: none;
	}
}


