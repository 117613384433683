@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";
@import (reference) "../../styles/common";

.plan-viewer {
	&__frame {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: scroll;
		height: 65vh;
		@media(min-width: @large-tablet-min){
			height: 80vh;
		}
	}

	&__wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: @gray2;
		overflow: hidden;
		will-change: width, height;
	}

	&__svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: content-box;
	}

	&__sector {
		fill: @blue;
		opacity: 0.25;
		stroke: @white;
		stroke-width: 2px;
		transition: fill 0.2s ease, opacity 0.2s ease;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&_selected {
			opacity: 0.9;
		}

		&_semi-selected {
			fill: rgba(@blue, 0.75);
		}

		&_hoverable {
			cursor: pointer;

			.desktop &:hover {
				opacity: 0.85;
			}
		}

		&_commented {
			fill: rgba(@red, 0.9);
		}

		&_entity {
			fill: @green90;
			opacity: 0.5;
		}
	}

	&__axis {
		stroke: #000;
		stroke-width: 2;
		stroke-dasharray: 120, 8, 7, 8;
	}

	&__point {
		filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.5));
		transition: fill 0.2s ease, opacity 0.2s ease;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&_problem {
			stroke: @grey90;
			opacity: 0.85;

		}

		&_structure {	
			stroke: @grey90;
			opacity: 0.6;
		}

		&_selected {
			fill: rgba(@blue, 0.9);
		}

		&_entity {
			fill: @green90;
			opacity: 0.5;
		}

		&_selected-point {
			stroke: @blue;
			opacity: 1;
		}

		&_description-marker {
			fill: @red70;
			stroke: @white;
		}

		&_hoverable {
			cursor: pointer;

			.desktop &:hover {
				opacity: 0.85;
			}
		}

		&_disabled {
			opacity: 0.6;
		}

		&_commented {
			stroke: @red;
			stroke-width: 10px;
		}
	}
}