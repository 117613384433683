.offline-journal-filters {
	display: flex;
	align-items: center;

	.entities-filters__item {
		margin: 0;
		margin-right: 16px;
	}
	.entities-filters__item:last-child {
		margin-right: 0;
	}
}