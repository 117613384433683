@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.m-header {
	// width: 100%; из за этого мобильный хедер был шире экрана на планшентных разрешениях
	background-color: @white;
	box-shadow: 0 2px 15px rgba(112, 126, 153, 0.12);
	will-change: transform;

	&__main-row {
		display: grid;
		grid-template-columns: auto 1fr auto;
		align-items: center;
		width: 100%;
		height: @mobile-header-height;
		padding: 0 8px;
	}
	&__title-section{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
	}
	&__title-wrap {
		margin: 0 8px;
		overflow: hidden;
	}

	&__title {
		.tm-bold-20();
		color: @black95;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&_small {
			.tm-semibold-15();
		}

		&-buttons{
			width: fit-content;
		}
	}

	&__sub-title {
		.tm-regular-13();
		color: @black60;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__right {
		& > .button-base:not(:last-child) {
			@media (max-width: @tablet-min - 1px) {
				margin-right: 8px;
			}

			@media (min-width: @tablet-min) {
				margin-right: 12px;
			}
		}
	}

	&__sections-menu {
		width: 100%;
	}
}