@import (reference) "./colors";
@import (reference) "./variables";
@import "./typography";

html {
	position: relative;
	height: 100%;
	min-height: 100%;
	overflow: hidden;
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 300px;
	margin: 0;
	background-color: @appBackground;
	font-family: 'Open Sans', sans-serif;
	.d-regular-14();
}

a {
	color: @deepBlue;
	text-decoration: none;
	transition: color 0.2s ease;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	.desktop &:hover {
		color: @buttonAccentHover;
	}

	&:active {
		color: @buttonAccentPressed !important;
	}
}

a:visited {
}

::-moz-selection {
	background: @blue2;
	color: @white
}

::selection {
	background: @blue2;
	color: @white
}

:focus {
	outline: none
}

* {
	box-sizing: border-box
}

*::before, *::after {
	box-sizing: border-box
}

svg {
	display: block;
}

.clearfix {
	&:before, &:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: inset 0 0 0 50px rgb(250, 250, 250) !important;
	-webkit-text-fill-color: black !important;
}

.semi-bold {
	font-weight: 600;
}

i.rotate-90 {
	transform: rotate(90deg);
}

i.rotate-180 {
	transform: rotate(180deg);
}

i.rotate-270 {
	transform: rotate(270deg);
}