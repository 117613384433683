@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-problem-info-cell {
	&__problem {
		padding-right: 8px;

		&_with-padding {
			padding-left: 56px;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			&-check-box {
				margin-left: 8px;
			}
		}

		&-desc {
			flex: 1 1;
			margin-bottom: 10px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		&-image {
			width: 48px;
			margin-left: 16px;
		}

		&-fix-date {
			font-weight: 600;
		}

		&-status-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 16px;
			padding-right: 8px;

			.problem-status-cell {
				flex-direction: row;
				gap: 15px;
			}
		}
	}

	&__extra-info {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		&-icon {
			margin-right: 8px;
			color: @greyLight;
		}

		&-value {
			max-width: 100%;
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}

	&__reply {
		margin: 20px 0 0 72px;
	}
}