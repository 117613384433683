@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-space-type-decoration {
	& > div:not(:last-child) {
		margin-bottom: 25px;
	}

	& > div:last-child {
		margin-bottom: 34px;
	}
}