@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.problem-tags-count {
	&__container {
		display: flex;
		align-items: center;
		padding: 8px 0px;

		&-count {
			margin-left: 12px;
		}
	}
	&__container:first-of-type {
		margin-left: 0;
	}
}
