@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/shadows";

.app-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;

	&_desktop {
		top: @desktop-header-height;
	}

	&.error {
		background-color: @white;
		box-shadow: @shadow1;
		z-index: 1100;

		&.isDesktop {
			width: @dockedSidebarMin;

			&.active {
				width: @dockedSidebarMax;
			}
		}

		&.isTablet {
			width: @dockedSidebarMinTablet;

			&.active {
				width: @dockedSidebarMaxTablet;
			}
		}

		&.isMobile {
			width: 60px;

			&.active {
				position: fixed;
				width: @dockedSidebarMobile;
				z-index: 5300;
			}
		}
	}
}