@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

@class: ~'.t-sidebar';

@{class} {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	width: auto;
	z-index: 1300;
	background-color: @white;
	box-shadow: @shadow1;

	&_on-top {
		z-index: 1600;
	}

	&__menu-btn-wrap {
		flex: none;
		margin: 8px 8px 16px;
	}

	&__anim-area {
		flex: auto;
		height: 100%;
		overflow: hidden;
	}

	&__cont {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: @dockedSidebarMinTablet;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: width 0s ease 0.2s;

		@{class}_expanded & {
			width: @dockedSidebarMaxTablet;
			transition: width 0s ease;
		}

		.simplebar-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: stretch;
			height: 100%;
		}
	}

	&__menu-wrap {
		flex: auto;
		margin-bottom: 16px;
	}

	.sidebar-menu-item__label {
		display: none;
		transition: color 0.2s ease;
	}

	&_expanded .sidebar-menu-item__label {
		display: block;
	}

	&:not(&_expanded) .sidebar-menu-separator {
		margin-left: 8px;
		margin-right: 8px;
	}

	&__sliding-area {
		flex: none;
	}

	&__indicators {
		padding: 16px 8px;
	}

	.notifications-button {
		display: block;
	}

	.notifications-button__label {
		display: none;
	}

	&_expanded .notifications-button__label {
		display: block;
	}

	&__user-info {
		width: 100%;
		padding: 13px 7px;
	}

	.button.user-button {
		display: block;
		padding-left: 4px;
		padding-right: 4px;
	}

	.user-info__avatar {
		width: 34px;
		height: 34px;
	}

	.user-info__name-block {
		display: none;
	}

	&_expanded .user-info__name-block {
		display: block;
	}

	.user-info__name {
		.tm-semibold-15();
	}

	.user-info__position {
		.tm-regular-13();
	}

	&__app-info-wrap {
		flex: none;
		padding: 16px;
		border-top: 1px solid @black05;
	}

	&__toggle-area {
		position: absolute;
		top: 0;
		right: -24px;
		width: 24px;
		height: 100%;
	}
}