@import (reference) '~@tehzor/ui-components/src/styles/colors';

.modified {
	display: grid;
	grid-column-gap: 20px;
	grid-template-columns: 1fr;
	line-height: 20px;
}

.modifiedAt {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;

	max-height: 40px;

	font-weight: 600;
	text-overflow: ellipsis;

	-webkit-line-clamp: 2;
}

.modifiedBy {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;

	max-height: 40px;
	margin-top: 4px;

	text-overflow: ellipsis;

	-webkit-line-clamp: 2;
}