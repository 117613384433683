@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';

.toolbar-dialog {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 12px;
	z-index: 1450;
	border-radius: 6px;
	background-color: @buttonFilterActive;
	box-shadow: 0px 8px 12px 0px rgba(@black4, 0.1), 0px 0px 4px 0px rgba(@black4, 0.1);
	width: 544px;
	height: 64px;
	bottom: 14px;
	border-radius: 8px;

	&_after-open {
		animation: slide-up 0.3s ease-in-out;
	}
	&_before-close {
		animation: slide-down 0.3s ease-in-out;
	}
}

.toolbar-dialog-overlay {
	background: transparent;
	pointer-events: auto;
}

@keyframes slide-up {
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slide-down {
	from {
		transform: translateY(0);
		opacity: 1;
	}
	to {
		transform: translateY(100%);
		opacity: 0;
	}
}
