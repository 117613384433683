@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.button {
	background-color: @buttonPressed;
	color: @black85;
	width: 133px;
	height: 32px;
	padding: 0 12px 0 8px;

	&:hover {
		background-color: @buttonPressed;
	}
}

.buttonWrap {
	justify-content: space-between;
}

.buttonLabel {
	margin-right: 8px;
	.d-regular-14();
}

.rightIcon i {
	color: @blue2;
}

.iconButton {
	background-color: @buttonPressed;
	min-height: 32px;
	min-width: 32px;
	color: @blue2;

	&:hover {
		background-color: @buttonPressed;
	}
}
