@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';

.dotted-link-button {
	display: inline;

	&__wrap {
		display: inline;
	}

	&__label {
		display: inline;
		border-bottom: 1px dashed @deepBlue;
		transition: border-bottom-color 0.2s ease;

		.link-button_disabled & {
			border-bottom-color: rgba(@grayBlue4, 0.4);
		}
	}

	.desktop &:not(.link-button_disabled)&:hover &__label {
		border-bottom-color: @buttonAccentHover;
	}

	&:not(.link-button_disabled)&:active &__label {
		border-bottom-color: @buttonAccentPressed;
	}
}
