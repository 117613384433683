@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.reason-select {
	width: 100%;

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__field {
		&-icon {
			&-svg {
				width: 1.4em;
				height: auto;
			}
		}
	}

	.dialog&__dialog {
		height: 60em;
		min-height: 38em;
		width: 80em;

		@media (max-width: @large-tablet-min) {
			height: 100%;
		}
	}

	&__action-buttons {
		margin-top: 1em;
	}

	&__body {
		display: flex;
		height:100%;
		flex-direction: column;
		align-items: stretch;
		flex:1;
		justify-content: stretch;
	}

	&__text-value {
		display: block;
		flex: none;
		width: 100%;
		margin-bottom: 2.5em;
	}

	&__standards-list {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		contain: strict;

		&-virtualized {
			position: relative;
			width: 100%;

			&-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}
	}

	&__reason-render-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__standard-item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		padding: 4px;

		&_active {
			background-color: @buttonLightSelected;
		}

		&-cb {
			display: block;
			flex: 1 1;

			&-input {
				width: 16px;
				height: 16px;

				&::after {
					width: 12px;
					height: 12px;
				}
			}

			&-label {
				margin-left: 10px;
				padding-right: 5px;
				font-size: 15px;
				line-height: 1.4em;
				font-weight: 600;
				letter-spacing: -0.01em;
				color: @black2;
				word-break: break-all;
				transition: color 0.2s ease;

				.checkbox_checked & {
					color: @blue;
				}
			}
		}

		&-link {
			display: block;
			flex: none;
			margin: 0 10px;
			outline: none;

			&-btn {
				display: block;
			}

			&-icon {
				width: 20px;
				height: auto;
			}
		}
	}

	&__not-found {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.5em;
		color: @gray4;
		text-align: center;
	}
}
