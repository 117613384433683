@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.button-menu {
	&__button {
		.button__wrap&-wrap {
			justify-content: space-between;
		}

		&-label {
			margin-right: 8px;
		}

		&-icon {
			transition: transform 0.2s ease;

			&_open {
				transform: rotate(180deg);
			}
		}
	}
}
