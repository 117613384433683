@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";


.date-time-picker {

	&__result {
		display: flex;
		justify-content: space-between;

		&__placeholder {
			color: @black75;
			font-size: 14px;
		}

		&__value {
			flex-grow: 1;

			&:first-child {
				margin-right: 16px;
			}

			&_label {
				color: @black75;
				font-size: 12px;
			}

			&_date {
				color: @black100;
				font-size: 14px;
				padding-bottom: 10px;
			}
		}
	}

	&_popup {
		width: 400px;
		padding: 22px 32px 24px;
		overflow: hidden;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: @tablet-max) {
			flex-direction: row-reverse;
		}

		&_margin {
			margin-top: 16px;
		}
	}

	&__button {
		flex: 1 1 50%;
		display: block;

		&:first-child {
			@media (max-width: @tablet-max) {
				margin-left: 16px;
			}

			@media (min-width: @large-tablet-min) {
				margin-right: 16px;
			}
		}
	}
}