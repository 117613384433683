@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.indicator-set {
	&__inline-button_filled {
		&--narrow {
			padding: 2px 6px !important;
			color: @blue2;
		}
	}
}
