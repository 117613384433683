@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.tabs {
	&__menu {}

	&__item {
		margin: 0 14px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__fake-item {
		display: flex;
		align-items: flex-end;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 4px;
			margin-bottom: -1px;
			background-color: @buttonAccent;
			border-radius: 6px;
		}
	}
}
