@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/common";

.export-dialog {
	@media (min-width: @tablet-min) {
		width: 600px;
	}

	@media (min-width: @large-tablet-min) {
		width: 800px;
	}

	&__dialog-body {
		overflow-y: hidden;
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		max-height: calc(100vh - 264px);
	}

	&__template-title {
		margin-bottom: 12px;
		.d-semibold-16();
		color: @black2;
	}

	&__template-select {
		flex: 1 1 auto;
		height: 100%;
		margin-bottom: 12px;
		overflow-y: auto;
	}

	&__make-document-cb {
		display: block;
		margin-top: 12px;
	}

	&__email-enabled-cb {
		display: block;
		margin-top: 12px;
	}

	&__email-title {
		margin-top: 12px;
		.d-semibold-16();
		color: @black2;
	}

	&__email-address {
		display: block;
		margin-top: 12px;
	}

	&__action-buttons {
		//margin-top: 1em;
	}
}
