@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.vertical-tabs {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;

	&__side-column {
		flex: none;
		position: sticky;
		top: 0;
		width: 180px;
		z-index: 1;
	}

	&__before-links {
		margin: 8px 16px 16px 0;
	}

	&__links {}

	&__content {
		margin-left: 10px;
		flex: 1 1 calc(100% - 180px);
		width: calc(100% - 180px);
		//баг в верстке статистики в сафари
		//filter: url("#sticky-filter");
	}

	&__fake-tab {
		position: absolute;
		width: 180px;
		border-radius: 14px;
	}

	&__filter {
		display: none;
	}
}