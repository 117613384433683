@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/typography';
@import (reference) '../../../../styles/variables';

.space-status-select {
	&__btn {
		.d-semibold-14();
		@media (max-width: @large-mobile-max) {
			margin-left: 24px;
		}
	}
	&__menu-item {
		&-tag {
			&_label {
				.d-semibold-14() !important;
			}
			&_disabled {
				opacity: 40%;
				color: @black85 !important;
			}
		}

		&-stage {
			&:not(:first-child) {
				margin-top: 12px;
			}
			&_title {
				.d-regular-12();
				color: @black75 !important;
			}	
		}
	}
}