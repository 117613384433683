@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.common-stats-cards {
	display: grid;
	grid-row-gap: 16px;
	grid-column-gap: 32px;
	align-content: start;

	@media (max-width: @large-tablet-min + 99px) {
		grid-template-columns: minmax(auto, 344px);
	}

	@media (min-width: @large-tablet-min + 100px) {
		grid-template-columns: repeat(2, minmax(auto, 344px));
	}

	@media (min-width: @desktop-min + 150px) {
		grid-template-columns: repeat(3, minmax(auto, 344px));
	}

	@media (min-width: @large-desktop-min) {
		grid-template-columns: repeat(4, 1fr);
	}

	&__card-placeholder {
		height: 56px;
	}
}