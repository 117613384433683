@import (reference) "../../../styles/colors";

div.simplebar-track.simplebar-vertical {
	width: 10px;
}

div.simplebar-track.simplebar-horizontal {
	height: 10px;
}

div.simplebar-scrollbar::before {
	background-color: @black60;
	border-radius: 3px;
}

.simplebar-hover div.simplebar-scrollbar::before {
	opacity: 0.7;
}

div.simplebar-scrollbar.simplebar-visible::before {
	transition: opacity 0.2s ease;
}