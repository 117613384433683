@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

@class: ~'.text-field';

@{class} {
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		background-color: @buttonLightHover;
		border: 1px solid @black10;
		border-radius: 8px;
		overflow: hidden;
		transition: background-color 0.2s ease, border-color 0.2s ease;

		&_focused {
			border-color: @iconButtonPressed;
		}

		&_disabled {
			background-color: @black05;
			pointer-events: none;
		}

		&_error {
			background-color: @statusExpired10;
			border-color: @statusExpired;
		}

		&_valid {
			border-color: @statusFixed;
		}

		&_clickable {
			&:not(@{class}__wrap_disabled) {
				cursor: pointer;
				transition: background-color 0.2s ease;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

				.desktop &:hover {
					background-color: @buttonHover;
				}

				&:active {
					background-color: @buttonPressed !important;
				}
			}
		}
	}

	&__div, &__input, &__textarea {
		display: block;
		width: 100%;
		background-color: transparent;
		font-family: 'Open Sans', sans-serif;
		color: @black100;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		@media (max-width: @tablet-max) {
			padding: 11px 0px 11px 15px;
			.tm-semibold-15();
}

		@media (min-width: @large-tablet-min) {
			padding: 13px 0px 13px 15px;
			.d-semibold-14();
}
	}

	&__div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: @tablet-max) {
			min-height: 44px;
		}

		@media (min-width: @large-tablet-min) {
			min-height: 47px;
		}

		@{class}__wrap_disabled & {
			cursor: default;
		}
	}

	&__input {
		border: none;
		outline: none;

		@{class}__wrap_clickable:not(@{class}__wrap_disabled) & {
			cursor: pointer;
		}
	}

	&__textarea {
		border: none;
		outline: none;
		resize: none;

		@{class}__wrap_clickable:not(@{class}__wrap_disabled) & {
			cursor: pointer;
		}
	}

	&__icon, &__clear-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;

		.tz-search-20 {
			color: @grey;
		}

		@media (max-width: (@large-tablet-min - 1px)) {
			min-height: 44px;
			min-width: 44px;
		}

		@media (min-width: @large-tablet-min) {
			min-height: 47px;
			min-width: 47px;
		}

		color: @buttonAccent;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&.clickable {
			cursor: pointer;

			&:hover {
				color: @buttonAccentHover;
			}
			&:active, &.isPressed {
				background-color: @buttonFilterActive;
				color: @buttonAccentPressed;
			}
		}
	}

	&__placeholder {
		position: absolute;
		color: @black60;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;

		@media (max-width: @tablet-max) {
			top: 10px;
			left: 11px;
			right: 11px;
			.tm-semibold-15();
		}

		@media (min-width: @large-tablet-min) {
			top: 12px;
			left: 15px;
			right: 15px;
			.d-regular-14();
		}
	}
}
