@import (reference) '~@tehzor/ui-components/src/styles/variables';

.profile-edit-page {
	@media (max-width: @tablet-min) {
		padding: 0;
	}

	&__save-btn {
		margin-top: 12px;
	}

	&__grid {
		display: grid;
		row-gap: 8px;
	}
}