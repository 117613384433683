@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-owner-acpt-info-cell {
	padding-right: 8px;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 16px;
	}

	&__extra-info {
		max-width: 100%;
		margin-bottom: 4px;
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
}