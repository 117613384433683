@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.manage-check-lists-page {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 0;
		}
	}
	&_mobile {
		@media (max-width: @tablet-min - 1px) {
			padding: 0;
		}
	}

	&__d-table {
		&-menu {
			margin: 0;
			padding: 8px 28px 8px 8px;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: transparent;
			transition: background-color 0.2s, opacity 0.2s ease;
			visibility: hidden;
			opacity: 0;

			&-wrap {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	& .table2__row_clickable {
		&:hover .manage-check-lists-page__d-table-menu {
			visibility: visible;
			opacity: 1;
			background-color: @buttonLightHover;
		}
		&:active .manage-check-lists-page__d-table-menu {
			background-color: @buttonLightPressed;
		}
		& .listTag {
			margin: 0 0 2px 0;
			padding: 0;
			display: block;
			max-width: 100%;
		}
	}
}
