@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-result-work-acceptance {
	&__description {
		margin-bottom: 25px;
	}

	&__info-grid {
		display: flex;
		flex-direction: column;
		row-gap: 26px;
		position: relative;

		&-units {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-text-input {
				width: 48%;
			}

			&-select-search {
				margin-bottom: 10px;
			}

			@media (max-width: @tablet-min) {
				flex-direction: column;
				align-items: flex-start;
				row-gap: 26px;

				&-text-input {
					width: 100%;
				}
			}
		}
	}

	&__attachments {
		margin: 32px 0 0;

		&-scroll-area {
			margin-top: 0;
			padding: 0 16px 0 0;

			@media (min-width: @large-tablet-min) and (min-height: 800px) {
				max-height: 389px;
			}

		}

	}
}