@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";
@import (reference) "../../../../../styles/shadows";

.switch-menu-option {
	display: block;
	position: relative;
	height: 40px;
	padding: 0 8px;
	background-color: transparent;
	border-radius: 8px;
	color: @greyLight;
	transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;

	&:not(&_disabled) {
		.desktop &:hover {
			background-color: @buttonLightHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonLightPressed;
		}
	}

	&_checked {
		z-index: 1;
		box-shadow: @shadow2;
		color: @buttonAccent;

		button& {
			background-color: @white;
		}
	}

	&:not(&_checked):not(&_disabled) {
		.desktop &:hover {
			color: @iconButtonHover;
		}

		.desktop &:active, .mobile &:active {
			color: @iconButtonPressed;
		}
	}

	&_disabled&_checked {
		color: @grey;
	}

	&_disabled&:not(&_checked) {
		color: @black10;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__label {
		max-width: 100%;
		.d-semibold-14();
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	& i {
		display: block;
	}
}