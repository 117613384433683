@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.calendar-button {
	display: inline-block;
	height: 48px;
	padding: 0;
	border-radius: 8px;
	color: rgba(@black4, 0.85);
	user-select: none;
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;

	.desktop &:not(&_disabled):hover {
		background-color: @buttonHover;
		color: @deepBlue;
	}

	&:not(&_disabled):active {
		background-color: @buttonPressed !important;
		color: @deepBlue;
	}

	&:not(&_disabled)&_inactive {
		color: rgba(@black4, 0.2);

		.desktop &:hover {
			color: rgba(@black4, 0.4);
		}

		&:active {
			color: rgba(@black4, 0.4) !important;
		}
	}

	&:not(&_disabled)&_active {
		background-color: @buttonAccent;
		color: @white;

		.desktop &:hover {
			background-color: @buttonAccentHover;
			color: @white;
		}

		&:active {
			background-color: @buttonAccentPressed !important;
			color: @white;
		}
	}

	&_disabled {
		color: rgba(@black4, 0.2);
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__label {
		@media (max-width: @tablet-max) {
			.tm-semibold-15();
		}

		@media (min-width: @large-tablet-min) {
			.d-semibold-14();
		}
	}
}
