@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.history-spaces-change {
	color: @black100;
	.d-regular-14();

	&__line {
		display: flex;
		width: fit-content;
		gap: 4px;
	}
	&__type {
		.d-semibold-14();
	}
}