@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";

@class: ~'.d-header-menu-item';

@{class} {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	cursor: pointer;
	user-select: none;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		background-color: transparent;
		border-radius: 3px;
		transition: background-color 0.2s ease;
	}

	&_active::after {
		background-color: @deepBlue;
	}

	&__wrap {
		display: flex;
		align-items: center;
		min-height: 40px;
		margin-bottom: 10px;
		border-radius: 6px;
		transition: background-color 0.2s ease;

		.desktop @{class}:hover & {
			background-color: @buttonHover;
		}

		@{class}:active & {
			background-color: @buttonPressed !important;
		}
	}

	&__label {
		padding: 0 4px;
		.d-semibold-16();
		color: @black60;
		white-space: nowrap;
		transition: color 0.2s ease;

		.desktop @{class}:hover & {
			color: @deepBlue;
		}

		@{class}:active & {
			color: @deepBlue !important;
		}
	}

	&_active &__label {
		color: @black100;
	}
}