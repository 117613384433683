@import (reference) '../../styles/colors';
@import (reference) '../../styles/variables';

.adjacent-page-links {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: -1em;

	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&__back-link {
		margin-bottom: 1em;

		&:not(:last-of-type) {
			margin-right: 4em;
		}

		&-icon {
			display: block;
			transform: rotate(180deg);
		}
	}

	&__link {
		margin-bottom: 1em;

		&:not(:last-of-type) {
			margin-right: 1.4em;
		}
	}
}
