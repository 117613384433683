@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__separator {
		transform: rotate(-90deg);
		color: @grey;

		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}