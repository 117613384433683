@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-problem-tags-set-info-cell {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__wrapper {
		display: flex;
		flex-direction: row;
		padding: 14px;
	}

	&__text {
		margin-left: 12px;
	}

	&__name {
		.d-semibold-14();
		color: @black4;
	}
	&__tags {
		.d-regular-12();
		margin-top: 4px;
		color: @black60;
	}

	&__stages {
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
		.d-regular-12();
	}

	&__company {
		margin-top: 8px;
		.d-regular-12();
		color: @black85;
	}
	&__objects {
		margin-top: 8px;
		color: @black4;
		&-tag{
			.d-semibold-12();
		}
	}
}