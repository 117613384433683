@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.apl-button {
	display: block;
	min-width: 12em;
	height: 3.2em;
	background-color: rgba(@blue2, 0.11);
	border-radius: 0.4em;
	color: @blue2;
	transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;

	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&_disabled {
		background-color: rgba(@grayBlue4, 0.08);
		color: rgba(@grayBlue4, 0.4) !important;
		cursor: default !important;
	}

	&_active {
		border: 1px solid @blue2;
	}

	.desktop &:not(&_disabled)&:hover {
		background-color: rgba(@blue2, 0.2);
		color: @blue2;
	}

	&:not(&_disabled)&:active {
		background-color: rgba(@blue2, 0.3) !important;
		color: @blue2 !important;
	}

	&_disabled:active {
		color: rgba(@grayBlue4, 0.4) !important;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
		padding: 0 1em;
	}

	&__label {
		max-width: 100%;
		font-size: 1.4em;
		line-height: 1.5em;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
