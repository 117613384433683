@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.mobile-entity {
	&__cont {
		display: grid;

		@media (max-width: @tablet-min - 1px) {
			grid-template-columns: 1fr min-content;
			grid-template-areas: 'title checkbox'
				'subtitle .'
				'content content'
				'footer footer';
			grid-column-gap: 16px;

			&_with-image {
				grid-template-columns: min-content 1fr min-content;
				grid-template-areas: 'image title checkbox'
					'image subtitle .'
					'content content content'
					'footer footer footer';
				grid-column-gap: 16px;
			}

			&_with-link {
				padding-bottom: 0;
			}
		}

		@media (min-width: @tablet-min) {
			grid-template-columns: min-content 2fr 3fr;
			grid-template-areas: 'checkbox title subtitle'
				'. content content'
				'footer footer footer';
			grid-column-gap: 22px;
			grid-row-gap: 16px;
			padding-top: 20px;
			padding-bottom: 0;

			&_with-image {
				grid-template-areas: 'checkbox title subtitle'
					'. image content'
					'footer footer footer';
				grid-column-gap: 22px;
				grid-row-gap: 16px;
				padding-top: 20px;
			}
		}
	}

	&__image-area {
		grid-area: image;
	}

	&__title-area {
		grid-area: title;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@media (max-width: @tablet-min - 1px) {
			padding-top: 4px;
			.tm-semibold-17();
		}

		@media (min-width: @tablet-min) {
			margin-top: -1px;
			align-self: center;
			.d-semibold-16();
		}
	}

	&__sub-title-area {
		grid-area: subtitle;
		align-self: end;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@media (max-width: @tablet-min - 1px) {
			padding-top: 9px;
		}
	}

	&__checkbox-area {
		grid-area: checkbox;
		align-self: center;

		@media (max-width: @tablet-min - 1px) {
			padding: 4px 10px 0 0;
		}

		@media (min-width: @tablet-min) {
			align-self: center;
		}
	}

	&__content {
		grid-area: content;
		overflow: hidden;

		@media (max-width: @tablet-min - 1px) {
			padding-top: 20px;
		}
	}

	&__link-area {
		grid-area: footer;
		margin-left: -16px;
		margin-right: -16px;
		border-top: 1px solid @black10;

		@media (max-width: @tablet-min - 1px) {
			margin-top: 20px;
		}

		@media (min-width: @tablet-min) {
			margin-top: 6px;
		}
	}

	&__checkbox {
		display: block;
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		@media (max-width: @tablet-min - 1px) {
			height: 48px;
		}

		@media (min-width: @tablet-min) {
			height: 52px;
		}
	}
}