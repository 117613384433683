@import (reference) "~@tehzor/ui-components/src/styles/shadows";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.app-bottom-menu {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;

	&.error {
		height: 56px;
		padding: 2px 0;
		background-color: @white;
		box-shadow: @shadow1;
	}
}