@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.manage-working-groups-page {
	&__filters {

		&-search {
			margin-right: 12px;
		}
	}

	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px){
			margin-bottom: 0;
		}
	}
	&_mobile {
		@media (max-width: @tablet-min - 1px) {
			padding: 0;
		}
	}

	&__d-table {
		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}
	&__menu-cell{
		width: fit-content;
	}
	&__mobile-cell {
		padding: 0;
	}
}
