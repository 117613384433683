@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.fab {
	display: inline-block;
	height: 52px;
	margin: 0;
	padding: 0;
	position: fixed;
	right: 16px;
	bottom: 16px;
	z-index: 1;
	background-color: @buttonAccent;
	border: none;
	border-radius: 55px;
	cursor: pointer;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: 'Open Sans', sans-serif;
	color: @white;
	transition: background-color 0.2s ease, color 0.2s ease;
	will-change: width;

	.desktop &:hover {
		background-color: @buttonAccentHover;
	}

	&:active {
		background-color: @buttonAccentPressed !important;
	}

	&_minified {
		width: 52px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 52px;
		height: 52px;
		flex: none;

		i {
			display: block;
		}
	}

	&__label {
		.d-semibold-14();

		&:first-child {
			margin-left: 18px;
		}

		&:last-child {
			margin-right: 18px;
		}
	}

	&__icon + &__label {
		margin-left: -4px;
	}
}