@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-attachments-field {
	&__scroll-area {
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__attach-btn {
		width: 100%;
		margin-top: 32px;
	}

	&__error {
		margin-top: 12px;
		.tm-regular-13();
		color: @primaryRed;
	}
}