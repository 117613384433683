@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';

.manage-space-type-decoration-page {
	&__breadcrumbs {
		@media (min-width: @tablet-min) and (max-width: @large-tablet-max) {
			margin-bottom: 16px;
		}
		@media (max-width: @tablet-min - 1px) {
			margin-bottom: 0;
		}
	}
	&_mobile {
		@media (max-width: @tablet-min - 1px) {
			padding: 0;
		}
	}

	&__d-table {
		&-menu {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}

	.objectTag {
		margin: 0 0 2px 0;
		padding: 0;
		max-width: 100%;

		&:nth-child(3) {
			margin-top: 4px;
			background-color: @appBackground;
			padding: 2px 8px;
		}
	}
}
