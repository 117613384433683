@import (reference) "../../../styles/colors";

.delegation-tree-group {
	margin: 0 0 16px;

	&__expand-btn {
		margin: 0 0 0 156px;

		.delegation-tree-group_level_0 & {
			margin-left: 56px;
		}

		.delegation-tree-group_level_1 & {
			margin-left: 96px;
		}

		.delegation-tree-group_level_2 & {
			margin-left: 136px;
		}

		&-icon {
			transform: rotate(0deg);
			transition: transform 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);

			&_reversed {
				transform: rotate(-180deg);
			}
		}
	}

	&__children {
		margin: 16px 0 -16px;
		overflow: hidden;
	}
}