@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.problems-count {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	&__container {
		margin-left: 11px;
		display: flex;
		align-items: center;
	}
	&__container:first-of-type {
		margin-left: 0;
	}

	&__count {
		margin-left: 5px;
	}
}
