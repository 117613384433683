@import (reference) '../../../styles/colors.less';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';

.mobile-filter {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 16px;
	background-color: @white;

	&:not(:last-child) {
		border-bottom: 1px solid @black10;
	}

	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	&__search {
		padding: 8px 0px;
	}

	&__label {
		.tm-semibold-15();
		color: @black100;
	}

	&__tabs {
		display: flex;
		flex-wrap: wrap;
		max-height: 126px;
		margin: 5px -6px -5px -6px;
		overflow: hidden;

		&_fullsize {
			max-height: none;
		}
	}

	&__item {
		margin: 5px 6px;
	}
}
