@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.notifications-dialog {
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 1450;
	background-color: @white2;
	border-radius: 2px;
	box-shadow: -4px 10px 20px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	outline: none;
	
	font-size: 10px;
	
	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}
	
	@media (max-width: @tablet-max) {
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	
	@media (min-width: @large-mobile-min) and (max-width: @tablet-max) {
		width: 35em;
	}
	
	@media (min-width: @large-tablet-min) {
		top: 7em;
		right: 1.5em;
		width: 35em;
		height: calc(100% - 8.5em);
		max-height: 60em;
	}
	
	&_after-open {
		animation-duration: 0.3s;
		animation-timing-function: cubic-bezier(0.32, 0.72, 0.37, 0.95);
		animation-fill-mode: backwards;
		animation-delay: 0.1s;
		
		@media (max-width: @large-mobile-min - 1px) {
			animation-name: notifications-dialog-fullscreen-zoom-in;
		}
		
		@media (min-width: @large-mobile-min) {
			animation-name: notifications-dialog-movement-zoom-in;
		}
	}
	
	&_before-close {
		animation-duration: 0.3s;
		animation-timing-function: cubic-bezier(0.5, 0.25, 0.88, 0.64);
		
		@media (max-width: @large-mobile-min - 1px) {
			animation-name: notifications-dialog-fullscreen-zoom-out;
		}
		
		@media (min-width: @large-mobile-min) {
			animation-name: notifications-dialog-movement-zoom-out;
		}
	}
}

.notifications-dialog-overlay {
	@media (max-width: @tablet-max) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		z-index: 1400;
		opacity: 0;
		transition: opacity 0.2s cubic-bezier(0.69, 0.17, 0.56, 0.77);
		
		@media (min-width: @large-mobile-min) {
			background-color: rgba(@black1, 0.35);
		}
		
		&_after-open {
			opacity: 1;
		}
		
		&_before-close {
			opacity: 0;
			transition-delay: 0.1s;
		}
	}
}

@keyframes notifications-dialog-fullscreen-zoom-in {
	from {
		opacity: 0;
		transform: scale3d(0.7, 0.7, 0.7);
	}
	
	50% {
		opacity: 1;
	}
}

@keyframes notifications-dialog-fullscreen-zoom-out {
	from {
		opacity: 1;
	}
	
	50% {
		opacity: 0;
		transform: scale3d(0.7, 0.7, 0.7);
	}
	
	to {
		opacity: 0;
	}
}

@keyframes notifications-dialog-movement-zoom-in {
	from {
		opacity: 0;
		transform: translate3d(0, -40%, 0) scale3d(0.2, 0.2, 0.2);
	}
	
	50% {
		opacity: 1;
	}
}

@keyframes notifications-dialog-movement-zoom-out {
	from {
		opacity: 1;
	}
	
	50% {
		opacity: 0;
		transform: translate3d(0, -40%, 0) scale3d(0.2, 0.2, 0.2);
	}
	
	to {
		opacity: 0;
	}
}
