@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.date-picker {
	&__result {
		margin-bottom: 32px;
	}

	&__calendar {
		min-height: 431px;
	}
}