@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.history-tags-change {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

