@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";

.entity-info-link {
	max-width: 100%;

	&__label {
		.d-regular-12();
		color: @black85;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	&__icon-wrap {
		margin-right: 8px;
	}

	&__icon {
		color: @buttonAccent;
		.d-regular-12();
	}
}