@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.empty-plate {
	&__no-content {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding: 15px;

		&-detail {
			min-height: 667px;
		}

		&-main {
			min-height: 456px;
		}

		&-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			row-gap: 20px;
			color: @grey;
		}

		&-icon > i {
			font-size: 184px;
			color: @greyLight;

			@media (max-width: @desktop-min) {
				font-size: 144px;
			}
		}

		&-text {
			.d-semibold-14();
			color: @black35;
		}

		@media (max-width: @desktop-min) {
			min-height: 344px;
		}
	}
}
