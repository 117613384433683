@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.entities-filters {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 16px;

	&-with-tabs {
		max-width: 350px;
		min-width: 350px;
	}

	&__responsibles-item {
		&-content {
			.d-semibold-14();
			color: @black75;
		}
		&-additional-content,
		&-leader {
			.d-regular-12();
		}
		&-additional-content {
			color: @black60;
		}
		&-leader {
			color: @deepBlue;
		}
	}

	&__btn-wrap {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 15px;
	}

	&__btn {
		&-icon {
			width: 13px;
			height: auto;
		}

		&-arrow-icon {
			width: 12px;
			height: auto;
			transform: rotate(0deg);
			transition: transform 0.3s ease;

			.entities-filters_visible & {
				transform: rotate(180deg);
			}
		}
	}

	&__item {
		margin-right: 12px;
		margin-bottom: 12px;
	}

	&__icon {
		color: @buttonAccent;
	}

	&__text-field {
		display: block;
		width: 100%;
	}

	&__number {
		display: block;
		width: 100%;

		&-input::-webkit-outer-spin-button,
		&-input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__select {
		display: block;
		width: 100%;

		&-value {
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&__ab {
		margin: 0 0 30px;
	}

	&__separator {
		width: 100%;
		padding-bottom: 40px;
		border-top: 1px @gray6 solid;
	}
	&__toggle {
		&-root {
			width: 100%;
			padding-left: 16px;
			padding-right: 16px;
		}
		&-content {
			.d-regular-12();
		}
	}
}
