@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.root {
	padding: 4px 0;
}

.label {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

.stageTitle {
	.d-regular-12();
	color: @black60;
	padding: 12px 28px 12px 16px;

	p {
		margin: 0;
	}
}

.divider {
	border: none;
	margin: 8px 0;
	border-bottom: 1px solid @grey20;
}

.group {
	margin-top: 20px;
}
