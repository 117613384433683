@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.activation-waiting {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 1.5em;
	line-height: 1.4em;
	font-weight: 600;
	color: @grayBlue2;
	text-align: center;
}
