@import (reference) "../../../../../styles/colors";

.auth-provider-button {
	border: 1px solid @iconButtonHover;

	&__icon {
		color: @deepBlue;
	}

	&__label {
		font-weight: 700;
		font-size: 14px;
		color: @deepBlue;
	}
}