@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.new-image {
	//max-width: 25em;
	
	font-size: 10px;
	
	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}
	
	&__preview {
		width: 100%;
	}
	
	&-title-block {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-top: 1em;
	}
	
	&__progress-block {
		margin: 0.6em 0;
	}
	
	&__controls-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	&__title {
		font-size: 1.4em;
		font-weight: 600;
		line-height: 1.5em;
		color: @black2;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		
		@media (max-width: @tablet-min - 1px) {
			padding-right: 0.5em;
		}
	}
	
	&__file-size {
		margin-left: 1em;
		font-size: 1.2em;
		line-height: 1.5em;
		letter-spacing: -0.01em;
		color: @gray4;
		white-space: nowrap;
	}
	
	&__progress-title {
		margin-bottom: 0.4em;
		font-size: 1.2em;
		line-height: 1.2em;
		letter-spacing: -0.01em;
		color: @gray4;
		
		&-started {
			color: @blue;
		}
		
		&-success {
			color: @green;
		}
		
		&-error {
			color: @red;
		}
	}
	
	&__progress {
	
	}
	
	&__btn {
		margin: 0.5em 0.2em 0.5em 0;
		
		&-icon {
			width: 1em;
			height: auto;
		}
		
		&-label {
			margin-left: -0.2em;
			font-size: 1.2em;
		}
		
		&:not(:first-child) {
			margin-left: 1em;
		}
	}
}
