@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.wrapper {
	height: calc(100dvh - @mobile-header-height);
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr max-content;
	background-color: @white;
	overflow: hidden;
}

.bar {
	padding: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0 16px;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 12px 0px rgba(31, 52, 79, 0.20);
}