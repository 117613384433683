@import (reference) "../../styles/colors";

@keyframes arrowAnimation {
	0% {
		transform: translateY(24px) rotate(-90deg);
	}

	100% {
		transform: translateY(-24px) rotate(-90deg);
	}
}

.icon {
	position: relative;
	padding: 0 2px;
	width: 24px;
	height: 24px;
	overflow: hidden;
}

.lineContainer {
	padding: 2px 2px 0 2px;
	position: relative;
	width: 100%;
	height: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: @white;
	z-index: 2;
}

.line {
	width: 100%;
	height: 2px;
	background-color: @greyLight;
	border-radius: 14px;
}

.arrow {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	color: @greyLight;
	animation: arrowAnimation 1.5s infinite ease-in-out;
	z-index: 1;

	i {
		font-size: 18px;
	}
}