@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.cloud-button {
	.icon-button& {
		&_online {
			color: @black95;
		}

		&_offline {
			color: @black35;
		}

		&_not-supported {
			color: @statusWaiting;
		}
	}
}
