@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.popup-menu {
	max-width: 300px;

	.popup_type_context& {

	}
}
