@import (reference) "../../../styles/colors";

.switch-menu {
	width: fit-content;
	background-color: @white;
	border: 1px solid @black10;
	border-radius: 8px;

	&__wrap {
		display: flex;
		margin: -1px;

		& > * {
			&:not(:last-child) {
				margin-right: 4px;
			}
		}
	}
}