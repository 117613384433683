@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.check-list-indicator {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	&__counter {
		.d-regular-12();

		margin-left: 7px;
		color: @black85;
	}
}