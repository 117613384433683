@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.offline-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 26px;
	background-color: @grey90;
	.d-semibold-12();
	color: @white;
}