@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.d-wclaim-info-cell {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&__info {
		flex: 1 1;
		margin-left: 26px;
		overflow: hidden;
	}

	&__title:not(:last-child) {
		margin-bottom: 16px;
	}

	&__extra-info:not(:last-child) {
		margin-bottom: 4px;
	}

	&__extra-info {
		max-width: 100%;
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
}