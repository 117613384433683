@import (reference) '~@tehzor/ui-components/src/styles/variables';

.editing-check-list {

	&__container {
		width: 100%;
		display: grid;
		grid-template-columns: 368px auto;
		grid-column-gap: 32px;
		align-items: flex-start;

		@media (max-width: @large-tablet-min) {
			grid-template-columns: auto;
			grid-template-rows: auto auto;
			grid-row-gap: 32px;
		}
	}

	&__info-grid {
		position: sticky;
		width: 368px;
		margin-right: 20px;

		@media (max-width: @large-tablet-min) {
			padding-top: 20px;
			width: 100%;
		}
	}

	&__items-grid {

	}
}