@import (reference) "~@tehzor/ui-components/src/styles/variables";

.problem-history-dialog {
	&__content {
		@media (min-width: @tablet-min) {
			width: 740px;
		}

		@media (min-width: @desktop-min) {
			width: 919px;
		}

		@media (min-width: @tablet-min) and (min-height: 432px) {
			min-height: 400px
		}

		@media (min-width: @desktop-min) and (min-height: 482px) {
			min-height: 450px
		}
	}
}