@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.mobile-entities-filters {
	display: flex;
	justify-content: flex-end;

	&__textfield {
		width: 100%;
		margin-bottom: 4px;
	}

	&__objects-filter {
		padding: 20px 0 16px;
		border-bottom: 1px solid @black10;

		&-header {
			display: flex;
			justify-content: space-between;
		}

		&-title {
			.tm-semibold-15();
			color: @black100;
		}

		&-label {
			margin-top: 2px;
			.d-regular-12();
			color: @black75;
		}
	}
}