@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.date-picker-layer {
	&_dialog {
		@media (min-width: @large-mobile-min) {
			width: 400px;

			&-body {
				padding-left: 32px;
				padding-right: 32px;

			}

			&-footer {
				padding-left: 32px;
				padding-right: 32px;
				padding-bottom: 24px;
			}

			&-body + &-fotter {
				margin-top: 24px;
			}
		}

		&-footer {
			padding-top: -16px;
		}
	}

	&_popup {
		width: 400px;
		padding: 22px 32px 24px;
		overflow: hidden;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: @tablet-max) {
			flex-direction: row-reverse;
		}

		&_margin {
			margin-top: 16px;
		}
	}

	&__button {
		flex: 1 1 50%;
		display: block;

		&:first-child {
			@media (max-width: @tablet-max) {
				margin-left: 16px;
			}

			@media (min-width: @large-tablet-min) {
				margin-right: 16px;
			}
		}
	}
}