@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/shadows';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.object-item {
	user-select: none;

	height: 100%;
	padding: 16px;

	font-size: 10px;

	background-color: @white;
	border-radius: 12px;

	-webkit-tap-highlight-color: rgb(0 0 0 / 0%);

	&__img {
		cursor: pointer;

		position: relative;

		overflow: hidden;

		background: no-repeat center;
		background-size: cover;
		border-radius: 6px;

		&::before {
			content: '';
			display: block;
			padding-top: 80%;
			transition: background-color 0.2s ease;
		}

		.desktop &:hover::before {
			background-color: rgb(@blue 0.3);
		}

		&:active::before {
			background-color: rgb(saturate(darken(@blue, 8%), 4%) 0.5) !important;
		}
	}

	&__footer {
		position: relative;

		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 8px;

		padding-top: 8px;

		&-title {
			cursor: default;
			user-select: text;

			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;

			max-width: 85%;
			height: 3.2em;
			padding: 9px 0;

			font-size: 1.6em;
			font-weight: 600;
			line-height: 1.3em;
			color: @black1;
			word-break: break-word;
		}

		&-menu {
			position: absolute;
			top: 9px;
			right: 0;
		}

		&-stages {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;

			&__label {
				.d-regular-12() !important;
			}
		}

		&-problems {
			align-content: center;
			min-height: 30px;

			.problems-count__container {
				margin-left: 12px;
			}
		}
	}

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}
	.shadow4();
}
