@import (reference) "~@tehzor/ui-components/src/styles/colors";

.category-view-field {
	&__chained {
		display: flex;
		flex-direction: row;
		align-items: stretch;

		&-line {
			z-index: 1;
			position: relative;

			margin-right: 20px;
			min-height: 100%;
			width: 2px;
			background-color: @grey20;
		}
		&-items {
			display: flex;
			flex-direction: column;

			&__item {
				position: relative;

				margin: 8px 0px;
				display: flex;
				justify-content: flex-start;
				align-items: center;


				&.first::before, &.last::before {
					content: '';

					width: 100%;
					height: 50%;
					position: absolute;
					right: 26px;
					z-index: 2;

					background-color: white;
				}
				&.first {
					margin-top: 0px;

					&::before {					
						top: 0px;
					}
				}
				&.last {
					margin-bottom: 0px;

					&::before {
						bottom: 0px;
					}
				}

				&-dot {
					position: relative;
					z-index: 3;

					right: 25px;
					min-width: 8px;
					min-height: 8px;

					background-color: @grey20;
					border-radius: 50%;
				}
				&.last > &-dot {
					display: flex;
					justify-content: center;
					align-items: center;

					right: 33px;
					min-width: 16px;
					min-height: 16px;

					background-color: @green70;
					color: white;
					border: 4px solid white;

					font-size: 10px;
				}

				&-content {
					position: relative;
					z-index: 3;

					font-family: 'Open Sans';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;

					color: @black85;
				}

				&.last > &-content {
					right: 16px;
					font-weight: 600;
										
					color: @black100;
				}
			}
		}
	}

	&__single {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		color: @black100;
	}
}