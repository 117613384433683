.not-found {
	background-image: url('./404.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center, 50%, 50%;
	display: block;
	height: 400px;

	&-h1 {
		font-size: 48px;
		line-height: 48px;
		text-align: center;
		margin: 36px 0;
	}

	&-text {
		text-align: center;
		margin: 0 16px;
	}

	&-btn {
		margin-top: 24px;
		display: flex;
		justify-content: center;

		&-content {
			width: 500px;
		}
	}
}