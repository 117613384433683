@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";

.d-header {
	display: grid;
	grid-template-columns: min-content 1fr min-content min-content;
	align-items: center;
	width: 100%;
	height: @desktop-header-height;
	padding: 0 16px;
	background-color: @white;
	box-shadow: 0 2px 15px rgba(112, 126, 153, 0.12);

	&_with-sections-menu {
		grid-template-columns: min-content fit-content(20%) 1fr min-content min-content;

		@media (min-width: @large-desktop-min) {
			grid-template-columns: min-content fit-content(30%) 1fr min-content min-content;
		}
	}

	&__links {
		display: flex;
		align-items: center;
	}

	&__logo-link {
		flex: none;
		margin-right: 40px;
	}

	&__back-btn {
		flex: none;
		margin-right: 16px;
		transform: rotate(180deg);
	}

	&__title {
		margin-right: 32px;
		.tm-title-bold-24();
		color: @black95;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__sections-menu {
		height: 100%;
		margin: 0 48px 0 16px;
		overflow: hidden;
	}

	&__indicators {
		display: flex;
		align-items: center;
		margin-right: 32px;

		& *:not(:last-child) {
			margin-right: 16px;
		}
	}

	.user-info {
		grid-template-columns: auto min-content;
	}

	.user-info__position {
		max-width: 200px;
		color: @black60;
	}
}
