@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.structure-item {
	margin: 4px 0;

	&__name {
		padding-right: 8px;
		display: inline-block;
	}

	&__markers {
		display: inline-block;
	}

	&__selected {
		background-color: @blue10;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 4px 8px;
		border-radius: 6px;
		min-width: none;
		width: fit-content;

		&_offset {
			margin-top: -16px;
			margin-bottom: 20px;
		}

		cursor: pointer;

		&_icon {
			display: flex;
			color: @deepBlue;
			align-items: center;
			padding-right: 4px;

		}

		&:hover {
			background-color: @blue20;
		}
	}

	&__display-place {
		display: flex;
		align-items: center;
		gap: 10px;

		> i {
			position: relative;
			color: @grey60;

			&::after {
				position: absolute;
				top: -4px;
				right: -4px;
				content: '';
				width: 12px;
				height: 12px;
				background-color: @red70;
				border-radius: 50%;
				border: 3px solid @white;
			}
		}
	}
}