@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";
@import (reference) "../../../../../styles/variables";

@class: ~'.m-header-menu-item';

@{class} {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	cursor: pointer;
	user-select: none;

	.button-base& {
		padding: 0;
	}

	&::after {
		content: '';
		display: block;
		width: calc(100% - 8px);
		height: 2px;
		margin: 0 4px;
		background-color: transparent;
		border-radius: 2px;
		transition: background-color 0.2s ease;
	}

	&_active::after {
		background-color: @deepBlue;
	}

	&__wrap {
		display: flex;
		align-items: center;
		height: 34px;
		margin: 2px 0;
		border-radius: 6px;
		transition: background-color 0.2s ease;

		.desktop @{class}:hover & {
			background-color: @buttonHover;
		}

		@{class}:active & {
			background-color: @buttonPressed !important;
		}
	}

	&__label {
		padding: 0 4px;
		.tm-semibold-13();
		color: @black60;
		white-space: nowrap;
		transition: color 0.2s ease;

		.desktop @{class}:hover & {
			color: @deepBlue;
		}

		@{class}:active & {
			color: @deepBlue !important;
		}
	}

	&_active &__label {
		color: @black100;
	}
}