@import (reference) "../../../styles/colors";

.editor-info {
	&__user {
		&:not(:first-child) {
			margin-top: 12px;
		}
	}

	&__offline {
		&_tag {
			color: #172A41;
			background-color: @grey10;
			margin-bottom: 10px;
		}

		&_icon {
			color: @grey;
		}
	}
}