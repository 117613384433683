@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.check-list-mobile-detail {
	display: flex;
	row-gap: 16px;
	flex-direction: column;

	&__info {
		padding: 8px 16px;
	}

	&__tabs {
		margin-bottom: 16px;
	}
}
