@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.sidebar-toggle-btn {
	width: 32px;
	height: 32px;
	padding: 2px;

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: @iconButtonHover;
		border-radius: 50%;
		transition: background-color 0.2s ease, border-color 0.2s ease;
	}

	.desktop &:hover &__wrap {
		background-color: @iconButtonPressed;
	}

	&:active &__wrap {
		background-color: @buttonAccent !important;
	}

	&__icon {
		flex: none;
		color: @white;
		transform: rotate(-90deg);
		transition: color 0.2s ease, transform 0.2s ease;

		& > i {
			display: block;
			margin-top: 3px;

			&::before {
				display: block;
			}
		}
	}

	&_reversed &__icon {
		transform: rotate(90deg);
	}
}