.inline-date-picker {
	&__time-select {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		&-label {
			margin-right: 20px;
		}
	}
	&__calendar {
		min-height: 431px;
	}
}