@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-space-owner-dialog {
	@media (min-width: @large-tablet-min) {
		width: 704px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}

	&__delete-button {
		margin-top: 32px;
		margin-left: 16px;
	}

	&__add-button {
		margin-bottom: 40px;
	}
}