@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.editable-check-items {
	&__header {
		display: flex;
		align-items: center;

		&-actions {
			margin-left: auto;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
	}

	&__sub-items {
		margin-top: 21px;
	}

	&__item {
		&-dnd {
			&-handle {
				margin-top: 13px;

				@media screen and (max-width: @tablet-max) {
					margin-top: 10px;
				}
			}
			&-without-handle {
				margin-left: 32px;
			}
		}

		display: flex;
		justify-content: space-between;
		width: 100%;

		&-index {
			font-weight: 600;
			font-size: 16px;
			color: @black60;
			line-height: 27px;
			padding: 13px 0px;
			margin: 0px;

			@media screen and (max-width: @tablet-max) {
				font-size: 14px;
				padding-top: 8px;
				margin: 0px;
			}
		}

		&-input {
			width: 100%;
			position: relative;
			z-index: 1;
			margin-left: 12px;

			&__view-wrap {
				width: 100%;
				margin: 1px;
				margin-right: 0px;
			}

			&__element {
				font-size: 14px;
				color: @black4;
				white-space: pre;
				overflow: hidden;
				text-wrap: balance;
			}
		}

		&-button {
			display: inline-block;
		}
		&-buttons {
			margin-top: 4px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			vertical-align: center;
			vertical-align: center;
		}

		&-options {
			padding-left: 24px;

			@media screen and (max-width: @tablet-max) {
				min-width: auto;
			}

			&-view {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 8px;
				margin: 4px 0px;

				&-delete,
				&-add {
					z-index: 2;
				}

				@media (hover: hover) {
					opacity: 0;

					.editable-check-items__item:hover & {
						opacity: 1;
					}
				}

				@media (max-width: @tablet-max) {
					opacity: 1;
					justify-content: center;
				}
			}
		}
		&-actions {
			padding-left: 24px;

			@media screen and (max-width: @tablet-max) {
				min-width: auto;
			}

			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			margin: 4px 0px;

			@media (hover: hover) {
				opacity: 0;

				.editable-check-items__item:hover & {
					opacity: 1;
				}
			}
		}

		&_disabled {
			opacity: 0.4;
		}

		&_archived .editable-check-items__item-index,
		&_archived .editable-check-items__item-input {
			opacity: 0.4;
		}

		&_disabled_visible {
			opacity: 1;
		}
	}

	&__preview {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
		align-items: center;

		&-icon {
			color: @black60;
			opacity: 0.5;
		}

		&-count {
			.d-semibold-18();
			color: @black60;
			background-color: @buttonFilterActive;
			border-radius: 6px;
			padding: 0px 8px;
		}

		&-text {
			width: 100%;
			margin: 1px;
			color: @black100;
			background-color: transparent;
			border: none;
		}
	}

	&__item-editing-dialog {
		@media screen and (min-width: @desktop-min) {
			width: 800px;
		}
		&-input {
			padding-bottom: 20px;
		}
	}

	&__add-button{
		margin-top: 16px;
		width: fit-content;
	}
}