.tz-choice-circle-24 {
	font-size: 24px;
}

.tz-toast-info-32 {
	font-size: 32px;
}

.tz-toast-warning-32 {
	font-size: 32px;
}

.tz-toast-error-32 {
	font-size: 32px;
}

.tz-toast-success-32 {
	font-size: 32px;
}

.tz-logo-latin,
.tz-logo-cyrillic {
	font-size: 36px;
}

.tz-logo {
	font-size: 40px;
}

.tz-cloud {
	font-size: 32px;
}

.tz-cloud-caching-active {
	font-size: 32px;
}

.tz-cloud-caching-done {
	font-size: 32px;
}

.tz-cloud-caching-error {
	font-size: 32px;
}

.tz-cloud-sync {
	font-size: 32px;
}

.tz-long-arrow-24 {
	font-size: 24px;
}

.tz-arrow-16 {
	font-size: 16px;
}

.tz-arrow-20 {
	font-size: 20px;
}

.tz-arrow-24 {
	font-size: 24px;
}

.tz-long-arrow {
	font-size: 0.71em;
}

.tz-simple-arrow-16 {
	font-size: 16px;
}

.tz-simple-arrow-20 {
	font-size: 20px;
}

.tz-simple-arrow-24 {
	font-size: 24px;
}

.tz-simple-arrow-28 {
	font-size: 28px;
}

.tz-expand-arrow-heavy {
	font-size: 8px;
}

.tz-corner-arrow {
	font-size: 24px;
}

.tz-reload-20 {
	font-size: 20px;
}

.tz-reload-24 {
	font-size: 24px;
}

.tz-blocks {
	font-size: 0.85em;
}

.tz-list {
	font-size: 0.92em;
}

.tz-question {
	font-size: 1.06em;
}

.tz-status-20 {
	font-size: 20px;
}

.tz-calendar-20 {
	font-size: 20px;
}

.tz-calendar-24 {
	font-size: 24px;
}

.tz-calendar-done-20 {
	font-size: 20px;
}

.tz-category-20 {
	font-size: 20px;
}

.tz-category-manage-28 {
	font-size: 28px;
}

.tz-location-20 {
	font-size: 20px;
}

.tz-indicator-20 {
	font-size: 20px;
}

.tz-area-20 {
	font-size: 20px;
}

.tz-space-owner-20 {
	font-size: 20px;
}

.tz-description-20 {
	font-size: 20px;
}

.tz-external-link-16 {
	font-size: 16px;
}

.tz-external-link-20 {
	font-size: 20px;
}

.tz-photo-20 {
	font-size: 20px;
}

.tz-document-20 {
	font-size: 20px;
}

.tz-edit-16 {
	font-size: 16px;
}

.tz-edit-20 {
	font-size: 20px;
}

.tz-clock {
	font-size: 20px;
}

.tz-alarm-clock {
	font-size: 20px;
}

.tz-plan {
	font-size: 0.83em;
}

.tz-map {
	font-size: 1.17em;
}

.tz-menu {
	font-size: 24px;
}

.tz-more-16 {
	font-size: 16px;
}

.tz-more-24 {
	font-size: 24px;
}

.tz-edit {
	font-size: 24px;
}

.tz-delete {
	font-size: 24px;
	color: @red50;
}

.tz-delete-20 {
	font-size: 20px;
	color: @red50;
}

.tz-copy {
	font-size: 24px;
}

.tz-copy-20 {
	font-size: 20px;
}

.tz-user-20 {
	font-size: 20px;
}

.tz-user-24 {
	font-size: 24px;
}

.tz-users-24 {
	font-size: 24px;
}

.tz-add-user-24 {
	font-size: 24px;
}

.tz-camera-24 {
	font-size: 24px;
}

.tz-exit {
	font-size: 24px;
}

.tz-comment {
	font-size: 24px;
}

.tz-comment-88 {
	font-size: 88px;
}

.tz-plus-20 {
	font-size: 20px;
}

.tz-plus-24 {
	font-size: 24px;
}

.tz-minus {
	font-size: 24px;
}

.tz-attach-20 {
	font-size: 20px;
}

.tz-attach-24 {
	font-size: 24px;
}

.tz-send {
	font-size: 24px;
}

.tz-mark-16 {
	font-size: 16px;
}

.tz-mark-2-16 {
	font-size: 16px;
}

.tz-mark-24 {
	font-size: 24px;
}

.tz-close-16 {
	font-size: 16px;
}

.tz-close-20 {
	font-size: 20px;
}

.tz-close-24 {
	font-size: 24px;
}

.tz-table-sort-20 {
	font-size: 20px;
}

.tz-filters-24 {
	font-size: 24px;
}

.tz-export-20 {
	font-size: 20px;
}

.tz-export-24 {
	font-size: 24px;
}

.tz-bar-chart {
	font-size: 24px;
}

.tz-pie-chart {
	font-size: 24px;
}

.tz-line-chart {
	font-size: 24px;
}

.tz-company-20 {
	font-size: 20px;
}

.tz-company-28 {
	font-size: 28px;
}

.tz-object-24 {
	font-size: 24px;
}

.tz-object-28 {
	font-size: 28px;
}

.tz-internal-acceptance-24 {
	font-size: 24px;
}

.tz-internal-acceptance-28 {
	font-size: 28px;
}

.tz-check-24 {
	font-size: 24px;
}

.tz-check-28 {
	font-size: 28px;
}

.tz-check-40 {
	font-size: 40px;
}

.tz-presale-check-28 {
	font-size: 28px;
}

.tz-problem {
	font-size: 28px;
}

.tz-problem-16 {
	font-size: 16px;
}

.tz-problem-24 {
	font-size: 24px;
}

.tz-problem-28 {
	font-size: 28px;
}

.tz-problem-40 {
	font-size: 40px;
}

.tz-problem-waiting {
	font-size: 28px;
}

.tz-problem-waiting-40 {
	font-size: 40px;
}

.tz-problem-handling {
	font-size: 28px;
}

.tz-problem-handling-40 {
	font-size: 40px;
}

.tz-problem-verification {
	font-size: 28px;
}

.tz-problem-verification-40 {
	font-size: 40px;
}

.tz-problem-fixed {
	font-size: 28px;
}

.tz-problem-fixed-40 {
	font-size: 40px;
}

.tz-problem-expired {
	font-size: 28px;
}

.tz-problem-expired-40 {
	font-size: 40px;
}

.tz-problem-created {
	font-size: 28px;
}

.tz-problem-created-40 {
	font-size: 40px;
}

.tz-comment-40 {
	font-size: 40px;
}

.tz-inspection {
	font-size: 28px;
}

.tz-inspection-24 {
	font-size: 24px;
}

.tz-inspection-28 {
	font-size: 28px;
}

.tz-inspection-40 {
	font-size: 40px;
}

.tz-space {
	font-size: 28px;
}

.tz-space-16 {
	font-size: 16px;
}

.tz-space-24 {
	font-size: 24px;
}

.tz-space-40 {
	font-size: 40px;
}

.tz-space-status-manage-28 {
	font-size: 28px;
}

.tz-owner-acceptance-24 {
	font-size: 24px;
}

.tz-owner-acceptance-28 {
	font-size: 28px;
}

.tz-warranty-claim-24 {
	font-size: 24px;
}

.tz-warranty-claim-28 {
	font-size: 28px;
}

.tz-warranty-claim-40 {
	font-size: 40px;
}

.tz-statistics-28 {
	font-size: 28px;
}

.tz-user-28 {
	font-size: 28px;
}

.tz-role-28 {
	font-size: 28px;
}

.tz-export-template-manage-28 {
	font-size: 28px;
}

.tz-reg-standard-28 {
	font-size: 28px;
}

.tz-log-28 {
	font-size: 28px;
}

.tz-help {
	font-size: 28px;
}

.tz-docx {
	font-size: 32px;
	color: #1652b5;
}

.tz-xlsx {
	font-size: 32px;
	color: #007732;
}

.tz-pdf {
	font-size: 32px;
	color: #ff402f;
}

.tz-file {
	font-size: 32px;
}

.tz-circle-warning-32 {
	font-size: 32px;
}

.tz-search-20 {
	font-size: 20px;
}

.tz-info-20 {
	font-size: 20px;
}

.tz-info-24 {
	font-size: 24px;
}

.tz-settings-24 {
	font-size: 24px;
}

.tz-name-20 {
	font-size: 20px;
}

.tz-list-24 {
	font-size: 24px;
}

.tz-schema-24 {
	font-size: 24px;
}

.tz-delegate-20 {
	font-size: 20px;
}

.tz-delegate-24 {
	font-size: 24px;
}

.tz-history-24 {
	font-size: 24px;
}

.tz-users-16 {
	font-size: 16px;
}

.tz-critical-20 {
	font-size: 20px;
}

.tz-history-24 {
	font-size: 24px;
}

.tz-toast-info {
	font-size: 24px;
}

.tz-toast-success {
	font-size: 24px;
}

.tz-toast-warning {
	font-size: 24px;
}

.tz-fix-date {
	font-size: 18px;
}

.tz-attachment {
	font-size: 18px;
}

.tz-reason-field {
	font-size: 20px;
}

.tz-reason-external-link {
	font-size: 18px;
}

.tz-prescription-20 {
	font-size: 20px;
}

.tz-group-manage-28 {
	font-size: 28px;
}

.tz-check-list-manage-28 {
	font-size: 28px;
}

.tz-check-list-16 {
	font-size: 16px;
}

.tz-check-list-categories-20 {
	font-size: 20px;
}

.tz-space-type-20 {
	font-size: 20px;
}

.tz-space-type-manage-28 {
	font-size: 28px;
}

.tz-brush-20 {
	font-size: 20px;
}

.tz-brush-24 {
	font-size: 24px;
}

.tz-eraser-24 {
	font-size: 24px;
}

.tz-priority-highest-16 {
	font-size: 16px;
}

.tz-priority-high-16 {
	font-size: 16px;
}

.tz-priority-medium-16 {
	font-size: 16px;
}

.tz-data-transfer-20 {
	font-size: 20px;
}

.tz-data-transfer-error-20 {
	font-size: 20px;
}

.tz-offline-mode-20 {
	font-size: 20px;
}

.tz-mark-20 {
	font-size: 20px;
}

.tz-password-visible-20 {
	font-size: 20px;
}

.tz-password-hidden-20 {
	font-size: 20px;
}

.tz-mail-20 {
	font-size: 20px;
}

.tz-task-24 {
	font-size: 24px;
}

.tz-task-16 {
	font-size: 16px;
}

.tz-task-28 {
	font-size: 28px;
}

.tz-drag-and-drop-24 {
	font-size: 24px;
}

.tz-tag-manage-28 {
	font-size: 28px;
}

.tz-tag-20 {
	font-size: 20px;
}

.tz-inspection-16 {
	font-size: 16px;
}

.tz-warning-20 {
	font-size: 20px;
}

.tz-comment-on-plan-16 {
	font-size: 16px;
}

.tz-work-acceptance-28 {
	font-size: 28px;
}

.tz-work-acceptance-16 {
	font-size: 16px;
}

.tz-comment-on-plan-16 {
	font-size: 16px;
}

.tz-eye-20 {
	font-size: 20px;
}

.tz-outline-object-20 {
	font-size: 20px;
}

.tz-star-priority-20 {
	font-size: 20px;
}

.tz-task-type-20 {
	font-size: 20px;
}

.tz-comment-20 {
	font-size: 20px;
}

.tz-problem-20 {
	font-size: 20px;
}

.tz-task-20 {
	font-size: 20px;
}

.tz-percent-20 {
	font-size: 20px;
}

.tz-unit-20 {
	font-size: 20px;
}

.tz-notifications-export-28 {
	font-size: 28px;
}

.tz-notifications-send-28 {
	font-size: 28px;
}

.tz-file-type-pdf {
	font-size: 24px;
}

.tz-file-type-doc {
	font-size: 24px;
}

.tz-file-type-xls {
	font-size: 24px;
}

.tz-bank-account-20 {
	font-size: 20px;
}

.tz-customer-20 {
	font-size: 20px;
}

.tz-contract-16 {
	font-size: 16px;
}

.tz-contract-28 {
	font-size: 28px;
}

.tz-contractor-20 {
	font-size: 20px;
}

.tz-created-20 {
	font-size: 20px;
}

.tz-modified-20 {
	font-size: 20px;
}

.tz-legal-entity-20 {
	font-size: 20px;
}

.tz-number-20 {
	font-size: 20px;
}

.tz-price-20 {
	font-size: 20px;
}

.tz-stage-20 {
	font-size: 20px;
}
.tz-document-format-20 {
	font-size: 20px;
}

.tz-legal-entity-28 {
	font-size: 28px;
}

.tz-phone-20 {
	font-size: 20px;
}

.tz-classifier-20 {
	font-size: 20px;
}

.tz-address-20 {
	font-size: 20px;
}

.tz-bank-account-28 {
	font-size: 28px;
}

.tz-link-20 {
	font-size: 20px;
}

.tz-document-16 {
	font-size: 16px;
}

.tz-attach-16 {
	font-size: 16px;
}

.tz-tile-24 {
	font-size: 24px;
}

.tz-inspection-add-24 {
	font-size: 24px;
}

.tz-problem-add-24 {
	font-size: 24px;
}

.tz-task-add-24 {
	font-size: 24px;
}

.tz-inspector-20 {
	font-size: 20px;
}

.tz-custom-rhombus-20 {
	font-size: 20px;
}

.tz-custom-socket-20 {
	font-size: 20px;
}

.tz-custom-lamp-20 {
	font-size: 20px;
}

.tz-custom-shovel-20 {
	font-size: 20px;
}

.tz-custom-exclamation-point-20 {
	font-size: 20px;
}

.tz-custom-rectangles-20 {
	font-size: 20px;
}

.tz-custom-block-20 {
	font-size: 20px;
}

.tz-custom-snowflake-20 {
	font-size: 20px;
}

.tz-custom-snowflake-24 {
	font-size: 24px;
}

.tz-custom-shield-20 {
	font-size: 20px;
}

.tz-custom-triangle-20 {
	font-size: 20px;
}

.tz-custom-hexagon-20 {
	font-size: 20px;
}

.tz-custom-polygon-20 {
	font-size: 20px;
}

.tz-links-list-20 {
	font-size: 20px;
}

.tz-water-cold-20 {
	font-size: 20px;
}

.tz-water-hot-20 {
	font-size: 20px;
}

.tz-electricity-20 {
	font-size: 20px;
}

.tz-heat-20 {
	font-size: 20px;
}

.tz-gas-20 {
	font-size: 20px;
}

.tz-empty-table {
	font-size: 184px;
}

.tz-fields-28 {
	font-size: 28px;
}

.tz-air-conditioner-20 {
	font-size: 20px;
}

.tz-repeat-16 {
	font-size: 16px;
}

.tz-operational-control-process-16 {
	font-size: 16px;
}

.tz-warranty-service-process-16 {
	font-size: 16px;
}

.tz-units-handover-process-16 {
	font-size: 16px;
}

.tz-internal-acceptance-process-16 {
	font-size: 16px;
}

.tz-acceptance-control-process-16 {
	font-size: 16px;
}

.tz-choice-circle-24 {
	font-size: 24px;
}
.tz-export-template-20 {
	font-size: 20px;
}
.tz-added-20 {
	font-size: 20px;
}
.tz-document-type-20 {
	font-size: 20px;
}

.tz-user-owner-20 {
	font-size: 20px;
}

.tz-choose-20 {
	font-size: 20px;
}
