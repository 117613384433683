@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.work-acceptances-page {
	&__filters-bar {
		@media (max-width: @large-tablet-max) {
			margin-bottom: 12px;
			display: grid;
			grid-auto-columns: max-content;
			grid-auto-rows: max-content;
			grid-auto-flow: column;
			grid-gap: 0 12px;
		}
		@media (max-width: @large-mobile-max) {
			display: grid;
			grid-auto-columns: max-content;
			grid-auto-rows: max-content;
			grid-auto-flow: column;
			grid-gap: 0 12px;
		}
	}

	& .table2__row_clickable {
		&:hover .work-acceptances-page__d-table-menu {
			visibility: visible;
			opacity: 1;
			background-color: @buttonLightHover;
		}

		&:active .work-acceptances-page__d-table-menu {
			background-color: @buttonLightPressed;
		}
	}

	&__loading-panel {
		min-height: 100%;
	}

	&__header {
		margin-bottom: 18px;

		&-wrap {
			flex-wrap: wrap;
		}

		&-left {
			align-self: flex-start;
			margin-bottom: 10px;
		}

		&-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			flex: 1 1;
			margin-bottom: 10px;
		}
	}

	&__m-table {
		&-menu {
			position: absolute;
			top: 0;
			right: 0;
			flex-basis: auto !important;
			width: auto !important;
			padding-top: 4px;
			padding-bottom: 4px;
		}
		&-description {
			&__name {
				.d-regular-14();
			}

			&__category {
				color: @black60;
				.d-regular-14();
			}

			&__acceptance-date {
				margin-top: 8px;
				display: flex;
				flex-direction: row;
				gap: 4px;
				.d-regular-12();

				&__title {
					color: @black60;
				}
			}

			&-indicators {
				display: flex;
				flex-wrap: wrap;
				row-gap: 12px;
				margin-bottom: 16px;
			}
			&__indicators {
				display: flex;
				flex-wrap: wrap;
				row-gap: 8px;
				& > *:not(:last-child) {
					margin-right: 12px;
				}
				&:not(:empty) + &:not(:empty)::before {
					content: ' ';

					display: flex;
					align-self: center;

					width: 4px;
					height: 4px;
					margin: 14px;

					background: @grey30;
					border-radius: 50%;
				}

				&:first-child::before {
					display: none;
				}
			}
		}

		&__cell {
			&-object {
				margin-top: 4px;
				margin-bottom: -6px;
			}

			&-stats {
				margin-top: -4px;
			}

			&__info {
				display: flex;
				padding: 2px 6px 4px;

				&-select {
					padding-right: 18px;
				}

				&-description {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}
			}

			&-acceptors {
				margin-top: -4px;

				&-title {
					color: @black60;
					.d-regular-12();
				}

				&-header {
					.d-semibold-12();
				}

				&-user {
					.d-regular-12();
				}

				&-info {
					display: flex;
					flex-direction: row;
					gap: 8px;
				}

				&-company {
					color: @black60;
					.d-regular-12();
				}

				&-rest {
					color: @deepBlue;
					.d-semibold-12();
				}
			}

			&-number {
				.d-semibold-14();
			}

			&-rest {
				color: @deepBlue;
				.d-semibold-14();
			}

			&-check-lists {
				display: flex;
				flex-direction: row;
				gap: 8px;
				align-items: center;

				.check-list-cell {
					height: 26px;

					&__label {
						.d-regular-12();
					}
				}
			}

			&-header {
				.d-semibold-12();
				color: @black4;
			}

			&-scope {
				display: flex;
				flex-direction: column;
				gap: 8px;
				padding: 4px 0px;

				&__structures {
					.structure-item {
						&__selected_text {
							.d-semibold-12();
						}
					}

					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}
		}
	}

	&__d-table {
		&-created {
			z-index: 20;
			color: @black4;
			display: flex;
			flex-direction: column;

			&-date {
				overflow: hidden;
			}

			&-name {
				margin-top: 4px;
			}
		}

		&-menu {
			margin: 0;
			padding: 8px 28px 8px 8px;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: transparent;
			transition:
				background-color 0.2s,
				opacity 0.2s ease;
			visibility: hidden;
			opacity: 0;

			&-wrap {
				display: flex;
				justify-content: space-between;
			}
		}

		&-status {
			& .status-button {
				max-width: 100%;
			}
		}

		&__cell {
			&-header {
				.d-semibold-14();
				color: @black4;
			}

			&-rest {
				color: @deepBlue;
				.d-semibold-14();
			}
		}

		&-inspectors {
			&-user {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: @black85;
			}

			&-company {
				.d-regular-12();
				color: @black75;
			}

			&-rest {
				color: @deepBlue;
			}
		}

		&-description {
			&__number {
				.d-semibold-14();
				margin-bottom: 4px;
				color: @black100;
			}

			&__name {
				color: @black100;
				.d-regular-14();
				margin-bottom: 4px;
				width: 90%;
				height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&__category {
				.d-regular-14();
				color: @black60;
				margin-bottom: 4px;
			}

			&__type {
				.d-regular-14();
				color: @black60;
				margin-bottom: 4px;
			}

			&__front-type {
				.d-regular-14();
				color: @black60;
				margin-bottom: 4px;
			}

			&__progress {
				.d-regular-14();
				color: @black100;
				margin-bottom: 8px;
			}

			&-indicators {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				row-gap: 12px;
				column-gap: 12px;
				margin-bottom: 16px;
			}

			&__indicators {
				display: flex;
				flex-wrap: wrap;
				column-gap: 12px;
				row-gap: 8px;
			}
			&-indicators {
				display: flex;
				flex-wrap: wrap;
				row-gap: 12px;
				margin-bottom: 16px;
			}
		}

		&-scope {
			&__object-name {
				padding-bottom: 8px;
			}
		}

		&-acceptanceDate {
			display: flex;
			flex-direction: column;
			gap: 4px;
			margin-bottom: 4px;
			width: auto;
			padding-right: 8px;
			.d-regular-14();
			color: @black100;

			&-display {
				width: max-content;
			}
		}
	}

	&__search {
		margin: 0 12px 12px 0;

		@media (max-width: @desktop-min) {
			margin: 0;
		}
	}
}
