@import (reference) '~@tehzor/ui-components/src/styles/variables';

.editing-task {
	&__container {
		width: 70vw;

		@media (max-width: @desktop-min) {
			width: 100%;
		}
	}
}