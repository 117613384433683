@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/typography';

.check-list-info {
	&:not(:last-child) {
		margin-bottom: 31px;
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		&-name {
			.d-semibold-16();
		}
		
		&-arrow {
			padding: 0px 10px;
			margin: 0px 10px;

			&-icon {
				transition: transform 200ms;
				transform: rotate(0deg);

				&--reversed {
					transform: rotate(180deg);
				}
			}
		}
	}

	&__status,
	&__item-info__status {
		.d-regular-12();
		color: @black4;
		min-width: max-content;
	}

	&__statuses {
		display: flex;
		gap: 12px;
		
		&-problems {
			display: flex;
			align-items: center;

			&__number {
				.d-semibold-12();
				margin-left: 6px;
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		padding-top: 8px;
	
		&:not(:last-child) {
			border-bottom: 1px solid @grey10;
			padding-bottom: 8px;
		}

		&-index {
			margin-right: 8px;
			font-family: 'Open Sans', sans-serif;
			font-style: normal;
			.d-semibold-14();
			color: @black4;
		}

		&-info {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__name {
				.d-regular-14();
			}

			&__status {
				padding: 4px 8px;
			}
		}
	}
}