@import (reference) '../../../../../styles/colors';
@import (reference) '../../../../../styles/typography';
@import (reference) '../../../../../styles/shadows';

.entity-btn {
	border-radius: 8px;
	padding: 16px;
	max-width: fit-content;

	.shadow1();

	&.withoutShadow {
		box-shadow: none;
		padding: 8px 8px 8px 0px;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		gap: 16px;
		user-select: none;
		cursor: pointer;
		align-items: flex-start;

		&.withoutShadow {
			justify-content: flex-start;
		}

		&.disable {
			cursor: auto;
		}

		&-title {
			.d-semibold-14();
			color: @deepBlue;

			.disable & {
				color: @black100;
			}

			.withoutShadow.disable & {
				color: @black75;
			}

			.withoutShadow & {
				color: @blue70;
			}
			.withoutShadow:hover & {
				color: @blue90;
			}
			.withoutShadow:active & {
				color: @blue50;
			}
		}

		&-edit-icon {
			padding: 4px;
			color: @buttonAccent;
			transition: 200ms background-color;
			border-radius: 8px;

			.withoutShadow:hover > & {
				background-color: @buttonHover;
			}
			.withoutShadow:active > & {
				background-color: @buttonPressed;
			}

			.disable > & {
				display: none;
				opacity: 0;
			}
		}
	}
}
