@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.editable-space-statuses-set {
	&__item {
		padding-left: 0px;

		&-header {
			.d-regular-14();
		}
	}

	&__warning {
		padding-top: 12px;
		display: flex;
		align-items: center;
		gap: 8px;

		@media (min-width: @large-tablet-min) {
			padding-top: 8px;
		}

		&-icon {
			font-size: 16px;
			color: @red60;
		}
		&-text {
			.d-regular-12();
			color: @black75;
		}
	}

	&__buttons {
		gap: 16px;

		@media (min-width: @large-tablet-min) {
			gap: 8px;
		}

		&-save, &-cancel {
			max-width: 125px;
		}
	}

	&__icon {
		color: @grey;
	}

	&__fields {
		&-company, &-objects {
			&__dialog {
				.select-search {
					padding-bottom: 16px;
				}
			}
		}

		&-default {
			&__name, &__stages {
				&-title {
					@media (min-width: @large-tablet-min) {
						padding-bottom: 10px;
					}
					padding-bottom: 8px;

					.d-regular-14();
					color: @black85;
				}
				&-input {
					min-width: 280px;
				}
			}

			&__color {
				&-picker {
					width: 48px;
					height: 48px;
					margin-top: 30px;
				}
			}

			&__content {
				&-name {
					padding-bottom: 16px;
				}
				&-values {
					display: flex;
					align-items: start;
					gap: 16px;
				}
			}
		}
	}
}