.plan-image {
	margin-top: 20px;

	&__btn {
		margin-top: 20px;
		
		&__custom {
			display: block !important;
			margin-top: 20px;
			border: none !important; 
			border-radius: 8px !important; 
		}
	}
}
