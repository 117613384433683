@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.d-problem-info-cell {
	&__problem {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&_with-padding {
			padding-left: 56px;
		}
	}

	&__info {
		display: flex;
		align-items: flex-start;
		flex: 1 1;
		margin-left: 26px;
		overflow: hidden;

		&-text {
			flex: 1 1;
			margin-right: 8px;
			overflow: hidden;
		}
	}

	&__desc {
		margin-bottom: 12px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__reply {
		margin: 24px 0 0 146px;

		.app-content_padding_288 & {
			@media (min-width: @desktop-min) and (max-width: 1499px) {
				margin-left: 100px;
			}
		}
	}
}