@import (reference) '../../../../styles/colors';
@import (reference) '../../../../styles/variables';

.base-status-select {
	&__scrollbar {
		min-width: 280px;

		@media (max-height: 479px) {
			max-height: 280px;
		}
		@media (min-height: 480px) {
			max-height: 480px;
		}
	}

	&__btn {
		align-self: flex-start;
	}

	&__menu-item.current {
		background-color: @buttonLightSelected;
	}
}