@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

.m-bottom-menu {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	height: 56px;
	padding: 2px 0;
	background-color: @white;
	box-shadow: @shadow1;
	transition: transform 0.2s ease;
	will-change: transform;

	&_hidden {
		transform: translate(0, 100%);
		box-shadow: none;
	}

	&_count {
		&_2, &_3, &_4, &_5 {
			display: grid;
			justify-content: center;
		}

		&_2 {
			grid-template-columns: repeat(2, minmax(80px, 128px));
		}

		&_3 {
			grid-template-columns: repeat(3, minmax(80px, 128px));
		}

		&_4 {
			grid-template-columns: repeat(4, minmax(80px, 128px));
		}

		&_5 {
			grid-template-columns: repeat(5, minmax(64px, 128px));
		}
	}
}