@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.editable-comment {
	&__text-block {
		display: flex;
		align-items: flex-end;

		@media (max-width: @tablet-max) {
			margin-left: -16px;
			margin-right: -16px;
			padding-right: 8px;
		}
	}

	&__avatar {
		flex: none;
		margin-right: 16px;
	}

	&__input-cont {
		position: relative;
		flex: 1 1;

		@media (max-width: @tablet-max) {
			margin-right: 10px;
		}

		@media (min-width: @large-tablet-min) {
			margin-right: 8px;
		}
	}

	&__input {
		@media (max-width: @tablet-max) {
			margin-left: 56px;
			margin-bottom: 1px;
		}

		&-textarea {
			@media (min-width: @large-tablet-min) {
				padding-right: 48px;
			}
		}

		&-placeholder {
			@media (min-width: @large-tablet-min) {
				right: 48px;
			}
		}
	}

	&__attach-btn {
		position: absolute;
		bottom: 0;

		@media (max-width: @tablet-max) {
			left: 4px;
		}

		@media (min-width: @large-tablet-min) {
			right: 8px;
		}
	}

	&__send-btn {
		flex: none;
	}

	&__attachments {
		margin-top: 16px;

		@media (min-width: 1400px) {
			margin-left: 56px;
			margin-right: 48px;
		}

		@media (max-width: @tablet-max) {
			margin-bottom: 12px;
		}

		&-scroll-area {
			max-height: 100px;
			padding: 8px 16px 0 0;

			@media (min-height: @large-tablet-min-height) {
				max-height: 150px;
			}

			@media (min-height: @desktop-min-height) {
				max-height: 200px;
			}

			@media (min-height: @large-desktop-min-height) {
				max-height: 250px;
			}
		}
	}

	&__empty-fields-msg {
		margin: 40px 0;
		text-align: center;
		.d-semibold-14();
		color: @grey;
	}
}