@import (reference) '../../../styles/colors';

.button-container {
	display: flex;
	height: fit-content;
	width: fit-content;
	align-items: center;

	&__element {
		display: flex;
	}

	&__separator {
		width: 1px;
		margin-top: 8px;
		margin-bottom: 8px;
		background-color: @gray8;
		border-radius: 2px;
	}
}
