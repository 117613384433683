.history-attachments-change {

	&__images{
		display: flex;
		flex-wrap: wrap;
		margin: -6px -6px;
		width: fit-content;
	}
	&__image {
		width: 52px;
		height: 52px;
		margin: 6px;
	}

	&__files{
		margin:-6px;
		padding-top: 12px;
	}
	&__file-wrap {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__file {
		max-width: 100%;
	}
}

