@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/variables.less';
@import (reference) '../../styles/typography.less';

.entity-indicators {
	display: flex;
	align-items: center;
	white-space: pre;
	color: @grayBlue2;
	@media (max-width: @large-mobile-max) {
		padding: 0 16px;
	}

	&__title {
		.d-regular-14();
		@media (max-width: @large-mobile-max) {
			.d-regular-12();
		}
	}
	&__loader-container {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 40px;
		max-height: 40px;
		padding: 5px;
		margin-left: 10px;
	}
	&__circle-loader {
		width: 19px;
		height: 19px;
	}
}
