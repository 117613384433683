@import (reference) "../../../../styles/colors";
@import (reference) "../../../../styles/variables";
@import (reference) "../../../../styles/shadows";
@import (reference) "../../../../fonts/tz-icons/variables";

.toggle-icon {
	position: relative;
	width: 32px;
	height: 12px;
	margin: 6px 4px;
	background-color: @black20;
	border-radius: 40px;
	transition: background-color 0.15s ease;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: -3px;
		left: 0;
		width: 18px;
		height: 18px;
		background-color: @white;
		border: 1px solid @white;
		border-radius: 50%;
		box-shadow: @shadow1;
		transition: left 0.22s ease, background-color 0.15s ease, border-color 0.15s ease;
	}

	&_checked::after {
		left: 14px;
	}

	&:not(&_disabled)&_checked {
		background-color: @iconButtonHover;

		&::after {
			background-color: @buttonAccent;
			border-color: @buttonAccent;
		}
	}

	// Наведение при неотмеченном
	.desktop .switch:hover &:not(&_disabled)&_unchecked {
		background-color: @iconButtonHover;

		&::after {
			border-color: @white;
		}
	}

	// Нажатие при неотмеченном
	.switch:active &:not(&_disabled)&_unchecked {
		background-color: @iconButtonPressed !important;
	}

	// Наведение при отмеченном
	.desktop .switch:hover &:not(&_disabled)&_checked::after {
		background-color: @buttonAccentHover;
		border-color: @buttonAccentHover;
	}

	// Нажатие при отмеченном
	.switch:active &:not(&_disabled)&_checked::after {
		background-color: @buttonAccentPressed !important;
		border-color: @buttonAccentPressed !important;
	}

	// Фокус
	.switch:focus-visible &:not(&_disabled)&_unchecked::after {
		border-color: @black35;
	}

	.switch:focus-visible &:not(&_disabled)&_checked::after {
		border-color: #0E72E3;
	}

	.switch:focus-visible &_disabled::after {
		border-color: @black35;
	}

	// Отключенный
	&_disabled {
		background-color: @black10;

		&::after {
			box-shadow: @shadow2;
		}
	}
}
