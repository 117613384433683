@black: #121315;
@black05: #F3F4F5;
@black20: #D1D4D9;
@white: #FFFFFF;
@gray2: #b9b9b9;
@gray3: #f0f0f0;
@gray4: rgba(18, 19, 21, 0.702);

.transition (@property: all, @duration: 400ms, @delay: 0ms) {
	transition: @property @duration cubic-bezier(0.23, 1, 0.32, 1) @delay;
}

.clearfix {
	&:before, &:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}
