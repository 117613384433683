@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.coloredCheckbox(@color) {
	.switch:not(.switch_disabled)& {
		.desktop &:hover {
			color: lighten(@color, 5%);
		}

		&:active {
			color: darken(@color, 5%) !important;
		}
	}
}

.coloredCheckboxIcon(@color) {
	border-color: @color;

	.checkbox-icon_checked:not(.checkbox-icon_semi-checked)& {
		border-color: @color;
	}

	.checkbox-icon__check-icon-bg {
		background-color: @color;
	}

	.desktop .switch:not(.switch_disabled):hover &:not(.checkbox-icon_checked) {
		border-color: lighten(@color, 10%);
	}

	.switch:not(.switch_disabled):active &:not(.checkbox-icon_checked) {
		border-color: darken(@color, 5%) !important;
	}
}

.stats-display-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -25px;
	padding: 8px 24px 40px 32px;
	background-color: @white;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;

	&__problems-statuses-filter {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex: 1 1 100%;
		margin-right: 48px;
		padding: 7px 0;
		border-bottom: 1px solid rgba(@black4, 0.05);
	}

	&__problem-status-cbx {
		margin: 3px 30px 3px 0;
		color: rgba(@black4, 0.45);

		.switch_checked& {
			color: rgba(@black4, 0.85);
		}

		&_waiting {
			.coloredCheckbox(@statusWaiting);
		}

		&_handling {
			.coloredCheckbox(@statusHandling);
		}

		&_verification {
			.coloredCheckbox(@statusVerification);
		}

		&_fixed {
			.coloredCheckbox(@statusFixed);
		}

		&_expired {
			.coloredCheckbox(@statusExpired);
		}

		&-icon {
			&_waiting {
				.coloredCheckboxIcon(@statusWaiting);
			}

			&_handling {
				.coloredCheckboxIcon(@statusHandling);
			}

			&_verification {
				.coloredCheckboxIcon(@statusVerification);
			}

			&_fixed {
				.coloredCheckboxIcon(@statusFixed);
			}

			&_expired {
				.coloredCheckboxIcon(@statusExpired);
			}
		}

		&-label {
			font-weight: 600;
		}
	}

	&__reset-btn {
		margin: 3px 0 3px 10px;
	}

	&__chart-type-menu {
		flex: none;
		display: block;
	}
}