@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.d-presale-check-info-cell {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&__info {
		flex: 1 1;
		margin-left: 26px;
		overflow: hidden;
	}
}