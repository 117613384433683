@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px 0;
}

.info {
	padding: 24px 0 0 0;
	display: flex;
	flex-direction: column;
	gap: 24px 0;
	border-top: 1px solid @black10;
}

.infoItem {
	padding: 0;
}

.infoItemContent {
	padding: 0;
}