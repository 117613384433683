@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.notifications-icon {
	width: 32px;
	height: 32px;

	& path {
		fill: currentColor;
	}

	&__counter {
		font-family: 'Open Sans', sans-serif;
		fill: @white;
	}
}
