@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.inline-button-menu {
	&__button {
		.button__wrap&-wrap {
			justify-content: space-between;
		}

		&-icon {
			transition: transform 0.2s ease;

			&_right {
				margin-left: 8px;
			}

			&_open {
				transform: rotate(180deg);
			}
		}
	}
}
