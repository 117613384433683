@import (reference) "~@tehzor/ui-components/src/styles/variables";

.fix-date-view-field {
	&__value {
		@media (min-width: @large-tablet-min) {
			font-weight: 600;
		}
	}

	&__tag {
		margin-top: 8px;
	}
}