@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';

.tab-button {
	display: inline-block;
	height: 34px;
	padding: 0 14px;
	border-radius: 8px;
	transition: background-color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;

	button& {
		background-color: @buttonLight;
		border: 1px solid @black10;

		&:not(&_active):not(&_disabled) {
			&:hover {
				background-color: @buttonLightHover;
				border-color: @iconButtonHover;
			}

			.desktop &:active, .mobile &:active {
				background-color: @buttonLightPressed;
				border-color: @iconButtonPressed;
			}
		}
	}

	button&_active {
		background-color: @iconButtonHover;
		border-color: @iconButtonHover;
		cursor: default;
	}

	&_disabled {
		background-color: @buttonLight;
		border-color: @black05;
		color: @black35;
	}

	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: 100%;
	}

	&__label {
		max-width: 100%;
		.d-semibold-14();
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&-title {
			color: @black85;

			&:not(:last-child) {
				padding-right: 10px;
			}
		}

		&-count {
			color: @black45;
		}
	}

	&_active &__label {
		&-title {
			color: @black100;
		}

		&-count {
			color: @deepBlue;
		}
	}

	&_disabled &__label {
		&-title {
			color: @black35;
		}

		&-count {
			color: @black20;
		}
	}
}
