@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';

.expandable-plate {
	overflow: hidden;

	.plate__content&__plate-content {
		padding: 0;
	}

	&__content {
		padding: 8px 16px 16px;

		@media (min-width: @tablet-min) {
			padding: 16px;
		}

		@media (min-width: @large-tablet-min) {
			padding: 24px;
		}
	}
}
