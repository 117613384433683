@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.tag-placeholder {
	background-color: @buttonPressed;
	border-radius: 8px;
	animation: placeholder-animation 2s linear infinite;

	&_small {
		height: 24px;
		padding: 0 10px;
	}

	&_medium {
		height: 28px;
		padding: 0 12px;
	}

	&_large {
		height: 32px;
		padding: 0 12px;
	}
}