@import "../../../styles/colors.less";

.time-select {
	font-family: 'Open Sans', sans-serif;
	color: @black100;

	&-hours {
		&-select {
			background-color: @buttonLight;
			border: 1px solid @grey60;
			border-radius: 8px 0 0 8px;
			padding: 8px;
			height: 40px;
			width: 60px;

			&:hover {
				background-color: @buttonHover;
			}
		}

		&-option {
			background-color: @buttonHover;
		}
	}

	&-minutes {
		&-select {
			background-color: @buttonLight;
			border: 1px solid @grey60;
			border-radius: 0 8px 8px 0;
			padding: 8px;
			height: 40px;
			width: 60px;
		}

		&-option {
			background-color: @buttonHover;
		}
	}
}
