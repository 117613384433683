@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.draggable-menu {
	position: relative;
	line-height: 100%;

	&__items {
		position: relative;
		z-index: 1;
		white-space: nowrap;
		line-height: 100%;
	}

	&__item {
		display: inline-block;
		flex: none;
	}

	&__fake-item {
		position: absolute;
		will-change: left, top, width, height;
	}
}