@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/shadows";

.plate {
	background-color: @white;
	.shadow4();
	overflow: hidden;

	@media (min-width: @tablet-min) {
		border-radius: 12px;
	}

	@media (min-width: @desktop-min) {
		border-radius: 24px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;

		@media (min-width: @desktop-min) {
			padding: 24px;
		}
	}

	&__title {
		.d-bold-16();
		color: @black95;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&__content {
		padding: 16px;

		@media (min-width: @desktop-min) {
			padding: 24px;
		}

		&_no-padding {
			padding: 0;
		}
	}
}