@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';

.wrapper {
	border-bottom: 1px solid @black10;
}

.header {
	padding: 28px;
	border-bottom: 1px solid @black10;

	@media (max-width: @large-mobile-max) {
		padding: 16px;
	}
}

.title {
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	color: @black100;
}

.container {
	display: grid;
	grid-template-columns: 300px 1fr;
	grid-template-rows: 1fr;

	@media (max-width: @large-mobile-max) {
		padding: 0 16px;
		grid-template-columns: 1fr;
	}
}