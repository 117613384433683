@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.calendar {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&__nav {
		flex: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;

		&-arrow-btn {
			min-width: 32px;
			min-height: 32px;
			padding: 0;
			color: @grey;

			.desktop &.icon-button:hover {
				color: @buttonAccentHover;
			}

			&.icon-button:active {
				color: @buttonAccentPressed !important;
			}
		}

		&-prev-icon {
			display: block;
			transform: rotate(90deg);
		}

		&-next-icon {
			display: block;
			transform: rotate(-90deg);
		}

		&-link {
			margin: 0 2px;
			color: @black4;

			&-label {
				@media (max-width: @tablet-max) {
					.tm-semibold-17();
				}

				@media (min-width: @large-tablet-min) {
					.d-semibold-18();
				}
			}
		}

		&-decades-label {
			cursor: default;
		}
	}

	&__week-names {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		border-bottom: 1px solid rgba(@black4, 0.1);
	}

	&__week-name {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 1 48px;
		width: 48px;
		height: 30px;
		color: rgba(@black4, 0.75);
		text-transform: lowercase;
		user-select: none;

		@media (max-width: @tablet-max) {
			.tm-regular-13();
		}

		@media (min-width: @large-tablet-min) {
			.d-regular-12();
		}
	}

	&__days-grid {
		flex: 1 1 100%;
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 8px;
		align-content: space-evenly;
	}

	&__week-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__day {
		flex: 0 1 48px;
		width: 48px;
		height: 48px;

		&_today {
			color: @deepBlue;
		}
	}

	&__months-grid {
		flex: 1 1 100%;
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 96px));
		grid-row-gap: 8px;
		grid-column-gap: 24px;
		justify-content: space-between;
		align-content: space-evenly;
	}

	&__month {
		height: 40px;
		text-transform: capitalize;
	}

	&__years-grid {
		flex: 1 1 100%;
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 96px));
		grid-row-gap: 8px;
		grid-column-gap: 24px;
		justify-content: space-between;
		align-content: space-evenly;
	}

	&__year {
		height: 40px;
	}

	&__decades-grid {
		flex: 1 1 100%;
		display: grid;
		grid-template-columns: repeat(3, minmax(min-content, 96px));
		grid-row-gap: 8px;
		grid-column-gap: 24px;
		justify-content: space-between;
		align-content: space-evenly;
	}

	&__decade {
		height: 40px;
	}
}