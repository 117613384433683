@import (reference) '~@tehzor/ui-components/src/styles/variables';

.containerPagination {
	padding: 0 32px;

	@media (max-width: @tablet-min) {
		padding: 0 16px;
	}
}

.containerPageSize {

	@media (max-width: @tablet-min) {
		padding: 0 16px;
	}
}