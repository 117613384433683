@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) '~@tehzor/ui-components/src/styles/colors';


.space-tree-select {

	&_locations{
		
		border-bottom: 1px solid @black10;
		border-top: 1px solid @black10;
		padding: 20px 0;
		margin-top: 20px;

		
		&_label {
			font-weight: 600;
		}

		&-select-area {
			grid-area: front-location-select-area;
		}

	}
	
	

	&_label {
		font-weight: 600;
	}

	&_front-select-area {
		grid-area: front-select-area;
	}

	&__info-grid {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 25px;
		position: relative;

		@media (min-width: @tablet-min) {
			grid-template-columns: calc(50% - 20px) calc(50% - 20px);
			grid-column-gap: 40px;
		}
	}

}
