@import (reference) "~@tehzor/ui-components/src/styles/colors";

.wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 40px;
	background-color: rgb(250, 215, 215);
}

.container {
	display: grid;
	grid-auto-columns: max-content;
	grid-template-rows: 1fr;
	grid-auto-flow: column;
	grid-gap: 0 48px;
}

.mobilePagination {
	border-bottom: 1px solid @black10;
}

.boardsWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	max-width: 100%;
	padding: 0 20px;
	grid-row-gap: 80px;

	> * {

		&:nth-child(3n + 1),
		&:nth-child(3n + 2) {
			border-right: 1px solid @black10;
		}
	}
}

.boardsWrapperTwoColumns {
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, 1fr));
	max-width: 100%;
	padding: 0 20px;
	grid-row-gap: 80px;

	> * {

		&:first-child {
			border-right: 1px solid @black10;
		}
	}
}