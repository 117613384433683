@import (reference) '~@tehzor/ui-components/src/styles/variables';


.app-breadcrumbs {
	overflow-y: hidden;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 24px;

	.hide-scrollbar {
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
			display: none;
		}
	}

	@media (max-width: @large-tablet-max) {
		padding-bottom: 19px;
		padding-top: 19px;
		flex-wrap: nowrap;
		-ms-overflow-style: none;
		scrollbar-width: none;
		.hide-scrollbar();
		&__links {
			overflow-x: scroll;
			display: flex;
			flex-shrink: 0;
			flex-wrap: nowrap;
			-ms-overflow-style: none;
			scrollbar-width: none;
			.hide-scrollbar();
		}
	}

	&__controls {
		display: flex;
		align-items: center;
		margin-left: 24px;

		& > *:not(:last-child) {
			margin-right: 16px;
		}
	}
}