@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/shadows';

.mobile-filter-button {
	position: relative;
	max-width: 344px;
	height: 32px;
	padding: 0 12px;
	border-radius: 6px;
	transition: background-color 0.2s ease, color 0.2s ease;

	button&_active {
		background-color: @buttonAccent;
		color: @white;

		.desktop &:hover {
			background-color: @buttonAccentHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonAccentPressed;
		}
	}

	button&_partial_active {
		outline: 1px solid @buttonAccent;
		background-color: @buttonLightSelected;

		.desktop &:hover {
			background-color: @buttonAccentHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonAccentPressed;
		}
	}

	button&_inactive {
		background-color: @buttonLightSelected;
		color: @black100;

		.desktop &:hover {
			background-color: @buttonLightHover;
		}

		.desktop &:active, .mobile &:active {
			background-color: @buttonLightPressed;
		}
	}

	&__label {
		.tm-regular-13();
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__icon {
		position: absolute;
		top: -4px;
		right: -4px;
		width: 16px;
		height: 16px;
		background-color: @buttonLightSelected;
		border-radius: 50%;
		color: @buttonAccent;
		.shadow3();
	}

	&__close-icon {
		display: block;
		position: absolute;
		top: 2px;
		left: 2px;
		font-size: 12px;
	}
}