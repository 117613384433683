@import (reference) "~@tehzor/ui-components/src/styles/variables";

.editable-inspection-dialog {
	@media (min-width: @tablet-min) {
		width: 100%;
	}

	@media (min-width: @large-desktop-min) {
		width: 1300px;
	}

	@media (min-width: @large-tablet-min) and (max-height: 799px) {
		height: 100%;
	}
}