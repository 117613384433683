@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.login-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 24px 0;
	background: @white2;

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&__logo {
		margin-bottom: 60px;
	}

	&__container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-x: hidden;
		overflow-y: visible;

		@media screen and (max-width: @large-mobile-min) {
			align-items: stretch;
		}

		&-auth-form {
			position: relative;
			z-index: 2;
		}

		&-background {
			z-index: 1;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			&__left, &__right {
				background: @white2;
				background-repeat: repeat-x;
				background-image: url('./assets/background.svg');

				height: 100%;
				width: 100%;

				position: absolute;
			}

			&__left {
				bottom: 15%;
				background-position: left bottom;
			}
			&__right {
				bottom: 5%;
				background-position: right bottom;
			}

			@media (min-width: @large-mobile-min) and (max-width: (@desktop-min - 1)) {
				&__left {
					background-position: left center;
					bottom: 5%;
				}
				&__right {
					background-position: center center;
					bottom: 0%;
				}
			}

			@media screen and (max-width: @large-mobile-min) {
				display: none;
			}
		}
	}

	&-appear {
		opacity: 0;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		animation-timing-function: ease;
		animation-play-state: paused;
	}

	&-appear&-appear-active {
		animation-name: login-page-fade-in;
		animation-play-state: running;
	}

	&.error {
		width: 100%;
		min-height: 80px;
	}
}

@keyframes login-page-fade-in {
	0% {
		opacity: 0;
		transform: scale3d(0.9, 0.9, 0.9);
	}
	100% {
		opacity: 1;
	}
}
