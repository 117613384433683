@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.documents-dialog {
	&__body {
		padding-right: 10px;
	}

	&__content {
		width: 512px;

		@media(max-width: @tablet-min) {
			width: 100%;
		}
	}

	&__title {
		display: flex;
		gap: 8px;

		&--counter {
			color: @black35;
		}
	}
}