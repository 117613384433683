@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';

.check-list-popup-content {
	width: 490px;
	max-height: 340px;
	overflow-y: auto;
	padding: 12px 16px 16px 16px;

	&__header {
		margin-bottom: 15px;
	}

	&__checkList {
		&-name {
			font-family: 'Open Sans', sans-serif;
			font-style: normal;
			.d-regular-14();
			color: @black4;		
		}

		&-number {
			font-family: 'Open Sans', sans-serif;
			font-style: normal;
			.d-regular-12();
			color: @black60;
		}
	}

	&__space-name {
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		.d-semibold-14();
		color: @black4;	
	}
}