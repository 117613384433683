@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.statistics-page {
	padding-top: 20px;
	padding-bottom: 32px;

	&__loading-panel {
		min-height: 100%;
	}

	&__filters-tags {
		margin-top: 8px;

		&-wrap {
			display: flex;
			flex-wrap: wrap;
			margin: -4px;
		}
	}

	&__controls {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 12px;

		&-left {
			display: flex;
			align-items: center;
		}

		&-right {
			display: flex;
			align-items: center;
		}

		&-btn {
			&_inactive {
				color: rgba(@black4, 0.6);
			}
		}
	}

	&__preselected-period-btn {
		margin-right: 8px;
	}

	&__custom-period-btn {
		margin-right: 8px;
	}

	&__comparison-btn {
		margin-right: 24px;
	}

	&__filters-btn {
		&-icon {
			font-size: 20px;
		}
	}

	&__reset-btn {
		margin-left: 8px;
	}

	&__export-btn {
		&-icon {
			font-size: 20px;
		}
	}

	&__grouping-btn {
		width: 100%;
	}

	&__tabs {
		margin-top: 32px;

		&-side-column {
			top: 8px;
		}

		&-content-panel {
			&:nth-child(2) {
				padding-top: 0;
			}
		}
	}
	&__deleted-title {
		color: @black45;
	}
	&__deleted-tag {
		background: @grey10;
		border-radius: 6px;
		height: 28px;
		padding-top: 5px;
		padding-bottom: 5px;
		color: @black100;

		&-icon {
			color: @grey;
			font-size: 14px;
		}

		&-label {
			.d-regular-12() !important;
		}
	}
}
