@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.tags-cont {
	&__wrap {
		display: flex;
		flex-wrap: wrap;
		margin: -4px;

		& > .tag {
			flex: none;
			max-width: 100%;
			margin: 4px;
		}
	}
}