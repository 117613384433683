@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.dates {
	&__date {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		&:not(:first-of-type){
			margin-top: 10px;
		}
	}

	&__label {
		margin-right: auto;
		max-width: 190px;
		color: @black85;
		.d-regular-12();
		padding-right: 8px;
	}

	&__value-container{
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	&__icon {
		font-size: 16px;
		height: fit-content;
		color: @red70;
	}

	&__value {
		color: @black4;
		.d-semibold-14();
		flex-shrink: 0;
		margin-left: 8px;
		&_expired {
			color: @red70;
		}
	}
}
