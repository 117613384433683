@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-group-items {
	&__header {
		display: flex;
		align-items: center;

		&-actions {
			margin-left: auto;
		}
	}

	&__body {

	}

	&__sub-items {
		margin-top: 21px;
	}

	&__item {
		display: grid;
		grid-gap: 6px;
		grid-template-columns: auto 44px;
		grid-template-areas:
			"input actions"
			"add none"
			"sub-items sub-items";

		&-input {
			grid-area: input;
		}

		&-remove-button {
			grid-area: actions;
		}

		&-add-button {
			grid-area: add;
			color: @black45;
			margin-top: 6px;
			margin-left: 10px;
		}
	}

	&__sub-items {
		grid-area: sub-items;
	}

	&__sub-items &__item {
		margin-left: 30px;
	}
}