@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.delegation-dialog {
	&__content {
		@media (min-width: @tablet-min) {
			width: 600px;
		}

		@media (min-width: @desktop-min) {
			width: 768px;
		}

		@media (min-width: @tablet-min) and (min-height: 432px) {
			min-height: 400px
		}

		@media (min-width: @desktop-min) and (min-height: 632px) {
			min-height: 600px
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
		overflow-y: hidden;
	}

	&__controls {
		display: flex;
		margin-bottom: 16px;
	}

	&__search-controls {
		margin-bottom: 16px;
	}

	&__view-buttons {
		display: flex;
		flex-wrap: wrap;
		row-gap: 16px;
	}

	&__view-button {
		margin: 0 8px 0 0;
	}

	&__add-user-button {
		margin: 0 0 0 auto;
	}

	&__tree {
		flex: 1 1 auto;
		overflow-y: auto;
		border-top: 1px solid @black10;
		border-bottom: 1px solid @black10;

		@media (max-width: @tablet-min - 1px) {
			margin: 0 -16px;
		}
	}
}