@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";

.stats-filters-dialog {
	width: calc(100% - 3em);
	height: calc(100% - 3em);

	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	@media (min-width: @tablet-min) {

	}

	@media (min-width: @large-desktop-min) {
		max-width: 165em;
	}

	&__body {
		display: flex;
		flex-direction: column;
		padding-left: 10em;
		padding-right: 10em;
		overflow-y: hidden;
	}

	&__tags {
		flex: none;
		min-height: 3.4em;
		max-height: 13.6em;
		margin-bottom: 2em;
		overflow: auto;
	}

	&__tabs {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: hidden;

		&-links {
			flex: none;
		}

		&-content {
			display: flex;
			flex-direction: column;
			flex: 1 1 100%;
			padding-top: 2em;
			overflow-y: hidden;
		}
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
		padding-top: 2em;
		overflow-y: hidden;
	}

	&__controls {
		flex: none;
		margin-bottom: 2em;
	}

	&__tree-wrap {
		flex: 1 1 100%;
		overflow-y: auto;
	}

	&__tree {
		display: grid;
		grid-template-columns: 48% 48%;
		grid-row-gap: 3em;
		grid-column-gap: 4%;
	}

	&__action-buttons {
		margin: 1em 10em 0;

		@media (min-width: @large-mobile-min) {
			margin-top: 1.5em;
		}

		@media (min-width: @tablet-min) {
			margin-top: 2em;
		}
	}
}