@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.m-space-type-decoration-cell {
	display: flex;
	flex-direction: row;
	border-top: 1px solid @black10;

	&__wrapper {
		display: flex;
		flex-direction: row;
		padding: 12px 16px;
	}

	&__view {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__text {
		margin-left: 12px;
	}

	&__title {
		.d-semibold-14();
		margin-top: 3px;
	}

	&__company {
		color:@black100;
		font-size: .75rem;

		span {
			margin-left: 3px;
			color:@black60;
		}
	}

	&__objects {
		color:@black100;
		font-size: .75rem;

		.objectTag {
			margin: 0 2px 2px 0;
			background-color: @appBackground;
			padding: 1px 6px 2px 6px;

			&:first-child {
				margin-left: 2px;
			}
		}
	}

	&__date {
		.d-regular-12();
		margin-top: 2px;
		display: flex;
	}
	&__name {
		.d-regular-12();
		margin-left: 8px;
		color:@black60;
	}
	&__menu-cell {
		margin-left: auto;
		padding: 0 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
