@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.linear-loader {
	position: relative;
	width: 100%;
	height: 3px;
	border-radius: 3px;
	overflow: hidden;

	&__progress {
		position: absolute;
		height: 100%;
	}

	&_looped > &__progress {
		width: 60px;
		will-change: left;
		animation: linear-loader-move-animation 2.4s linear infinite;
	}

	&_definite > &__progress {
		left: 0;
		transition: 0.2s ease width;
		will-change: width;
	}
}

@keyframes linear-loader-move-animation {
	0% {
		left: -60px;
	}
	100% {
		left: 100%;
	}
}