@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.pagination-page-size {
	color: @black75;
	text-align: right;

	&__link {
		font-weight: 600;
	}
}