@import (reference) "../../styles/colors";
@import (reference) "../../styles/variables";

.attachment-preview {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 2px;
	overflow: hidden;
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	font-size: 10px;

	@media (min-width: @large-tablet-min) {
		font-size: 11px;
	}

	&::before {
		content: "";
		display: block;
		position: relative;
		z-index: 1;
		padding-top: 100%;
		border-radius: 2px;
		transition: background-color 0.2s ease;
	}

	&_clickable {
		cursor: pointer;

		.desktop &:hover::before {
			background-color: rgba(@blue, 0.3);
		}

		&:active::before {
			background-color: rgba(saturate(darken(@blue, 8%), 4%), 0.5) !important;
		}

		.desktop &.attachment-preview_error:hover::before {
			background-color: rgba(@red, 0.3);
		}

		&.attachment-preview_error:active::before {
			background-color: rgba(saturate(darken(@red, 8%), 4%), 0.5) !important;
		}
	}

	&_error::before {
		background-color: rgba(@red, 0.5);
	}

	&__img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__file {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 8%;

		&-icon {
			flex: 1 1;
		}

		&-name-wrap {
			display: flex;
			align-items: center;
			flex: none;
			height: 3.6em;
			margin-top: 10%;
		}

		&-name {
			max-height: 100%;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-size: 1.2em;
			line-height: 1.5em;
			text-align: center;
			color: @black2;
			overflow: hidden;
			word-break: break-word;
		}
	}
}
