@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.auth-form {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: relative;
	width: 456px;
	background: @white;
	border-radius: 8px;
	box-shadow: 0 10px 14px rgba(31, 52, 79, 0.05);
	overflow: hidden;
	padding: 56px 64px;
	font-family: 'Open Sans', sans-serif;

	@media (max-width: @large-mobile-min - 1px) {
		margin: 0 16px;
		padding: 32px 24px;
		max-width: 456px;
		width: auto;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 40px;

		&_center {
			justify-content: center;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 28px;
		color: @black75;
	}

	&__lng-select {
		margin-left: 8px;
		text-align: right;
	}

	&__form {
		&-email {
			margin-bottom: 16px;
		}

		&-email, &-password {
			font-size: 14px;
		}

		&-label {
			display: block;
			margin-bottom: 10px;
			font-weight: 400;
			color: @black75;
		}

		&-icons {
			color: @grey;
		}
	}

	&__recovery {
		margin-top: 12px;

		&-btn-label {
			font-weight: 400;
		}
	}

	&__remember {
		margin-top: 12px;
	}

	&__error {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-top: 24px;
		padding: 12px 0;
		color: @red70;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		&-icon {
			font-size: 20px;
			color: #FD2942;
		}
	}

	&__submit {
		margin-top: 64px;
	}

	&__divider {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 14px 0;
		color: @black75;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		&::after, &::before {
			content: '';
			position: relative;
			width: 50%;
			height: 1px;
			background-color: @grey20;
		}

		&-text {
			margin: 0 19px;
		}
	}

	&__providers {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 12px;
	}

	&__registration {
		display: flex;
		justify-content: center;

		&-btn {
			&-label {
				font-weight: 700;
			}
		}
	}

	&__providers + &__registration {
		margin-top: 24px;
	}
}
