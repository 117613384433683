@import (reference) '~@tehzor/ui-components/src/styles/colors';

.editable-problem-tags-set {
	& > div:not(:last-child) {
		margin-bottom: 25px;
	}

	& > div:last-child {
		margin-bottom: 34px;
	}

	&__warning {
		color: @primaryRed;
		margin-bottom: 20px;
	}
	&__buttons {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
	}
}
