@import (reference) "~@tehzor/ui-components/src/styles/colors";

.editable-owner-is-not-claimer-checkbox {

    margin-top: 16px;

    &__label {
        margin-left: 8px;
        color: @black45;
    }
}