@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/variables";

.mpv-close-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 10;
	min-width: 40px;
	min-height: 40px;
	border: none;
	border-radius: 8px;
	color: @white;
	transition: background-color 0.2s ease;

	button& {
		background-color: rgba(0, 0, 0, 0.5);;
	}

	@media (max-width: @tablet-min - 1px) {
		top: 16px;
		right: 16px;
	}

	@media (min-width: @tablet-min) {
		top: 24px;
		right: 24px;
	}

	&:active {
		background-color: rgba(0, 0, 0, 0.3) !important;
	}

	&__icon {
		line-height: 1em;

		i {
			display: block;

			&::before {
				display: block;
			}
		}
	}
}