@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.wrapper {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid @black20;
}

.rowSelect {
	margin: 12px 0 0 18px
}

.container {
	padding: 12px 16px;
}

.view {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text {
	margin-left: 6px;
}

.category {
	margin-top: 4px;
}

.checkLists {
	margin-top: 4px;
}

.title {
	.d-semibold-14();
}

.stats {
	margin-left: -8px;
}

.type {
	.d-semibold-14();
	color: @black60;
	font-weight: 400;
	font-size: 12px;
}

.tagContainer {
	margin-left: -9px;
}