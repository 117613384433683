@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.categories-pie-chart {
	position: relative;

	&__circle {
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(64% - 18px);
		height: calc(64% - 18px);
		transform: translate(-50%, -50%);
		background-color: transparent;
		border-radius: 50%;
		box-shadow: 0 7px 7px rgba(@black4, 0.15);
		pointer-events: none;
	}
}