@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.user-avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	user-select: none;

	&_coloring {
		&_background {
			background-color: @black05;
			color: @black100;
		}

		&_text {
			background-color: @grey10;
		}
	}

	&_size {
		&_32 {
			width: 32px;
			height: 32px;
			.d-semibold-14();
		}

		&_34 {
			width: 34px;
			height: 34px;
			padding-bottom: 1px;
			.d-semibold-14();
		}

		&_40 {
			width: 40px;
			height: 40px;
			padding-bottom: 1px;
			.d-semibold-16();
		}

		&_56 {
			width: 56px;
			height: 56px;
			padding-bottom: 2px;
			.d-semibold-24();
		}
	}

	&_dark {
		color: @white;
	}
}