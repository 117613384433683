@import (reference) '../../../styles/typography';

.read-more {
	.d-regular-12();

	&:not(:first-child) {
		margin-top: 2px;
	}
	&-button .inline-button__label {
		.d-semibold-12();
	}
}