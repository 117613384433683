@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.warranty-claims-table {
	&__stats-cell {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&-number {
			color: @black100;
			.d-semibold-14();
		}

		&-stats {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
		}
	}

	&__created-cell {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: @black100;
	}

	&__modified-cell {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: @black100;
	}

	&__space-cell {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: @black100;
	}

	&__menu-cell {
		margin: 0;
		padding: 8px 28px 8px 8px;

		opacity: 0;
		background-color: transparent;

		transition:
			background-color 0.2s,
			opacity 0.2s ease;

		&-wrap {
			display: flex;
			justify-content: space-between;
		}
	}

	&__mobile-cell {
		.me-table__cell-header {
			margin-bottom: 0;
		}

		&-content {
			display: flex;
			gap: 16px;

			&-list {
				display: flex;
				flex-direction: column;

				&-number {
					.d-semibold-14();

					margin-bottom: 8px;
					color: @black100;
				}

				&-registration-date {
					margin-bottom: 6px;
					font-size: 12px;
					color: @black60;

					&-text {
						color: @black100;
					}
				}

				&-space-owner {
					margin-bottom: 8px;
					font-size: 12px;
					color: @black100;

					&-title {
						color: @black60;
					}
				}

				&-status {
					margin-bottom: 8px;
				}

				&-space {
					display: flex;
					margin-bottom: 6px;
					font-size: 12px;
					color: @black100;
				}

				&-object {
					.d-semibold-12();

					margin-bottom: 6px;
				}
			}
		}
	}

	& .table2__row {
		@media (min-width: @large-mobile-max) and (max-width: @large-tablet-max) {
			padding: 12px;
		}
		@media (max-width: @large-mobile-max) {
            padding: 10px 16px 26px;
        }
	}
	& .me-table__cell-header {
		display: none;
	}

	& .table2__row_clickable {
		&:hover .warranty-claims-table__menu-cell {
			opacity: 1;
			background-color: @buttonLightHover;
		}

		&:active .warranty-claims-table__menu-cell {
			background-color: @buttonLightPressed;
		}
	}
}
