@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/typography';

.colorpicker {
	&__preset {
		max-width: 200px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&-button {
			margin: 4px;
			width: 24px;
			height: 24px;
			border-radius: 3px;
            border: 1px solid @black45;
		}
	}
}

