@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/variables";

.vertical-tab-link {
	width: 100%;
	padding: 0;
	color: rgba(@black4, 0.6);
	white-space: normal;
	transition: background-color 0.2s ease, color 0.2s ease;
	border-radius: 14px;
	font-size: 9px;

	@media (min-width: @large-tablet-min) {
		font-size: 10px;
	}

	&:not(:last-child) {
		margin-bottom: 1.6em;
	}

	.desktop &:hover {
		color: rgba(@black4, 0.75);
	}

	&:active {
		color: @black4 !important;
	}

	&_active {
		color: @black4 !important;
		background-color: @white;
	}

	&__wrap {
		padding: 2.2em 3.2em 2.2em 2em;
	}

	&__label {
		font-size: 1.4em;
		line-height: 1.5em;
		font-weight: 600;
		text-align: left;
	}
}