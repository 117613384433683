@import (reference) "~@tehzor/ui-components/src/styles/typography";
@import (reference) "~@tehzor/ui-components/src/styles/colors";

.devices-history-dialog {
	&__plate {
		margin: 10px 0px;

		&-card {
			&:not(&:last-child) {
				border-bottom: 1px solid @grey20;
				margin-bottom: 20px;
				padding-bottom: 10px;
			}

			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			gap: 10px;

			&__content {
				.d-regular-14();

				&-item {
					padding: 5px 0px;

					span {
						.d-semibold-14();
					}

					&:has(.tz-notifications-status) {
						span {
							color: @statusFixed;
						}
					}
					.tz-notifications-status {
						color: @statusFixed;
						padding-right: 5px;
					}
				}
			}
		}
	}

	.dialog__footer {
		display: flex;
		flex-direction: column;
	}
}