@import (reference) "../../../styles/colors";

.dnd-list {
	display: flex;
	flex-direction: column;
	user-select: none;

	&__empty-item {
		padding: 7px 8px;
		margin: 2px;

		&.isOver {
			margin: 0px;
			border: 2px solid @buttonAccentHover;
		}
	}

	&__item {
		display: flex;
		gap: 8px;

		&.isDragging {
			opacity: .5;
		}

		&-container {
			padding: 7px 0px;
			margin: 2px;

			&.isOver {
				position: relative;

				&::after {
					content: '';

					opacity: 1;
					width: 9px;
					height: 9px;
					border-radius: 100%;
					position: absolute;
					left: -3px;
					background-color: @buttonAccentHover;
				}

				&::before {
					content: '';

					opacity: 1;
					width: 100%;
					height: 2px;
					position: absolute;
					background-color: @buttonAccentHover;
				}

				&.top {
					&::after {
						top: -7px;
					}

					&::before {
						top: -3px;
					}
				}

				&.center {
					margin: 0px;
					border: 2px solid @buttonAccentHover;

					&::after {
						content: none;
					}

					&::before {
						content: none;
					}
				}

				&.bottom {
					&::after {
						bottom: -6px;
					}

					&::before {
						bottom: -3px;
					}
				}
			}
		}

		&-handle {
			color: @black60;
		}
	}
}