@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.date-picker-result {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	padding: 8px 4px;
	background-color: @buttonPressed;
	border-radius: 8px;

	&__value {
		flex: 1 1 100%;
		.tm-semibold-13();
		color: rgba(@black4, 0.85);
		text-align: center;
	}

	&__placeholder {
		flex: 1 1 100%;
		.tm-semibold-13();
		color: rgba(@black4, 0.35);
		text-align: center;
	}

	&__separator {
		transform: rotate(-90deg);
		color: @grey;
	}
}