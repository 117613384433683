@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables.less';

.table-head-cell {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	position: relative;
	padding: 24px 18px;
	word-break: break-word;
	overflow: hidden;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@media (max-width: @large-desktop-min) {
		padding: 12px;
	}

	&__children {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	&_with-border {
		&:not(:last-child) {
			border-right: 1px solid @black05;
		}
	}

	&__sort-icon {
		display: block;
		margin-left: 16px;
		color: @grey;

		&_asc {
			transform: scale(1, -1);
		}
	}
}
