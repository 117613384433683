@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";

.offline-data-item {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 8px;
	align-items: center;

	&__title-block {
		white-space: nowrap;
		overflow: hidden;
	}

	&__title {
		color: @black100;
		overflow-x: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
	}

	&__subtitle {
		margin-top: 2px;
		color: @black60;
		overflow-x: hidden;
		text-overflow: ellipsis;
		.d-regular-12();

		&_error {
			color: @red70;
		}
	}

	&__icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-width: 40px;
		min-height: 40px;
		color: @grey40;
	}

	&__icon-error-mark {
		position: absolute;
		top: 6px;
		right: 6px;
		padding: 3px;
		background-color: @white;
		border-radius: 50%;

		&::after {
			content: '';
			display: block;
			width: 5px;
			height: 5px;
			background-color: @red70;
			border-radius: 50%;
		}
	}

	&__progress {
		margin-top: 4px;
	}
}