@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.problems-stats-chart {
	&_type {
		&_bar {

		}

		&_pie {
			display: flex;
			align-items: center;
		}
	}

	&__pie-chart {
		flex: none;
		width: 144px;
		margin-left: 64px;

		&_level {
			&_0 {
				width: 208px;
				margin-left: 0;
			}

			&_1 {
				width: 176px;
				margin-left: 32px;
			}
		}
	}

	&__bar-chart-placeholder {
		height: 56px;
	}

	&_type_bar &__cards {
		margin-top: 16px;
	}

	&_type_pie &__cards {
		flex: 1 1 100%;
		margin-left: 36px;

		@media (min-width: @desktop-min) {
			margin-left: 56px;
		}
	}
}