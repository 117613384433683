@import (reference) "~@tehzor/ui-components/src/styles/colors";
@import (reference) "~@tehzor/ui-components/src/styles/variables";
@import (reference) "~@tehzor/ui-components/src/styles/typography";

.stats-no-data {
	padding: 20px 0;
	.d-semibold-14();
	color: @grey;

	&__tab-content {
		display: none;

		&:nth-of-type(2) {
			display: block;
		}
	}
}