@import (reference) '~@tehzor/ui-components/src/styles/typography';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.check-item-detail {
	height: 100%;

	&__container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__scrollable {
		overflow-y: auto;
	}

	&__header {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-areas: 'text actions';
		padding: 10px 24px;
		border-bottom: solid 1px #e8eaec;
		align-items: center;

		&-text {
			grid-area: text;
			.d-semibold-16();
			color: @black100;
		}

		&-actions {
			grid-area: actions;

			&-btn i {
				font-size: 20px;
			}
		}
	}

	&__action {
		border-top: solid 1px #e8eaec;
		padding: 16px 24px;
		margin-top: auto;

		&-btn {
			width: 100%;
		}
	}

	&__item {
		padding: 16px 24px;
	}

	&__problems {
		position: relative;
		padding: 0 20px;
	}

	&__content {
		height: 100%;
	}

	&__no-content {
		height: 790px;
	}

	&__list {
		&-filter {
			display: flex;
			justify-content: space-between;
		}
	}
}
