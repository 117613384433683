@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/typography";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/shadows";

@class: ~'.d-sidebar';

@{class} {
	display: inline-block;
	position: relative;
	width: auto;
	z-index: 1100;
	background-color: @white;
	box-shadow: @shadow1;

	&__anim-area {
		width: 72px;
		height: 100%;
		overflow: hidden;
		transition: width 0.2s cubic-bezier(0.32, 0.72, 0.37, 0.95);

		&_expanded {
			width: 288px;
		}
	}

	&__cont {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 72px;
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: width 0s ease 0.2s;

		@{class}_expanded & {
			width: 288px;
			transition: width 0s ease;
		}

		.simplebar-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: stretch;
			height: 100%;
		}
	}

	&__menu-wrap {
		flex: auto;
		padding: 8px 0;
	}

	.sidebar-menu-item__label {
		display: none;
		transition: visibility 0s linear, color 0.2s ease;
	}

	&_expanded .sidebar-menu-item__label {
		display: block;
	}

	&:not(&_expanded) .sidebar-menu-separator {
		margin-left: 8px;
		margin-right: 8px;
	}

	&__info-wrap {
		flex: none;
		padding: 16px;
	}

	&__toggle-area {
		position: absolute;
		top: 0;
		right: -24px;
		width: 24px;
		height: 100%;
	}

	&__toggle-btn {
		position: absolute;
		bottom: 88px;
		right: -16px;
		z-index: 2;
		opacity: 0;
		transition: opacity 0.2s ease 0.6s;
	}

	&:hover &__toggle-btn {
		opacity: 1;
		transition: opacity 0.2s ease;
	}
}