@import (reference) "../../../../../styles/colors";
@import (reference) "../../../../../styles/typography";

.entity-select {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	&__btn {
		color: @black85;
		&.active { color: @black100; }
	}
}