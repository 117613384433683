@import (reference) "~@tehzor/ui-components/src/styles/variables";

.app-content {
	position: relative;
	display: flex;
	flex-direction: column;

	@media (max-width: @tablet-min - 1px) {
		min-height: calc(100% - 56px);
	}

	@media (min-width: @tablet-min) {
		min-height: 100%;
	}

	&_padding {
		&_56 {
			margin-left: 56px;
		}

		&_72 {
			margin-left: 72px;
		}

		&_288 {
			margin-left: 288px;
		}
	}
}
