@import (reference) '../../../styles/colors';
@import (reference) '../../../styles/variables';
@import (reference) '../../../styles/typography';

.status-button {
	display: block;
	position: relative;
	padding: 5px 12px 6px 12px;
	border-radius: 6px;
	overflow: hidden;
	transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease,
		opacity 0.2s ease;

	&_disabled {
		opacity: 0.8;
		cursor: default !important;
	}

	&--multiline {
		max-width: 100%;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-color: transparent;
		transition: background-color 0.2s ease;
	}

	&_dark {
		color: @white;
	}

	&_light {
		color: @black95;
	}

	&:not(&_disabled) {
		.desktop &:hover::after {
			background-color: rgba(255, 255, 255, 0.15);
		}

		&:active::after {
			background-color: rgba(0, 0, 0, 0.08) !important;
		}
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		width: auto;
		height: 100%;
		z-index: 1;
	}

	&__loader {
		margin-left: 8px;
		width: 16px !important;
	}

	&__arrow-icon {
		margin-left: 8px;
		transition: transform 0.2s ease;

		&_reversed {
			transform: rotate(180deg);
		}

		i {
			display: block;
		}
	}

	&_light &__arrow-icon {
		color: @grey;
	}

	&__label {
		max-width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		.d-semibold-14();

		&--multiline {
			white-space: normal; 
			word-wrap: break-word;
			text-align: left;
		}
	}
}
