@import (reference) '~@tehzor/ui-components/src/styles/colors';

.desktopControls {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.labelValuesClassName {
	color: @blue80;
}