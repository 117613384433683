@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";
@import (reference) "../../../styles/typography";

.problems-bar-chart {
	padding: 14px;
	background-color: @buttonHover;
	border-radius: 14px;

	&__total {
		display: flex;
		justify-content: flex-start;
		background-color: rgba(@black4, 0.05);
		border-radius: 8px;
	}

	&__bar {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 28px;
		border-radius: 4px;
		.d-semibold-14();
		color: @white;

		& + & {
			margin-left: 4px;
		}

		&:first-of-type {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		&:last-of-type {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
}