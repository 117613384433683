@import (reference) "../../../styles/colors";
@import (reference) "../../../styles/variables";

.pie-chart-placeholder {
	position: relative;
	animation: placeholder-animation 2s linear infinite;

	&__aspect-ratio {
		width: 100%;
		padding-top: 100%;
	}

	&__wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 16px;
	}

	&__circle {
		width: 100%;
		height: 100%;
		background-color: @buttonHover;
		border-radius: 50%;
	}

	&__hole {
		position: absolute;
		top: 50%;
		left: 50%;
		width: calc(64% - 18px);
		height: calc(64% - 18px);
		transform: translate(-50%, -50%);
		background-color: @white;
		border-radius: 50%;
	}
}