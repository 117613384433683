@import (reference) '~@tehzor/ui-components/src/styles/variables';
@import (reference) '~@tehzor/ui-components/src/styles/colors';

.tasks-dialog {
	@media (min-width: @tablet-min) {
		min-width: 512px;
	}

	&__body {
		padding-right: 10px;
	}

	&__content {
		width: 512px;

		@media (max-width: @tablet-min) {
			width: 100%;
		}
	}

	&__title {
		display: flex;
		gap: 8px;

		&--counter {
			@media (max-width: @large-mobile-max) {
				color: @black4;
			}
			color: @black35;
		}
	}
}
